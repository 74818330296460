import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  Form,
  Input,
  //Radio,
  Select,
  DatePicker,
} from "antd";
import debounce from "lodash/debounce";
import moment from "moment";
import { useSelector } from "react-redux";
import { selectNations } from "redux/selectors/common";

// import allNations from "data/nations.json";

function CustomerFields({
  prefix,
  clientType = "privato",
  //onSetClientType,
  mode,
  locale,
  onSearchCity = () => {},
  onSelectedCity,
  cityOptions,
  validating,
}) {
  const [provinceDisabled, setProvinceDisable] = useState(false);

  const nations = useSelector(selectNations);

  const nationsByLabel = useMemo(() => {
    return nations.map(({ name, code, abbr }) => ({
      value: name,
      label: name,
      code,
      abbr,
    }));
  }, [nations]);

  return (
    <>
      {/* <h3 style={{ marginBottom: 16, color: '#0a3e4b' }}>CONTATTI</h3> */}
      {mode !== "second_half" && (
        <>
          {/* <Form.Item label="Tipo cliente" name={[prefix,"type"]}>
              <Radio.Group onChange={(e) => onSetClientType(e.target.value)} disabled>
                <Radio.Button value="privato">Privato</Radio.Button>
                <Radio.Button value="business">Business</Radio.Button>
              </Radio.Group>
            </Form.Item> */}

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                name={[prefix, "first_name"]}
                label={clientType === "privato" ? "Nome" : "Denominazione"}
                rules={[
                  {
                    required: true,
                    message: "Campo obbligatorio",
                  },
                ]}
              >
                <Input size="large" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={[prefix, "last_name"]}
                label="Cognome"
                rules={[
                  {
                    required: clientType === "privato",
                    message: "Campo obbligatorio",
                  },
                ]}
              >
                <Input size="large" disabled={clientType === "business"} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                name={[prefix, "phone_number"]}
                label="Telefono"
                rules={[
                  {
                    required: true,
                    message: "Campo obbligatorio",
                  },
                  {
                    pattern: new RegExp(/(3{1}[2-9]{1}[0-9]{1})(\d{6,7})/),
                    message: "Numero di cellulare non valido",
                  },
                ]}
              >
                <Input size="large" addonBefore="+39" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={[prefix, "email"]}
                label="E-mail"
                rules={[
                  {
                    required: true,
                    message: "Campo obbligatorio",
                  },
                ]}
              >
                <Input size="large" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item
                name={[prefix, "home_phone_number"]}
                label={"Telefono fisso"}
                rules={[
                  {
                    pattern: new RegExp(/0{1}\d{6,12}/),
                    message: "Telefono fisso non valido",
                  },
                ]}
              >
                <Input size="large" />
              </Form.Item>
            </Col>
          </Row>
        </>
      )}
      {mode !== "first_half" && (
        <>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                name={[prefix, "born_country"]}
                label="Nazione di nascita"
                initialValue="Italia"
                rules={[
                  {
                    required: true,
                    message: "Campo obbligatorio",
                  },
                ]}
              >
                <Select
                  showSearch
                  size="large"
                  options={nationsByLabel}
                  onSelect={(value, option) => {
                    if (option.value === "Italia") {
                      setProvinceDisable(false);
                    } else setProvinceDisable(true);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              {!provinceDisabled && (
                <Form.Item
                  name={[prefix, "born_city"]}
                  label="Luogo di nascita"
                  // hidden={provinceDisabled}
                  hasFeedback
                  validateStatus={validating?.city && "validating"}
                  rules={[
                    {
                      required: provinceDisabled ? false : true,
                      message: "Campo obbligatorio",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    allowClear
                    size="large"
                    showArrow={false}
                    defaultActiveFirstOption={false}
                    placeholder="Cerca il comune"
                    onSearch={debounce(onSearchCity, 700)}
                    onSelect={(value, option) => {
                      onSelectedCity(prefix, {
                        born_province: option.sigla,
                        born_city_code: option.code,
                      });
                    }}
                    notFoundContent={null}
                    options={cityOptions}
                    autoComplete="new-password"
                  />
                </Form.Item>
              )}
            </Col>
          </Row>
          <Col span={6}>
            <Form.Item
              name={[prefix, "born_province"]}
              label="Provincia di nascita"
              hidden={true}
              rules={[
                {
                  required: provinceDisabled ? false : true,
                  message: "Campo obbligatorio",
                },
              ]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name={[prefix, "born_city_code"]}
              label="Codice catastale"
              hidden={true}
            >
              <Input size="large" />
            </Form.Item>
          </Col>

          <Row gutter={[16, 16]}>
            {/* <Col span={12}>
                <Form.Item 
                  name={[prefix,"born_province"]}
                  label="Provincia"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: 'Campo obbligatorio'
                  //   },
                  // ]}
                >
                  <Input
                    size="large"
                    maxLength={2}
                  />
                </Form.Item>
              </Col> */}

            <Col span={12}>
              <Form.Item
                name={[prefix, "born_at"]}
                label="Data di nascita"
                rules={[
                  {
                    required: true,
                    message: "Campo obbligatorio",
                  },
                ]}
              >
                <DatePicker
                  size="large"
                  style={{ width: "100%" }}
                  locale={locale}
                  format={"DD/MM/YYYY"}
                  defaultPickerValue={moment().subtract(18, "year")}
                  disabledDate={(current) =>
                    current > moment().subtract(18, "year")
                  }
                  placeholder={"DD/MM/YYYY"}
                  showToday={false}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={[prefix, "gender"]}
                label="Genere"
                rules={[
                  {
                    required: true,
                    message: "Campo obbligatorio",
                  },
                ]}
              >
                <Select
                  size="large"
                  options={[
                    { label: "Femminile", value: "female" },
                    { label: "Maschile", value: "male" },
                    { label: "Non definito", value: "not_specified" },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                name={[prefix, "fiscal_code"]}
                label={
                  clientType === "privato" ? "Codice fiscale" : "Partita iva"
                }
                rules={[
                  {
                    required: true,
                    message: "Campo obbligatorio",
                  },
                  {
                    pattern: new RegExp(
                      "^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$"
                    ),
                    message: "Codice fiscale non valido",
                  },
                ]}
              >
                <Input size="large" />
              </Form.Item>
            </Col>
          </Row>
        </>
      )}
    </>
  );
}

CustomerFields.defaultProps = {
  disabled: false,
  mode: "full",
  clientType: "privato",
};

CustomerFields.propTypes = {
  prefix: PropTypes.string,
  disable: PropTypes.bool,
  initialValues: PropTypes.object,
  mode: PropTypes.string,
  locale: PropTypes.object,
  clientType: PropTypes.string,
  onSetClientType: PropTypes.func,
  onSearchCity: PropTypes.func,
  onSelectedCity: PropTypes.func,
  cityOptions: PropTypes.array,
  validating: PropTypes.object,
};

export default CustomerFields;
