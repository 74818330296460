import React, { 
  useState, 
  useEffect 
} from 'react';
//import PropTypes from 'prop-types';
import { 
  Steps, 
  Layout, 
  Result, 
  Button,
  PageHeader
} from 'antd';
import Coverage from './_Coverage/Coverage';
import PaperContract from './_Contract/Paper';
import DigitalCustomer from './_Contract/DigitalCustomer';
import DigitaData from './_Contract/DigitalData';
import Verify from './_Verify/Verify';
import Signature from './_Signature/Signature';
import Payment from './_Payment/Payment';
//import Validate from './_Validate/Validate';

import LoadingFullwidth from 'components/Loading/Fullwidth';

import './ContractsEdit.css';
import {
  useLocation,
  useHistory
} from "react-router-dom";
import { useDispatch } from 'react-redux';
import { 
  getCommercialOffers,
  getContractToActivateDetails
} from 'redux/reducers/contractsReducer';

function ContractsEdit() {
  const { Step } = Steps;
  const dispatch = useDispatch()

  let history = useHistory();
  const params = new URLSearchParams(useLocation().search)
  const [state, setState] = useState({
    currentStep: -1,
    contractFormat: useLocation().state?.format || 'digital',
    leadId: useLocation().state?.leadId,
    allOffers: []
  })
  //console.log('useLocation()',useLocation())

  const locales = {
    'digital': 'digitale',
    'on_paper': 'cartaceo'
  }

  const stepsStateMap = {
    'digital': {
      'created': 2,
      'lead_otp_confirmed': 3,
      //'data_filled': 4,
      'payment_submitted': 4
    },
    'on_paper': {
      'created': 2,
      'papers_generated': 3,
      'papers_uploaded': 4
    }
  }

  const stepContentMap = {
    'digital': {
      0: {
        title: 'Copertura',
        content: (
          <Coverage 
            onSuccess={() => next()} 
            onLeadSubmit={() => history.push('/contratti')}
          />
        )
      },
      1: {
        title: 'Cliente',
        content: (
          <DigitalCustomer 
            allOffers={state.allOffers}
            onSuccess={() => next()} 
            onCancel={() => prev()} 
          />
        )
      },
      2: {
        title: 'Verifica OTP',
        content: <Verify onSuccess={() => next()} />
      },
      3: {
        title: 'Contratto',
        content: <DigitaData onSuccess={() => next()} />
      },
      // 4: {
      //   title: 'Pagamento',
      //   content: <Payment 
      //     contractFormat="digital"
      //     onSuccess={() => next()} 
      //     //onCancel={() => prev()} 
      //   />
      // },
      4: {
        title: 'Esito',
        content: <Result
          status="success"
          title="Contratto creato con successo!"
          subTitle="Continuare il processo di registrazione tramite il link sull'e-mail del cliente"
          extra={[
            <Button type="primary" key="console" onClick={() => history.push('/contratti')}>
              Torna ai contratti
            </Button>
          ]}
        />
      }
    },
    'on_paper': {
      0: {
        title: 'Copertura',
        content: (
          <Coverage 
            onSuccess={() => next()} 
            onLeadSubmit={() => history.push('/contratti')}
          />
        )
      },
      1: {
        title: 'Contratto',
        content: (
          <PaperContract 
            onSuccess={() => next()} 
            onCancel={() => prev()} 
          />
        )
      },
      2: {
        title: 'Pagamento',
        content: (
          <Payment 
            contractFormat="paper"
            onSuccess={() => next()} 
            //onCancel={() => prev()} 
          />
        )
      },
      3: {
        title: 'Documenti',
        content: (
          <Signature 
            onSuccess={() => next()}
            //onCancel={() => prev()} 
          />
        )
      },
      // 4: {
      //   title: 'Validazione',
      //   content: (
      //     <Validate 
      //       onSuccess={() => history.push('/contratti')}
      //       //onCancel={() => prev()} 
      //     />
      //   )
      // },
      4: {
        title: 'Esito',
        content: <Result
          status="success"
          title="Contratto caricato correttamente!"
          subTitle="In attesa di validazione da parte del customer care"
          extra={[
            <Button type="primary" key="console" onClick={() => history.push('/contratti')}>
              Torna ai contratti
            </Button>
          ]}
        />
      }
    }
  }
  
  useEffect(()=>{
    const newContractId = params.get('id')
    
    if(newContractId) {
      const getContract = async(id) => {
        const result = await dispatch(getContractToActivateDetails(id))
        //console.log('result', result)
        if(result.payload.data || result.payload.status <= 400) {
          //setCurrentStep(3)
          const contractFormat = result.payload.data.type
          
          setState({
            contractFormat: contractFormat,
            currentStep: stepsStateMap[contractFormat][result.payload.data.state]
          })
        }
        else {
          history.push('/contratti')
        }
      }
      
      getContract(newContractId)
    } else {
      const getOffers = async () => {
        const result = await dispatch(getCommercialOffers())
        console.log(result.payload)
        const allOffers = result.payload.data.reduce((acc,offer) => {
          acc.push({
            label: offer.name,
            value: offer.id
          })
           return acc
        },[])
  
        setState({
          ...state,
          currentStep: 0,
          allOffers: allOffers
        })
      }
      getOffers()
    }
    return () => {
      dispatch({ type: 'contracts/resetState'});
    }
  },[])

  const next = () => {
    //setCurrentStep(currentStep + 1)
    setState({
      ...state,
      currentStep: state.currentStep + 1
    })
  }

  const prev = () => {
    //setCurrentStep(currentStep - 1)
    setState({
      ...state,
      currentStep: state.currentStep - 1
    })
  }

  return (
    state.currentStep >= 0 ? (
      <>
        <PageHeader
          title={`Nuovo contratto ${locales[state?.contractFormat]}`}
          style={{ paddingBottom: 0 }}
        >
        </PageHeader>    
        <div className="pageContainer"> 
          <Steps current={state.currentStep} type="navigation">
            {
              Object
                .keys(stepContentMap[state.contractFormat])
                .map((key,i) => <Step key={i} title={stepContentMap[state.contractFormat][key].title} />)
            }
          </Steps>
          <Layout>
            {stepContentMap[state.contractFormat][state.currentStep].content} 
          </Layout>
        </div>
      </>
    ) : <LoadingFullwidth />
  )
}

// ContractsEdit.PropTypes = {
  
// }

export default ContractsEdit;