import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios";
import queryString from "query-string";
import { BASE_API_URL } from "config/constants";
import { removeEmptyOneLevel } from "utils/removeEmpty";

axios.defaults.withCredentials = true;
axios.defaults.baseURL = BASE_API_URL;

export const getTicketDetails = createAsyncThunk(
  'tickets/getTicketDetailsStatus',
  async (ticketId) => {
    try {
      const response = await axios.get(`/tickets/${ticketId}`)
      return response.data
    } catch (error) {
      return {
        status: error.response.status
      }
    }
    
  }
)

export const getTicketMotivations = createAsyncThunk(
  "tickets/getTicketMotivations",
  async (data) => {
    const response = await axios.get(`/tickets/motivations`);
    return response.data;
  }
);

export const getTicketEdit = createAsyncThunk(
  'tickets/getTicketEditStatus',
  async (ticketId) => {
    try {
      const response = await axios.get(`/tickets/${ticketId}`)
      //console.log("RESPONSE > > >",response)
      localStorage.setItem('ticketId',ticketId)
      return response.data
    } catch (error) {
      //console.log("ERROR RESPONSE > > >",error.response)
      //return rejectWithValue(error.response.data)
      return {
        status: error.response.status
      }
    }
    
  }
)

export const getTicketsList = createAsyncThunk(
  'tickets/getTicketsListStatus',
  async (data) => {
    try {
      const query = {
        page: data?.page?.current || 1,
        'filter[ticket_code]': data?.filters?.ticket_code?.data,
        'filter[customer.first_name]': data?.filters?.['customer.first_name']?.data,
        'filter[customer.last_name]': data?.filters?.['customer.last_name']?.data,
        'filter[contract.contract_code]': data?.filters?.['contract.contract_code']?.data,
        'filter[state]': data?.filters?.state_name?.data.value,
        'filter[created_between]': data?.filters?.created_at?.data,
        'filter[origin]': data?.filters?.origin_name?.data.value,
        'filter[triplets]': data?.filters?.support_cat?.data.value,
      }
      //console.log("BEFORE RESPONSE > > >")
      const response = await axios.get(`/tickets?${queryString.stringify(query)}`)
      response.data.filters = data.filters
      //console.log("RESPONSE > > >",response)
      return response.data
    } catch (error) {
      //console.log("ERROR RESPONSE > > >",error.response)
      //return rejectWithValue(error.response.data)
      return {
        status: error.response.status
      }
    }
  }
)


export const getCustomersTickets = createAsyncThunk(
  'tickets/getCustomersTicketsStatus',
  async (value) => {
    try {
      //console.log('getTicketList',pagination)
      const response = await axios.get(`/tickets/create/search-customer?filter[search]=${value}`)
      //console.log("RESPONSE > > >",response)
      return response.data
    } catch (error) {
      //console.log("ERROR RESPONSE > > >",error.response)
      //return rejectWithValue(error.response.data)
      return {
        status: error.response.status
      }
    }
  }
)

export const fetchTriplets = createAsyncThunk(
  'tickets/fetchTripletsStatus',
  async (value) => {
    try {
      const response = await axios.get(`/tickets/triplets`)
      return response.data
    } catch (error) {
      return {
        status: error.response.status
      }
    }
  }
)

// export const fetchTicketDetails = createAsyncThunk(
//   'tickets/fetchDraftStatus',
//   async (ticketId) => {
//     try {
//       const response = await axios.get(`/tickets/${ticketId}`)
//       return response.data
//     } catch (error) {
//       return {
//         status: error.response.status
//       }
//     }
//   }
// )

export const updateTicket = createAsyncThunk(
  'contracts/updateTicketStatus',
  async ({ticketId, values}, { getState }) => {
    try {
      const state = getState().tickets.newTicket?.draft?.state
      if(!values.support_cat_third_id) {
        values.support_cat_third_id = getState().tickets.newTicket.draft.support_cat_third_id
      }
      if(state === 'draft') {
        values.reminder = 0
      }
      //console.log('createNewTicket > ',type,values,newTicketsValues,valuesParsed)
      const response = await axios.post(`tickets/${ticketId}/update`, values)
      
      return response.data
    } catch (error) {
      //console.log(error)
      return {
        status: error.response.status,
        errors: error.response.data.errors
      }
      //return rejectWithValue(error.response.data)
    }
  }
)

export const createNewTicketDraft = createAsyncThunk(
  'contracts/createNewTicketDraftStatus',
  async ({values}, { getState }) => {
    try {
      const newTicketsValues = getState().tickets.newTicket.values
      const valuesParsed = {
        ...values,
        ...newTicketsValues
      }
      //console.log('createNewTicket > ',type,values,newTicketsValues,valuesParsed)
      const response = await axios.post(`tickets/create/draft`, valuesParsed)
      
      return response.data
    } catch (error) {
      //console.log(error)
      return {
        status: error.response.status,
        errors: error.response.data.errors
      }
      //return rejectWithValue(error.response.data)
    }
  }
)

export const createNewTicketFull = createAsyncThunk(
  'contracts/createNewTicketFullStatus',
  async ({values}, { getState, dispatch }) => {
    try {
      const newTicketsValues = getState().tickets.newTicket.values
      const valuesParsed = {
        ...values,
        ...newTicketsValues
      }

      if(valuesParsed.customer_registered === 0) {
        delete valuesParsed.contract_id;
        delete valuesParsed.customer_id;
      }

      console.log('createNewTicket > ',values,newTicketsValues,valuesParsed)
      const response = await axios.post(`tickets/create/full`, valuesParsed)

      window.location.reload();
     
      return response.data
    } catch (error) {
      //console.log(error)
      return {
        status: error.response.status,
        errors: error.response.data.errors
      }
      //return rejectWithValue(error.response.data)
    }
  }
)

export const createNewMotivation = createAsyncThunk(
  'tickets/createNewMotivationStatus',
  async (values, { getState }) => {
    try {
      const response = await axios.post(`tickets/motivations/create`, {
        name: values.motivation,
      })
      
      return response.data;
    } catch (error) {
      //console.log(error)
      return {
        status: error.response.status,
        errors: error.response.data.errors
      }
      //return rejectWithValue(error.response.data)
    }
  })

function isFulfilledAction(action) {
  return action.type.endsWith('/fulfilled')
}

function isPendingAction(action) {
  return action.type.endsWith('/pending')
}

function isRejectedAction(action) {
  return action.type.endsWith('/rejected')
}


const initialState = {
  loading: '',
  error: null,
  selected: null,
  list: [],
  newTicket: {
    triplets:[],
    values: {
      strong_identity: 0,
      foreign: {
        first_name: '',
        last_name: ''
      }
    }
  },
  isDrawerOpen: false,
  showNewTicket: false,
  showAlertWorking: false,
  actionBeforeAlert: '',
  workingTicketId: 0
}


const ticketsSlice = createSlice({
  name: 'tickets',
  initialState: initialState,
  reducers: {
    createNewTicketHalf(state, action) {
      const parsedValues = removeEmptyOneLevel(action?.values)
      console.log('createTicketHalf > ',action, parsedValues)
      state.newTicket.values = {
        ...state.newTicket.values,
        ...parsedValues
      }
    },
    setIsDrawerOpen(state, action) {
      state.isDrawerOpen = action.value
    },
    setShowNewTicket(state, action) {
      state.showNewTicket = action.value
    },
    setshowAlertWorking(state, action) {
      state.showAlertWorking = action.options.value
      state.actionBeforeAlert = action.options.actionName
      state.workingTicketId = action?.options?.params?.id || 0
    },
    leaveDraft(state) {
      state.newTicket.draft = undefined
      state.isDrawerOpen = false
      localStorage.removeItem('ticketId')
    },
    leaveDetails(state) {
      state.selected = null
    },
    handleSuccesAlertModal(state, action) {
      switch(state.actionBeforeAlert) {
        case 'LEAVE':
          state.isDrawerOpen = false
          state.newTicket.draft = undefined
          break;
        case 'CREATE': 
          state.showNewTicket = true
          state.newTicket.draft = undefined 
          break;
        // case 'EDIT': 
        //   console.log('getTicketID > ',state.workingTicketId)
        //   await getTicketEdit(state.workingTicketId)
        //   break;
      }
      state.actionBeforeAlert = ''
      state.showAlertWorking = false
      localStorage.removeItem('ticketId')
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getTicketsList.fulfilled, (state, action) => {
      if(action.payload) {
        const pagination = action.payload.meta
        state.list = action.payload.data
        state.pagination = {
          pageSize: pagination.per_page,
          current: pagination.current_page,
          total: pagination.total
        }
        state.filters = action.payload.filters
      }
    })
    builder.addCase(getTicketDetails.fulfilled, (state, action) => {
      if(action.payload) {
        state.selected = action.payload.data
      }
    })
    builder.addCase(getTicketEdit.fulfilled, (state, action) => {
      if(action.payload) {
        state.newTicket.draft = action.payload.data
        state.isDrawerOpen = true
        state.showAlertWorking = false
      }
    })
    builder.addCase(fetchTriplets.fulfilled, (state, action) => {
      if(action.payload) {
        state.newTicket.triplets = action.payload.data
      }
    })
    builder.addCase(getTicketMotivations.fulfilled, (state, action) => {
      if(action.payload) {
        state.ticketMotivations = action.payload.data
      }
    })
    builder.addCase(updateTicket.fulfilled, (state, action) => {
      if(action.payload) {
        state.newTicket.draft = action.payload.data
      }
    })
    // builder.addCase(fetchTicketDetails.fulfilled, (state, action) => {
    //   if(action.payload) {
    //     state.newTicket.draft = action.payload.data
    //   }
    // })
    builder.addCase(createNewTicketDraft.fulfilled, (state, action) => {
      if(action.payload) {
        state.newTicket.draft = action.payload.data
        state.isDrawerOpen = true
      }
    })
    builder.addMatcher(isFulfilledAction, (state, action) => {
      state.loading = ''
      state.error = false
    })
    builder.addMatcher(isPendingAction, (state, action) => {
      const id = typeof action.meta.arg === 'object' ? action.meta.arg.id : action.meta.arg
      state.loading = action.type.split('/')[1] + (id ? '_' +id : '')
      state.error = false
    })
    builder.addMatcher(isRejectedAction, (state, action) => {
      state.loading = ''
      state.error = action.error
    })
  }
})

const { 
  //actions, 
  reducer 
} = ticketsSlice
// export const {
//   updateContractsList
// } = actions


export default reducer