import React, { useEffect } from 'react';
//import moment from 'moment';
//import PropTypes from 'prop-types';
import { 
  useSelector,
  //useDispatch 
} from 'react-redux';
import { Link } from "react-router-dom";
import { 
  PageHeader,
} from 'antd';
//import { DownOutlined, UserOutlined } from '@ant-design/icons';
import Table from 'views/Tickets/TicketsList/_Table';
//import './ContractsList.css';

import { 
  getCustomersList
} from 'redux/reducers/customersReducer';

function CustomersList() {

  const { list, pagination, filters, loading } = useSelector(state => state.customers);
  //const dispatch = useDispatch()
  //let history = useHistory();

  //const params = new URLSearchParams(useLocation().search)
  //console.log('useParams',params.get('page'))
  const columns = [
    {
      title: 'Codice cliente',
      dataIndex: 'customer_code',
      search: true,
      render: (value,row) => <Link to={`/clienti/${row.id}`}>{value || '---'}</Link>,
    },
    {
      title: 'Stato',
      dataIndex: 'state_name'
    },
    {
      title: 'Nome',
      dataIndex: 'first_name',
      search: true
    },
    {
      title: 'Cognome',
      dataIndex: 'last_name',
      search: true
    },
    {
      title: 'Codice fiscale',
      dataIndex: 'fiscal_code',
      search: true,
    },
    {
      title: 'Telefono',
      dataIndex: 'phone_number',
      search: true
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      search: true
    },
    {
      title: 'Canale',
      dataIndex: 'channel_type',
      search: true,
      filterType: 'radio',
      filterOptions: [
        { text: 'Spontaneo', value: 'Spontaneo' },
        { text: 'Partner', value: 'Partner' },
        { text: 'Customer care', value: 'CustomerCare' },
      ]
    },
    {
      title: 'Tipo',
      dataIndex: 'type',
    }
  ];


  return (
    <div className="main-container">
      <PageHeader
        title="Elenco clienti"
        style={{ paddingBottom: 0 }}
      >
      </PageHeader>
      <Table 
        columns={columns}
        dataSource={list}
        pagination={pagination}
        filters={filters}
        loading={loading}
        getList={(data) => getCustomersList(data)}
      />
    </div>
  )
}

// ContractsList.PropTypes = {
  
// }

export default CustomersList;