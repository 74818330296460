import React from 'react';
import moment from 'moment';
//import PropTypes from 'prop-types';
import { 
  useSelector,
  //useDispatch 
} from 'react-redux';
import { Link } from "react-router-dom";
import { 
  //Menu,
  //Dropdown,
  PageHeader,
  //Button
} from 'antd';
//import { DownOutlined } from '@ant-design/icons';
import Table from 'views/Tickets/TicketsList/_Table';
import './ContractsList.css';

import { 
  getContractsListAwaiting
} from 'redux/reducers/contractsReducer';

function ContractsListWaiting() {
  
  const { list, pagination, filters, loading } = useSelector(state => state.contracts);
  const { userRole, states } = useSelector(state => state.auth);

  const columns = [
    {
      title: 'Cod. contratto',
      dataIndex: 'contract_code',
      search: true,
      //sorter: true,
      render: (value,row) => <Link to={`/contratti/${row.id}`}>{value}</Link>,
      //width: '20%',
    },
    {
      title: 'Canale',
      dataIndex: 'channel_type',
      hideForUsers: ['PARTNER']
    },
    {
      title: 'BP',
      dataIndex: 'bp_code',
      search: true,
      //render: value => value || '---',
      //hideForUsers: ['PARTNER']
    },
    {
      title: 'Nome',
      dataIndex: ['customer','first_name'],
      search: true
    },
    {
      title: 'Cognome',
      dataIndex: ['customer','last_name'],
      search: true
    },
    {
      title: 'Indirizzo',
      dataIndex: ['coverage','street_name'],
      render: (value,row) => `${row.coverage?.toponymic_particle} ${row.coverage?.street_name}, ${row.coverage?.address_number}`,
      search: true
    },
    {
      title: 'Città',
      dataIndex: ['coverage','city'],
      search: true
    },
    {
      title: 'Offerta',
      dataIndex: ['commercial_offer','name'],
    },
    {
      title: 'Carrier',
      dataIndex: ['carrier'],
      hideForUsers: ['PARTNER'],
      search: true
    },
    {
      title: 'Velocità',
      dataIndex: ['connection_type', 'speed']
    },
    {
      title: 'Zona',
      dataIndex: ['connection_type','zone'],
      hideForUsers: ['PARTNER'],
      search: true,
      filterType: 'radio',
      filterOptions: [{text: 'A&B', value: 'A&B' }, {text: 'C&D', value: 'C&D' }]
    },
    {
      title: 'Sottoscrizione',
      dataIndex: 'created_at',
      render: value => value ? moment(value).format('D MMM YYYY') : '---',
      search: true,
      filterType: 'dateRange'
    },
    {
      title: 'Attivazione',
      dataIndex: 'activated_at',
      render: value => value ? moment(value).format('D MMM YYYY') : '---',
      search: true,
      filterType: 'dateRange',
    },
    {
      title: 'Cessazione',
      dataIndex: 'canceled_at',
      render: value => value ? moment(value).format('D MMM YYYY') : '---',
      search: true,
      filterType: 'dateRange',
    },
    {
      title: 'Stato provisioning',
      dataIndex: 'state_provisioning_name',
      hideForUsers: ['PARTNER']
    },
    {
      title: 'In recesso',
      dataIndex: 'recess_requested',
      render: value => value ? 'Si' : 'No',
      search: true,
      filterType: 'radio',
      filterOptions: [{text: 'Si', value: '1' }, {text: 'No', value: '0' }]
    },
    {
      title: 'In cancellazione',
      dataIndex: 'cancel_requested',
      render: value => value ? 'Si' : 'No',
      search: true,
      filterType: 'radio',
      filterOptions: [{text: 'Si', value: '1' }, {text: 'No', value: '0' }]
    },
    {
      title: 'Stato',
      dataIndex: 'state_name',
      search: true,
      filterType: 'radio',
      filterOptions: states?.contracts
    }
  ];
  

  return (
    <div className="main-container">
      <PageHeader
        title="Elenco contratti in attesa di provisioning"
        style={{ paddingBottom: 0 }}
        // extra={[
        //   <Dropdown overlay={menu} key="crea-contratto-button">
        //     <Button 
        //       type="primary" 
        //       //size="large"
        //     >
        //       Crea contratto <DownOutlined />
        //     </Button>
        //   </Dropdown>
        // ]}
      >
      </PageHeader>
      <Table
        columns={columns.filter(col => !col.hideForUsers?.includes(userRole))}
        dataSource={list}
        pagination={pagination}
        filters={filters}
        loading={loading}
        getList={(data) => getContractsListAwaiting(data)}
      />
    </div>
  )
}

// ContractsList.PropTypes = {
  
// }

export default ContractsListWaiting;