import React, { useEffect } from 'react';
//import PropTypes from 'prop-types';
import { 
  useDispatch 
} from 'react-redux';
import { useHistory, useLocation } from "react-router-dom";
import { 
  Tag
} from 'antd';
import Table from 'components/Table/Table'

function TicketsList({
  columns,
  dataSource,
  pagination,
  filters,
  loading,
  reloadList,
  getList
}) {

  const dispatch = useDispatch()
  let history = useHistory();
  
  const params = new URLSearchParams(useLocation().search)
  const currentPageParams = params.get('page') || 1
  
  useEffect(() => {
    const fetchList = async() => {
      try {
        await dispatch(getList({
          page: {current: currentPageParams},
          filters: filters
        }))
      } catch (error) {
        
      }
    }

    fetchList()
  },[reloadList])

  
  const updateList = async(page) => {
    try {
      console.log('update > ',page)
      const result = await dispatch(getList({
        page: {current: page},
        filters: filters
      }))

      if(result.payload.data) {
        
        history.replace({
          search: "?" + new URLSearchParams({page: page}).toString()
        })
      }
    } catch (error) {
      
    }
  }


  const handleCleanFilter = async (dataIndex) => {
    try {
      const filterKey = typeof dataIndex === 'object' ? dataIndex.join('.') : dataIndex;
      
      const filtered = Object.fromEntries(
        Object.entries(filters).filter(
           ([key, val])=> key !== filterKey
        )
      );
      params.set('page','1')
      history.replace({
        search: "?" + params.toString()
      })
      await dispatch(getList({
        page: {current: 1},
        filters: filtered
      }))

    } catch (error) {
      
    }
  }

  const getFilters = (filters) => {
    const allTags = []
    
    if(filters) {
      for (const [key, obj] of Object.entries(filters)) {
        
        const column = columns.filter(column => {
          if(typeof column.dataIndex === 'object') {
            return column.dataIndex.join('.') === key
          }
          return column.dataIndex === key
        })
        console.log('ALL TAGS > ',column)
        allTags.push({
          key: key,
          label: column?.[0]?.title_name || column?.[0]?.title,
          value: obj?.data
        })
      }
    }
    
    return allTags.map((tag,i) => {
      const value = typeof tag.value === 'object' ? tag.value.label : tag.value
      return (
        <Tag 
          key={tag?.key} 
          color="purple"
          onClose={() => handleCleanFilter(tag?.key)}
          closable
        >
          {`${tag?.label}: ${value}`}
        </Tag>
      )}
    )
  }

  const handleSearch = async(dataIndex, data, title) => {
    
    try {
      console.log('handle search > ', params)
      const index = (typeof dataIndex === 'object') ? dataIndex.join('.') : dataIndex
      //const newValue = typeof value === 'object' ? data.value : data;
      const newFilters = {
        ...filters,
        [index]: {
          label: title,
          data: data,
          //value_name: data.label
        }
      }
      params.set('page','1')
      history.replace({
        search: "?" + params.toString()
      })
      await dispatch(getList({
        page: {current: 1},
        filters: newFilters
      }))

    } catch (error) {
      console.log('error handle search> ',error)
    }
  }

  return (
    <>
      <div style={{ 
          height: 24,
          marginBottom: 8,
          paddingLeft: 24 
        }}
      >
        {getFilters(filters)}
      </div>
      <Table
        columns={columns}
        rowKey={record => record.id}
        dataSource={dataSource}
        loading={loading !== ''}
        locale={{
          emptyText: 'Nessun dato'
        }}
        size="small"
        pagination={pagination}
        onChangePage={updateList}
        onSearch={handleSearch}
        onResetFilter={handleCleanFilter}
      />
    </>
  )
}


export default TicketsList;