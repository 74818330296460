import React from 'react';
import { Button, Form, Modal, Input, Row, Col } from "antd";

const EnterMotivation = ({ onSave = () => {}, onClose = () => { } }) => {

  const [formEnterMotivation] = Form.useForm();

  return (
    <Modal
      title="Inserisci motivazione"
      visible
      destroyOnClose
      width={760}
      onCancel={onClose}
      footer={[
        <Button
          key="back"
          type="primary"
          onClick={onClose}
        >
          Chiudi
        </Button>,
         <Button
         key="enter"
         type="primary"
         onClick={() => formEnterMotivation.submit()}
       >
         Salva
       </Button>
      ]}
    >
      <Form
        form={formEnterMotivation}
        name='formEnterMotivation'
        layout="vertical"
        colon={false}
        validateTrigger="onBlur"
        autoComplete="off"
        onFinish={onSave}
      >
        <Form.Item
          name="motivation"
          label="Nome"
          rules={[
            {
              required: true,
              message: 'Campo obbligatorio'
            },
          ]}
        >
          <Input
            size="large"
          />
        </Form.Item>

      </Form>
    </Modal>
  )

}

export default EnterMotivation;