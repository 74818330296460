import React, { useState } from 'react';
import {
  Form,
  Row,
  Col,
  Input,
  Button,
  Space,
  message
} from 'antd';

import { 
  useDispatch 
} from 'react-redux';
import {  
  submitCancelOrder
} from 'redux/reducers/contractsReducer';


const CancelOrder = ({ onSuccess, onCancel }) => {

  const [formCancelOrder] = Form.useForm();
  const dispatch = useDispatch();
  
  const onFinish = async (values) => {
    console.log(values)
    const result = await dispatch(submitCancelOrder(values))
    if(result.payload.status !== 422 && result.payload.data) {
      onSuccess()
    }
    else {
      //console.log(result)
      message.error(`Errore nella compilazione del modulo`);
    }
  }

  

  return (
    <Form 
      style={{
        width: 400,
        margin: '16px auto'
      }}
      form={formCancelOrder}
      name="formCancelOrder"
      layout="vertical" 
      colon={false} 
      onFinish={onFinish}
      //initialValues={initialValues}
      //scrollToFirstError={true}
      validateTrigger="onBlur"
    >
      <Row>
        <Col span={24}>
          <Form.Item
            name="notes"
            label="Inserisci note"
            rules={[
              {
                required: true,
                message: 'Campo obbligatorio'
              },
            ]}
          >
            <Input
              name='notes'
              size='large'
            />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="center" align="middle">
        <Col>
          <Space 
            align="center"
            size="large"
          >
            <Button 
              //type="primary" 
              //htmlType="submit"
              size="large"
              onClick={onCancel}
              //disabled={!enableSubmit}
              //loading={loading === 'confirmApproveContractStatus'}
            >
              Annulla
            </Button>
            <Button 
              type="primary" 
              htmlType="submit"
              size="large"
              //onClick={confirmValidation}
              //disabled={!enableSubmit}
              //loading={loading === 'confirmApproveContractStatus'}
            >
              Conferma
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  )
}

export default CancelOrder;