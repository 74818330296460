import React, { useState } from 'react';
import {
  Form,
  Select,
  DatePicker,
  Input,
  Row,
  Col,
  Button,
  Space,
  message
} from 'antd';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/it_IT';

import { 
  useDispatch 
} from 'react-redux';
import {  
  submitResumeRequest
} from 'redux/reducers/contractsReducer';


const ResumeRequest = ({ loading, onSuccess, onCancel }) => {

  const [formResumeRequest] = Form.useForm();
  const dispatch = useDispatch();
  
  const onFinish = async (values) => {
    //console.log(values)
    const result = await dispatch(submitResumeRequest({
      ...values,
      appointment_date: moment(values.appointment_date).format('YYYY-MM-DD')
    }))
    if(result.payload.status !== 422 && result.payload.data) {
      onSuccess()
    }
    else {
      //console.log(result)
      message.error(`Errore nella compilazione del modulo`);
    }
  }

  return (
    <Form 
      style={{
        width: 400,
        margin: '16px auto'
      }}
      form={formResumeRequest}
      name="formResumeRequest"
      layout="vertical" 
      colon={false} 
      onFinish={onFinish}
      //initialValues={initialValues}
      //scrollToFirstError={true}
      validateTrigger="onBlur"
    >
      <Row gutter={[16,16]}>
        <Col span={24}>
          <Form.Item
            name="action"
            label="Azione"
            rules={[
              {
                required: true,
                message: 'Campo obbligatorio'
              },
            ]}
          >
            <Select 
              size="large"
            >
              <Select.Option value="1">Re-submission without modifications</Select.Option>
              <Select.Option value="2">Re-submission after blocking issue resolution</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item 
            name="appointment_date"
            label="Data di appuntamento"
            rules={[
              {
                required: true,
                message: 'Campo obbligatorio'
              }
            ]}
          >
            <DatePicker
              size="large"
              style={{width: '100%'}}
              locale={locale}
              format={'DD/MM/YYYY'}
              disabledDate={(current) => current < moment()}
              placeholder={'DD/MM/YYYY'}
              showToday={false}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item 
            name="slot"
            label="Fascia oraria"
            rules={[
              {
                required: true,
                message: 'Campo obbligatorio'
              },
            ]}
          >
            <Select 
              size="large"
            >
              <Select.Option value="8:30-10:30">8:30-10:30</Select.Option>
              <Select.Option value="10:30-12:30">10:30-12:30</Select.Option>
              <Select.Option value="13:00-15:00">13:00-15:00</Select.Option>
              <Select.Option value="15:00-17:00">15:00-17:00</Select.Option>
              {/* <Select.Option value="01">8:30-10:30</Select.Option>
              <Select.Option value="02">10:30-12:30</Select.Option>
              <Select.Option value="03">13:00-15:00</Select.Option>
              <Select.Option value="04">15:00-17:00</Select.Option> */}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="notes"
            label="Note"
            rules={[
              {
                required: true,
                message: 'Campo obbligatorio'
              },
            ]}
          >
            <Input 
              size="large"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="center" align="middle">
        <Col>
          <Space 
            align="center"
            size="large"
          >
            <Button 
              //type="primary" 
              //htmlType="submit"
              size="large"
              onClick={onCancel}
              disabled={loading === 'submitResumeRequestStatus'}
            >
              Annulla
            </Button>
            <Button 
              type="primary" 
              htmlType="submit"
              size="large"
              //onClick={confirmValidation}
              disabled={loading === 'submitResumeRequestStatus'}
              loading={loading === 'submitResumeRequestStatus'}
            >
              Conferma
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  )
}

export default ResumeRequest;