//import Login from 'Views/Login/Login'
import LoggedOutLayout from 'views/Layouts/LoggedOutLayout'
import MainLayoutAuthenticated from 'views/Layouts/MainLayoutAuthenticated'

import ContractsList from 'views/Contracts/ContractsList/ContractsList'
import ContractsListToValidate from 'views/Contracts/ContractsList/ContractsListToValidate'
import ContractsListToUpload from 'views/Contracts/ContractsList/ContractsListToUpload'
import ContractsListPending from 'views/Contracts/ContractsList/ContractsListPending'
import ContractsListKoProvisioning from 'views/Contracts/ContractsList/ContractsListKoProvisioning'
import ContractsListEarlyWithDrawal from 'views/Contracts/ContractsList/ContractsListEarlyWithdrawal'
import ContractsListExplicitWithdrawal from 'views/Contracts/ContractsList/ContractsListExplicitWithdrawal'
import ContractsListPostWithDrawal from 'views/Contracts/ContractsList/ContractsListPostWithdrawal'
import ContractsListCreating from 'views/Contracts/ContractsList/ContractsListCreating'
import ContractsListNotSurfing from 'views/Contracts/ContractsList/ContractsListNotSurfing'
import ContractsListNotInvoiceable from 'views/Contracts/ContractsList/ContractsListNotInvoiceable'
import ContractsListWaiting from 'views/Contracts/ContractsList/ContractsListWaiting'
import ContractsListRecess from 'views/Contracts/ContractsList/ContractsListRecess'
import ContractToValidate from 'views/Contracts/ContractToValidate/ContractToValidate'
import ContractsRidMissing from 'views/Contracts/ContractsList/ContractsRidMissing'
import ContractsEdit from 'views/Contracts/ContractsEdit/ContractsEdit'
import ContractsDetails from 'views/Contracts/ContractsDetails/ContractsDetails'
import ContractsCreatingDetails from 'views/Contracts/ContractsDetails/ContractsCreatingDetails'

import CustomersList from 'views/Customers/CustomersList/CustomersList'
import CustomersDetails from 'views/Customers/CustomersDetails/CustomersDetails'

import LeadsList from 'views/Leads/LeadsList/LeadsList'
import LeadsDetails from 'views/Leads/LeadsDetails/LeadsDetails'

import TicketsList from 'views/Tickets/TicketsList/TicketsList'

import OrdersList from 'views/Orders/OrdersList/OrdersList'

import InvoicesList from 'views/Invoices/InvoicesList/InvoicesList'

import OdlsList from 'views/Odls/OdlsList/OdlsList'

import PortedOutList from 'views/PortedOut/PortedOutList/PortedOutList'

import { userRoles } from 'config/definitions';

import {
  TeamOutlined,
  WalletOutlined,
  CustomerServiceOutlined,
  ContainerOutlined,
  SolutionOutlined,
  FileProtectOutlined,
  LogoutOutlined
} from '@ant-design/icons';

const routes = [
  {
    path: ["/contratti","/clienti","/leads","/tickets","/ordini","/fatture","/odls", "/portedout"],
    component: MainLayoutAuthenticated,
    exact: true,
    routes: [
      {
        path: "/contratti",
        component: ContractsList,
        exact: true,
      },
      {
        path: "/contratti/pending",
        component: ContractsListPending,
        exact: true
      },
      {
        path: "/contratti/ko-provisioning",
        component: ContractsListKoProvisioning,
        exact: true
      },
      {
        path: "/contratti/recesso-anticipato",
        component: ContractsListEarlyWithDrawal,
        exact: true
      },
      {
        path: "/contratti/rinuncia-esplicita",
        component: ContractsListExplicitWithdrawal,
        exact: true
      },
      {
        path: "/contratti/recesso-posticipato",
        component: ContractsListPostWithDrawal,
        exact: true
      },
      {
        path: "/contratti/in-recesso",
        component: ContractsListRecess,
        exact: true
      },
      {
        path: "/contratti/da-caricare",
        component: ContractsListToUpload,
        exact: true
      },
      {
        path: "/contratti/in-acquisizione",
        component: ContractsListCreating,
        exact: true
      },
      {
        path: "/contratti/non-naviganti",
        component: ContractsListNotSurfing,
        exact: true
      },
      {
        path: "/contratti/senza-data-di-fatturazione",
        component: ContractsListNotInvoiceable,
        exact: true
      },
      {
        path: "/contratti/in-attesa",
        component: ContractsListWaiting,
        exact: true
      },
      {
        path: "/contratti/validazione",
        component: ContractsListToValidate,
        exact: true
      },
      {
        path: "/contratti/rid-mancante",
        component: ContractsRidMissing,
        exact: true
      },
      {
        path: "/contratti/validazione/:id",
        component: ContractToValidate,
        exact: true
      },
      {
        path: "/contratti/nuovo",
        component: ContractsEdit,
        exact: true,
      },
      {
        path: "/contratti/:id",
        component: ContractsDetails,
        exact: true,
      },
      {
        path: "/contratti/in-acquisizione/:id",
        component: ContractsCreatingDetails,
        exact: true,
      },
      {
        path: "/clienti",
        component: CustomersList,
        exact: true
      },
      {
        path: "/clienti/:id",
        component: CustomersDetails,
        exact: true
      },
      {
        path: "/leads/lavorate",
        component: LeadsList,
        exact: true
      },
      {
        path: "/leads/da-lavorare",
        component: LeadsList,
        exact: true
      },
      {
        path: "/leads/appuntamenti",
        component: LeadsList,
        exact: true
      },
      {
        path: "/leads/:id",
        component: LeadsDetails,
        exact: true
      },
      {
        path: "/tickets",
        component: TicketsList,
        exact: true
      },
      {
        path: "/ordini",
        component: OrdersList,
        //exact: true
      },
      {
        path: "/portedout",
        component: PortedOutList,
        exact: true
      },
      {
        path: "/fatture",
        component: InvoicesList,
        exact: true
      },
      {
        path: "/odls",
        component: OdlsList,
        exact: true
      }
    ]
  },
  {
    path: "/",
    component: LoggedOutLayout
  }
];

export const menuLinks = [
  {
    accessControl: [
      userRoles.CUSTOMER_CARE,
      userRoles.PARTNER
    ],
    key: 'contratti',
    path: '/contratti',
    icon: WalletOutlined,
    label: 'Contratti',
    items: [
      {
        accessControl: [
          userRoles.CUSTOMER_CARE,
          //userRoles.PARTNER
        ],
        key: 'pending',
        path: '/contratti/pending',
        //icon: WalletOutlined,
        label: 'Pending cliente',
      },
      {
        accessControl: [
          userRoles.CUSTOMER_CARE,
          //userRoles.PARTNER
        ],
        key: 'ko-provisioning',
        path: '/contratti/ko-provisioning',
        //icon: WalletOutlined,
        label: 'KO provisioning',
      },
      {
        accessControl: [
          userRoles.CUSTOMER_CARE,
          //userRoles.PARTNER
        ],
        key: 'early-withdrawal',
        path: '/contratti/recesso-anticipato',
        //icon: WalletOutlined,
        label: 'In rinuncia o recesso anticipato',
      },
      {
        accessControl: [
          userRoles.CUSTOMER_CARE,
          //userRoles.PARTNER
        ],
        key: 'explicit-withdrawal',
        path: '/contratti/rinuncia-esplicita',
        //icon: WalletOutlined,
        label: 'In rinuncia esplicita',
      },
      {
        accessControl: [
          userRoles.CUSTOMER_CARE,
          //userRoles.PARTNER
        ],
        key: 'post-withdrawal',
        path: '/contratti/recesso-posticipato',
        //icon: WalletOutlined,
        label: 'In recesso posticipato',
      },
      {
        accessControl: [
          userRoles.CUSTOMER_CARE,
          //userRoles.PARTNER
        ],
        key: 'recessing',
        path: '/contratti/in-recesso',
        //icon: WalletOutlined,
        label: 'In recesso',
      },
      {
        accessControl: [
          userRoles.CUSTOMER_CARE,
        ],
        key: 'in-attesa',
        path: '/contratti/in-attesa',
        //icon: WalletOutlined,
        label: 'In attesa provisioning',
      },
      {
        accessControl: [
          userRoles.CUSTOMER_CARE,
          userRoles.PARTNER
        ],
        key: 'in-acquisizione',
        path: '/contratti/in-acquisizione',
        //icon: WalletOutlined,
        label: 'In acquisizione',
      },
      {
        accessControl: [
          userRoles.CUSTOMER_CARE,
          userRoles.PARTNER
        ],
        key: 'non-naviganti',
        path: '/contratti/non-naviganti',
        //icon: WalletOutlined,
        label: 'Non naviganti',
      },
      {
        accessControl: [
          userRoles.CUSTOMER_CARE,
          userRoles.PARTNER
        ],
        key: 'senza-data-di-fatturazione',
        path: '/contratti/senza-data-di-fatturazione',
        //icon: WalletOutlined,
        label: 'Senza data di fatturazione',
      },
      // {
      //   accessControl: [
      //     //userRoles.CUSTOMER_CARE,
      //     userRoles.PARTNER
      //   ],
      //   key: 'da-caricare',
      //   path: '/contratti/da-caricare',
      //   //icon: WalletOutlined,
      //   label: 'Da caricare',
      // },
      // {
      //   accessControl: [
      //     userRoles.CUSTOMER_CARE,
      //     //userRoles.PARTNER
      //   ],
      //   key: 'validazione',
      //   path: '/contratti/validazione',
      //   //icon: WalletOutlined,
      //   label: 'In validazione'
      // },
      // {
      //   accessControl: [
      //     userRoles.CUSTOMER_CARE,
      //     //userRoles.PARTNER
      //   ],
      //   key: 'rid-missing',
      //   path: '/contratti/rid-mancante',
      //   //icon: WalletOutlined,
      //   label: 'Rid mancante'
      // },
      {
        accessControl: [
          userRoles.CUSTOMER_CARE,
          userRoles.PARTNER
        ],
        key: 'lista',
        path: '/contratti',
        //icon: WalletOutlined,
        label: 'Tutti',
      },
    ]
  },
  {
    accessControl: [
      userRoles.CUSTOMER_CARE,
      //userRoles.PARTNER
    ],
    key: 'ordini',
    path: '/ordini',
    icon: SolutionOutlined,
    label: 'Ordini',
    items: [
      {
        accessControl: [
          userRoles.CUSTOMER_CARE,
          //userRoles.PARTNER
        ],
        key: 'lista-in-attivazione',
        path: '/ordini/in-attivazione',
        //icon: WalletOutlined,
        label: 'In attivazione',
      },
      {
        accessControl: [
          userRoles.CUSTOMER_CARE,
          //userRoles.PARTNER
        ],
        key: 'lista-sospensione-olo',
        path: '/ordini/sospensione-olo',
        //icon: WalletOutlined,
        label: 'Sospensione OLO',
      },
      {
        accessControl: [
          userRoles.CUSTOMER_CARE,
          //userRoles.PARTNER
        ],
        key: 'lista-ordine-annullato',
        path: '/ordini/ordine-annullato',
        //icon: WalletOutlined,
        label: 'Ordine annullato',
      },
      {
        accessControl: [
          userRoles.CUSTOMER_CARE,
          //userRoles.PARTNER
        ],
        key: 'lista-servizio-attivo',
        path: '/ordini/servizio-attivo',
        //icon: WalletOutlined,
        label: 'Servizio attivo',
      },
      // {
      //   accessControl: [
      //     userRoles.CUSTOMER_CARE,
      //     //userRoles.PARTNER
      //   ],
      //   key: 'lista-servizio-sospeso',
      //   path: '/ordini/servizio-sospeso',
      //   //icon: WalletOutlined,
      //   label: 'Servizio sospeso',
      // },
      {
        accessControl: [
          userRoles.CUSTOMER_CARE,
          //userRoles.PARTNER
        ],
        key: 'lista-servizio-cessato',
        path: '/ordini/servizio-cessato',
        //icon: WalletOutlined,
        label: 'Servizio cessato',
      },
      {
        accessControl: [
          userRoles.CUSTOMER_CARE,
          //userRoles.PARTNER
        ],
        key: 'lista',
        path: '/ordini',
        //icon: WalletOutlined,
        label: 'Tutti',
      },
    ] 
  },
  {
    accessControl: [
      userRoles.CUSTOMER_CARE,
      userRoles.PARTNER
    ],
    key: 'clienti',
    path: '/clienti',
    icon: TeamOutlined,
    label: 'Clienti'
  },
  {
    accessControl: [
      userRoles.CUSTOMER_CARE,
      //userRoles.PARTNER
    ],
    key: 'leads',
    path: '/leads',
    icon: CustomerServiceOutlined,
    label: 'Leads',
    items: [
      {
        key: 'leads-da-lavorare',
        accessControl: [userRoles.CUSTOMER_CARE],
        path: '/leads/da-lavorare',
        //icon: CustomerServiceOutlined,
        label: 'Da Lavorare',
      },
      {
        key: 'leads-lavorate',
        accessControl: [userRoles.CUSTOMER_CARE],
        path: '/leads/lavorate',
        //icon: CustomerServiceOutlined,
        label: 'Lavorate',
      },
      {
        key: 'appuntamenti',
        accessControl: [userRoles.CUSTOMER_CARE],
        path: '/leads/appuntamenti',
        //icon: CustomerServiceOutlined,
        label: 'Appuntamenti',
      },
    ]
  },
  {
    accessControl: [
      userRoles.CUSTOMER_CARE,
      //userRoles.PARTNER
    ],
    key: 'tickets',
    path: '/tickets',
    icon: ContainerOutlined,
    label: 'Tickets'
  },
  {
    accessControl: [
      userRoles.CUSTOMER_CARE,
      //userRoles.PARTNER
    ],
    key: 'portedout',
    path: '/portedout',
    icon: LogoutOutlined,
    label: 'Migrazioni PO'
  },
  {
    accessControl: [
      userRoles.CUSTOMER_CARE,
      //userRoles.PARTNER
    ],
    key: 'fatture',
    path: '/fatture',
    icon: FileProtectOutlined,
    label: 'Fatture'
  },
  {
    accessControl: [
      userRoles.CUSTOMER_CARE,
      //userRoles.PARTNER
    ],
    key: 'odl',
    path: '/odls',
    icon: FileProtectOutlined,
    label: 'Odls'
  }
]

export default routes