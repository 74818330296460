import React from 'react';
import moment from 'moment';
//import PropTypes from 'prop-types';
import { 
  useSelector,
  //useDispatch 
} from 'react-redux';
import { Link } from "react-router-dom";
import { 
  Menu,
  Dropdown,
  PageHeader,
  Statistic,
  Button
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import Table from 'views/Tickets/TicketsList/_Table';
//import './InvoicesList.css';

import { 
  getPortedOutList
} from 'redux/reducers/portedOutReducer';

function PortedOutList() {
  
  const { list, pagination, filters, loading } = useSelector(state => state.portedout);
  const { userRole } = useSelector(state => state.auth);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      search: false
    },
        {
      title: 'Cod. contratto',
      dataIndex: ['contract','contract_code'],
      search: true,
      //sorter: true,
      render: (value,row) => row.contract !== null ? <Link to={`/contratti/${row.contract.id}`}>{value}</Link> : <div>---</div>,
      //width: '20%',
    },
    {
      title: 'Nome',
      dataIndex: ['contract', 'customer', 'first_name'],
      search: false
    },
    {
      title: 'Cognome',
      dataIndex: ['contract', 'customer', 'last_name'],
      search: false,
    },
    {
      title: 'Risorsa',
      dataIndex: 'resource',
      search: true,
    },
    {
      title: 'Servizio',
      dataIndex: 'service',
      search: true,
    },
    {
      title: 'Recipient',
      dataIndex: ['recipient', 'name'],
      search: true
    },
    {
      title: 'Codice',
      dataIndex: 'recipient_code',
      search: true
    },
    {
      title: 'Data ricezione',
      dataIndex: 'created_at',
      render: value => value ? moment(value).format('D MMM YYYY HH:mm') : '---',
      search: true,
      filterType: 'dateRange'
    },
    {
      title: 'Data scadenza',
      dataIndex: 'expires_at',
      render: value => value ? moment(value).format('D MMM YYYY HH:mm') : '---',
      search: true,
      filterType: 'dateRange'
    },
    {
      title: 'Note',
      dataIndex: 'notes',
      search: false
    },
    {
      title: 'Stato',
      dataIndex: 'state_name',
      search: true,
      filterType: 'radio',
      filterOptions: [
        {
          text: 'Da gestire',
          value: 'pending'
        },
        {
          text: 'Annullata dal recipient',
          value: 'canceled_by_recipient'
        },
        {
          text: 'Richiesta mutatata volontà',
          value: 'will_changed'
        },
        {
          text: 'Approvata',
          value: 'approved'
        },
        {
          text: 'Negata',
          value: 'denied'
        },
        {
          text: 'Approvata per scadenza',
          value: 'expired'
        }
      ]
    },
  ];
  

  return (
    <div className="main-container">
      <PageHeader
        title="Elenco migrazioni ported-out"
        style={{ paddingBottom: 0 }}
        // extra={[
        //   <Dropdown overlay={menu} key="crea-contratto-button">
        //     <Button 
        //       type="primary" 
        //       //size="large"
        //     >
        //       Crea contratto <DownOutlined />
        //     </Button>
        //   </Dropdown>
        // ]}
      >
      </PageHeader>
      <Table
        columns={columns.filter(col => !col.hideForUsers?.includes(userRole))}
        dataSource={list}
        pagination={pagination}
        filters={filters}
        loading={loading}
        getList={(data) => getPortedOutList(data)}
      />
    </div>
  )
}

export default PortedOutList;