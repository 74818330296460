import React, { useEffect, useState } from 'react';
import { 
  Tabs,
  Skeleton,
  PageHeader,
  Button,
  Select,
  Modal,
  Form,
  Row,
  Col,
  Tag,
  Input,
  message,
  DatePicker,
  TimePicker
} from 'antd';
import moment from 'moment';
//import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { 
  getLeadDetails,
  unqualifyLead
} from 'redux/reducers/leadsReducer';
import {
  useParams,
  useHistory
} from "react-router-dom";
import { DescriptionsContainer, Item } from 'components/Descriptions/Description';
import locale from 'antd/es/date-picker/locale/it_IT';
//import { DownOutlined } from '@ant-design/icons';

function LeadsDetails() {

  const { selected, loading, motivations, error } = useSelector(state => state.leads);
  const dispatch = useDispatch();
  let history = useHistory();
  const params = useParams();
  const [selectedMotivation, setSelectedMotivation] = useState(null);
  const [formMotivationLead] = Form.useForm();

  const [isModalMotivationOpen, setIsModalMotivationOpen] = useState(false);

  useEffect(() => {
    const getDetails = async() => {
      try {
        //console.log('DOCID MOUNT > ',docId)
        const result = await dispatch(getLeadDetails(params.id))
      } catch (error) {
        
      }
    }

    getDetails()
  },[])

  const handleConfirmMotivation = async(values) => {
    try {
      console.log({ values })
      //formMotivationLead
        //.validateFields()
        //.then(async (values) => {

        if(values.not_qualified_motivation === 'appointment') {
          values.appointment_at = moment(values.date).hours(moment(values.time).get('hours')).minutes(moment(values.time).get('minutes')).format('YYYY-MM-DD HH:mm');
        }

          const result = await dispatch(unqualifyLead(values))
          console.log('result > ',result)
          if(result.payload.data) {
            formMotivationLead.resetFields();
            setIsModalMotivationOpen(false);
            message.success('Dati inviati con successo!')
          }
        // })
        // .catch()
    } catch (error) {
      console.log('error handle Confirm motivation')
    }
  }
  
  return (
    <div className="main-container">
      {
        (loading !== '' || selected === null) ? 
        <Skeleton active /> :
        <div className="main-content">
          <PageHeader
            //className="site-page-header-responsive"
            onBack={() => history.goBack()}
            title={`Lead: ${selected?.type_name} `}
            tags={<Tag color="blue" size="large" >{selected?.state_name}</Tag>}
            //subTitle="This is a subtitle"
            extra={[
              // <Button key="3">Operation</Button>,
              // <Button key="2">Qualifica</Button>,
              selected?.state === 'open' &&
                <Button 
                  key="add-contract" 
                  type="primary" 
                  onClick={() => history.push({
                      pathname: '/contratti/nuovo',
                      search: '?lead_id='+params.id,
                      state: { format: 'digital' }
                    })
                  }>
                  Crea contratto
                </Button>,
              selected?.state === 'open' &&
                <Button 
                  key="select-motivations"
                  type="primary" 
                  onClick={() => {
                    setIsModalMotivationOpen(true)
                  }}
                  //size="large"
                >
                  Non qualificare
                </Button>
            ]}
          >
            {/* <Content extra={extraContent}>{renderContent()}</Content> */}
          </PageHeader>
          {/* <div className="buttonBar">
          </div> */}
          <Tabs type="card" defaultActiveKey="1" onChange={()=>console.log('test')}>
            <Tabs.TabPane tab="Dettagli" key="1">
              <Row gutter={[24,24]}>
                <Col span={8}>
                  <DescriptionsContainer title="Dati anagrafici" type="card">
                    <Item label="Nome" value={selected?.first_name || '---'}/>
                    <Item label="Cognome" value={selected?.last_name || '---'}/>
                    <Item label="Telefono" value={selected?.phone_number || '---'}/>
                    <Item label="E-mail" value={selected?.email || '---'}/>
                    <Item label="Città" value={selected?.city || '---'}/>
                  </DescriptionsContainer>
                </Col>
                <Col span={8}>
                  <DescriptionsContainer title="Dettaglio" type="card">
                    <Item label="Categoria" value={selected?.type_name || '---'}/>
                    <Item label="Stato" value={selected?.state_name || '---'}/>
                    {
                      selected?.state === 'not_qualified' && 
                      <Item label="Stato" value={selected?.not_qualified_motivation_name || '---'}/>
                    }
                    <Item label="Non risponde" value={selected?.doesnt_answer?.toString() || '---'}/>
                    <Item label="Appuntamenti" value={selected?.appointment?.toString() || '---'}/>
                    <Item label="Data appuntamento" value={selected?.appointment_at && moment(selected?.appointment_at).format('DD/MM/YYYY HH:MM') || '---'}/>
                    <Item label="Note" value={selected?.notes || '---'} />
                    <Item label="Modificato da" value={selected?.managed_by?.fullname || '---'}/>
                    <Item label="Ultima modifica" value={selected?.updated_at && moment(selected?.updated_at).format('DD/MM/YYYY HH:MM') || '---'}/>
                    <Item label="Data attivazione" value={selected?.active_at && moment(selected?.active_at).format('DD/MM/YYYY HH:MM') || '---'}/>
                    
                  </DescriptionsContainer>
                </Col>
                {
                  selected?.contract &&
                  <Col span={8}>
                    <DescriptionsContainer title="Contratto" type="card">
                      <Item label="Tipologia" value={selected?.contract?.type || '---'}/>
                      <Item label="Stato" value={selected?.contract?.state_name || '---'}/>
                      <Item label="Data crezione" value={selected?.contract?.created_at && moment(selected?.contract?.created_at).format('DD/MM/YYYY HH:MM') || '---'}/>
                    </DescriptionsContainer>
                  </Col>
                }
                {
                  selected?.coverage &&
                  <Col span={8}>
                    <DescriptionsContainer title="Copertura" type="card">
                      <Item label="Città" value={
                          (selected?.coverage?.city || '') + 
                          ' (' + (selected?.coverage?.province || '---')+')'
                        }
                      />
                      <Item label="Indirizzo" value={
                          (selected?.coverage?.toponymic_particle || '---' ) + ' ' +
                          (selected?.coverage?.street_name || '---' ) + ', ' +
                          (selected?.coverage?.address_number || '---' )
                        }
                      />

                      <Item label="Velocità di download effettiva" value={selected?.coverage?.actual_download_speed || '---'}/>
                      <Item label="Velocità di upload effettiva" value={selected?.coverage?.actual_upload_speed || '---'}/>
                      <Item label="Latenza" value={selected?.coverage?.latency || '---'}/>
                      <Item label="Kit Id" value={selected?.coverage?.kit_id || '---'}/>
                    </DescriptionsContainer>
                  </Col>
                }
              </Row>


              {/* <Descriptions title="CONTATTO" bordered={false} columns={2}>
                <Descriptions.Item label="Nome">{selected.first_name || '---'}</Descriptions.Item>
                <Descriptions.Item label="Cognome">{selected.last_name || '---'}</Descriptions.Item>
                <Descriptions.Item label="Telefono">{selected.phone_number || '---'}</Descriptions.Item>
                <Descriptions.Item label="E-mail">{selected.email || '---'}</Descriptions.Item>
              </Descriptions>

              <Descriptions title="DETTAGLIO" bordered={false} columns={2}>
                <Descriptions.Item label="Categoria">{selected.type_name || '---'}</Descriptions.Item>
                <Descriptions.Item label="Stato">{selected.state_name || '---'}</Descriptions.Item>
                {
                  selected?.state === 'not_qualified' && <Descriptions.Item label="Motivazione">{selected.not_qualified_motivation_name || '---'}</Descriptions.Item>
                }
              </Descriptions>  */}
              
            </Tabs.TabPane>
            {/* <Tabs.TabPane tab="Cliente" key="2">
              <Descriptions title="CONTATTI" bordered={false} columns={2}>
                <Descriptions.Item label="Nome">{selected.client.firstname}</Descriptions.Item>
                <Descriptions.Item label="Cognome">{selected.client.lastname}</Descriptions.Item>
                <Descriptions.Item label="Partita iva">{selected.client.piva}</Descriptions.Item>
                <Descriptions.Item label="Telefono">{selected.client.email}</Descriptions.Item>
                <Descriptions.Item label="E-mail">{selected.client.email}</Descriptions.Item>
                <Descriptions.Item label="PEC">{selected.client.pec || '---'}</Descriptions.Item>
              </Descriptions>
            </Tabs.TabPane> */}
            {/* <Tabs.TabPane tab="Tab 3" key="3">
              Content of Tab Pane 3
            </Tabs.TabPane> */}
          </Tabs>
        </div>
      }
      <Modal
        title="Seleziona la motivazione per la quale non qualificare la lead"
        visible={isModalMotivationOpen}
        // onOk={handleBackButton}
        onCancel={() => setIsModalMotivationOpen(false)}
        footer={[
          <Button 
            key="back" 
            onClick={() => setIsModalMotivationOpen(false)}
            disabled={loading !== ''}
          >
            Annulla
          </Button>,
          <Button 
            key="submit" 
            type="primary" 
            //htmlType="submit"
            disabled={loading !== ''}
            loading={loading === 'unqualifyLeadStatus'}
            onClick={() => {
              formMotivationLead
                .validateFields()
                .then((values) => {
                  //formMotivationLead.submit(values)
                  //formMotivationLead.resetFields();
                  handleConfirmMotivation(values);
                })
                .catch((info) => {
                  console.log('Validate Failed:', info);
                })
              }
            }
          >
            Conferma
          </Button>
        ]}
      >
        <Form
          form={formMotivationLead}
          name="formMotivationLead"
          layout="vertical" 
          colon={false} 
          //onFinish={handleConfirmMotivation}
        >
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                name="not_qualified_motivation"
                label="Seleziona motivazione"
                rules={[
                  {
                    required: true,
                    message: 'Campo obbligatorio'
                  }
                ]}
              >
                <Select onSelect={(value) => setSelectedMotivation(value)}>
                  {motivations && Object.entries(motivations).map(([key,value]) => (
                    <Select.Option key={key} value={key}>
                      {value}
                    </Select.Option>  
                  ))}   
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="cc_call"
                label="Chiamata cc"
                initialValue={1}
                rules={[
                  {
                    required: true,
                    message: 'Campo obbligatorio'
                  }
                ]}
              >
                <Select >
                  <Select.Option value={1}>Si</Select.Option>  
                  <Select.Option value={0}>No</Select.Option>  
                </Select>
              </Form.Item>
            </Col>
            {selectedMotivation === 'appointment' && 
             <Col span={12}>
             <Form.Item
               name="date"
               label="Data"
               rules={[
                 {
                   required: true,
                   message: "Campo obbligatorio",
                 },
               ]}
             >
                <DatePicker
                 size="large"
                 style={{width: '100%'}}
                 locale={locale}
                 format={'DD/MM/YYYY'}
                 disabledDate={(current) => current < moment()}
                 placeholder={'DD/MM/YYYY'}
               />
             </Form.Item>
             </Col>
              
            }
            {selectedMotivation === 'appointment' && 
             <Col span={12}>
             <Form.Item
               name="time"
               label="Ora"
               rules={[
                 {
                   required: true,
                   message: "Campo obbligatorio",
                 },
               ]}
             >
               <TimePicker
               size="large"
               style={{width: '100%'}}
               locale={locale}
               format="HH:mm"
               />
             </Form.Item>
           </Col>
           }
            <Col span={24}>
              <Form.Item 
                name="notes"
                label="Note"
              >
                <Input
                  size="large"
                  value={selected?.notes || ''}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  )
}

// LeadsDetails.PropTypes = {
  
// }

export default LeadsDetails;