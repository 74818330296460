import React, { useCallback, useState } from 'react';
import moment from 'moment';
import { 
  PageHeader,
  Tag,
  Button,
  Modal,
  message
} from 'antd';

import { 
  useSelector,
  useDispatch 
} from 'react-redux';

import { useHistory } from 'react-router-dom';

import TableTickets from './_Table';
import TicketsDetails from 'views/Tickets/TicketsDetails/TicketsDetails';

import { 
  getTicketEdit,
  getTicketDetails,
  getTicketsList,
  createNewMotivation,
  getTicketMotivations
} from 'redux/reducers/ticketsReducer';
import EnterMotivation from './_actions/EnterMotivation';
import { selectTicketMotivations } from 'redux/selectors/tickets';

function TicketsList() {

  const [ticketSelected, setTicketSelected] = useState(null);
  const [showEnterMotivation, setShowEnterMotivation] = useState(false);

  const { list,selected, pagination, filters, loading, newTicket } = useSelector(state => state.tickets);
  const { triplets } = newTicket;
  const dispatch = useDispatch()
  let history = useHistory();

  const enterMotivationSaveHandler = useCallback(async (values) => {
    const { payload } = await dispatch(createNewMotivation(values));

    if(payload?.status === 422) {
      message.error('Motivazione già presente');
    } else {
      message.success('Motivazione inserita correttamente');
      setShowEnterMotivation(false);
      dispatch(getTicketMotivations());
    }

  }, []);
  
  const columns = [
    {
      title: 'Numero',
      dataIndex: 'ticket_code',
      search: true,
      render: (value,row) => <a onClick={() => handleDetails(row.id)}>{value}</a>,
    },
    {
      title: 'Tipologia - Famiglia - Sottofamiglia',
      title_name: 'Tripletta',
      dataIndex: 'support_cat',
      render: (value,row) => {
        return (
          <>
            <Tag color="geekblue" size="small">{row.support_cat_first.name}</Tag>
            <Tag color="green" size="small">{row.support_cat_second.name}</Tag>
            <Tag color="volcano" size="small">{row.support_cat_third.name}</Tag>
          </>
        )
      },
      search: true,
      filterType: 'tree',
      filterOptions: triplets
    },
    // {
    //   title: 'Famiglia',
    //   dataIndex: ['support_cat_second','name'],
    // },
    // {
    //   title: 'Sottofamiglia',
    //   dataIndex: ['support_cat_third','name'],
    // },
    {
      title: 'Nome',
      dataIndex: ['customer','first_name'],
      render: (value,row) => value || '---',
      search: true
    },
    {
      title: 'Cognome',
      dataIndex: ['customer','last_name'],
      render: (value,row) => value || '---',
      search: true
    },
    {
      title: 'Cod. contratto',
      dataIndex: ['contract','contract_code'],
      render: (value,row) => value || '---',
      search: true
    },
    {
      title: 'Stato',
      dataIndex: 'state_name',
      search: true,
      filterType: 'radio',
      filterOptions: [
        { text: 'Aperto', value: 'open' },
        { text: 'Chiuso', value: 'closed' },
        { text: 'Sospeso', value: 'suspended' }
      ]
    },
    {
      title: 'Ocs',
      dataIndex: 'ocs',
      render: (value,row) => value ? 'Si' : 'No',
    },
    {
      title: 'Origine',
      dataIndex: 'origin_name',
      search: true,
      filterType: 'radio',
      filterOptions: [
        { text: 'Chiamata', value: 'call' },
        { text: 'Segnalazione esterna', value: 'external' },
        { text: 'Business partner', value: 'bp' },
        { text: 'Chat', value: 'chat' },
        { text: 'Raccomandata', value: 'reg_letter' },
        { text: 'E-mail', value: 'mail' },
        { text: 'Web', value: 'web' }
      ]
    },
    {
      title: 'Data creazione',
      dataIndex: 'created_at',
      render: value => moment(value).format('D MMM YYYY HH:mm'),
      search: true,
      filterType: 'dateRange'
    },
    {
      //title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Button 
          size="small" 
          type="link"
          onClick={() => handleEdit(record.id)}
        >
          Lavora
        </Button>
      ),
    },
  ];


  const handleDetails = async (id) => {
    await dispatch(getTicketDetails(id))
  }

  const handleEdit = (id) => {
    
    if(newTicket.draft) {
      if(newTicket.draft.id !== id) {
        return dispatch({
          type:"tickets/setshowAlertWorking", 
          options: {
            value: true,
            actionName: 'EDIT',
            params: {
              id
            }
          }
        })
      }
      else {
        return dispatch({ type: 'tickets/setIsDrawerOpen', value: true })
      }
    }
    //localStorage.setItem('ticketId', id)
    dispatch(getTicketEdit(id))
    //dispatch({type:"tickets/setShowNewTicket", value: true})
  }

  return (
    <div className="main-container">
      <PageHeader title="Elenco tickets" extra={[
        <Button key="1" type="primary" onClick={() => setShowEnterMotivation(true)}>
          Inserisci motivazione
        </Button>,
      ]} style={{ paddingBottom: 0 }}>
      </PageHeader>
      <TableTickets 
        columns={columns}
        dataSource={list}
        pagination={pagination}
        filters={filters}
        loading={loading}
        getList={(data) => getTicketsList(data)}
      />
      {
        showEnterMotivation && 
        <EnterMotivation onSave={enterMotivationSaveHandler} onClose={() => setShowEnterMotivation(false) } />
      }
      <Modal
        title={`Ticket: ${selected?.ticket_code}`}
        visible={selected !== null}
        destroyOnClose
        width={760}
        onCancel={() => dispatch({ type: 'tickets/leaveDetails' })}
        footer={[
          <Button 
            key="back" 
            type="primary" 
            onClick={() => dispatch({ type: 'tickets/leaveDetails' })}
          >
            Chiudi
          </Button>
        ]}
      >
        <TicketsDetails 
          data={selected} 
          goTo={(route) => {
            history.push(route);
            dispatch({ type: 'tickets/leaveDetails' })}
          }
        />
      </Modal>
    </div>
  )
}


export default TicketsList;