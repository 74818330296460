import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from "axios";
import queryString from "query-string";
import { BASE_API_URL } from "config/constants";
//import convertJsonToFormData from 'utils/convertJsonToFormData';

axios.defaults.withCredentials = true;
axios.defaults.baseURL = BASE_API_URL;

export const getOrdersList = createAsyncThunk(
  'orders/getOrdersListStatus',
  async ({data, stateFilter},_) => {
    try {
      console.log({stateFilter})
      const query = {
        page: data?.page?.current || 1,
        'filter[crm_code]': data?.filters?.crm_code?.data,
        'filter[fw_code]': data?.filters?.fw_code?.data,
        'filter[e2e_code]': data?.filters?.e2e_code?.data
      }

      const getUrl = () => {
        const mapUrl = {
          'in-attivazione': `/orders/activation-sent?${queryString.stringify(query)}`,
          'sospensione-olo': `/orders/pending?${queryString.stringify(query)}`,
          'ordine-annullato': `/orders/canceled?${queryString.stringify(query)}`,
          'servizio-attivo': `/orders/active?${queryString.stringify(query)}`,
          'servizio-cessato': `/orders/terminated?${queryString.stringify(query)}`
        }

        return mapUrl[stateFilter] || `/orders?${queryString.stringify(query)}`
      }
      //console.log("BEFORE RESPONSE > > >")
      const response = await axios.get(getUrl())
      response.data.filters = data.filters
      //console.log("RESPONSE > > >",response)
      return response.data
    } catch (error) {
      console.log("ERROR RESPONSE GET ORDERS LIST > > >",error)
      //return rejectWithValue(error.response.data)
      return {
        status: error.response.status
      }
    }
  }
)

const initialState = {
  loading: '',
  error: null,
  list: [],
  filters: {}
}

const ordersSlice = createSlice({
  name: 'orders',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOrdersList.fulfilled, (state, action) => {
      if(action.payload) {
        const pagination = action.payload.meta
        state.list = action.payload.data
        state.pagination = {
          pageSize: pagination.per_page,
          current: pagination.current_page,
          total: pagination.total
        }
        state.filters = action.payload.filters
      }
    })
  }
})

const { reducer } = ordersSlice

export default reducer