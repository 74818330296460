import React, { useState } from 'react';
import {
  Form,
  Select,
  Row,
  Col,
  Upload,
  Button,
  Space,
  message
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
// import moment from 'moment';
// import locale from 'antd/es/date-picker/locale/it_IT';

import { 
  useDispatch 
} from 'react-redux';
import {  
  submitUploadWithdrawal
} from 'redux/reducers/contractsReducer';


const UploadWithdrawal = ({ loading, onSuccess, onCancel }) => {

  const [formUploadWithdrawal] = Form.useForm();
  const dispatch = useDispatch();
  const [fileList, setFileList] = useState([]);

  const onFinish = async (values) => {
    console.log(values)
    const result = await dispatch(submitUploadWithdrawal({
      file: values.file.file.originFileObj,
      type: values.type
    }))
    if(result.payload.status !== 422 && result.payload.data) {
      message.success('Richiesta di recesso inoltrata con successo!')
      onSuccess()
    }
    else {
      //console.log(result)
      message.error(`Errore nella compilazione del modulo`);
    }
  }

  const handleChange = info => {
    let fileList = [...info.fileList];
    const { status } = info.file;
    //console.log(info, info.file)
    if (status !== 'uploading') {
      //console.log(info.file, info.fileList);
    }
    if (status === 'done') {
      message.success(`File ${info.file.name} caricato con successo.`);
      //console.log(fileList)
      if(fileList.length > 1) {
        fileList.shift()
      }
      setFileList([fileList[0]]);
    } else if (status === 'error') {
      message.error(`Problemi nel caricamento del file ${info.file.name}.`);
    }
  };

  const customRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const handleRemoveFile = () => {
    formUploadWithdrawal.resetFields(['file']);
    setFileList([]);
  }

  return (
    <Form 
      style={{
        width: 400,
        margin: '16px auto'
      }}
      form={formUploadWithdrawal}
      name="formUploadWithdrawal"
      layout="vertical" 
      colon={false} 
      onFinish={onFinish}
      //initialValues={initialValues}
      //scrollToFirstError={true}
      validateTrigger="onBlur"
    >
      <Row>
        <Col>
          <Form.Item
            name="file"
            label="Carica il file"
            rules={[
              {
                required: true,
                message: 'Campo obbligatorio'
              },
            ]}
          >
            <Upload
              name='file_recesso'
              customRequest={customRequest}
              multiple={false}
              //disabled={fileList?.length > 0}
              //customRequest={customRequest}
              onChange={handleChange}
              //showUploadList={false}
              onRemove={handleRemoveFile}
              fileList={fileList}
            >
              <Button icon={<UploadOutlined />}>Scegli</Button>
            </Upload>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Form.Item 
            name="type"
            label="Documento"
            rules={[
              {
                required: true,
                message: 'Campo obbligatorio'
              },
            ]}
          >
            <Select 
              size="large"
            >
              <Select.Option value="early_withdrawal">Modulo recesso ex art. 54 D.lgs</Select.Option>
              <Select.Option value="post_withdrawal">Modulo di interruzione contratto</Select.Option>
              <Select.Option value="contract_withdrawal">Modulo disdetta contratto</Select.Option>
              <Select.Option value="explicit_withdrawal">Modulo rinuncia esplicita</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row justify="center" align="middle">
        <Col>
          <Space 
            align="center"
            size="large"
          >
            <Button 
              //type="primary" 
              //htmlType="submit"
              size="large"
              onClick={onCancel}
              disabled={loading === 'submitUploadWithdrawalStatus'}
              //loading={loading === 'confirmApproveContractStatus'}
            >
              Annulla
            </Button>
            <Button 
              type="primary" 
              htmlType="submit"
              size="large"
              //onClick={confirmValidation}
              disabled={loading === 'submitUploadWithdrawalStatus'}
              loading={loading === 'submitUploadWithdrawalStatus'}
            >
              Conferma
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  )
}

export default UploadWithdrawal;