import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col
} from 'antd';

import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';


function SpeedBox({ values }) {
  console.log('sss', values)
  return (
    <div className="speedBoxContainer">
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <h3 className="speedBoxTitle">Dati indicativi della velocità</h3>
        </Col>
      </Row>
      <Row gutter={[16, 16]} align="middle">
        <Col span={6}>Teorica</Col>
        <Col span={9}>
          <ArrowDownOutlined style={{ fontSize: '32px', color: 'green' }} />
          <span className="speedValue">{values && values.download || '---'}</span>
          <span className="speedUnit">MB/s</span>
        </Col>
        <Col span={9}>
          <ArrowUpOutlined style={{ fontSize: '32px', color: 'red' }}  />
          <span className="speedValue">{values && values.upload || '---'}</span>
        < span className="speedUnit">MB/s</span>
        </Col>
      </Row>
      <Row gutter={[16, 16]} align="middle">
        <Col span={6}>Effettiva (fino a)</Col>
        <Col span={9}>
          <ArrowDownOutlined style={{ fontSize: '32px', color: 'green' }} />
          <span className="speedValue">{values && values.actual_download || '---'}</span>
          <span className="speedUnit">MB/s</span>
        </Col>
        <Col span={9}>
          <ArrowUpOutlined style={{ fontSize: '32px', color: 'red' }}  />
        <span className="speedValue">{values && values.actual_upload || '---'}</span>
        <span className="speedUnit">MB/s</span>
        </Col>
      </Row>
      <span>{`Latenza: ${values.latency}ms | Media giorni attivazione: ${values.dac_days}`}</span>
    </div>
  )
}

SpeedBox.defaultProps = {
  values: {}
}

SpeedBox.propTypes = {
  values: PropTypes.object
}

export default SpeedBox;