import { combineReducers } from "redux";
import commonReducer from "./commonReducer";
import authReducer from "./authReducer";
import contractsReducer from "./contractsReducer";
import customersReducer from "./customersReducer";
import odlsReducer from "./odlsReducer";
import leadsReducer from "./leadsReducer";
import ticketsReducer from "./ticketsReducer";
import ordersReducer from "./ordersReducer";
import invoicesReducer from "./invoicesReducer";
import portedOutReducer from "./portedOutReducer";

export default combineReducers({ 
  common: commonReducer,
  auth: authReducer,
  contracts: contractsReducer,
  customers: customersReducer,
  odls: odlsReducer,
  leads: leadsReducer,
  tickets: ticketsReducer,
  orders: ordersReducer,
  invoices: invoicesReducer,
  portedout: portedOutReducer
});
