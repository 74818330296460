import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios";
import queryString from "query-string";
import { BASE_API_URL } from "config/constants";

axios.defaults.withCredentials = true;
axios.defaults.baseURL = BASE_API_URL;


export const getLeadDetails = createAsyncThunk(
  'leads/getLeadDetailsStatus',
  async (docId) => {
    try {
      const response = await axios.get(`/leads/${docId}`)
      //console.log("RESPONSE > > >",response)
      return response.data
    } catch (error) {
      //console.log("ERROR RESPONSE > > >",error.response)
      //return rejectWithValue(error.response.data)
      return {
        status: error.response.status
      }
    }
    
  }
)

export const getLeadMotivations = createAsyncThunk(
  'leads/getLeadMotivationsStatus',
  async () => {
    try {
      const response = await axios.get(`/leads/not-qualified-motivations`)
      //console.log("RESPONSE > > >",response)
      return response.data
    } catch (error) {
      //console.log("ERROR RESPONSE > > >",error.response)
      //return rejectWithValue(error.response.data)
      return {
        status: error.response.status
      }
    }
    
  }
)

export const getLeadsList = createAsyncThunk(
  'leads/getLeadsListStatus',
  async ({data, stateFilter}, _) => {
    try {
      const query = {
        page: data?.page?.current || 1,
        'filter[type]': data?.filters?.type_name?.data.value,
        'filter[lead_origin]': data?.filters?.lead_origin_name?.data.value,
        'filter[first_name]': data?.filters?.first_name?.data,
        'filter[last_name]': data?.filters?.last_name?.data,
        'filter[phone_number]': data?.filters?.phone_number?.data,
        'filter[email]': data?.filters?.email?.data,
        'filter[state]': data?.filters?.state_name?.data.value,
        'filter[doesnt_answer]': data?.filters?.doesnt_answer?.data.value,
        'filter[appointment_between]': data?.filters?.appointment_at?.data,
        //'filter[activated_between]': data?.filters?.active_at?.data,
      }

      const getUrl = () => {
        const mapUrl = {
          'da-lavorare': `/leads?${queryString.stringify(query)}`,
          'lavorate': `/leads/closed?${queryString.stringify(query)}`,
          'appuntamenti': `/leads/appointment?${queryString.stringify(query)}`,
        }

        return mapUrl[stateFilter] || `/leads?${queryString.stringify(query)}`
      }
      //console.log("BEFORE RESPONSE > > >")
      const response = await axios.get(getUrl())
      //const response = await axios.get(`/leads?page=${pagination?.current || 1}`)
      //const response = await axios.get(`/leads?${queryString.stringify(query)}`)
      response.data.filters = data.filters
      //console.log("RESPONSE > > >",response)
      return response.data
    } catch (error) {
      //console.log("ERROR RESPONSE > > >",error.response)
      //return rejectWithValue(error.response.data)
      return {
        status: error.response.status
      }
    }
  }
)

export const unqualifyLead = createAsyncThunk(
  'contracts/unqualifyLeadStatus',
  async (values, { getState }) => {
    try {
      console.log('motivation', values)
      const leadId = getState().leads.selected.id
      const response = await axios.post(`leads/${leadId}/unqualify`, values)
      console.log(response)
      return response.data
    } catch (error) {
      //console.log(error)
      return {
        status: error.response.status,
        errors: error.response.data.errors
      }
      //return rejectWithValue(error.response.data)
    }
  }
)

function isFulfilledAction(action) {
  return action.type.endsWith('/fulfilled')
}

function isPendingAction(action) {
  return action.type.endsWith('/pending')
}

function isRejectedAction(action) {
  return action.type.endsWith('/rejected')
}


const initialState = {
  loading: '',
  error: null,
  selected: null,
  list: [],
  motivations: null
}


const leadsSlice = createSlice({
  name: 'leads',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLeadsList.fulfilled, (state, action) => {
      if(action.payload) {
        const pagination = action.payload.meta
        state.list = action.payload.data
        state.pagination = {
          pageSize: pagination.per_page,
          current: pagination.current_page,
          total: pagination.total
        }
        state.filters = action.payload.filters
      }
    })
    builder.addCase(getLeadDetails.fulfilled, (state, action) => {
      if(action.payload) {
        state.selected = action.payload.data
      }
    })
    builder.addCase(getLeadMotivations.fulfilled, (state, action) => {
      if(action.payload) {
        state.motivations = action.payload.data
      }
    })
    builder.addCase(unqualifyLead.fulfilled, (state, action) => {
      if(action.payload) {
        state.selected = action.payload.data
      }
    })
    builder.addMatcher(isFulfilledAction, (state, action) => {
      state.loading = ''
      state.error = false
    })
    builder.addMatcher(isPendingAction, (state, action) => {
      const id = typeof action.meta.arg === 'object' ? action.meta.arg.id : action.meta.arg
      state.loading = action.type.split('/')[1] + (id ? '_' +id : '')
      state.error = false
    })
    builder.addMatcher(isRejectedAction, (state, action) => {
      state.loading = ''
      state.error = action.error
    })
  }
})

const { reducer } = leadsSlice


export default reducer