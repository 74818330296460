import React, { 
  useState,
  useEffect
} from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col, 
  Form,
  Button,
  Space,
  Modal,
  Checkbox,
  message
} from 'antd';
import { useHistory } from 'react-router-dom';
import { 
  useSelector, 
  useDispatch 
} from 'react-redux';
import { 
  verifyCoverageCity,
  verifyCoverageZipcode,
  createNewContract
} from 'redux/reducers/contractsReducer';

import locale from 'antd/es/date-picker/locale/it_IT';

import CustomerFields from 'components/Form/CustomerFields/CustomerFields';
import ContractFields from 'components/Form/ContractFields/ContractFields';
import ReferenceFields from 'components/Form/ReferenceFields/ReferenceFields';
import SpeedBox from 'components/SpeedBox/SpeedBox';

function PaperContract({ onCancel, onSuccess }) {
  
  const { newContract, loading, error } = useSelector(state => state.contracts);
  const dispatch = useDispatch();
  let history = useHistory();
  const [formContract] = Form.useForm();

  
  const initialValues = {
    client_type: 'privato',
    activation_address: {
      street_name: newContract.coverage ? (newContract.coverage.toponymic_particle + ' ' +  newContract.coverage.street_name) : (newContract?.address?.streetType + ' ' +  newContract?.address?.name || ''),
      address_number: newContract.coverage ? newContract.coverage.address_number : (newContract.addressNumber || ''),
      city: newContract.coverage ? newContract.coverage.city : (newContract.city && newContract.city.name || ''),
      province: newContract.coverage ? newContract.coverage.province : (newContract.city?.province || ''),
      postal_code: '',
    },
    billing_address: {
      street_name: newContract.address && newContract.address.streetType + ' ' +  newContract.address.name || '',
      address_number: newContract.addressNumber || '',
      city: newContract.city && newContract.city.name || '',
      province: newContract.city && newContract.city.province || '',
      postal_code: ''
    }
  };

  const [clientType, setClientType] = useState(initialValues.client_type);
  const [paymentType, setPaymentType] = useState();
  const [zipcodeOptions, setZipcodeOptions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [cityOptions, setCityOptions] = useState([])
  const [validating, setValidating] = useState({
    city: false
  })

  useEffect(()=>{
    const loadZipcode = async () => {
      try{
        const result = await dispatch(verifyCoverageZipcode({
          city: newContract.coverage ? newContract.coverage.city : (newContract?.city?.name || ''),
          province:  newContract.coverage ? newContract.coverage.province : (newContract.city.province || '')
        }))
        // if(result.payload && result.payload.ESITO === 'KO') {
        //   setShowLead(true)
        //   return false
        // }
        //console.log('return > ',result)
        const zipcodes = result && result.payload && result.payload.map(city => ({label: city.label, value: city.CAP})) || []
        setZipcodeOptions(zipcodes)
      }
      catch(err) {
        console.log('error ',err)
      }
    }

    loadZipcode();

  },[])

  const onCopyValues = (from, to) => {
    let values = Object.assign({},formContract.getFieldValue(from));
    if(to === 'billing_address') {
      values.intercom_name = ''
    }
    
    formContract.setFieldsValue({
      [to]: values
    })
  }

  const handleSearchCity = async (text) => {
    if(text.length > 3) {
      try{
        setValidating({ ...validating, city: true })
        const result = await dispatch(verifyCoverageCity(text))        
        const optionsParsed = result.payload && result.payload.reduce((acc,city) => {
          acc.push({
            label: city.label,
            value: city.COMUNE,
            sigla: city.Sigla || ''
          })
           return acc
        },[])

        setValidating({ ...validating, city: false })
        return setCityOptions(optionsParsed)
        
      }
      catch(err) {
        console.log('error ',err)
      }
    }
  }

  const saveProvince = (prefix, field, value) => {
    const section = formContract.getFieldValue(prefix)
    section[field] = value

    formContract.setFieldsValue({
      [prefix]: section
    })
  }

  const handleBackButton = () => {
    formContract.resetFields();
    dispatch({ type: 'contracts/resetState'});
    setShowModal(false);
    onCancel();
  }

  const onFinish = async (values) => {
    
    const result = await dispatch(createNewContract(values))

    if(result.payload.status !== 422 && result.payload.data) {
      const paramId = result.payload.data.id ? '&id='+result.payload.data.id : ''
      history.replace({
        search: history.location.search+paramId
      })
      onSuccess()
    }
    else {
      message.error(`Errore nella compilazione del modulo`);

      const errors = Object.keys(result.payload.errors).map((errorKey,i) => {
        return {
          name: errorKey.split('.'),
          errors: result.payload.errors[errorKey]
        }
      })
      formContract.setFields(errors)
      formContract.scrollToField(errors[0].name,{behavior: 'smooth'})

    }
  };

  
  return (
    <div className="pageContainer">
      <SpeedBox values={newContract.speedValues} />

      <Form 
        form={formContract}
        autoComplete="off"
        name="formNewContract"
        layout="vertical" 
        colon={false} 
        onFinish={onFinish}
        initialValues={initialValues}
        scrollToFirstError={true}
        validateTrigger="onBlur"
      >
        <h3 style={{ marginBottom: 16, color: '#0a3e4b' }}>ANAGRAFICA CLIENTE</h3>
        <CustomerFields 
          initialValues={initialValues} 
          clientType={clientType}
          prefix="customer"
          onSetClientType={setClientType}
          mode="full"
          locale={locale}
          onSearchCity={handleSearchCity}
          onSelectedCity={saveProvince}
          cityOptions={cityOptions}
          validating={validating}
        />

        <h3 style={{ marginBottom: 16, color: '#0a3e4b' }}>REFERENTE</h3>
        <ReferenceFields prefix="referent" />
        
        <ContractFields 
          locale={locale}
          initialValues={initialValues}
          paymentType={paymentType}
          onSetPaymentType={setPaymentType}
          zipcodeOptions={zipcodeOptions}
          onCopyValues={(from,to) => onCopyValues(from,to)}
        />
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Form.Item 
              name="cgc_agreement"
              //label="E-mail"
              initialValue={false}
              valuePropName="checked"
              rules={[
                {
                  required: true,
                  message: 'Campo obbligatorio'
                },
              ]}
            >
              <Checkbox>CGC agreement*</Checkbox>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Form.Item 
              name="contract_agreement"
              //label="E-mail"
              initialValue={false}
              valuePropName="checked"
              rules={[
                {
                  required: true,
                  message: 'Campo obbligatorio'
                },
              ]}
            >
              <Checkbox>Contract agreement*</Checkbox>
            </Form.Item>
          </Col>
        </Row>  
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Form.Item 
              name="commercial_agreement"
              //label="E-mail"
              initialValue={false}
              valuePropName="checked"
            >
              <Checkbox>Commercial agreement (opzionale)</Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row justify="center" align="middle">
          <Col>
            <Space 
              align="center"
              size="large"
              //className="actionBar" 
              //{...tailLayout}
            >
              <Button 
                size="large" 
                onClick={() => setShowModal(true)}
                disabled={loading !== ''}
              >
                  Indietro
                </Button>
              <Button 
                type="primary" 
                size="large"
                htmlType="submit"
                disabled={loading === 'createNewContractStatus'}
                loading={loading === 'createNewContractStatus'}
              >
                Salva
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
      <Modal
        title="Sei sicuro?"
        visible={showModal}
        // onOk={handleBackButton}
        onCancel={() => setShowModal(false)}
        footer={[
          <Button 
            key="back" 
            onClick={() => setShowModal(false)}
          >
            Annulla
          </Button>,
          <Button 
            key="submit" 
            type="primary" 
            onClick={handleBackButton}
          >
            Confermo
          </Button>
        ]}
      >
        <p>Tornando indietro andranno i dati inseriti andranno persi</p>
      </Modal>
    </div>
  )
}

PaperContract.propTypes = {
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func
}

export default PaperContract;