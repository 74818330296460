import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col, 
  Form,
  Input,
  Select,
  Checkbox
} from 'antd';


function AddressFields({ 
  prefix, 
  labels,
  title, 
  disabled, 
  includeInternal,
  initialValues, 
  zipcodeOptions, 
  notRequiredFields,
  action, 
  onBlur ,
  onChange
}) {
  
  return (
    <>
      {
        (title || action) && 
        <div style={{display: 'flex', justifyContent:'space-between'}}>
          {title && <h3 style={{ color: '#0a3e4b' }}>{title.toUpperCase()}</h3>}
          {
            action && 
              <Checkbox defaultChecked={action.value} onChange={action.execute}>{action.label}</Checkbox>
              // <Button type="link" onClick={action.execute}>{action.label}</Button>
          }
        </div>
      }
      
      <div style={{padding: '16px 0px'} } style={{ display: action?.hidden == false ? 'none' : 'block' }}>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item 
              name={[prefix,'intercom_name']}
              label={labels?.intercom || "Riferimento citofono"}
              rules={[
                {
                  required: typeof notRequiredFields?.intercom_name !== 'undefined' ? notRequiredFields?.intercom_name : true,
                  message: 'Campo obbligatorio'
                },
              ]}
            >
              <Input
                size="large"
                disabled={typeof disabled === 'object' ? !disabled.includes('intercom_name') : disabled}
                onBlur={onBlur}
                onChange={onChange}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item 
              name={[prefix,'street_name']}
              label="Indirizzo"
              rules={[
                {
                  required: true,
                  message: 'Campo obbligatorio'
                },
              ]}
            >
              <Input
                size="large"
                value={initialValues && initialValues[prefix].street || ''}
                disabled={typeof disabled === 'object' ? !disabled.includes('street_name') : disabled}
                onBlur={onBlur}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item 
              name={[prefix,'address_number']}
              label="Civico"
              rules={[
                {
                  required: true,
                  message: 'Campo obbligatorio'
                },
              ]}
            >
              <Input
                size="large"
                value={initialValues && initialValues[prefix].number || ''}
                disabled={typeof disabled === 'object' ? !disabled.includes('address_number') : disabled}
                onBlur={onBlur}
              />
            </Form.Item>
          </Col>
        </Row>

        {
          includeInternal &&
          <>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item 
                  name={[prefix,'stair']}
                  label="Scala"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: 'Campo obbligatorio'
                  //   },
                  // ]}
                >
                  <Input
                    size="large"
                    value={initialValues && initialValues[prefix].stair || ''}
                    disabled={typeof disabled === 'object' ? !disabled.includes('stair') : disabled}
                    onBlur={onBlur}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item 
                  name={[prefix,'nui']}
                  label="Interno"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: 'Campo obbligatorio'
                  //   },
                  // ]}
                >
                  <Input
                    size="large"
                    value={initialValues && initialValues[prefix].nui || ''}
                    disabled={typeof disabled === 'object' ? !disabled.includes('nui') : disabled}
                    onBlur={onBlur}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item 
                  name={[prefix,'floor']}
                  label="Piano"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: 'Campo obbligatorio'
                  //   },
                  // ]}
                >
                  <Input
                    size="large"
                    value={initialValues && initialValues[prefix].floor || ''}
                    disabled={typeof disabled === 'object' ? !disabled.includes('floor') : disabled}
                    onBlur={onBlur}
                  />
                </Form.Item>
              </Col>
            </Row>
          </>
        }
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item 
              name={[prefix,'city']}
              label="Città"
              rules={[
                {
                  required: true,
                  message: 'Campo obbligatorio'
                },
              ]}
            >
              <Input
                size="large"
                value={initialValues && initialValues[prefix].city || ''}
                disabled={typeof disabled === 'object' ? !disabled.includes('city') : disabled}
                onBlur={onBlur}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item 
              name={[prefix,'province']}
              label="Provincia"
              rules={[
                {
                  required: true,
                  message: 'Campo obbligatorio'
                },
              ]}
            >
              <Input
                size="large"
                value={initialValues && initialValues[prefix].province || ''}
                disabled={typeof disabled === 'object' ? !disabled.includes('province') : disabled}
                onBlur={onBlur}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item 
              name={[prefix,'postal_code']}
              label="CAP"
              rules={[
                {
                  required: true,
                  message: 'Campo obbligatorio'
                },
              ]}
            >
            {
              (zipcodeOptions && zipcodeOptions.length > 0) ?
              <Select 
                showSearch
                disabled={typeof disabled === 'object' ? !disabled.includes('postal_code') : disabled}
                size="large"
                value={initialValues && initialValues.zipcode || ''}
                options={zipcodeOptions}
                onChange={(e) => { onBlur(e); onChange(e); }}
                /> :
              <Input
                size="large"
                value={initialValues && initialValues.zipcode || ''}
                disabled={typeof disabled === 'object' ? !disabled.includes('postal_code') : disabled}
                onBlur={onBlur}
              />
            }
            </Form.Item>
          </Col>
        </Row>
      </div>
    </>
  )
}

AddressFields.defaultProps = {
  disabled: false,
  includeInternal: false,
  onChange: (e) => {}
}

AddressFields.propTypes = {
  prefix: PropTypes.string,
  labels: PropTypes.object,
  title: PropTypes.string,
  disabled: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.bool
  ]),
  notRequiredFields: PropTypes.object,
  includeInternal: PropTypes.bool,
  zipcodeOptions: PropTypes.array,
  onChange: PropTypes.func
}

export default AddressFields;