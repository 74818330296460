import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col, 
  Form,
  Button,
  Space,
  Modal,
  Checkbox,
  Select,
  message,
  Alert,
} from 'antd';

import { useHistory, useLocation } from 'react-router-dom';
import { 
  useSelector, 
  useDispatch
} from 'react-redux';

import {  
  createNewContractDigital
} from 'redux/reducers/contractsReducer';

import locale from 'antd/es/date-picker/locale/it_IT';

//import ContractForm from './ContractForm';
//import ReferenceFields from './ReferenceFields';
import CustomerForm from 'components/Form/CustomerFields/CustomerFields';
import SpeedBox from 'components/SpeedBox/SpeedBox';



function DigitalCustomer({ onCancel, onSuccess, allOffers }) {
  
  const { newContract, loading, error } = useSelector(state => state.contracts);
  const dispatch = useDispatch();
  let history = useHistory();
  const params = new URLSearchParams(useLocation().search)

  const [formContract] = Form.useForm();

  const initialValues = {
    client_type: 'privato'
  };

  const [clientType, setClientType] = useState(initialValues.client_type);
  const [showModal, setShowModal] = useState(false);


  const handleBackButton = () => {
    formContract.resetFields();
    dispatch({ type: 'contracts/resetState'});
    setShowModal(false);
    onCancel();
  }

  const onFinish = async (values) => {
    //console.log(formContract.getFieldsValue());
    const lead_id = params.get('lead_id')
    const result = await dispatch(createNewContractDigital({values, lead_id}))

    //console.log('result > ',result)
    if(result.payload.status !== 422 && result.payload.data) {
      const paramId = result.payload.data.id ? '&id='+result.payload.data.id : ''
      history.replace({
        search: history.location.search+paramId
      })
      onSuccess()
    }
    else {
      //console.log(result)
      message.error(`Errore nella compilazione del modulo`);
    }
  };
  
  return (
    <div className="pageContainer">
      <SpeedBox values={newContract.speedValues} />

      <Form 
        form={formContract}
        name="formNewContract"
        layout="vertical" 
        colon={false} 
        onFinish={onFinish}
        initialValues={initialValues}
        scrollToFirstError={true}
        validateTrigger="onBlur"
      >
        <h3 style={{ marginBottom: 16, color: '#0a3e4b' }}>TIPOLOGIA ABBONAMENTO</h3>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item 
                name={"commercial_offer_id"}
                label="Offerta commericale"
                initialValue={allOffers[0].value}
                rules={[
                  {
                    required: true,
                    message: 'Campo obbligatorio'
                  }
                ]}
              >
                <Select
                  showSearch
                  size="large"
                  options={allOffers}
                  //onSelect={(value, option) => option.value === 'Italia' ? setProvinceDisable(false) : setProvinceDisable(true)}
                />
              </Form.Item>
            </Col>
          </Row>
       
        <h3 style={{ marginBottom: 16, color: '#0a3e4b' }}>ANAGRAFICA CLIENTE</h3>
        <CustomerForm 
          initialValues={initialValues} 
          clientType={clientType}
          prefix="customer"
          onSetClientType={setClientType}
          mode="first_half"
          locale={locale}
        />

        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Form.Item 
              name="lead_agreement"
              //label="E-mail"
              initialValue={false}
              valuePropName="checked"
            >
              <Checkbox>Accetta privacy (opzionale)</Checkbox>
            </Form.Item>
          </Col>
        </Row>

        <Row justify="center" align="middle">
          <Col>
            <Space 
              align="center"
              size="large"
              //className="actionBar" 
              //{...tailLayout}
            >
              <Button 
                size="large" 
                onClick={() => setShowModal(true)}
                disabled={loading !== ''}
              >
                  Indietro
                </Button>
              <Button 
                type="primary" 
                size="large"
                htmlType="submit"
                disabled={loading === 'createNewContractDigitalStatus'}
                loading={loading === 'createNewContractDigitalStatus'}
              >
                Salva
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
      <Modal
        title="Sei sicuro?"
        visible={showModal}
        // onOk={handleBackButton}
        onCancel={() => setShowModal(false)}
        footer={[
          <Button 
            key="back" 
            onClick={() => setShowModal(false)}
          >
            Annulla
          </Button>,
          <Button 
            key="submit" 
            type="primary" 
            onClick={handleBackButton}
          >
            Confermo
          </Button>
        ]}
      >
        <p>Tornando indietro andranno i dati inseriti andranno persi</p>
      </Modal>
    </div>
  )
}

DigitalCustomer.propTypes = {
  allNations: PropTypes.array,
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func
}

export default DigitalCustomer;