import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col, 
  Form,
  Input
} from 'antd';

//import './step2.css';

function ReferenceFields({ prefix }) {
  
  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Form.Item 
            name={[prefix,"first_name"]}
            label="Nome referente"
            rules={[
              {
                required: true,
                message: 'Campo obbligatorio'
              },
            ]}
          >
            <Input
              size="large"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item 
            name={[prefix,"last_name"]}
            label="Cognome referente"
            rules={[
              {
                required: true,
                message: 'Campo obbligatorio'
              },
            ]}
          >
            <Input
              size="large"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Form.Item 
            name={[prefix,"phone_number"]}
            label="Cellulare referente"
            rules={[
              {
                required: true,
                message: 'Campo obbligatorio'
              },
              {
                pattern: new RegExp(/(3{1}[2-9]{1}[0-9]{1})(\d{6,7})/),
                message: 'Numero di cellulare non valido'
              }
            ]}
          >
            <Input
              size="large"
              //type="number"
              addonBefore="+39"
              minLength={9}
              maxLength={10}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item 
            name={[prefix,"home_phone_number"]}
            label={'Telefono fisso referente'}
            rules={[
              {
                pattern: new RegExp(/0{1}\d{6,12}/),
                message: "Telefono fisso non valido"
              }
            ]}
          >
            <Input
              size="large"
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  )
}

// ReferenceFields.defaultProps = {
//   disabled: false
// }

ReferenceFields.propTypes = {
  prefix: PropTypes.string
}

export default ReferenceFields;