import React from 'react';
import {
  Button,
  Tag
} from 'antd';

function TicketBox({ticket, onSelect}) {
  const containerStyle = {
    height: '160px',
    color: '#fff',
    //textAlign: 'center',
    background: '#364d79',
    display: 'flex',
    padding: '24px 24px 32px'
  };

  const contentStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    flex: 1
  };
  const actionStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  };
  
  return (
    <div style={containerStyle}>
      <div style={contentStyle}>
  <div><strong>Ticket id:</strong> {ticket.id} <Tag color="blue" size="large">{ticket.state_name}</Tag></div>
        <div><strong>Tipologia:</strong> {ticket.support_cat_first?.name || '---'}</div>
        <div><strong>Famiglia:</strong> {ticket.support_cat_second?.name || '---'}</div>
        <div><strong>Sottofamiglia:</strong> {ticket.support_cat_third?.name || '---'}</div>
        <div><strong>Solleciti:</strong> {ticket.reminders}</div>
      </div>
      <div style={actionStyle}>
        <Button 
          //type="primary" 
          onClick={() => onSelect(ticket.id)}
          size="large"
        >
          Lavora
        </Button>
      </div>
    </div>
  )
}

export default TicketBox;