import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import moment from 'moment';
import queryString from "query-string";
import { BASE_API_URL, BASE_PAYMENT_URL } from "config/constants";
import convertJsonToFormData from "utils/convertJsonToFormData";
import { useLocation } from 'react-router-dom';

axios.defaults.withCredentials = true;
axios.defaults.baseURL = BASE_API_URL;

export const verifyCoverageCity = createAsyncThunk(
  "contracts/verifyCoverageCityStatus",
  async (searchText, thunkAPI) => {
    const response = await axios.post("coverage/city", {
      search: searchText,
    });
    return response.data.risultato || response.data;
  }
);

export const getCity = createAsyncThunk(
  "contracts/getCityStatus",
  async (searchText, thunkAPI) => {
    const response = await axios.post('cities/search-city', {
      search: searchText,
    });
    return response.data.data;
  }
);

export const verifyCoverageAddress = createAsyncThunk(
  "contracts/verifyCoverageAddressStatus",
  async (address, thunkAPI) => {
    const response = await axios.post("coverage/street_name", {
      city: address.city,
      province: address.province,
      search: address.text,
    });
    return response.data.risultato || response.data;
  }
);

export const verifyCoverageAddressNumber = createAsyncThunk(
  "contracts/verifyCoverageAddressNumberStatus",
  async (address, thunkAPI) => {
    const response = await axios.post("coverage/address_number", {
      city: address.city,
      province: address.province,
      street_name: address.address,
      toponymic_particle: address.streetType,
      search: address.text,
    });

    return response.data.risultato || response.data;
  }
);

// export const verifyCoverageZipcode = createAsyncThunk(
//   "contracts/verifyCoverageZipcodeStatus",
//   async (address, thunkAPI) => {
//     const response = await axios.post("coverage/postal_code", {
//       city: address.city,
//       province: address.province,
//     });

//     return response.data.risultato || response.data;
//   }
// );

export const verifyCoverageZipcode = createAsyncThunk(
  "contracts/verifyCoverageZipcodeStatus",
  async (address, thunkAPI) => {
    const response = await axios.post("cities/search-postal-code", {
      istat: address.istat
    });

    return response.data.data
  }
);

export const verifyCoverageFinal = createAsyncThunk(
  "contracts/verifyCoverageStatus",
  async (address, thunkAPI) => {
    const response = await axios.post("coverage/check", {
      city: address.city,
      province: address.province,
      istat: address.cod,
      street_name: address.address,
      toponymic_particle: address.streetType,
      address_number: address.addressNumber,
      egon: address.addressNumberEgon,
      lead_id: address.leadId
    });

    return response.data;
  }
);

export const verifyCoverageFinalMock = createAsyncThunk(
  "contracts/verifyCoverageStatus",
  async (address, thunkAPI) => {
    const response = await axios.post("coverage/check-mock");

    return response.data;
  }
);

export const addLeadFinal = createAsyncThunk(
  "contracts/addLeadStatus",
  async (lead, { getState }) => {
    try {
      const newContract = getState().contracts.newContract;
      const response = await axios.post("leads/create/coverage-ko", {
        first_name: lead.firstName,
        last_name: lead.lastName,
        phone_number: lead.phone,
        email: lead.email,
        privacy_accepted: lead.privacy_accepted,
        coverage: {
          city: newContract?.city.name,
          province: newContract?.city.province,
          toponymic_particle: newContract?.address.streetType,
          street_name: newContract?.address.name,
          address_number: newContract?.addressNumber,
        },
      });
      //console.log('RESPONSE > ',response)
      return response.data;
    } catch (error) {
      console.log("ERROR LEAD > ", error);
      return {
        status: error.response.status,
        message: error.response.data.message,
        errors: error.response.data.errors,
      };
    }
  }
);

export const checkLeadOtp = createAsyncThunk(
  "contracts/checkLeadOtpStatus",
  async (otp, { getState }) => {
    try {
      //console.log(otp)
      const newContract = getState().contracts.newContract;
      const response = await axios.post(
        `/contracts/creating/${newContract.id}/digital/check-lead-otp`,
        otp
      );
      //console.log('RESPONSE > ',response)
      return response.data;
    } catch (error) {
      //console.log('ERROR UPLOAD > ',error.response)
      return {
        status: error.response.status,
        message: error.response.data.message,
        errors: error.response.data.errors,
      };
    }
  }
);

export const resendLeadOtp = createAsyncThunk(
  "contracts/resendLeadOtpStatus",
  async (otp, { getState }) => {
    try {
      const newContract = getState().contracts.newContract;
      const response = await axios.post(
        `/contracts/creating/${newContract.id}/digital/resend-lead-otp`,
        otp
      );
      //console.log('RESPONSE > ',response)
      return response.status;
    } catch (error) {
      //console.log('ERROR UPLOAD > ',error.response)
      return {
        status: error.response.status,
        message: error.response.data.message,
      };
    }
  }
);

export const createPaymentCC = createAsyncThunk(
  "contracts/createPaymentCCStatus",
  async (paymentObj, { getState }) => {
    try {
      const response = await axios({
        method: "POST",
        url: BASE_PAYMENT_URL + "/axerve/payment/create/",
        headers: {
          "Content-Type": "application/json",
        },
        data: paymentObj,
      });

      return response.data;
    } catch (error) {
      return {
        status: error.response.status,
        message: error.response.data.message,
      };
    }
  }
);

export const submitPaymentCC = createAsyncThunk(
  "contracts/submitPaymentCCStatus",
  async (paymentObj, { getState }) => {
    try {
      const response = await axios({
        method: "POST",
        url: BASE_PAYMENT_URL + "/axerve/payment/submit/",
        headers: {
          paymentToken: paymentObj.token,
          "Content-Type": "application/json",
        },
        data: paymentObj.data,
      });
      return response.data;
    } catch (error) {
      return {
        status: error.response.status,
        message: error.response.data.message,
      };
    }
  }
);

export const insertPaymentMethod = createAsyncThunk(
  "contracts/insertPaymentMethodStatus",
  async ({ paymentMethod, paymentValues, contractFormat }, { getState }) => {
    try {
      const newContract = getState().contracts.newContract;
      const response = await axios.post(
        `/contracts/creating/${newContract.id}/${contractFormat}/payment/${paymentMethod}/insert`,
        paymentValues
      );
      //console.log('RESPONSE > ',response)
      return response.data;
    } catch (error) {
      //console.log('ERROR UPLOAD > ',error.response)
      return {
        status: error.response.status,
        message: error.response.data.message,
        errors: error.response.data.errors,
      };
    }
  }
);

export const createNewContract = createAsyncThunk(
  "contracts/createNewContractStatus",
  async (formValues, { getState }) => {
    try {
      const newContract = getState().contracts.newContract;
      const {
        activeLine,
        city,
        address,
        addressNumber,
        connection_type_id,
        egon_number,
        speedValues,
        kit_id,
      } = newContract;

      const formToSubmit = {
        ...formValues,
        delivery_address: formValues.activation_address,
        coverage: {
          city: city.name,
          city_code: city.cod || 'X',
          province: city.province,
          postal_code: formValues.activation_address.postal_code,
          toponymic_particle: address.streetType,
          street_name: address.name,
          address_number: addressNumber,
          egon_number: egon_number,
          upload_speed: speedValues.upload,
          download_speed: speedValues.download,
          actual_upload_speed: speedValues.actual_upload,
          actual_download_speed: speedValues.actual_download,
          connection_type_id: connection_type_id,
          kit_id: kit_id,
          latency: speedValues.latency,
        },
        active_line: activeLine,
      };

      const formToSubmitParsed = convertJsonToFormData(formToSubmit);
      //console.log('PARSED : ',formToSubmit, formToSubmitParsed)

      const response = await axios.post(
        "contracts/create/paper",
        formToSubmitParsed
      );

      return response.data;
    } catch (error) {
      return {
        status: error.response.status,
        errors: error.response.data.errors,
      };
      //return rejectWithValue(error.response.data)
    }
  }
);

export const createNewContractDigital = createAsyncThunk(
  "contracts/createNewContractDigitalStatus",
  async ({ values, lead_id }, { getState }) => {
    try {
      const newContract = getState().contracts.newContract;
      const {
        activeLine,
        coverage_id,
        // city,
        // address,
        // addressNumber,
        // connection_type_id,
        // egon_number,
        // speedValues,
        // kit_id
      } = newContract;

      const formToSubmit = {
        ...values,
        // coverage: {
        //   city: city.name,
        //   province: city.province,
        //   toponymic_particle: address.streetType,
        //   street_name: address.name,
        //   address_number: addressNumber,
        //   egon_number: egon_number,
        //   upload_speed: speedValues.upload,
        //   download_speed: speedValues.download,
        //   actual_upload_speed: speedValues.actual_upload,
        //   actual_download_speed: speedValues.actual_download,
        //   connection_type_id: connection_type_id,
        //   kit_id: kit_id,
        //   latency: 18
        // },
        coverage_id: coverage_id,
        active_line: activeLine,
        lead_id: lead_id,
      };

      const formToSubmitParsed = convertJsonToFormData(formToSubmit);
      //console.log('PARSED : ',formToSubmit, formToSubmitParsed)

      const response = await axios.post(
        "contracts/create/digital",
        formToSubmitParsed
      );

      return response.data;
    } catch (error) {
      //console.log(error)
      return {
        status: error.response.status,
        errors: error.response.data.errors,
      };
      //return rejectWithValue(error.response.data)
    }
  }
);

export const insertContractDataDigital = createAsyncThunk(
  "contracts/insertContractDataDigitalStatus",
  async (formValues, { getState }) => {
    try {
      const newContract = getState().contracts.newContract;

      const formToSubmit = {
        ...formValues,
        // delivery_address: formValues.activation_address
      };

      const formToSubmitParsed = convertJsonToFormData(formToSubmit);
      //console.log('PARSED insertContractDataDigital: ',formToSubmit, formToSubmitParsed)
      const response = await axios.post(
        `/contracts/creating/${newContract.id}/digital/data`,
        formToSubmitParsed
      );

      return response.data;
    } catch (error) {
      return {
        status: error.response.status,
        errors: error.response.data.errors,
      };
      //return rejectWithValue(error.response.data)
    }
  }
);

export const getContractDetails = createAsyncThunk(
  "contracts/getContractDetailsStatus",
  async (docId, thunkAPI) => {
    try {
      const response = await axios.get(`/contracts/${docId}`);
      //console.log("RESPONSE > > >",response)
      return response.data;
    } catch (error) {
      //console.log("ERROR RESPONSE > > >",error.response)
      //return rejectWithValue(error.response.data)
      return {
        status: error.response.status,
      };
    }
  }
);

export const getContractCreatingDetails = createAsyncThunk(
  "contracts/getContractCreatingDetailsStatus",
  async (docId, thunkAPI) => {
    try {
      const response = await axios.get(`/contracts/creating/${docId}`);
      //console.log("RESPONSE > > >",response)
      return response.data;
    } catch (error) {
      //console.log("ERROR RESPONSE > > >",error.response)
      //return rejectWithValue(error.response.data)
      return {
        status: error.response.status,
      };
    }
  }
);

export const getContractDetailsCustomer = createAsyncThunk(
  "contracts/getContractDetailsCustomerStatus",
  async (docId, thunkAPI) => {
    try {
      const response = await axios.get(`/contracts/${docId}/customer`);
      //console.log("RESPONSE > > >",response)
      return response.data;
    } catch (error) {
      //console.log("ERROR RESPONSE > > >",error.response)
      //return rejectWithValue(error.response.data)
      return {
        status: error.response.status,
      };
    }
  }
);

export const getContractDetailsNotifications = createAsyncThunk(
  "contracts/getContractDetailsNotificationsStatus",
  async (docId, thunkAPI) => {
    try {
      const response = await axios.get(`/contracts/${docId}/olo-flow`);
      //console.log("RESPONSE > > >",response)
      return response.data;
    } catch (error) {
      //console.log("ERROR RESPONSE > > >",error.response)
      //return rejectWithValue(error.response.data)
      return {
        status: error.response.status,
      };
    }
  }
);

export const getContractDetailsHistory = createAsyncThunk(
  "contracts/getContractDetailsHistoryStatus",
  async (docId, thunkAPI) => {
    try {
      const response = await axios.get(`/contracts/${docId}/history`);
      //console.log("RESPONSE > > >",response)
      return response.data;
    } catch (error) {
      //console.log("ERROR RESPONSE > > >",error.response)
      //return rejectWithValue(error.response.data)
      return {
        status: error.response.status,
      };
    }
  }
);

export const getContractDetailsShippings = createAsyncThunk(
  "contracts/getContractDetailsShippingsStatus",
  async (docId, thunkAPI) => {
    try {
      const response = await axios.get(`/contracts/${docId}/shippings`);
      //console.log("RESPONSE > > >",response)
      return response.data;
    } catch (error) {
      //console.log("ERROR RESPONSE > > >",error.response)
      //return rejectWithValue(error.response.data)
      return {
        status: error.response.status,
      };
    }
  }
);

export const getContractDetailsNetwork = createAsyncThunk(
  "contracts/getContractDetailsNetworkStatus",
  async (docId, thunkAPI) => {
    try {
      const response = await axios.get(`/contracts/${docId}/network-data`);
      //console.log("RESPONSE > > >",response)
      return response.data;
    } catch (error) {
      //console.log("ERROR RESPONSE > > >",error.response)
      //return rejectWithValue(error.response.data)
      return {
        status: error.response.status,
      };
    }
  }
);

export const getCommercialOffers = createAsyncThunk(
  "contracts/getCommercialOffersStatus",
  async () => {
    try {
      const response = await axios.get(`/commercial-offers`);
      console.log("RESPONSE > > >", response.data);
      return response.data;
    } catch (error) {
      //console.log("ERROR RESPONSE > > >",error.response)
      //return rejectWithValue(error.response.data)
      return {
        status: error.response.status,
      };
    }
  }
);

export const getContractToActivateDetails = createAsyncThunk(
  "contracts/getContractToActivateDetailsStatus",
  async (docId, { getState }) => {
    try {
      const response = await axios.get(`/contracts/creating/${docId}`);
      //console.log("RESPONSE > > >",response)
      return response.data;
    } catch (error) {
      //console.log("ERROR RESPONSE > > >",error.response)
      //return rejectWithValue(error.response.data)
      return {
        status: error.response.status,
      };
    }
  }
);

export const confirmCreatingContract = createAsyncThunk(
  "contracts/confirmCreatingContractStatus",
  async (docId, { getState }) => {
    try {
      const newContract = getState().contracts.newContract;
      const response = await axios.post(
        `/contracts/creating/${newContract.id}/paper/confirm`
      );
      //console.log('RESPONSE > ',response)
      return response.status;
    } catch (error) {
      //onsole.log('ERROR UPLOAD > ',error.response)
      return {
        status: error.response.status,
        message: error.response.data.message,
        errors: error.response.data.errors,
      };
    }
  }
);

export const confirmApproveContract = createAsyncThunk(
  "contracts/confirmApproveContractStatus",
  async (docId, { getState }) => {
    try {
      const newContract = getState().contracts.newContract;
      const response = await axios.post(
        `/contracts/creating/${newContract.id}/paper/approve`
      );
      //console.log('RESPONSE > ',response)
      return response;
    } catch (error) {
      //console.log('ERROR UPLOAD > ',error.response)
      return {
        status: error.response.status,
        message: error.response.data.message,
      };
    }
  }
);

export const removeDocument = createAsyncThunk(
  "contracts/removeDocumentStatus",
  async (docId, { getState }) => {
    try {
      const newContract = getState().contracts.newContract;
      const response = await axios.post(
        `/contracts/creating/${newContract.id}/paper/remove/${docId}`
      );
      //console.log('RESPONSE > ',response)
      return response.data;
    } catch (error) {
      //console.log('ERROR UPLOAD > ',error)
    }
  }
);

export const uploadDocument = createAsyncThunk(
  "contracts/uploadDocumentStatus",
  async (doc, { getState }) => {
    try {
      //console.log('DOC > ',doc)
      const { id, file, onProgress } = doc;
      const newContract = getState().contracts.newContract;
      const formData = new FormData();
      formData.append("file", file);

      const response = await axios.post(
        `/contracts/creating/${newContract.id}/paper/upload/${id}`,
        formData,
        {
          onUploadProgress: (e) => {
            onProgress({ percent: (e.loaded / e.total) * 100 });
          },
        }
      );
      //console.log('RESPONSE > ',response)
      return response.data;
    } catch (error) {
      //console.log('ERROR UPLOAD > ',error)
    }
  }
);

export const downloadDocument = createAsyncThunk(
  "contracts/downloadDocumentStatus",
  async (doc, { getState }) => {
    const type = doc.type === "generated" ? "generated" : "uploaded";
    const newContract = getState().contracts.newContract;
    //console.log('newContracts > ',newContract)
    const response = await axios.get(
      `/contracts/creating/${newContract.id}/paper/download/${type}/${doc.id}`
    );
    //console.log(response.data.data)
    return response.data.data;
  }
);

export const downloadDocumentContracts = createAsyncThunk(
  "contracts/downloadDocumentContractsDocumentStatus",
  async ({ type, docId, isCreating }, { getState }) => {
    const contractId = getState().contracts.selected.id;
    let response;
    if (isCreating) {
      response = await axios.get(
        `/contracts/creating/${contractId}/paper/download/${type}/${docId}`
      );
    } else {
      response = await axios.get(
        `/contracts/${contractId}/documentation/${type}/${docId}/download`
      );
    }
    //console.log(response.data.data)
    return response.data.data;
  }
);

export const approveDocument = createAsyncThunk(
  "contracts/approveDocumentStatus",
  async (docId, { getState }) => {
    //const type = doc.type === 'generated' ? 'generated' : 'uploaded';
    const newContract = getState().contracts.newContract;
    //console.log('newContracts > ',newContract)
    const response = await axios.get(
      `/contracts/creating/${newContract.id}/paper/allow/uploaded/${docId}`
    );
    //console.log(response.data.data)
    return response.data;
  }
);

export const creatingContract = createAsyncThunk(
  "contracts/creatingContractStatus",
  async (docId, { getState }) => {
    try {
      const response = await axios.get(`/contracts/creating/${docId}/paper`);
      // console.log("RESPONSE > > >",response)
      return response.data;
    } catch (error) {
      //console.log("ERROR RESPONSE > > >",error.response)
      //return rejectWithValue(error.response.data)
      return {
        status: error.response.status,
        errors: error.response.data.errors,
      };
    }
  }
);

export const getContractsToUploadList = createAsyncThunk(
  "contracts/getContractsToUploadListStatus",
  async (pagination) => {
    try {
      const response = await axios.get(
        `/contracts/uploading?page=${pagination?.current || 1}`
      );
      //console.log("RESPONSE > > >",response)
      return response.data;
    } catch (error) {
      //console.log("ERROR RESPONSE > > >",error.response)
      //return rejectWithValue(error.response.data)
      return {
        status: error.response.status,
      };
    }
  }
);

export const getContractsCreatingList = createAsyncThunk(
  "contracts/getContractsCreatingListStatus",
  async (data) => {
    try {
      //const response = await axios.get(`/contracts/creating?page=${pagination?.current || 1}`)

      const query = {
        page: data?.page?.current || 1,
        "filter[customer.first_name]":
          data?.filters?.["customer.first_name"]?.data,
        "filter[customer.last_name]":
          data?.filters?.["customer.last_name"]?.data,
        "filter[carrier]": data?.filters?.carrier?.data,
        "filter[connection_type.zone]": data?.filters?.["connection_type.zone"]?.data.value,
        "filter[coverage.city]": data?.filters?.["coverage.city"]?.data,
        "filter[coverage.street_name]":
          data?.filters?.["coverage.street_name"]?.data,
        // 'filter[state]': data?.filters?.state_name?.data.value,
        // 'filter[recess_requested]': data?.filters?.recess_requested?.data.value,
        // 'filter[cancel_requested]': data?.filters?.cancel_requested?.data.value,
        // 'filter[created_between]': data?.filters?.created_at?.data,
        // 'filter[activated_between]': data?.filters?.activated_at?.data,
      };
      //console.log("BEFORE RESPONSE > > >")
      const response = await axios.get(
        `/contracts/creating?${queryString.stringify(query)}`
      );
      response.data.filters = data.filters;

      return response.data;
    } catch (error) {
      //console.log("ERROR RESPONSE > > >",error.response)
      //return rejectWithValue(error.response.data)
      return {
        status: error.response.status,
      };
    }
  }
);

export const getContractsToValidateList = createAsyncThunk(
  "contracts/getContractsToValidateListStatus",
  async (pagination) => {
    try {
      const response = await axios.get(
        `/contracts/validating?page=${pagination?.current || 1}`
      );
      //console.log("RESPONSE > > >",response)
      return response.data;
    } catch (error) {
      //console.log("ERROR RESPONSE > > >",error.response)
      //return rejectWithValue(error.response.data)
      return {
        status: error.response.status,
      };
    }
  }
);

export const getContractsRidMissingList = createAsyncThunk(
  "contracts/getContractsRidMissingListStatus",
  async (pagination) => {
    try {
      const response = await axios.get(
        `/contracts/rid-missing?page=${pagination?.current || 1}`
      );
      //console.log("RESPONSE > > >",response)
      return response.data;
    } catch (error) {
      //console.log("ERROR RESPONSE > > >",error.response)
      //return rejectWithValue(error.response.data)
      return {
        status: error.response.status,
      };
    }
  }
);

export const getContractsListNotSurfing = createAsyncThunk(
  "contracts/getContractsListNotSurfingStatus",
  async (data) => {
    try {
      const query = {
        page: data?.page?.current || 1,
        "filter[contract_code]": data?.filters?.contract_code?.data,
        "filter[carrier]": data?.filters?.carrier?.data,
        "filter[connection_type.zone]": data?.filters?.["connection_type.zone"]?.data.value,
        "filter[bp_code]": data?.filters?.bp_code?.data,
        "filter[customer.first_name]": data?.filters?.["customer.first_name"]?.data,
        "filter[customer.last_name]": data?.filters?.["customer.last_name"]?.data,
        "filter[coverage.city]": data?.filters?.["coverage.city"]?.data,
        "filter[coverage.street_name]": data?.filters?.["coverage.street_name"]?.data,
        "filter[state]": data?.filters?.state_name?.data.value,
        "filter[recess_requested]": data?.filters?.recess_requested?.data.value,
        "filter[cancel_requested]": data?.filters?.cancel_requested?.data.value,
        "filter[created_between]": data?.filters?.created_at?.data,
        "filter[activated_between]": data?.filters?.activated_at?.data,
        "filter[canceled_between]": data?.filters?.activated_at?.data,
      };
      //console.log("BEFORE RESPONSE > > >")
      const response = await axios.get(
        `/contracts/not-surfing?${queryString.stringify(query)}`
      );
      response.data.filters = data.filters;
      //console.log("RESPONSE > > >",response)
      return response.data;
    } catch (error) {
      console.log("ERROR RESPONSE GET CONTRACT LIST > > >", error);
      //return rejectWithValue(error.response.data)
      return {
        status: error.response.status,
      };
    }
  }
);

export const getContractsListNotInvoiceable = createAsyncThunk(
  "contracts/getContractsListNotInvoiceableStatus",
  async (data) => {
    try {
      const query = {
        page: data?.page?.current || 1,
        "filter[contract_code]": data?.filters?.contract_code?.data,
        "filter[carrier]": data?.filters?.carrier?.data,
        "filter[connection_type.zone]": data?.filters?.["connection_type.zone"]?.data.value,
        "filter[bp_code]": data?.filters?.bp_code?.data,
        "filter[customer.first_name]": data?.filters?.["customer.first_name"]?.data,
        "filter[customer.last_name]": data?.filters?.["customer.last_name"]?.data,
        "filter[coverage.city]": data?.filters?.["coverage.city"]?.data,
        "filter[coverage.street_name]": data?.filters?.["coverage.street_name"]?.data,
        "filter[state]": data?.filters?.state_name?.data.value,
        "filter[recess_requested]": data?.filters?.recess_requested?.data.value,
        "filter[cancel_requested]": data?.filters?.cancel_requested?.data.value,
        "filter[created_between]": data?.filters?.created_at?.data,
        "filter[activated_between]": data?.filters?.activated_at?.data,
        "filter[canceled_between]": data?.filters?.activated_at?.data,
      };
      //console.log("BEFORE RESPONSE > > >")
      const response = await axios.get(
        `/contracts/not-invoiceable?${queryString.stringify(query)}`
      );
      response.data.filters = data.filters;
      //console.log("RESPONSE > > >",response)
      return response.data;
    } catch (error) {
      console.log("ERROR RESPONSE GET CONTRACT LIST > > >", error);
      //return rejectWithValue(error.response.data)
      return {
        status: error.response.status,
      };
    }
  }
);


export const getContractsList = createAsyncThunk(
  "contracts/getContractsListStatus",
  async (data) => {
    try {
      const query = {
        page: data?.page?.current || 1,
        "filter[contract_code]": data?.filters?.contract_code?.data,
        "filter[carrier]": data?.filters?.carrier?.data,
        "filter[connection_type.zone]": data?.filters?.["connection_type.zone"]?.data.value,
        "filter[bp_code]": data?.filters?.bp_code?.data,
        "filter[customer.first_name]": data?.filters?.["customer.first_name"]?.data,
        "filter[customer.last_name]": data?.filters?.["customer.last_name"]?.data,
        "filter[coverage.city]": data?.filters?.["coverage.city"]?.data,
        "filter[coverage.street_name]": data?.filters?.["coverage.street_name"]?.data,
        "filter[state]": data?.filters?.state_name?.data.value,
        "filter[recess_requested]": data?.filters?.recess_requested?.data.value,
        "filter[cancel_requested]": data?.filters?.cancel_requested?.data.value,
        "filter[created_between]": data?.filters?.created_at?.data,
        "filter[activated_between]": data?.filters?.activated_at?.data,
        "filter[canceled_between]": data?.filters?.activated_at?.data,
      };
      //console.log("BEFORE RESPONSE > > >")
      const response = await axios.get(
        `/contracts?${queryString.stringify(query)}`
      );
      response.data.filters = data.filters;
      //console.log("RESPONSE > > >",response)
      return response.data;
    } catch (error) {
      console.log("ERROR RESPONSE GET CONTRACT LIST > > >", error);
      //return rejectWithValue(error.response.data)
      return {
        status: error.response.status,
      };
    }
  }
);

export const getContractsListAwaiting = createAsyncThunk(
  "contracts/getContractsListAwaitingStatus",
  async (data) => {
    try {
      const query = {
        page: data?.page?.current || 1,
        "filter[contract_code]": data?.filters?.contract_code?.data,
        "filter[carrier]": data?.filters?.carrier?.data,
        "filter[connection_type.zone]": data?.filters?.["connection_type.zone"]?.data.value,
        "filter[bp_code]": data?.filters?.bp_code?.data,
        "filter[customer.first_name]": data?.filters?.["customer.first_name"]?.data,
        "filter[customer.last_name]": data?.filters?.["customer.last_name"]?.data,
        "filter[coverage.city]": data?.filters?.["coverage.city"]?.data,
        "filter[coverage.street_name]": data?.filters?.["coverage.street_name"]?.data,
        "filter[state]": data?.filters?.state_name?.data.value,
        "filter[recess_requested]": data?.filters?.recess_requested?.data.value,
        "filter[cancel_requested]": data?.filters?.cancel_requested?.data.value,
        "filter[created_between]": data?.filters?.created_at?.data,
        "filter[activated_between]": data?.filters?.activated_at?.data,
        "filter[canceled_between]": data?.filters?.activated_at?.data,
      };
      //console.log("BEFORE RESPONSE > > >")
      const response = await axios.get(
        `/contracts/waiting?${queryString.stringify(query)}`
      );
      response.data.filters = data.filters;
      //console.log("RESPONSE > > >",response)
      return response.data;
    } catch (error) {
      console.log("ERROR RESPONSE GET CONTRACT LIST > > >", error);
      //return rejectWithValue(error.response.data)
      return {
        status: error.response.status,
      };
    }
  }
);

export const getContractsListPending = createAsyncThunk(
  "contracts/getContractsListPendingStatus",
  async (data) => {
    try {
      const query = {
        page: data?.page?.current || 1,
        "filter[contract_code]": data?.filters?.contract_code?.data,
        "filter[carrier]": data?.filters?.carrier?.data,
        "filter[connection_type.zone]": data?.filters?.["connection_type.zone"]?.data.value,
        "filter[bp_code]": data?.filters?.bp_code?.data,
        "filter[customer.first_name]":
          data?.filters?.["customer.first_name"]?.data,
        "filter[customer.last_name]":
          data?.filters?.["customer.last_name"]?.data,
        "filter[coverage.city]": data?.filters?.["coverage.city"]?.data,
        "filter[coverage.street_name]":
          data?.filters?.["coverage.street_name"]?.data,
        //'filter[state]': data?.filters?.state_name?.data.value,
        //'filter[recess_requested]': data?.filters?.recess_requested?.data.value,
        //'filter[cancel_requested]': data?.filters?.cancel_requested?.data.value,
        "filter[created_between]": data?.filters?.created_at?.data,
        //'filter[activated_between]': data?.filters?.activated_at?.data,
      };
      //console.log("BEFORE RESPONSE > > >")
      const response = await axios.get(
        `/queues/contracts/pending?${queryString.stringify(query)}`
      );
      response.data.filters = data.filters;
      //console.log("RESPONSE > > >",response)
      return response.data;
    } catch (error) {
      console.log("ERROR RESPONSE GET CONTRACT LIST Pending > > >", error);
      //return rejectWithValue(error.response.data)
      return {
        status: error.response.status,
      };
    }
  }
);

export const getContractsListKoProvisioning = createAsyncThunk(
  "contracts/getContractsListKoProvisioningStatus",
  async (data) => {
    try {
      const query = {
        page: data?.page?.current || 1,
        "filter[contract_code]": data?.filters?.contract_code?.data,
        "filter[bp_code]": data?.filters?.bp_code?.data,
        "filter[carrier]": data?.filters?.carrier?.data,
        "filter[connection_type.zone]": data?.filters?.["connection_type.zone"]?.data.value,
        "filter[customer.first_name]":
          data?.filters?.["customer.first_name"]?.data,
        "filter[customer.last_name]":
          data?.filters?.["customer.last_name"]?.data,
        "filter[coverage.city]": data?.filters?.["coverage.city"]?.data,
        "filter[coverage.street_name]":
          data?.filters?.["coverage.street_name"]?.data,
        //'filter[state]': data?.filters?.state_name?.data.value,
        //'filter[recess_requested]': data?.filters?.recess_requested?.data.value,
        //'filter[cancel_requested]': data?.filters?.cancel_requested?.data.value,
        "filter[created_between]": data?.filters?.created_at?.data,
        //'filter[activated_between]': data?.filters?.activated_at?.data,
      };
      //console.log("BEFORE RESPONSE > > >")
      const response = await axios.get(
        `/queues/contracts/ko-provisioning?${queryString.stringify(query)}`
      );
      response.data.filters = data.filters;
      //console.log("RESPONSE > > >",response)
      return response.data;
    } catch (error) {
      console.log("ERROR RESPONSE GET CONTRACT LIST Pending > > >", error);
      //return rejectWithValue(error.response.data)
      return {
        status: error.response.status,
      };
    }
  }
);

export const getContractsListEarlyWithdrawal = createAsyncThunk(
  "contracts/getContractsListEarlyWithdrawalStatus",
  async (data) => {
    try {
      const query = {
        page: data?.page?.current || 1,
        "filter[contract_code]": data?.filters?.contract_code?.data,
        "filter[carrier]": data?.filters?.carrier?.data,
        "filter[connection_type.zone]": data?.filters?.["connection_type.zone"]?.data.value,
        "filter[bp_code]": data?.filters?.bp_code?.data,
        "filter[customer.first_name]":
          data?.filters?.["customer.first_name"]?.data,
        "filter[customer.last_name]":
          data?.filters?.["customer.last_name"]?.data,
        "filter[coverage.city]": data?.filters?.["coverage.city"]?.data,
        "filter[coverage.street_name]":
          data?.filters?.["coverage.street_name"]?.data,
        //'filter[state]': data?.filters?.state_name?.data.value,
        //'filter[recess_requested]': data?.filters?.recess_requested?.data.value,
        //'filter[cancel_requested]': data?.filters?.cancel_requested?.data.value,
        "filter[created_between]": data?.filters?.created_at?.data,
        //'filter[activated_between]': data?.filters?.activated_at?.data,
      };
      //console.log("BEFORE RESPONSE > > >")
      const response = await axios.get(
        `/queues/contracts/early-withdrawal?${queryString.stringify(query)}`
      );
      response.data.filters = data.filters;
      //console.log("RESPONSE > > >",response)
      return response.data;
    } catch (error) {
      console.log(
        "ERROR RESPONSE GET CONTRACT LIST Ealry withdrawal > > >",
        error
      );
      //return rejectWithValue(error.response.data)
      return {
        status: error.response.status,
      };
    }
  }
);

export const getContractsListExplicitWithdrawal = createAsyncThunk(
  "contracts/getContractsListExplicitWithdrawalStatus",
  async (data) => {
    try {
      const query = {
        page: data?.page?.current || 1,
        "filter[contract_code]": data?.filters?.contract_code?.data,
        "filter[carrier]": data?.filters?.carrier?.data,
        "filter[connection_type.zone]": data?.filters?.["connection_type.zone"]?.data.value,
        "filter[bp_code]": data?.filters?.bp_code?.data,
        "filter[customer.first_name]":
          data?.filters?.["customer.first_name"]?.data,
        "filter[customer.last_name]":
          data?.filters?.["customer.last_name"]?.data,
        "filter[coverage.city]": data?.filters?.["coverage.city"]?.data,
        "filter[coverage.street_name]":
          data?.filters?.["coverage.street_name"]?.data,
        //'filter[state]': data?.filters?.state_name?.data.value,
        //'filter[recess_requested]': data?.filters?.recess_requested?.data.value,
        //'filter[cancel_requested]': data?.filters?.cancel_requested?.data.value,
        "filter[created_between]": data?.filters?.created_at?.data,
        //'filter[activated_between]': data?.filters?.activated_at?.data,
      };
      //console.log("BEFORE RESPONSE > > >")
      const response = await axios.get(
        `/queues/contracts/explicit-withdrawal?${queryString.stringify(query)}`
      );
      response.data.filters = data.filters;
      //console.log("RESPONSE > > >",response)
      return response.data;
    } catch (error) {
      console.log(
        "ERROR RESPONSE GET CONTRACT LIST Explicit withdrawal > > >",
        error
      );
      //return rejectWithValue(error.response.data)
      return {
        status: error.response.status,
      };
    }
  }
);

export const getContractsListPostWithdrawal = createAsyncThunk(
  "contracts/getContractsListPostWithdrawalStatus",
  async (data) => {
    try {
      const query = {
        page: data?.page?.current || 1,
        "filter[contract_code]": data?.filters?.contract_code?.data,
        "filter[carrier]": data?.filters?.carrier?.data,
        "filter[connection_type.zone]": data?.filters?.["connection_type.zone"]?.data.value,
        "filter[bp_code]": data?.filters?.bp_code?.data,
        "filter[customer.first_name]":
          data?.filters?.["customer.first_name"]?.data,
        "filter[customer.last_name]":
          data?.filters?.["customer.last_name"]?.data,
        "filter[coverage.city]": data?.filters?.["coverage.city"]?.data,
        "filter[coverage.street_name]":
          data?.filters?.["coverage.street_name"]?.data,
        //'filter[state]': data?.filters?.state_name?.data.value,
        //'filter[recess_requested]': data?.filters?.recess_requested?.data.value,
        //'filter[cancel_requested]': data?.filters?.cancel_requested?.data.value,
        "filter[created_between]": data?.filters?.created_at?.data,
        //'filter[activated_between]': data?.filters?.activated_at?.data,
      };
      //console.log("BEFORE RESPONSE > > >")
      const response = await axios.get(
        `/queues/contracts/post-withdrawal?${queryString.stringify(query)}`
      );
      response.data.filters = data.filters;
      //console.log("RESPONSE > > >",response)
      return response.data;
    } catch (error) {
      console.log(
        "ERROR RESPONSE GET CONTRACT LIST Post withdrawal > > >",
        error
      );
      //return rejectWithValue(error.response.data)
      return {
        status: error.response.status,
      };
    }
  }
);

export const getContractsListRecess = createAsyncThunk(
  "contracts/getContractsListRecessStatus",
  async (data) => {
    try {
      const query = {
        page: data?.page?.current || 1,
        "filter[contract_code]": data?.filters?.contract_code?.data,
        "filter[carrier]": data?.filters?.carrier?.data,
        "filter[connection_type.zone]": data?.filters?.["connection_type.zone"]?.data.value,
        "filter[bp_code]": data?.filters?.bp_code?.data,
        "filter[customer.first_name]":
          data?.filters?.["customer.first_name"]?.data,
        "filter[customer.last_name]":
          data?.filters?.["customer.last_name"]?.data,
        "filter[coverage.city]": data?.filters?.["coverage.city"]?.data,
        "filter[coverage.street_name]":
          data?.filters?.["coverage.street_name"]?.data,
        "filter[created_between]": data?.filters?.created_at?.data,
        //'filter[state]': data?.filters?.activated_at?.data.value,
      };
      //console.log("BEFORE RESPONSE > > >")
      const response = await axios.get(
        `/contracts/recessing?${queryString.stringify(query)}`
      );
      response.data.filters = data.filters;
      //console.log("RESPONSE > > >",response)
      return response.data;
    } catch (error) {
      console.log("ERROR RESPONSE GET CONTRACT LIST Recessing > > >", error);
      //return rejectWithValue(error.response.data)
      return {
        status: error.response.status,
      };
    }
  }
);

export const getContractInvoices = createAsyncThunk(
  "contracts/getContractInvoicesStatus",
  async ({ data, contractId }) => {
    try {
      //console.log('contractId',data, contractId)
      const query = {
        page: data?.page?.current || 1,
        "filter[invoice_proof]": data?.filters?.invoice_proof?.data,
        "filter[document_date_between]": data?.filters?.document_date?.data,
        "filter[expires_at_between]": data?.filters?.expires_at?.data,
        "filter[result]": data?.filters?.result?.data.value,
      };
      //console.log("BEFORE RESPONSE > > >")
      const response = await axios.get(
        `/contracts/${contractId}/invoices?${queryString.stringify(query)}`
      );
      response.data.filters = data.filters;
      //console.log("RESPONSE > > >",response)
      return response.data;
    } catch (error) {
      //console.log("ERROR RESPONSE > > >",error.response)
      //return rejectWithValue(error.response.data)
      return {
        status: error.response.status,
      };
    }
  }
);

export const getContractPortedOut = createAsyncThunk(
  "contracts/getContractPortedOutStatus",
  async ({ data, contractId }) => {
    try {
      //console.log('contractId',data, contractId)
      const query = {
        page: data?.page?.current || 1
      };
      //console.log("BEFORE RESPONSE > > >")
      const response = await axios.get(
        `/contracts/${contractId}/migrations/outbound?${queryString.stringify(query)}`
      );
      response.data.filters = data.filters;
      //console.log("RESPONSE > > >",response)
      return response.data;
    } catch (error) {
      //console.log("ERROR RESPONSE > > >",error.response)
      //return rejectWithValue(error.response.data)
      return {
        status: error.response.status,
      };
    }
  }
);

export const getContractTickets = createAsyncThunk(
  "contracts/getContractTicketsStatus",
  async ({ data, contractId }) => {
    try {
      console.log("contractId", data, contractId);
      const query = {
        page: data?.page?.current || 1,
        "filter[ticket_code]": data?.filters?.ticket_code?.data,
        "filter[customer.first_name]":
          data?.filters?.["customer.first_name"]?.data,
        "filter[customer.last_name]":
          data?.filters?.["customer.last_name"]?.data,
        "filter[contract.contract_code]":
          data?.filters?.["contract.contract_code"]?.data,
        "filter[state]": data?.filters?.state_name?.data.value,
        "filter[created_between]": data?.filters?.created_at?.data,
        "filter[origin]": data?.filters?.origin_name?.data.value,
        "filter[triplets]": data?.filters?.support_cat?.data.value,
      };
      //console.log("BEFORE RESPONSE > > >")
      const response = await axios.get(
        `/contracts/${contractId}/tickets?${queryString.stringify(query)}`
      );
      response.data.filters = data.filters;
      //console.log("RESPONSE > > >",response)
      return response.data;
    } catch (error) {
      //console.log("ERROR RESPONSE > > >",error.response)
      //return rejectWithValue(error.response.data)
      return {
        status: error.response.status,
      };
    }
  }
);

export const getContractsStates = createAsyncThunk(
  "contracts/getContractsStatesStatus",
  async () => {
    try {
      const response = await axios.get(`/contracts/states`);
      return response.data;
    } catch (error) {
      console.log("errore nel ricevere gli stati del contratto", error);
    }
  }
);

export const submitRecessRequest = createAsyncThunk(
  "contracts/submitRecessRequestStatus",
  async (values, { getState }) => {
    try {
      const contractId = getState().contracts.selected.id;
      const formData = new FormData();
      formData.append("file", values.file);
      formData.append("date", values.date);
      formData.append("action", values.action);
      const response = await axios({
        method: "POST",
        url: `contracts/${contractId}/recess`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      });
      return response.data;
    } catch (error) {
      return {
        status: error.response.status,
        message: error.response.data.message,
      };
    }
  }
);

export const submitUploadWithdrawal = createAsyncThunk(
  "contracts/submitUploadWithdrawalStatus",
  async (values, { getState }) => {
    try {
      const contractId = getState().contracts.selected.id;
      const formData = new FormData();
      formData.append("file", values.file);
      formData.append("type", values.type);
      //formData.append('action',values.action)
      const response = await axios({
        method: "POST",
        url: `contracts/${contractId}/upload-withdrawal`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      });
      return response.data;
    } catch (error) {
      return {
        status: error.response.status,
        message: error.response.data.message,
      };
    }
  }
);

export const submitUploadRid = createAsyncThunk(
  "contracts/submitUploadRidStatus",
  async (values, { getState }) => {
    try {
      const contractId = getState().contracts.selected.id;
      const formData = new FormData();
      formData.append("file", values.file);

      const response = await axios({
        method: "POST",
        url: `contracts/${contractId}/upload-rid`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      });
      return response.data;
    } catch (error) {
      return {
        status: error.response.status,
        message: error.response.data.message,
      };
    }
  }
);

export const submitResumeRequest = createAsyncThunk(
  "contracts/submitResumeRequestStatus",
  async (values, { getState }) => {
    try {
      const contractId = getState().contracts.selected.id;
      const response = await axios({
        method: "POST",
        url: `contracts/${contractId}/resume-order`,
        data: values,
      });
      return response.data;
    } catch (error) {
      return {
        status: error.response.status,
        message: error.response.data.message,
      };
    }
  }
);

export const submitRescheduleAppointment = createAsyncThunk(
  "contracts/submitRescheduleAppointmentStatus",
  async (values, { getState }) => {
    try {
      const contractId = getState().contracts.selected.id;
      const response = await axios({
        method: "POST",
        url: `contracts/${contractId}/reschedule-order`,
        data: values,
      });
      return response.data;
    } catch (error) {
      return {
        status: error.response.status,
        message: error.response.data.message,
      };
    }
  }
);

export const submitRidProcess = createAsyncThunk(
  "contracts/submitRidProcessStatus",
  async (id, { getState }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `queues/${id}/process`,
      });
      return response.data;
    } catch (error) {
      return {
        status: error.response.status,
        message: error.response.data.message,
      };
    }
  }
);

export const submitSendContract = createAsyncThunk(
  "contracts/submitSendContractStatus",
  async (_, { getState }) => {
    try {
      const contractId = getState().contracts.selected.id;
      const response = await axios({
        method: "POST",
        url: `contracts/${contractId}/send-contract-mail`,
      });
      return response.data;
    } catch (error) {
      return {
        status: error.response.status,
        message: error.response.data.message,
      };
    }
  }
);

export const submitSendCredentials = createAsyncThunk(
  "contracts/submitSendCredentialsStatus",
  async (_, { getState }) => {
    try {
      const customerId = getState().contracts.selected.customer_id;
      const response = await axios({
        method: "POST",
        url: `customers/${customerId}/send-credentials`,
      });
      return response.data;
    } catch (error) {
      return {
        status: error.response.status,
        message: error.response.data.message,
      };
    }
  }
);

export const submitAddPremiumOdl = createAsyncThunk(
  "contracts/submitAddPremiumOdlStatus",
  async (_, { getState }) => {
    try {
      const contractId = getState().contracts.selected.id;
      const response = await axios({
        method: "POST",
        url: `contracts/${contractId}/create-work-order`,
      });
      return response.data;
    } catch (error) {
      return {
        status: error.response.status,
        message: error.response.data.message,
      };
    }
  }
);

export const submitRequestContractTermination = createAsyncThunk(
  "contracts/submitRequestContractTerminationStatus",
  async (_, { getState }) => {
    try {
      const contractId = getState().contracts.selected.id;
      const response = await axios({
        method: "POST",
        url: `contracts/${contractId}/terminate-order`,
      });
      return response.data;
    } catch (error) {
      return {
        status: error.response.status,
        message: error.response.data.message,
      };
    }
  }
);

export const submitResendEmailValidation = createAsyncThunk(
  "contracts/submitResendEmailValidationStatus",
  async (_, { getState }) => {
    try {
      const contractId = getState().contracts.selected.id;
      const response = await axios({
        method: "POST",
        url: `contracts/creating/${contractId}/resend-validation-mail`,
      });
      return response.data;
    } catch (error) {
      return {
        status: error.response.status,
        message: error.response.data.message,
      };
    }
  }
);

export const submitSendValidationContract = createAsyncThunk(
  "contracts/submitSendValidationContractStatus",
  async (_, { getState }) => {
    try {
      console.log("submitSendValidationContract");
      const contractId = getState().contracts.selected.id;
      const response = await axios({
        method: "POST",
        url: `contracts/${contractId}/send-email-temp-process`,
      });
      return response.data;
    } catch (error) {
      return {
        status: error.response.status,
        message: error.response.data.message,
      };
    }
  }
);

export const submitLinkemContractCode = createAsyncThunk(
  "contracts/submitLinkemContractCodeStatus",
  async ({ code }, { getState }) => {
    try {
      const contractId = getState().contracts.selected.id;
      const response = await axios({
        method: "POST",
        url: `contracts/${contractId}/insert-linkem-contract-code`,
        data: {
          linkem_contract_code: code
        }
      });
      return response.data;
    } catch (error) {
      return {
        status: error.response.status,
        message: error.response.data.message,
      };
    }
  }
);

export const submitChangeStartBillingDate = createAsyncThunk(
  "contracts/submitChangeStartBillingDate",
  async ({ date }, { getState }) => {
    try {
      const contractId = getState().contracts.selected.id;
      const response = await axios({
        method: "POST",
        url: `contracts/${contractId}/change-invoiceable-date`,
        data: {
          date: date && moment(date).format('YYYY-MM-DD') || null
        }
      });
      return response.data;
    } catch (error) {
      return {
        status: error.response.status,
        message: error.response.data.message,
      };
    }
  }
);

export const handleActionConfirmation = createAsyncThunk(
  "contracts/handleActionConfirmationStatus",
  async ({ action }, { getState }) => {
    try {
      const contractId = getState().contracts.selected.id;
      console.log(
        "submitSendContract",
        action,
        `contracts/${contractId}/${action}`
      );

      const response = await axios({
        method: "POST",
        url: `contracts/${contractId}/${action}`,
      });
      return response.data;
    } catch (error) {
      return {
        status: error.response.status,
        message: error.response.data.message,
      };
    }
  }
);

export const submitRecessCancel = createAsyncThunk(
  "contracts/submitRecessCancelStatus",
  async (_, { getState }) => {
    try {
      //console.log('submitSendContract')
      const contractId = getState().contracts.selected.id;
      const response = await axios({
        method: "POST",
        url: `contracts/${contractId}/recess-undo`,
      });
      return response.data;
    } catch (error) {
      return {
        status: error.response.status,
        message: error.response.data.message,
      };
    }
  }
);

export const submitCancelOrder = createAsyncThunk(
  "contracts/submitCancelOrderStatus",
  async (values, { getState }) => {
    try {
      const contractId = getState().contracts.selected.id;

      const response = await axios({
        method: "POST",
        url: `contracts/${contractId}/cancel-order`,
        data: values,
      });
      return response.data;
    } catch (error) {
      return {
        status: error.response.status,
        message: error.response.data.message,
      };
    }
  }
);

export const getDocumentation = createAsyncThunk(
  "contracts/getDocumentationStatus",
  async (contractId, getState) => {
    try {
      //console.log('getDocumentation > ',contractId)
      //const contractId = getState().contracts.selected.id;
      const response = await axios.get(
        `/contracts/${contractId}/documentation`
      );
      //console.log("RESPONSE > > >",response)
      return response.data;
    } catch (error) {
      //console.log("ERROR RESPONSE > > >",error.response)
      //return rejectWithValue(error.response.data)
      return {
        status: error.response.status,
      };
    }
  }
);

function isFulfilledAction(action) {
  return action.type.endsWith("/fulfilled");
}

function isPendingAction(action) {
  return action.type.endsWith("/pending");
}

function isRejectedAction(action) {
  return action.type.endsWith("/rejected");
}

const mock = {
  city: {
    name: "PESCARA",
    province: "PE",
  },
  address: {
    streetType: "STRADA",
    name: "COLLE PINETA",
  },
  addressNumber: "12",
};

const initialState = {
  loading: "",
  error: null,
  newContract: {
    city: null,
    address: null,
    addressNumber: "",
    //...mock,
    speedValues: {},
    activeLine: "",
  },
  selected: null,
  list: [],
  filters: {},
  contract_states: [],
};

const contractsSlice = createSlice({
  name: "contracts",
  initialState: initialState,
  reducers: {
    updateContractCity(state, action) {
      state.newContract.city = action ? action.city : null;
      state.newContract.address = null;
      state.newContract.addressNumber = "";
      state.loading = "";
    },
    updateContractAddress(state, action) {
      state.newContract.address = action ? action.address : null;
      state.newContract.addressNumber = "";
      state.loading = "";
    },
    updateContractAddressNumber(state, action) {
      state.newContract.addressNumber = action ? action.addressNumber : "";
      state.newContract.addressNumberEgon = action ? action.addressNumberEgon : "";
      state.loading = "";
    },
    updateActiveLine(state, action) {
      state.newContract.activeLine = action ? action.activeLine : "";
      state.loading = "";
    },
    resetState(state, action) {
      state.loading = "";
      state.error = null;
      state.selected = null;
      state.newContract = initialState.newContract;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getContractsList.fulfilled, (state, action) => {
      if (action.payload) {
        const pagination = action.payload.meta;
        state.list = action.payload.data;
        state.pagination = {
          pageSize: pagination.per_page,
          current: pagination.current_page,
          total: pagination.total,
        };
        state.filters = action.payload.filters;
      }
    });
    builder.addCase(getContractsListPending.fulfilled, (state, action) => {
      if (action.payload) {
        const pagination = action.payload.meta;
        state.list = action.payload.data;
        state.pagination = {
          pageSize: pagination.per_page,
          current: pagination.current_page,
          total: pagination.total,
        };
        state.filters = action.payload.filters;
      }
    });
    builder.addCase(getContractsListAwaiting.fulfilled, (state, action) => {
      if (action.payload) {
        const pagination = action.payload.meta;
        state.list = action.payload.data;
        state.pagination = {
          pageSize: pagination.per_page,
          current: pagination.current_page,
          total: pagination.total,
        };
        state.filters = action.payload.filters;
      }
    });
    builder.addCase(
      getContractsListKoProvisioning.fulfilled,
      (state, action) => {
        if (action.payload) {
          const pagination = action.payload.meta;
          state.list = action.payload.data;
          state.pagination = {
            pageSize: pagination.per_page,
            current: pagination.current_page,
            total: pagination.total,
          };
          state.filters = action.payload.filters;
        }
      }
    );
    builder.addCase(
      getContractsListEarlyWithdrawal.fulfilled,
      (state, action) => {
        if (action.payload) {
          const pagination = action.payload.meta;
          state.list = action.payload.data;
          state.pagination = {
            pageSize: pagination.per_page,
            current: pagination.current_page,
            total: pagination.total,
          };
          state.filters = action.payload.filters;
        }
      }
    );
    builder.addCase(
      getContractsListExplicitWithdrawal.fulfilled,
      (state, action) => {
        if (action.payload) {
          const pagination = action.payload.meta;
          state.list = action.payload.data;
          state.pagination = {
            pageSize: pagination.per_page,
            current: pagination.current_page,
            total: pagination.total,
          };
          state.filters = action.payload.filters;
        }
      }
    );
    builder.addCase(
      getContractsListPostWithdrawal.fulfilled,
      (state, action) => {
        if (action.payload) {
          const pagination = action.payload.meta;
          state.list = action.payload.data;
          state.pagination = {
            pageSize: pagination.per_page,
            current: pagination.current_page,
            total: pagination.total,
          };
          state.filters = action.payload.filters;
        }
      }
    );
    builder.addCase(getContractsListRecess.fulfilled, (state, action) => {
      if (action.payload) {
        const pagination = action.payload.meta;
        state.list = action.payload.data;
        state.pagination = {
          pageSize: pagination.per_page,
          current: pagination.current_page,
          total: pagination.total,
        };
        state.filters = action.payload.filters;
      }
    });
    builder.addCase(getContractsStates.fulfilled, (state, action) => {
      if (action.payload) {
        state.contract_states = action.payload.data;
      }
    });
    builder.addCase(getContractsToValidateList.fulfilled, (state, action) => {
      //console.log(action)
      if (action.payload) {
        const pagination = action.payload.meta;
        state.list = action.payload.data;
        state.pagination = {
          pageSize: pagination.per_page,
          current: pagination.current_page,
          total: pagination.total,
        };
      }
    });
    builder.addCase(getContractsRidMissingList.fulfilled, (state, action) => {
      //console.log(action)
      if (action.payload) {
        const pagination = action.payload.meta;
        state.list = action.payload.data;
        state.pagination = {
          pageSize: pagination.per_page,
          current: pagination.current_page,
          total: pagination.total,
          lastPage: pagination.last_page,
        };
      }
    });
    builder.addCase(getContractsToUploadList.fulfilled, (state, action) => {
      //console.log(action)
      if (action.payload) {
        const pagination = action.payload.meta;
        state.list = action.payload.data;
        state.pagination = {
          pageSize: pagination.per_page,
          current: pagination.current_page,
          total: pagination.total,
        };
      }
    });
    builder.addCase(getContractsCreatingList.fulfilled, (state, action) => {
      //console.log(action)
      if (action.payload) {
        const pagination = action.payload.meta;
        state.list = action.payload.data;
        state.pagination = {
          pageSize: pagination.per_page,
          current: pagination.current_page,
          total: pagination.total,
        };
        state.filters = action.payload.filters;
      }
    });
    builder.addCase(getContractsListNotSurfing.fulfilled, (state, action) => {
      if (action.payload) {
        const pagination = action.payload.meta;
        state.list = action.payload.data;
        state.pagination = {
          pageSize: pagination.per_page,
          current: pagination.current_page,
          total: pagination.total,
        };
        state.filters = action.payload.filters;
      }
    });
    builder.addCase(getContractsListNotInvoiceable.fulfilled, (state, action) => {
      if (action.payload) {
        const pagination = action.payload.meta;
        state.list = action.payload.data;
        state.pagination = {
          pageSize: pagination.per_page,
          current: pagination.current_page,
          total: pagination.total,
        };
        state.filters = action.payload.filters;
      }
    });
    builder.addCase(uploadDocument.fulfilled, (state, action) => {
      if (action.payload) {
        state.newContract.uploaded_documentation =
          action.payload.data.uploaded_documentation;
      }
    });
    builder.addCase(removeDocument.fulfilled, (state, action) => {
      if (action.payload) {
        state.newContract.uploaded_documentation =
          action.payload.data.uploaded_documentation;
      }
    });
    builder.addCase(approveDocument.fulfilled, (state, action) => {
      //console.log('action > ',action.payload)
      if (action.payload) {
        state.newContract.uploaded_documentation =
          action.payload.data.uploaded_documentation;
      }
    });
    builder.addCase(getContractDetails.fulfilled, (state, action) => {
      if (action.payload) {
        state.selected = action.payload.data;
      }
    });
    builder.addCase(getContractCreatingDetails.fulfilled, (state, action) => {
      if (action.payload) {
        state.selected = action.payload.data;
      }
    });
    builder.addCase(getContractDetailsCustomer.fulfilled, (state, action) => {
      if (action.payload) {
        state.selected = { ...state.selected, ...action.payload.data };
      }
    });
    builder.addCase(
      getContractDetailsNotifications.fulfilled,
      (state, action) => {
        if (action.payload) {
          state.selected = { ...state.selected, ...action.payload.data };
        }
      }
    );
    builder.addCase(getDocumentation.fulfilled, (state, action) => {
      if (action.payload) {
        state.selected = { ...state.selected, ...action.payload.data };
      }
    });
    builder.addCase(submitRecessRequest.fulfilled, (state, action) => {
      if (action.payload) {
        state.selected = { ...state.selected, ...action.payload.data };
      }
    });
    builder.addCase(submitRescheduleAppointment.fulfilled, (state, action) => {
      if (action.payload) {
        state.selected = { ...state.selected, ...action.payload.data };
      }
    });
    builder.addCase(submitResumeRequest.fulfilled, (state, action) => {
      if (action.payload) {
        state.selected = { ...state.selected, ...action.payload.data };
      }
    });
    builder.addCase(submitUploadRid.fulfilled, (state, action) => {
      if (action.payload) {
        state.selected = { ...state.selected, ...action.payload.data };
      }
    });
    builder.addCase(submitCancelOrder.fulfilled, (state, action) => {
      if (action.payload) {
        state.selected = { ...state.selected, ...action.payload.data };
      }
    });
    builder.addCase(handleActionConfirmation.fulfilled, (state, action) => {
      if (action.payload) {
        state.selected = { ...state.selected, ...action.payload.data };
      }
    });
    builder.addCase(submitSendContract.fulfilled, (state, action) => {
      if (action.payload) {
        state.selected = { ...state.selected, ...action.payload.data };
      }
    });
    builder.addCase(submitSendCredentials.fulfilled, (state, action) => {
      if (action.payload) {
        state.selected = { ...state.selected, ...action.payload.data };
      }
    });
    builder.addCase(
      submitRequestContractTermination.fulfilled,
      (state, action) => {
        if (action.payload) {
          state.selected = { ...state.selected, ...action.payload.data };
        }
      }
    );
    builder.addCase(submitResendEmailValidation.fulfilled, (state, action) => {
      if (action.payload) {
        state.selected = { ...state.selected, ...action.payload.data };
      }
    });
    builder.addCase(submitSendValidationContract.fulfilled, (state, action) => {
      if (action.payload) {
        state.selected = { ...state.selected, ...action.payload.data };
      }
    });
    builder.addCase(submitLinkemContractCode.fulfilled, (state, action) => {
      if (action.payload) {
        state.selected = { ...state.selected, ...action.payload.data };
      }
    });
    builder.addCase(submitChangeStartBillingDate.fulfilled, (state, action) => {
      if (action.payload) {
        state.selected = { ...state.selected, ...action.payload.data };
      }
    });
    builder.addCase(getContractDetailsHistory.fulfilled, (state, action) => {
      if (action.payload) {
        state.selected = { ...state.selected, ...action.payload.data };
      }
    });
    builder.addCase(getContractDetailsShippings.fulfilled, (state, action) => {
      if (action.payload) {
        state.selected = { ...state.selected, ...action.payload.data };
      }
    });
    builder.addCase(getContractDetailsNetwork.fulfilled, (state, action) => {
      if (action.payload) {
        state.selected = { ...state.selected, ...action.payload.data };
      }
    });
    builder.addCase(getContractInvoices.fulfilled, (state, action) => {
      if (action.payload) {
        const pagination = action.payload.meta;

        state.selected = {
          ...state.selected,
          invoices: {
            list: action.payload.data,
            pagination: {
              pageSize: pagination.per_page,
              current: pagination.current_page,
              total: pagination.total,
            },
            filters: action.payload.filters,
          },
        };
      }
    });
    builder.addCase(getContractPortedOut.fulfilled, (state, action) => {
      if (action.payload) {
        const pagination = action.payload.meta;

        state.selected = {
          ...state.selected,
          portedout: {
            list: action.payload.data,
            pagination: {
              pageSize: pagination.per_page,
              current: pagination.current_page,
              total: pagination.total,
            },
            filters: action.payload.filters,
          },
        };
      }
    });
    builder.addCase(getContractTickets.fulfilled, (state, action) => {
      if (action.payload) {
        const pagination = action.payload.meta;

        state.selected = {
          ...state.selected,
          tickets: {
            list: action.payload.data,
            pagination: {
              pageSize: pagination.per_page,
              current: pagination.current_page,
              total: pagination.total,
            },
            filters: action.payload.filters,
          },
        };
      }
    });
    builder.addCase(getContractToActivateDetails.fulfilled, (state, action) => {
      if (action.payload.data) {
        state.newContract = action.payload.data;
      }
    });
    builder.addCase(creatingContract.fulfilled, (state, action) => {
      if (action.payload) {
        state.newContract = action.payload.data;
      }
    });
    builder.addCase(createNewContract.fulfilled, (state, action) => {
      if (action.payload && action.payload.data) {
        state.newContract.id = action.payload.data.id;
      }
    });
    builder.addCase(createNewContractDigital.fulfilled, (state, action) => {
      if (action.payload) {
        state.newContract.id = action.payload.data.id;
        state.newContract.customer = action.payload.data.customer;
        state.newContract.alerts = action.payload.data.alerts;
      }
    });
    builder.addCase(insertPaymentMethod.fulfilled, (state, action) => {
      if (action.payload && action.payload.data) {
        state.newContract.generated_documentation =
          action.payload.data.generated_documentation;
        state.newContract.uploaded_documentation =
          action.payload.data.uploaded_documentation;
      }
    });
    builder.addCase(verifyCoverageFinal.fulfilled, (state, action) => {
      if (action.payload) {
        state.newContract = {
          ...state.newContract,
          connection_type_id: action.payload.connection_type_id,
          connection_type: action.payload.connection_type,
          coverage_id: action.payload.coverage_id,
          egon_number: action.payload.egon_number,
          kit_id: action.payload.kit_id,
          speedValues: {
            download: action.payload.download_speed,
            upload: action.payload.upload_speed,
            actual_download: action.payload.actual_download_speed,
            actual_upload: action.payload.actual_upload_speed,
            latency: action.payload.latency,
            dac_days: action.payload.dac_days,
          },
        };
      }
    });
    builder.addMatcher(isFulfilledAction, (state, action) => {
      state.loading = "";
      state.error = false;
    });
    builder.addMatcher(isPendingAction, (state, action) => {
      const id =
        typeof action.meta.arg === "object"
          ? action.meta.arg.id
          : action.meta.arg;
      state.loading = action.type.split("/")[1] + (id ? "_" + id : "");
      state.error = false;
    });
    builder.addMatcher(isRejectedAction, (state, action) => {
      state.loading = "";
      state.error = action.error;
    });
  },
});

const { actions, reducer } = contractsSlice;

export const { createContact, updateContact, deleteContact } = actions;

export default reducer;
