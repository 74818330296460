import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
//import { useSelector, useDispatch } from 'react-redux';
import { message } from 'antd';
import routes from './routes'
import 'moment/locale/it';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { getNations } from 'redux/reducers/commonReducer';

axios.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  //console.log('AXIOS RESPONSE ', response)
  return response;
}, function (error) {
  //console.log('AXIOS ERROR ', error.response)
  if(window.location.pathname !== '/login') {
    if(
      error.response.status === 401 && 
      error.response.data.message === 'Unauthenticated.'
      ) {
      const origin = window.location.origin
      window.location.replace(origin+"/login")
    }
    else if(error.response.status === 401 || error.response.status === 403) {
      message.error('Accesso non consentito')
      window.history.back()
    }
  }

  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error);
});

function App() {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getNations());
  }, []);

  return (
      <Router>
        <Switch>
          { routes.map((route, i) => (
            <Route
              key={route.path}
              path={route.path}
              render={props => (
                <route.component {...props} routes={route.routes} />
              )}
            />
          ))}
        </Switch>
      </Router>
  );
}

export default App;
