import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col, 
  Form,
  Button,
  Space,
  message
} from 'antd';
//import { useHistory } from 'react-router-dom';
import { 
  useSelector, 
  useDispatch 
} from 'react-redux';
import { 
  createNewTicketFull, getTicketMotivations
} from 'redux/reducers/ticketsReducer';

import TicketAddNote from 'components/Form/TicketAddNote/TicketAddNote';
import { selectTicketMotivations } from 'redux/selectors/tickets';

function TicketConclusion({ onCancel, onSuccess }) {
  
  const { newTicket, loading, error } = useSelector(state => state.tickets);
  
  const dispatch = useDispatch();
  //let history = useHistory();
  const [formNewTicketConclusion] = Form.useForm();

  const initialValues = {};

  const ticketMotivations = useSelector(selectTicketMotivations);

  useEffect(() => {
    dispatch(getTicketMotivations());
  }, []);

  const onFinish = async (values) => {
    
    const result = await dispatch(createNewTicketFull({values: values}))

    if(result.payload.status !== 422 && result.payload.data) {
      message.success(`Ticket creato con successo!`);
      onSuccess();
    }
    else {
      message.error(`Errore nella compilazione del ticket`);

      const errors = Object.keys(result.payload.errors).map((errorKey,i) => {
        return {
          name: errorKey.split('.'),
          errors: result.payload.errors[errorKey]
        }
      })
      formNewTicketConclusion.setFields(errors)
      formNewTicketConclusion.scrollToField(errors[0].name,{behavior: 'smooth'})
    }
  };

  
  return (
    <div className="pageContainer" style={{height: '100%', overflowY: 'auto'}}>
      <Form 
        form={formNewTicketConclusion}
        autoComplete="off"
        name="formNewContractConclusion"
        layout="vertical" 
        colon={false} 
        onFinish={onFinish}
        initialValues={initialValues}
        scrollToFirstError={true}
        validateTrigger="onBlur"
      >
        <TicketAddNote 
          hasTriplets={!newTicket.values.support_cat_third_id} 
          triplets={newTicket.triplets}
          motivations={ticketMotivations}
        />
        <Row justify="center" align="middle">
          <Col>
            <Space 
              align="center"
              size="large"
              //className="actionBar" 
              //{...tailLayout}
            >
              <Button 
                size="large" 
                onClick={() => onCancel()}
              >
                Indietro
              </Button>
              <Button 
                type="primary" 
                size="large"
                htmlType="submit"
                disabled={loading === 'createNewTicketFullStatus'}
                loading={loading === 'createNewTicketFullStatus'}
              >
                Salva
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

TicketConclusion.propTypes = {
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func
}

export default TicketConclusion;