import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import queryString from "query-string";
import { BASE_API_URL } from "config/constants";

axios.defaults.withCredentials = true;
axios.defaults.baseURL = BASE_API_URL;

export const getNations = createAsyncThunk(
  "common/getNationsStatus",
  async (data) => {
    const response = await axios.get(`/tools/countries`);
    return response.data;
  }
);

function isFulfilledAction(action) {
  return action.type.endsWith("/fulfilled");
}

function isPendingAction(action) {
  return action.type.endsWith("/pending");
}

function isRejectedAction(action) {
  return action.type.endsWith("/rejected");
}

const initialState = {
  loading: "",
  error: null,
  nations: [],
};

const commonSlice = createSlice({
  name: "common",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getNations.fulfilled, (state, action) => {
      if (action.payload) {
        state.nations = action.payload.data;
      }
    });
    builder.addMatcher(isFulfilledAction, (state, action) => {
      state.loading = "";
      state.error = false;
    });
    builder.addMatcher(isPendingAction, (state, action) => {
      const id =
        typeof action.meta.arg === "object"
          ? action.meta.arg.id
          : action.meta.arg;
      state.loading = action.type.split("/")[1] + (id ? "_" + id : "");
      state.error = false;
    });
    builder.addMatcher(isRejectedAction, (state, action) => {
      state.loading = "";
      state.error = action.error;
    });
  },
});

const { reducer } = commonSlice;

export default reducer;
