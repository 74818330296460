import React, { 
  useState,
  useEffect
} from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col, 
  Form,
  Button,
  Space,
  Modal,
  Radio,
  Input,
  Select,
  message
} from 'antd';
import { useHistory } from 'react-router-dom';
import { 
  useSelector, 
  useDispatch 
} from 'react-redux';
import TicketNewRegisteredCustomer from 'components/Form/TicketNewRegisteredCustomer/TicketNewRegisteredCustomer';
import { 
  getCustomersTickets,
  createNewTicketDraft,
  getTicketEdit
} from 'redux/reducers/ticketsReducer';
import Triplets from 'components/Form/Triplets/Triplets';



function TicketDraft({ onCancel, onSuccess, onSuccessDraft }) {
  
  const [localState, setLocaleState] = useState({
    customer: {
      options: [],
      selected: null,
      vip: false,
      defaulting: false
    },
    contracts: [],
    tickets: []
  }) 
  const { newTicket, loading, error } = useSelector(state => state.tickets);
  
  const dispatch = useDispatch();
  //let history = useHistory();
  const [formNewTicket] = Form.useForm();

  console.log('TICKET DRATS > newTicket > ',newTicket)
  const initialValues = {
  };

  const [showModal, setShowModal] = useState(false);
  const [isCustomerRegistered, setIsCustomerRegistered] = useState(0);
  

  const handleSearchCustomer = async (text) => {
    if(text.length > 2) {
      try {
        const result = await dispatch(getCustomersTickets(text))
        console.log('result > ',result)
        const optionsParsed = result?.payload?.data.reduce((acc,option) => {
          acc.push({
            label: `${option.customer_code} - ${option.first_name} ${option.last_name} - ${option.fiscal_code}`,
            value: option.id,
            tickets: option.tickets,
            contracts: option.contracts,
            vip: option.vip.toString(),
            defaulting: option.defaulting.toString()
          })
           return acc
        },[])
        
        return setLocaleState({
          ...localState,
          customer: {
            options: optionsParsed,
            selected: null
          }
          //tickets: []
          //contracts: []
        })
      } catch (error) {
        
      }
    }
  }

  const handleSelectCustomer = ({ value, vip, defaulting, tickets, contracts }) => {

    const contractOptions = contracts.reduce((acc,option) => {
      acc.push({
        label: `${option.contract_code} - ${option.activation_address.street_name} ${option.activation_address.address_number}, ${option.activation_address.city} (${option.activation_address.province})`,
        value: option.id
      })
       return acc
    },[])

    formNewTicket.resetFields(['contract_id'])
    setLocaleState({
      customer:{
        ...localState.customer,
        selected: value,
        vip: vip,
        defaulting: defaulting
      },
      tickets,
      contracts: contractOptions
    })
  }

  const handleSelectTicket = async (id) => {
    await dispatch(getTicketEdit(id));
    console.log('RESET FIELDS')
    formNewTicket.resetFields();
    console.log('SET SHOW MODAL > FALSE')

    dispatch({type:"tickets/setShowNewTicket", value: false})
  }

  const handleBackButton = () => {
    formNewTicket.resetFields();
    // dispatch({ type: 'contracts/resetState'});
    setShowModal(false);
    onCancel();
  }

  const storeData = (values) => {
    dispatch({ type: 'tickets/createNewTicketHalf', values:values })
    onSuccess()
  }

  const handleFormSubmit = async (values) => {
    formNewTicket.validateFields()
			.then(async (values) => {
        
        const result = await dispatch(createNewTicketDraft({values:values}))
        console.log(result)
        if(result.payload.status !== 422 && result.payload.data) {
          // const paramId = result.payload.data.id ? '&id='+result.payload.data.id : ''
          // history.replace({
          //   search: history.location.search+paramId
          // })
          localStorage.setItem('ticketId',result.payload.data.id)
          message.success(`Bozza ticket creata!`);
          onSuccessDraft()
        }
        else {
          message.error(`Errore nella compilazione del modulo`);
    
          const errors = Object.keys(result.payload.errors).map((errorKey,i) => {
            return {
              name: errorKey.split('.'),
              errors: result.payload.errors[errorKey]
            }
          })
          formNewTicket.setFields(errors)
          formNewTicket.scrollToField(errors[0].name,{behavior: 'smooth'})
    
        }
			})
			.catch((errorInfo) => {});
  };

  
  return (
    <div className="pageContainer" style={{height: '100%', overflowY: 'auto'}}>
      <Form 
        form={formNewTicket}
        autoComplete="off"
        name="formNewContract"
        layout="vertical" 
        colon={false} 
        onFinish={storeData}
        initialValues={initialValues}
        scrollToFirstError={true}
        validateTrigger="onBlur"
      >
        {/* <h3 style={{ marginBottom: 16, color: '#0a3e4b' }}>{isCustomerRegistered}</h3> */}
        
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item 
              name="customer_registered"
              label="Cliente registrato?"
              initialValue={0}
              // rules={[
              //   {
              //     required: true,
              //     message: 'Campo obbligatorio'
              //   },
              // ]}
            >
              <Radio.Group
                onChange={(e) => setIsCustomerRegistered(e.target.value)}
              >
                <Radio value={0}>No</Radio>
                <Radio value={1}>Si</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>

        {
          isCustomerRegistered === 0 && 
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item 
                  name={['foreign','first_name']}
                  label="Nome cliente"
                  initialValue=""
                >
                  <Input 
                    size="large"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item 
                  name={['foreign','last_name']}
                  label="Cognome cliente"
                  initialValue=""
                >
                  <Input 
                    size="large"
                  />
                </Form.Item>
              </Col>
            </Row> || 
            <TicketNewRegisteredCustomer
              customer={localState.customer}
              contractOptions={localState.contracts}
              tickets={localState.tickets}
              onSearchCustomer={handleSearchCustomer}
              onSelectCustomer={(value, option) => handleSelectCustomer(option)}
              onSelectTicket={(value) => handleSelectTicket(value)}
            />
        }

        {
          (
            (isCustomerRegistered == 1 && localState.customer.selected) ||
            (isCustomerRegistered == 0) 
          ) && (
            <>
              <Form.Item 
                name="support_cat_third_id"
                label="Tripletta"
                // rules={[
                //   {
                //     required: true,
                //     message: 'Campo obbligatorio'
                //   }
                // ]}
              >
                <Triplets 
                  data={newTicket.triplets} 
                  required={false}
                />
              </Form.Item>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item 
                    name="origin"
                    label="Origine"
                    initialValue="call"
                  >
                    <Select 
                      size="large"
                    >
                      <Select.Option value="call">Telefono</Select.Option>
                      <Select.Option value="external">Esterna</Select.Option>
                      <Select.Option value="bp">Partner</Select.Option>
                      <Select.Option value="chat">Chat</Select.Option>
                      <Select.Option value="reg_letter">Raccomandata</Select.Option>
                      <Select.Option value="mail">E-mail</Select.Option>
                      <Select.Option value="web">Web</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>  
            </>
          )
        }
        <Row justify="center" align="middle">
          <Col>
            <Space 
              align="center"
              size="large"
              //className="actionBar" 
              //{...tailLayout}
            >
              {/* <Button 
                size="large" 
                onClick={() => setShowModal(true)}
                disabled={loading !== ''}
              >
                Indietro
              </Button> */}
              <Button 
                //type="primary" 
                size="large"
                //htmlType="submit"
                onClick={handleFormSubmit}
                disabled={loading === 'createNewTicketStatus'}
                loading={loading === 'createNewTicketStatus'}
              >
                Lavora ticket
              </Button>
              <Button 
                type="primary" 
                size="large"
                htmlType="submit"
                disabled={loading === 'createNewTicketStatus'}
                loading={loading === 'createNewTicketStatus'}
              >
                Continua e concludi
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
      <Modal
        title="Sei sicuro?"
        visible={showModal}
        // onOk={handleBackButton}
        onCancel={() => setShowModal(false)}
        footer={[
          <Button 
            key="back" 
            onClick={() => setShowModal(false)}
          >
            Annulla
          </Button>,
          <Button 
            key="submit" 
            type="primary" 
            onClick={handleBackButton}
          >
            Confermo
          </Button>
        ]}
      >
        <p>Tornando indietro andranno i dati inseriti andranno persi</p>
      </Modal>
    </div>
  )
}

TicketDraft.propTypes = {
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func,
  onSuccessDraft: PropTypes.func
}

export default TicketDraft;