import React from 'react';
//import PropTypes from 'prop-types';
import {
  Row,
  Col, 
  Form,
  Input
} from 'antd';

//import './step2.css';

function RidFields() {
  
  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Form.Item 
            name={"bank_name"}
            label="Nome banca"
            rules={[
              {
                required: true,
                message: 'Campo obbligatorio'
              },
            ]}
          >
            <Input
              size="large"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item 
            name={"iban"}
            label="Iban"
            rules={[
              {
                required: true,
                message: 'Campo obbligatorio'
              },
              {
                pattern: new RegExp(/^([A-Z]{2}[ \-]?[0-9]{2})(?=(?:[ \-]?[A-Z0-9]){9,30}$)((?:[ \-]?[A-Z0-9]{3,5}){2,7})([ \-]?[A-Z0-9]{1,3})?$/),
                message: 'Formato IBAN non valido'
              }
            ]}
          >
            <Input
              size="large"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item 
            name={"holder_name"}
            label="Titolare"
            rules={[
              {
                required: true,
                message: 'Campo obbligatorio'
              },
            ]}
          >
            <Input
              size="large"
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  )
}

// RidFields.defaultProps = {
//   disabled: false
// }

RidFields.propTypes = {
  //prefix: PropTypes.string
}

export default RidFields;