import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col, 
  Form,
  Tag,
  Select,
  Checkbox,
  Carousel
} from 'antd';
import debounce from 'lodash/debounce';
import TicketBox from 'components/TicketBox/TicketBox'


function TicketNewRegisteredCustomer({ 
  customer,
  contractOptions,
  tickets,
  onSearchCustomer,
  onSelectCustomer,
  onSelectTicket
}) {

  console.log('customerOptions > ',customer)
  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Form.Item 
            name={'customer_id'}
            label="Cerca cliente"
            rules={[
              {
                required: true,
                message: 'Campo obbligatorio'
              },
            ]}
          >
            <Select 
              size="large"
              placeholder="Cerca per nome, cognome o codice cliente"
              showSearch
              showArrow={false}
              notFoundContent={null}
              defaultActiveFirstOption={false}
              onSearch={debounce(onSearchCustomer, 700)}
              onSelect={onSelectCustomer}
              options={customer?.options}
              optionFilterProp="label"
            />
          </Form.Item>
          {
            (customer?.defaulting === 'true' || customer?.vip === 'true' ) && 
              <p style={{ textAlign: 'right', position: 'relative', top: -16, margin: 0 }}>
                {customer?.vip === 'true' && <Tag color="gold" style={{ marginRight: 8 }}> Vip </Tag>}
                {customer?.defaulting === 'true' && <Tag color="volcano"> Moroso </Tag>}
              </p>
          }
        </Col>
        <Col span={24}>
          <Form.Item 
            hidden={tickets.length === 0}
            label="Ticket già aperti dal cliente: "
          >
            <Carousel 
              //dotPosition={'left'}
              draggable={true}
              dots={{
                className: 'ticket-carousel-dots-bottom'
              }}
            >
              {
                tickets?.map(ticket => 
                    <TicketBox
                      key={ticket.id}
                      ticket={ticket}
                      onSelect={()=> onSelectTicket(ticket.id)}
                    />
                  )
              }
            </Carousel>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item 
            name="contract_id"
            label="Contratto"
            hidden={contractOptions.length === 0}
            rules={[
              {
                required: true,
                message: 'Campo obbligatorio'
              }
            ]}
          >
            <Select 
              size="large"
              placeholder="Seleziona il contratto"
              disabled={contractOptions.length === 0}
              options={contractOptions}
            />
          </Form.Item>
        </Col>
      </Row>

      {/* <Row gutter={[16, 16]}>
        <Col span={24}>
          <Form.Item 
            hidden={tickets.length === 0}
            label="Ticket già aperti dal cliente: "
          >
            <Carousel 
              //dotPosition={'left'}
              draggable={true}
              dots={{
                className: 'ticket-carousel-dots-bottom'
              }}
            >
              {
                tickets?.map(ticket => 
                    <TicketBox
                      key={ticket.id}
                      ticket={ticket}
                      onSelect={()=> onSelectTicket(ticket.id)}
                    />
                  )
              }
            </Carousel>
          </Form.Item>
        </Col>
      </Row> */}
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Form.Item 
            name="strong_identity"
            initialValue={false}
            hidden={contractOptions.length === 0}
            valuePropName="checked"
          >
            <Checkbox>Verifica anagrafica</Checkbox>
          </Form.Item>
        </Col>
      </Row>
    </>
  )
}

// TicketNewRegisteredCustomer.defaultProps = {
//   onSearchCustomer: () => {}
// }

TicketNewRegisteredCustomer.propTypes = {
  customer: PropTypes.object,
  //customerOptions: PropTypes.array,
  contractOptions: PropTypes.array,
  tickets: PropTypes.array,
  onSearchCustomer: PropTypes.func.isRequired,
  onSelectCustomer: PropTypes.func,
  onSelectTicket: PropTypes.func.isRequired,
}

export default TicketNewRegisteredCustomer;