import React from "react";

export const DescriptionsContainer = ({ title, children, style, type }) => {
  const styles = {
    card: {
      padding: "8px 24px",
      borderRadius: 4,
      boxShadow: "0px 0px 1px 1px darkgrey",
      height: "100%",
    },
  };

  return (
    <div style={{ ...style, ...styles[type] }}>
      {title && (
        <h3
          style={{
            margin: "8px 0 16px",
            textTransform: "uppercase",
            fontSize: 18,
            color: "lightgray",
            letterSpacing: "0.7px",
          }}
        >
          {title}
        </h3>
      )}
      <div style={{ display: "flex", flexWrap: "wrap" }}>{children}</div>
    </div>
  );
};

export const Item = ({
  label,
  noLabel,
  value,
  discount,
  discountText,
  mlAuto,
  onClick,
  style
}) => {
  return (
    <div
      style={
        (discount && {
          marginBottom: 16,
          flexBasis: "50%",
          marginLeft: mlAuto && "auto",
          ...style,
        }) || {
          marginBottom: 16,
          flexBasis: "50%",
          marginLeft: mlAuto && "auto",
          ...style,
        }
      }
    >
      {!noLabel && (
        <div style={{ fontWeight: "bold", opacity: (label && 1) || 0 }}>
          {label || "---"}
        </div>
      )}

      <div onClick={value && onClick} style={{  }}>
        <span
          style={
            (discount && {
              fontSize: 16,
              textDecoration: "line-through",
              color: "grey",
              cursor: onClick && value && "pointer",
            }) || {
              fontSize: 16,
              color: onClick && value && "#1890ff",
              cursor: onClick && value && "pointer",
            }
          }
        >
          {discountText && (
            <span>
              <span style={{ textDecoration: "line-through", color: "grey", marginRight: '5px' }}>
                {discountText}
              </span>
              {value || "---"}
            </span>
          )}
          {!discountText && (value || "---")}
        </span>
      </div>
    </div>
  );
};
export const BoxItem = ({ label, value, color, bgColor, onClick }) => {
  return (
    <div
      style={{
        marginBottom: 16,
        color: color,
        // flexBasis: "50%",
        height: "43px",
        backgroundColor: bgColor,
        borderRadius: "60px",
        padding: "10px",
      }}
    >
      <div style={{ fontWeight: "bold" }}>{label}</div>

      <div onClick={value && onClick}>
        <span
          style={{
            fontSize: 14,
            color: onClick && value && "#1890ff",
            cursor: onClick && value && "pointer",
          }}
        >
          {value || "---"}
        </span>
      </div>
    </div>
  );
};
