import { Button, Col, Form, Input, Row, Space, message } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { submitLinkemContractCode } from "redux/reducers/contractsReducer";

const InsertLinkemContractCode = ({ loading, onSuccess, onCancel, initialValues }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const onFinish = async (values) => {
    const result = await dispatch(
      submitLinkemContractCode({
        code: values.code,
      })
    );

    if (result.payload.status !== 422 && result.payload.data) {
      message.success("Operazione inoltrata con successo!");
      onSuccess();
    } else {
      //console.log(result)
      message.error(`Errore nella compilazione del modulo`);
    }
  };

  return (
    <Form
      style={{
        width: 400,
        margin: "16px auto",
      }}
      form={form}
      name="formLinkemContractCode"
      layout="vertical"
      colon={false}
      onFinish={onFinish}
      initialValues={initialValues}
      //scrollToFirstError={true}
      validateTrigger="onBlur"
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Form.Item
            name="code"
            label="Codice contratto"
            // rules={[
            //   {
            //     required: true,
            //     message: "Campo obbligatorio",
            //   },
            // ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="center" align="middle">
        <Col>
          <Space align="center" size="large">
            <Button
              //type="primary"
              //htmlType="submit"
              size="large"
              onClick={onCancel}
              disabled={loading === "submitUploadWithdrawalStatus"}
              //loading={loading === 'confirmApproveContractStatus'}
            >
              Annulla
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              //onClick={confirmValidation}
              disabled={loading === "submitUploadWithdrawalStatus"}
              loading={loading === "submitUploadWithdrawalStatus"}
            >
              Conferma
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
};

export default InsertLinkemContractCode;
