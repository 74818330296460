import React from 'react';
import {
  Tooltip,
  Tag
} from 'antd';
import {
  WarningOutlined
} from '@ant-design/icons';

export default function TitleNote ({note}) {
  if(note) {
    return (
      <div style={{display:'flex', justifyContent:'space-between'}}>
        <div style={{paddingRight:8}}>
          {`${note.creator?.first_name} ${note.creator?.last_name} [${note.creator_type}]`} 
        </div>
        <div>
          { note.reminder && 
            <Tooltip title="Sollecito">
              <WarningOutlined style={{ marginRight: 8, color: '#faad14'}} /> 
            </Tooltip>
          }
          <Tag color="blue">{note.state_name}</Tag>
        </div>
      </div>
    )
  }
  return <div>Anonimo</div>
}