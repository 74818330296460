import React, { 
  useState, 
  useEffect, 
  useRef 
} from 'react'
import { Table, Pagination } from 'antd';
//import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import InputFilter from './InputFilter';
import RadioFilter from './RadioFilter';
import DatePickerFilter from './DatePickerFilter';
import TripletsFilter from './TripletsFilter';

const EnanchedTable = (
  { 
    size,
    columns, 
    dataSource, 
    rowKey, 
    locale, 
    loading, 
    pagination, 
    onChangePage,
    onSearch,
    onResetFilter
  }) => {

  const [parsedColumns, setParsedColumns] = useState([])
  const inputRefs = new Array(columns.length).fill(useRef(null))
  // const [searchText, setSearchText] = useState('')
  // const [searchedColumn, setSearchedColumn] = useState('')


  
  useEffect(() => {
    let searchInput //= useRef()
    const getColumnSearchProps = (
      {
        key,
        dataIndex,
        placeholder,
        title,
        filterType='input',
        filterOptions
      }
    ) => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        //console.log(inputRefs[key])
        const filterTypeMap = {
          'input' : (
            <InputFilter 
              ref={inputRefs[key]}
              dataIndex={dataIndex}
              placeholder={placeholder}
              title={title}
              setSelectedKeys={setSelectedKeys}
              selectedKeys={selectedKeys}
              confirm={confirm}
              clearFilters={clearFilters}
              handleSearch={handleSearch}
              handleReset={handleReset}
            />
          ),
          'radio' : (
            <RadioFilter 
              dataIndex={dataIndex}
              options={filterOptions}
              title={title}
              setSelectedKeys={setSelectedKeys}
              selectedKeys={selectedKeys}
              confirm={confirm}
              clearFilters={clearFilters}
              handleSearch={handleSearch}
              handleReset={handleReset}
            />
          ),
          'dateRange' : (
            <DatePickerFilter 
              dataIndex={dataIndex}
              title={title}
              setSelectedKeys={setSelectedKeys}
              selectedKeys={selectedKeys}
              confirm={confirm}
              clearFilters={clearFilters}
              handleSearch={handleSearch}
              handleReset={handleReset}
            />
          ),
          'tree' : (
            <TripletsFilter 
              data={filterOptions}
              dataIndex={dataIndex}
              title={title}
              setSelectedKeys={setSelectedKeys}
              selectedKeys={selectedKeys}
              confirm={confirm}
              clearFilters={clearFilters}
              handleSearch={handleSearch}
              handleReset={handleReset}
            />
          )
        }
      
        return filterTypeMap[filterType]
      },
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      //onFilter: (value, record) => console.log(value, record) ,
        // record[dataIndex]
        //   ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        //   : '',
      onFilterDropdownVisibleChange: visible => {
        if (visible && filterType === 'input') {
          //console.log(inputRefs[key].current)
          setTimeout(() => inputRefs[key].current.focus(), 200);
        }
      },
      // render: text =>
      //   this.state.searchedColumn === dataIndex ? (
      //     <Highlighter
      //       highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
      //       searchWords={[this.state.searchText]}
      //       autoEscape
      //       textToHighlight={text ? text.toString() : ''}
      //     />
      //   ) : (
      //     text
      //   ),
    });

    const newColumns = columns.map((column,key) => {
      if(!column.render) {
        column.render = (value) => value ? value : '---'
      }
      
      if(column.search) {
        return {
          //render: (value) => value ? value : '---',
          ...column,
          ...getColumnSearchProps({
              key: key,
              dataIndex: column.dataIndex, 
              placeholder: 'Cerca '+column.title.toLowerCase(),
              title: column.title,
              filterType: column.filterType,
              filterOptions: column.filterOptions,
              //isClean: filters[]
            }
          )
        }
      }
      return column
    })
    
    setParsedColumns(newColumns)
  },[columns])
    
  

  const handleSearch = (selectedKeys, confirm, dataIndex,title) => {
    confirm();
    // setSearchText(selectedKeys[0])
    // setSearchedColumn(dataIndex)
    //console.log('TABLE > handleSearch > ',dataIndex)
    onSearch(dataIndex,selectedKeys[0],title)
  };

  const handleReset = (clearFilters, dataIndex) => {
    clearFilters();
    onResetFilter(dataIndex);
    //setSearchText('');
  };

  const newPagination = {
    showSizeChanger: false, 
    ...pagination
  }
  //console.log('DATA TABLE > ',pagination, newPagination)
  return (
    <>
    <Table 
      size={size}
      columns={parsedColumns} 
      dataSource={dataSource} 
      rowKey={rowKey}
      locale={locale}
      loading={loading}
      pagination={false}
    />
    <Pagination 
      style={{
        backgroundColor: 'white',
        paddingBottom: 16,
        paddingRight: 8, 
        textAlign: 'right'
      }}
      size={size} 
      {...newPagination} 
      onChange={(pagination) => onChangePage(pagination)}
    />
    </>
  )
}

export default EnanchedTable