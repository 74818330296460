import React, { 
  useState 
} from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col, 
  Form,
  Input,
  Select,
  Checkbox,
  Switch,
  DatePicker
} from 'antd';
import moment from 'moment';
import allNations from 'data/nations.json'
//import './step2.css';
import AddressFields from 'components/Form/AddressFields/AddressFields';
// import CreditCardFields from './CreditCardFields';
// import RidFields from './RidFields';

function ContractFields({ 
  initialValues, 
  locale, 
  paymentType,
  zipcodeOptions,
  onCopyValues
  }) {
  
  const [isBillingDisabled, setIsBillingDisabled] = useState(true);
  const [isDeliveryDisabled, setIsDeliveryDisabled] = useState(true);

  const [values, setValues] = useState(initialValues);

  return (
    <>
      <AddressFields 
        prefix="activation_address" 
        title="Indirizzo di attivazione" 
        disabled={['intercom_name','postal_code','stair','floor','nui']}
        includeInternal 
        initialValues={values} 
        zipcodeOptions={zipcodeOptions}
        onBlur={(fieldName) => isBillingDisabled && onCopyValues('activation_address','billing_address')}
        onChange={(e) => { onCopyValues('activation_address','delivery_address') }}
      />
      <AddressFields 
        prefix="delivery_address" 
        title="Indirizzo di spedizione" 
        disabled={isDeliveryDisabled}
        includeInternal 
        initialValues={values} 
        zipcodeOptions={zipcodeOptions}
        onBlur={(fieldName) => isDeliveryDisabled && onCopyValues('activation_address','delivery_address')}
        action={{
          label: 'Coincide con indirizzo di attivazione',
          value: true,
          hidden: !isDeliveryDisabled,
          execute: (e) => {
            setIsDeliveryDisabled(e.target.checked)
            e.target.checked && onCopyValues('activation_address','delivery_address')
          }
        }}
      />
      {/* <AddressFields 
        prefix="contract_delivery" 
        title="Spedizione" 
        action={{
          label: 'Copia utenza',
          execute: () => onCopyValues('contract_user','contract_delivery')
        }} 
      /> */}
      <AddressFields 
        prefix="billing_address" 
        labels={{intercom: 'c/o'}}
        title="Indirizzo di fatturazione" 
        initialValues={values}
        disabled={isBillingDisabled}
        notRequiredFields={{ intercom_name: false }}
        action={{
          label: 'Coincide con indirizzo di attivazione',
          value: true,
          hidden: !isBillingDisabled,
          execute: (e) => {
            setIsBillingDisabled(e.target.checked)
            e.target.checked && onCopyValues('activation_address','billing_address')
          }
        }}
      />

      <h3 style={{ marginTop: 16, marginBottom: 16, color: '#0a3e4b' }}>DOCUMENTI PERSONALI</h3>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Form.Item 
            name={["customer","document","nationality"]}
            label="Nazionalità"
            initialValue="Italia"
            rules={[
              {
                required: true,
                message: 'Campo obbligatorio'
              }
            ]}
          >
            <Select
              showSearch
              size="large"
              options={allNations}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Form.Item 
            name={["customer","document","type"]}
            label="Tipo di documento d'identità"
            rules={[
              {
                required: true,
                message: 'Campo obbligatorio'
              }
            ]}
          >
            <Select
              size="large"
              options={[
                { label: 'Carta d\'identità', value: 'ci' },
                { label: 'Patente', value: 'pt' },
                { label: 'Passaporto', value: 'pp' }
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item 
            name={["customer","document","number"]}
            label="Numero documento"
            rules={[
              {
                required: true,
                message: 'Campo obbligatorio'
              }
            ]}
          >
            <Input
              size="large"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Form.Item 
            name={["customer","document","issued_at"]}
            label="Data di rilascio"
            rules={[
              {
                required: true,
                message: 'Campo obbligatorio'
              }
            ]}
          >
            <DatePicker
              size="large"
              style={{width: '100%'}}
              locale={locale}
              format={'DD/MM/YYYY'}
              disabledDate={(current) => current > moment()}
              placeholder={'DD/MM/YYYY'}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item 
            name={["customer","document","entity"]}
            label="Rilasciato dal"
            rules={[
              {
                required: true,
                message: 'Campo obbligatorio'
              },
            ]}
          >
            <Input
              size="large"
            />
          </Form.Item>
        </Col>
      </Row>

      
      


      {/* <Row gutter={[16, 16]}>
        <Col span={12}>
          <Form.Item 
            name={["customer","document","city"]}
            label="Comune di rilascio"
            rules={[
              {
                required: true,
                message: 'Campo obbligatorio'
              }
            ]}
          >
            <Input
              size="large"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item 
            name={["customer","document","province"]}
            label="Provincia di rilascio"
            rules={[
              {
                required: true,
                message: 'Campo obbligatorio'
              },
            ]}
          >
            <Input
              size="large"
              maxLength={2}
            />
          </Form.Item>
        </Col>
      </Row> */}
      

      
    </>
  )
}

ContractFields.defaultProps = {
  disabled: false
}

ContractFields.propTypes = {
  disable: PropTypes.bool,
  initialValues: PropTypes.object,
  locale: PropTypes.object,
  paymentType: PropTypes.string,
  zipcodeOptions: PropTypes.array,
  onCopyValues: PropTypes.func
}

export default ContractFields;