import React, { 
  useState, 
  useEffect 
} from 'react';
import { 
  Tabs,
  Skeleton,
  PageHeader,
  Tag,
  Row,
  Col,
  Statistic,
  Button,
  Dropdown,
  Menu,
  Modal,
  message
} from 'antd';
import moment from 'moment';
//import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { DownOutlined } from '@ant-design/icons';
import { 
  getContractCreatingDetails,
  submitResendEmailValidation,
  submitRecessCancel,
  downloadDocumentContracts
} from 'redux/reducers/contractsReducer';
import {
  getTicketEdit
} from 'redux/reducers/ticketsReducer';
import {
  Link,
  useParams,
  useHistory
} from "react-router-dom";
import DocBox from 'components/DocBox/DocBox';
import { DescriptionsContainer, Item } from 'components/Descriptions/Description';

import RecessRequest from './_actions/RecessRequest';
import ResumeRequest from './_actions/ResumeRequest';
import UploadRid from './_actions/UploadRid';
import CancelOrder from './_actions/CancelOrder';

function ContractsCreatingDetails() {

  const [stateActions, setStateActions] = useState({
    type: '',
    title: ''
  });
  const [stateConfirm, setStateConfirm] = useState({
    type: '',
    message: '',
    messageSuccess: ''
  });
  const { userRole } = useSelector(state => state.auth);
  const { selected, loading, error } = useSelector(state => state.contracts);
  const { newTicket } = useSelector(state => state.tickets);
  const dispatch = useDispatch();
  let history = useHistory();
  const params = useParams();
  
  useEffect(() => {
    const getDetails = async() => {
      try {
        //console.log('DOCID MOUNT > ',params)
        const result = await dispatch(getContractCreatingDetails(params.id))
      } catch (error) {
        
      }
    }

    getDetails()
  },[])

  const columns = [
    {
      title: 'Numero',
      dataIndex: 'ticket_code',
      search: true
      //render: (value,row) => <a onClick={() => dispatch(getTicketEdit(row.id))}>{value}</a>,
    },
    {
      title: 'Tipologia - Famiglia - Sottofamiglia',
      title_name: 'Tripletta',
      dataIndex: 'support_cat',
      render: (value,row) => {
        return (
          <>
            <Tag color="geekblue" size="small">{row.support_cat_first.name}</Tag>
            <Tag color="green" size="small">{row.support_cat_second.name}</Tag>
            <Tag color="volcano" size="small">{row.support_cat_third.name}</Tag>
          </>
        )
      },
      search: true,
      filterType: 'tree',
      filterOptions: newTicket?.triplets
    },
    // {
    //   title: 'Famiglia',
    //   dataIndex: ['support_cat_second','name'],
    // },
    // {
    //   title: 'Sottofamiglia',
    //   dataIndex: ['support_cat_third','name'],
    // },
    {
      title: 'Stato',
      dataIndex: 'state_name',
      search: true,
      filterType: 'radio',
      filterOptions: [
        { text: 'Aperto', value: 'open' },
        { text: 'Chiuso', value: 'closed' },
        { text: 'Sospeso', value: 'suspended' }
      ]
    },
    {
      title: 'Ocs',
      dataIndex: 'ocs',
      render: (value,row) => value ? 'Si' : 'No',
    },
    {
      title: 'Origine',
      dataIndex: 'origin_name',
      search: true,
      filterType: 'radio',
      filterOptions: [
        { text: 'Chiamata', value: 'call' },
        { text: 'Segnalazione esterna', value: 'external' },
        { text: 'Business partner', value: 'bp' },
        { text: 'Chat', value: 'chat' },
        { text: 'Raccomandata', value: 'reg_letter' },
        { text: 'E-mail', value: 'mail' },
        { text: 'Web', value: 'web' }
      ]
    },
    {
      title: 'Data creazione',
      dataIndex: 'created_at',
      render: value => moment(value).format('D MMM YYYY HH:mm'),
      search: true,
      filterType: 'dateRange'
    },
    {
      //title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Button 
          size="small" 
          type="link"
          onClick={() => handleEdit(record.id)}
        >
          Lavora
        </Button>
      ),
    },
  ];

  const handleEdit = (id) => {
    
    if(newTicket?.draft) {
      if(newTicket?.draft.id !== id) {
        return dispatch({
          type:"tickets/setshowAlertWorking", 
          options: {
            value: true,
            actionName: 'EDIT',
            params: {
              id
            }
          }
        })
      }
      else {
        return dispatch({ type: 'tickets/setIsDrawerOpen', value: true })
      }
    }
    //localStorage.setItem('ticketId', id)
    dispatch(getTicketEdit(id))
    //dispatch({type:"tickets/setShowNewTicket", value: true})
  }

  const actionsContentMap = ({
    actionName,
    onSuccess,
    onCancel
  }) => {
    const actions = {
      'RECESS_REQUEST': <RecessRequest loading={loading} onSuccess={onSuccess} onCancel={onCancel} />,
      'RESUME_REQUEST': <ResumeRequest loading={loading} onSuccess={onSuccess} onCancel={onCancel} />,
      'UPLOAD_RID': <UploadRid loading={loading} onSuccess={onSuccess} onCancel={onCancel} />,
      'CANCEL_ORDER': <CancelOrder loading={loading} onSuccess={onSuccess} onCancel={onCancel} />
    }

    return actions[actionName]
  }

  const handleConfirm = async () => {
    console.log(stateConfirm)
    try {
      const messageSuccess = stateConfirm.messageSuccess
      const actionsMap = {
        'SEND_VALIDATION_MAIL': submitResendEmailValidation, 
        'RECESS_CANCEL': submitRecessCancel
      }
      const result = await dispatch(actionsMap[stateConfirm.type]());
      if(result.payload.data) {
        message.success(messageSuccess)
        setStateConfirm({
          type: '',
          message: '',
          messageSuccess: ''
        })
      }
    } catch (error) {
      setStateConfirm({
        type: '',
        message: '',
        messageSuccess: ''
      })
      message.error(error.message)
    }
  }

  const actionsMenu = (
    <Menu>
      {
        userRole === 'PARTNER' &&
        selected?.state === 'papers_generated' &&
          <Menu.Item key="papers_generated">
            <Link to={`/contratti/in-acquisizione/${selected?.id}`}>Carica documentazione</Link>
          </Menu.Item>
      }
      {
        userRole === 'CUSTOMER_CARE' &&
        selected?.state === 'papers_uploaded' &&
          <Menu.Item key="papers_uploaded">
            <Link to={`/contratti/validazione/${selected?.id}`}>Valida documentazione</Link>
          </Menu.Item>
      }
      {
        //userRole === 'CUSTOMER_CARE' &&
        selected?.type !== 'on_paper' &&
        ['data_filled','email_confirmed','otp_confirmed','payment_submitted'].includes(selected?.state) &&
        <Menu.Item key="send_validation_mail">
          <a onClick={() => setStateConfirm({
              type: 'SEND_VALIDATION_MAIL', 
              message:'Rispedisci e-mail di validazione al cliente?',
              messageSuccess: 'E-mail inviata con sucesso!' 
            })}>Spedisci validazione via e-mail</a>
        </Menu.Item>
      }
    </Menu>
  )
  

  const parsePaymentMethodType = {
    'cc': 'Carta di credito',
    'rid': 'RID/SDD'
  }

  const downloadAndOpen = async (type,id, isCreating) => {
    
    const result = await dispatch(downloadDocumentContracts({type, docId:id, isCreating}))
    
    if(result.payload) {
      window.open(result.payload.url, '_blank')
    }
  }

  return (
    <div className="main-container">
      {
        (loading.includes('getContractDetailsStatus') || selected === null) ? 
        <Skeleton active /> :
        <div className="main-content">
          <PageHeader
            //className="site-page-header-responsive"
            onBack={() => history.goBack()}
            title={`Contratto in acquisizione: ${selected?.customer?.first_name || '---'} ${selected?.customer?.last_name || '---'}`}
            tags={<Tag color="blue" size="large" >{selected?.state_name}</Tag>}
            //subTitle="This is a subtitle"
            extra={[
              <Dropdown 
                overlay={actionsMenu} 
                key="contract-details-actions"
                trigger={["click"]}
                style={{ minWidth: 250 }}
              >
                <Button 
                  type="primary" 
                >
                  Azioni
                  <DownOutlined />
                </Button>
              </Dropdown>
              // <Button key="1" type="primary">
              //   Azioni
              // </Button>,
            ]}
          >
            <Row>
              <Col 
                span={20} 
                style={{ 
                  display: 'flex',
                  justifyContent: 'space-between'
                }}>
                <Statistic title="Tipologia" value={selected?.type_name || '---'} />
                <Statistic title="Offerta" value={selected?.commercial_offer?.name || '---'} />
                <Statistic
                  title="Costo mensile"
                  prefix="€"
                  precision={2}
                  value={selected?.commercial_offer?.monthly_amount || '---'}
                  style={{
                    margin: '0 32px',
                  }}
                />
                <Statistic 
                  title="Data sottoscrizione" 
                  value={selected?.created_at ? moment(selected?.created_at).format("DD/MM/YYYY HH:mm") : '---'} />
                <Statistic 
                  title="Data ultima modifica" 
                  value={selected?.updated_at ? moment(selected?.updated_at).format("DD/MM/YYYY HH:mm"): '---'} 
                />
              </Col>
            </Row>
          </PageHeader>
          
          <Tabs type="card" defaultActiveKey="1" >
            <Tabs.TabPane tab="Dettagli" key="details">
              <Row gutter={[24,24]}>
                <Col span={8}>
                  <DescriptionsContainer title="Indirizzo di attivazione" type="card">
                    <Item label="Nome sul citofono" value={selected?.activation_address?.intercom_name || '---'}/>
                    <Item label="Indirizzo" value={(selected?.activation_address?.street_name || '---') +', '+ (selected?.activation_address?.address_number || '---')}/>
                    {/* <Item label="Civico" value={selected?.activation_address?.address_number || '---'}/> */}
                    <Item label="Scala - Interno -Piano" value={(selected?.activation_address?.stair || '')+' - '+(selected?.activation_address?.nui || '') + ' - ' + (selected?.activation_address?.floor || '')}/>
                    {/* <Item label="Interno" value={selected?.activation_address?.nui || '---'}/>
                    <Item label="Piano" value={selected?.activation_address?.floor || '---'}/> */}
                    <Item label="Città" value={(selected?.activation_address?.city || '---') + ' ('+ (selected?.activation_address?.province || '---') +')'}/>
                    {/* <Item label="Provincia" value={selected?.activation_address?.province || '---'}/> */}
                    <Item label="Cap" value={selected?.activation_address?.postal_code || '---'}/>
                  </DescriptionsContainer>  
                </Col>
                <Col span={8}>
                  <DescriptionsContainer title="Indirizzo di spedizione" type="card">
                    <Item label="Nome sul citofono" value={selected?.delivery_address?.intercom_name || '---'}/>
                    <Item label="Indirizzo" value={(selected?.delivery_address?.street_name || '---') + ', ' + (selected?.delivery_address?.address_number || '---')}/>
                    {/* <Item label="Civico" value={selected?.delivery_address?.address_number || '---'}/> */}
                    <Item label="Scala - Interno - Piano" value={(selected?.delivery_address?.stair || '') +' - '+ (selected?.delivery_address?.nui || '') + ' - '+ (selected?.delivery_address?.floor ||'')}/>
                    {/* <Item label="Interno" value={selected?.delivery_address?.nui || '---'}/>
                    <Item label="Piano" value={selected?.delivery_address?.floor || '---'}/> */}
                    <Item label="Città" value={(selected?.delivery_address?.city || '---') +' ('+ (selected?.delivery_address?.province || '---') +')' }/>
                    {/* <Item label="Provincia" value={selected?.delivery_address?.province || '---'}/> */}
                    <Item label="Cap" value={selected?.delivery_address?.postal_code || '---'}/>
                  </DescriptionsContainer>  
                </Col>
                <Col span={8}>
                  <DescriptionsContainer title="Indirizzo di fatturazioneo" type="card">
                    <Item label="Nome sul citofono" value={selected?.billing_address?.intercom_name || '---'}/>
                    <Item label="Indirizzo" value={(selected?.billing_address?.street_name || '---') + ', ' + (selected?.billing_address?.address_number || '---')}/>
                    {/* <Item label="Civico" value={selected?.billing_address?.address_number || '---'}/> */}
                    <Item label="Scala - Interno - Piano" value={(selected?.billing_address?.stair || '') + ' - '+(selected?.billing_address?.nui|| '')+ ' - ' + (selected?.billing_address?.floor || '')}/>
                    {/* <Item label="Interno" value={selected?.billing_address?.nui || '---'}/>
                    <Item label="Piano" value={selected?.billing_address?.floor || '---'}/> */}
                    <Item label="Città" value={(selected?.billing_address?.city || '---') +' ('+ (selected?.billing_address?.province || '---') + ')' || '---'}/>
                    {/* <Item label="Provincia" value={selected?.billing_address?.province || '---'}/> */}
                    <Item label="Cap" value={selected?.billing_address?.postal_code || '---'}/>
                  </DescriptionsContainer>                  
                </Col>
                <Col span={8}>
                  <DescriptionsContainer title="Dati di connessione" type="card">
                    <Item label="Tipologia di connessione" value={selected?.connection_type?.connection_type || '---'}/>
                    <Item label="Velocità di download" value={selected?.connection_type?.download_speed || '---'}/>
                    <Item label="Velocità di upload" value={selected?.connection_type?.upload_speed || '---'}/>
                    <Item label="Velocità di download effettiva" value={selected?.coverage?.actual_download_speed || '---'}/>
                    <Item label="Velocità di upload effettiva" value={selected?.coverage?.actual_upload_speed || '---'}/>
                    <Item label="Latenza" value={selected?.coverage?.latency || '---'}/>
                    <Item label="Kit Id" value={selected?.coverage?.kit_id || '---'}/>
                  </DescriptionsContainer>  
                </Col>
                <Col span={8}>
                  <DescriptionsContainer title="Dati referente" type="card">
                  <Item label="Nome" value={selected?.referent?.first_name || '---'}/>
                  <Item label="Cognome" value={selected?.referent?.last_name || '---'}/>
                  <Item label="Cellulare" value={selected?.referent?.phone_number || '---'}/>
                  <Item label="Telefono fisso" value={selected?.referent?.home_phone_number || '---'}/>
                </DescriptionsContainer>
                </Col>
                <Col span={8}>
                  <DescriptionsContainer title="Informazioni di pagamento" type="card">
                  <Item label="Tipologia" value={parsePaymentMethodType[selected?.payment_method?.type] || '---'}/>
                  {
                    selected?.payment_method?.type === 'cc' ?
                    <>
                      <Item label="Numero carta" value={`**** **** **** *${selected?.payment_method?.cc_last_numbers}` || '---'}/>
                      <Item label="Scadenza carta" value={(selected?.payment_method?.token_expiration_month || '---') +'/'+(selected?.payment_method?.token_expiration_year || '---')}/>
                    </> : 
                    <>
                      <Item label="Iban" value={selected?.payment_method?.iban || '---'}/>
                    </>
                  }
                  <Item label="Data di inserimento" value={selected?.payment_method?.created_at && moment(selected?.payment_method?.created_at).format('DD/MM/YYYY') || '---'}/>
                </DescriptionsContainer>
                </Col>
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Cliente" key="customer">
              {
                //loading.includes('getContractDetailsCustomerStatus') 
                //? <LoadingFullwidth />
                  <Row gutter={[24,24]}>
                    <Col span={8}>
                      <DescriptionsContainer title="Dati anagrafica" type="card">
                        <Item label="Codice cliente" value={selected?.customer?.customer_code} onClick={() => history.push(`/clienti/${selected?.customer?.id}`)}/>
                        <Item label="Nome" value={selected?.customer?.first_name || '---'}/>
                        <Item label="Cognome" value={selected?.customer?.last_name || '---'}/>
                        <Item label="Genere" value={selected?.customer?.gender_name || '---'}/>
                        <Item label="Cellulare" value={selected?.customer?.phone_number || '---'}/>
                        <Item label="Telefono fisso" value={selected?.customer?.home_phone_number || '---'}/>
                        <Item label="E-mail" value={selected?.customer?.email || '---'}/>
                        <Item label="Data di nascita" value={selected?.customer?.born_at ? moment(selected.customer.born_at).format('DD/MM/YYYY') : '---'}/>
                        <Item label="Luogo di nascita" value={selected?.customer?.born_city || '---'}/>
                        <Item label="Provincia di nascita" value={selected?.customer?.born_province || '---'}/>
                        <Item label="Nazione di nascita" value={selected?.customer?.born_country || '---'}/>
                        <Item label="Codice fiscale" value={selected?.customer?.fiscal_code || '---'}/>
                      </DescriptionsContainer>
                    </Col>
                    <Col span={8}>
                      <DescriptionsContainer title="Documento di riconoscimento" type="card">
                        <Item label="Nazionalità" value={selected?.customer?.document?.nationality || '---'}/>
                        <Item label="Tipologia" value={selected?.customer?.document?.type_name || '---'}/>
                        <Item label="Numero" value={selected?.customer?.document?.number || '---'}/>
                        <Item label="Data di rilascio" value={selected?.customer?.document?.issued_at ? moment(selected.customer.document.issued_at).format('DD/MM/YYYY') : '---'}/>
                        <Item label="Rilasciato dal " value={selected?.customer?.document?.entity || '---'}/>
                      </DescriptionsContainer>
                    </Col>
                  </Row>
              }
            </Tabs.TabPane>
            <Tabs.TabPane tab="Documenti" key="documentation">
              <Row gutter={[16,16]}>
                <Col span={12}>
                  <Row gutter={[16,16]}>
                    <Col span={24}>
                      <h2>Generati</h2>
                      {
                        selected?.generated_documentation?.map(doc => (
                          <Row gutter={[16, 16]} key={doc?.id}>
                            <Col span={20}>
                              <DocBox 
                                docId={doc?.id}
                                docName={doc?.type_name}
                                category="generated"
                                loading={loading}
                                tagColor={'red'}
                                tagName={doc?.state === 'rejected' && 'Rifiutato'}
                                onDownload={(cat,id) => downloadAndOpen('generated',id, true)}
                                subtitle={doc?.updated_at && moment(doc.updated_at).format('DD/MM/YYYY HH:MM') || '---'}
                              />
                            </Col>
                          </Row>
                        ))
                      }
                      {
                        selected?.generated_documentation?.length === 0 && 
                          <Row gutter={[16, 16]}>
                            <Col span={20}>Nessun contratto generato</Col>
                          </Row>
                      }
                    </Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row gutter={[16,16]}>
                    <Col span={24}>
                      <h2>Caricati</h2>
                      {
                        selected?.uploaded_documentation?.map(doc => (
                          <Row gutter={[16, 16]} key={doc?.id}>
                            <Col span={20}>
                              <DocBox 
                                key={doc?.id}
                                docId={doc?.id}
                                docName={doc?.type_name}
                                state={doc?.state}
                                category="uploaded"
                                loading={loading}
                                tagColor={'red'}
                                isReadOnly={true}
                                tagName={doc?.state === 'rejected' && 'Rifiutato'}
                                onDownload={(cat,id) => downloadAndOpen('uploaded',id, true)}
                                subtitle={doc?.updated_at && moment(doc.updated_at).format('DD/MM/YYYY HH:MM') || '---'}
                              />
                            </Col>
                          </Row>
                        ))
                      }
                      {
                        selected?.uploaded_documentation?.length === 0 && 
                          <Row gutter={[16, 16]}>
                            <Col span={20}>Nessun contratto caricato</Col>
                          </Row>
                      }
                    </Col>
                  </Row>
                </Col>
              </Row>
              
            </Tabs.TabPane>
          </Tabs>
          <Modal
            title={stateActions.title}
            visible={stateActions.type !== ''}
            destroyOnClose
            width={760}
            onCancel={() => setStateActions({ title: '', type: ''})}
            footer={false}
            // footer={[
            //   <Button 
            //     key="back" 
            //     type="primary" 
            //     onCancel={() => setStateActions({ title: '', type: ''})}
            //   >
            //     Chiudi
            //   </Button>
            // ]}
          >
            {
              actionsContentMap({
                actionName: stateActions.type,
                onSuccess:() => {setStateActions({ title: '', type: ''})},
                onCancel:() => setStateActions({ title: '', type: ''})
              })
            }
          </Modal>
          <Modal
            title="Richiesta conferma"
            visible={stateConfirm.type !== ''}
            // onOk={handleBackButton}
            onCancel={() => setStateConfirm({ message: '', type: ''})}
            footer={[
              <Button 
                key="back" 
                onClick={() => setStateConfirm({ message: '', type: ''})}
              >
                Annulla
              </Button>,
              <Button 
                key="submit" 
                type="primary" 
                onClick={handleConfirm}
              >
                Confermo
              </Button>
            ]}
          >
            <p>{stateConfirm.message}</p>
          </Modal>
        </div>
      }
    </div>
  )
}

// ContractsCreatingDetails.PropTypes = {
  
// }

export default ContractsCreatingDetails;