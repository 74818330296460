import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col, 
  Form,
  Input,
  Checkbox
} from 'antd';

//import './step2.css';

function LeadFields({ prefix }) {
  
  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Form.Item 
            name={prefix+"_firstname"}
            label="Nome"
            rules={[
              {
                required: true,
                message: 'Campo obbligatorio'
              },
            ]}
          >
            <Input
              size="large"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item 
            name={prefix+"_lastname"}
            label="Cognome"
            rules={[
              {
                required: true,
                message: 'Campo obbligatorio'
              },
            ]}
          >
            <Input
              size="large"
            />
          </Form.Item>
        </Col>
      </Row>
      {/* <Row gutter={[16, 16]}>
        <Col span={12}> */}
          <Form.Item 
            name={prefix+"_phone"}
            label="Telefono"
            rules={[
              {
                required: true,
                message: 'Campo obbligatorio'
              },
              {
                pattern: new RegExp(/(3{1}[2-9]{1}[0-9]{1})(\d{6,7})/),
                message: 'Numero di cellulare non valido'
              }
            ]}
          >
            <Input
              size="large"
              addonBefore="+39"
            />
          </Form.Item>
        {/* </Col>
        <Col span={12}> */}
          <Form.Item 
            name={prefix+"_email"}
            label="E-mail"
            rules={[
              {
                required: true,
                message: 'Campo obbligatorio'
              },
              {
                type: 'email',
                message: 'Formato non valido'
              }
            ]}
          >
            <Input
              size="large"
            />
          </Form.Item>

          <Form.Item 
            name={prefix+"_privacy"}
            initialValue={false}
            //label=""
            valuePropName="checked"
            rules={[
              {
                required: true,
                transform: value => (value || undefined),
                type: 'boolean',
                message: 'Campo obbligatorio'
              },
            ]}
          >
            <Checkbox>Accetta privacy*</Checkbox>
          </Form.Item>
        {/* </Col>
      </Row> */}
    </>
  )
}

// LeadFields.defaultProps = {
//   disabled: false
// }

LeadFields.propTypes = {
  prefix: PropTypes.string
}

export default LeadFields;