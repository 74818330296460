import React from 'react';
import moment from 'moment';
//import PropTypes from 'prop-types';
import { 
  useSelector,
  //useDispatch 
} from 'react-redux';
import { Link } from "react-router-dom";
import { 
  Menu,
  Dropdown,
  PageHeader,
  Statistic,
  Button
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import Table from 'views/Tickets/TicketsList/_Table';
//import './InvoicesList.css';

import { 
  getInvoicesList
} from 'redux/reducers/invoicesReducer';

function InvoicesList() {
  
  const { list, pagination, filters, loading } = useSelector(state => state.invoices);
  const { userRole } = useSelector(state => state.auth);

  const columns = [
    {
      title: 'N. fattura',
      dataIndex: 'invoice_proof',
      search: true
    },
    {
      title: 'Tipologia',
      dataIndex: 'document_type',
      search: true,
      render: (value, row) => {
        if(value === 1) return 'Fattura'
        return 'NC'
      },
      filterType: 'radio',
      filterOptions: [
        {
          text: 'Fattura',
          value: '1'
        },
        {
          text: 'Nota di credito',
          value: '2'
        },
      ]
    },
    {
      title: 'Data emissione',
      dataIndex: 'document_date',
      render: value => value ? moment(value).format('D MMM YYYY HH:mm') : '---',
      search: true,
      filterType: 'dateRange'
    },
    {
      title: 'Data scadenza',
      dataIndex: 'expires_at',
      render: value => value ? moment(value).format('D MMM YYYY HH:mm') : '---',
      search: true,
      filterType: 'dateRange'
    },
    {
      title: 'Importo',
      dataIndex: 'total_amount',
      render: (value,row) => (
        <Statistic
          //title="Costo mensile"
          decimalSeparator=","
          prefix="€"
          precision={2}
          value={value || '---'}
          valueStyle={{
            fontSize: 13
          }}
        />
      )
    },
    {
      title: 'Cod. contratto',
      dataIndex: ['contract','contract_code'],
      search: true,
      //sorter: true,
      render: (value,row) => <Link to={`/contratti/${row.contract.id}`}>{value}</Link>,
      //width: '20%',
    },
    {
      title: 'Nome',
      dataIndex: ['customer','first_name'],
      search: true
    },
    {
      title: 'Cognome',
      dataIndex: ['customer','last_name'],
      search: true
    },
    {
      title: 'Offerta',
      dataIndex: ['commercial_offer','name'],
    },
    {
      title: 'Esito pagamento',
      dataIndex: 'result',
      search: true,
      render: (value, row) => {
        if(value === 0) return 'Non pagata'
        if(value === 1) return 'Pagata parzialmente'
        return 'Pagata'
      },
      filterType: 'radio',
      filterOptions: [
        {
          text: 'Non pagata',
          value: '0'
        },
        {
          text: 'Pagata parzialmente',
          value: '1'
        },
        {
          text: 'Pagata',
          value: '2'
        },
      ]
    },
    {
      title: 'Stato pagamento',
      dataIndex: 'payment_state',
      search: false,
    },
    {
      title: 'AC abilitata',
      dataIndex: 'payable_from_ca',
      search: true,
      render: (value, row) => {
        if(value === false) return 'No'
        return 'Sì'
      },
      filterType: 'radio',
      filterOptions: [
        {
          text: 'Sì',
          value: '1'
        },
        {
          text: 'No',
          value: '0'
        }
      ]
    },
  ];
  

  return (
    <div className="main-container">
      <PageHeader
        title="Elenco fatture"
        style={{ paddingBottom: 0 }}
        // extra={[
        //   <Dropdown overlay={menu} key="crea-contratto-button">
        //     <Button 
        //       type="primary" 
        //       //size="large"
        //     >
        //       Crea contratto <DownOutlined />
        //     </Button>
        //   </Dropdown>
        // ]}
      >
      </PageHeader>
      <Table
        columns={columns.filter(col => !col.hideForUsers?.includes(userRole))}
        dataSource={list}
        pagination={pagination}
        filters={filters}
        loading={loading}
        getList={(data) => getInvoicesList(data)}
      />
    </div>
  )
}

// InvoicesList.PropTypes = {
  
// }

export default InvoicesList;