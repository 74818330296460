import React from 'react';

import {
  TreeSelect,
  Button,
  Space
} from 'antd';

import Triplets from 'components/Form/Triplets/Triplets';

import { SearchOutlined } from '@ant-design/icons';

export default function({
  data,
  dataIndex,
  placeholder,
  title,
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
  handleSearch,
  handleReset
}) {
  
  return (
    <div style={{ 
      padding: 8,
      display: 'flex',
      flexDirection: 'column',
      width: 400,
      overflow: 'hidden'
    }}>
      <Triplets 
        data={data} 
        size='small'
        showLabel={false} 
        selectables={[true,true,true]}
        onSelect={node => setSelectedKeys([{label: node.tree_value, value: node.value}])}
      />
      <div style={{
        marginTop: 16,
        display: 'flex',
        justifyContent: 'space-between'
      }}>
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex, title)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Filtra
        </Button>
        <Button 
          onClick={() => handleReset(clearFilters,dataIndex)} 
          size="small" 
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    </div>
  )
}