import React from 'react';

import {
  Upload,
  Button,
  Tooltip,
  message
} from 'antd';

import { UploadOutlined } from '@ant-design/icons';


function Uploader({ 
  name, 
  action, 
  disabled,
  loading,
  customRequest, 
  onFinish, 
  showUploadList 
}) {

  const onUploading = (info) => {
    //console.log(info)
    const { status } = info.file;
    if (status !== 'uploading') {
      //console.log(info.file, info.fileList);
    }
    if (status === 'done') {
      message.success(`File ${info.file.name} caricato con successo.`);
    } else if (status === 'error') {
      message.error(`Problemi nel caricamento del file ${info.file.name}.`);
    }
  }


  return(
    <Upload
      name={name}
      action={action}
      disabled={disabled}
      customRequest={customRequest}
      onChange={onUploading}
      showUploadList={showUploadList}
    >
      <Tooltip title="Carica file">
        <Button 
          shape="circle"
          icon={<UploadOutlined />}
          loading={loading}
        />
      </Tooltip>
    </Upload>
  )
}

export default Uploader