import React, { 
  //useState, 
  useEffect 
} from 'react';
//import PropTypes from 'prop-types';
// import { 
//   Steps, 
//   Layout, 
//   Result, 
//   Button 
// } from 'antd';
import Validate from './Validate';

import LoadingFullwidth from 'components/Loading/Fullwidth';

//import './ContractsEdit.css';
import {
  useParams,
  useLocation,
  useHistory
} from "react-router-dom";
import { 
  useDispatch, 
  useSelector 
} from 'react-redux';
import { 
  getContractToActivateDetails
} from 'redux/reducers/contractsReducer';
//import Validate from '../ContractsEdit/_Validate/Validate';

function ContractsEdit() {
  const dispatch = useDispatch()
  const { newContract, loading } = useSelector(state => state.contracts);

  let history = useHistory();
  const params = useParams()
  //const params = new URLSearchParams(useLocation().search)
  // const [state, setState] = useState({
  //   currentStep: -1,
  //   contractFormat: useLocation().state?.format || 'on_paper'
  // })
  


  useEffect(()=>{
    const newContractId = params.id
    if(newContractId) {
      const getContract = async(id) => {
        const result = await dispatch(getContractToActivateDetails(id))
        if(
          result.payload.data && 
          result.payload.data.state !== 'papers_uploaded' || 
          result.payload.status >= 400 
        ) {
          //setCurrentStep(3)
          //const contractFormat = result.payload.data.type
          
          // setState({
          //   contractFormat: contractFormat,
          //   currentStep: stepsStateMap[contractFormat][result.payload.data.state]
          // })
        //}
        //else {
          history.push('/contratti')
        }
      }
      
      getContract(newContractId)
    } 
    // else {
    //   setState({
    //     ...state,
    //     currentStep: 0
    //   })
    // }
    // return () => {
    //   dispatch({ type: 'contracts/resetState'});
    // }
  },[])

  

  return (
    newContract.uploaded_documentation ? (
      <div className="pageContainer">     
        <Validate 
          contract={newContract}
          loading={loading}
          onSubmit={() => history.push('/contratti/validazione')}
        />
      </div>
    ) : <LoadingFullwidth /> 
  )
}

// ContractsEdit.PropTypes = {
  
// }

export default ContractsEdit;