import React from 'react';
import PropTypes from 'prop-types';
import {
  // Row,
  // Col, 
  Form,
  TreeSelect
} from 'antd';

const { TreeNode } = TreeSelect;

function Triplets({ 
  data,
  size,
  selectables,
  onSelect,
  onChange,
  ...props
}) {
  
  return (
    // <Form.Item 
    //   name="support_cat_third_id"
    //   label={"Tripletta"}
    //   rules={[
    //     {
    //       required: true,
    //       message: 'Campo obbligatorio'
    //     }
    //   ]}
    // >
      <TreeSelect
        showSearch
        size={size}
        defaultValue={props.value}
        placeholder="Seleziona"
        allowClear
        treeLine
        filterTreeNode={(string, node) => node.title.toLowerCase().includes(string.toLowerCase())}
        onSelect={(value, node, extra) => onSelect(node)}
        onChange={(value) => onChange(value)}
      >
        {
          data?.map(first => (
            <TreeNode 
              key={`first_${first.id}`} 
              value={`first_${first.id}`} 
              title={first.name}
              tree_value={`${first.name}`} 
              selectable={selectables[0]}
            >
              {
                first.childs.map(second => (
                  <TreeNode 
                    key={`second_${second.id}`} 
                    value={`second_${second.id}`} 
                    title={second.name}
                    tree_value={`${first.name} > ${second.name}`} 
                    selectable={selectables[1]}
                  >
                    {
                      second.childs.map(third => (
                        <TreeNode 
                          key={third.id} 
                          value={third.id}
                          title={third.name} 
                          tree_value={`${first.name} > ${second.name} > ${third.name}`} 
                          selectable={selectables[2]}
                        />
                      ))
                    }
                  </TreeNode>
                ))
              }
            </TreeNode>    
          ))
        }
      </TreeSelect>
    // </Form.Item>
  )
}

Triplets.defaultProps = {
  size: 'large',
  selectables: [false,false,true],
  onSelect: () => {},
  onChange: () => {}
}

Triplets.propTypes = {
  onChange: PropTypes.func,
  required: PropTypes.bool,
  size: PropTypes.string,
  selectables: PropTypes.array
}

export default Triplets;