import React, { 
  useState,
  useEffect
} from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col, 
  Form,
  Button,
  Checkbox,
  Switch,
  Select,
  Space,
  message,
  Alert
} from 'antd';
//import { useHistory } from 'react-router-dom';
import { 
  useSelector, 
  useDispatch 
} from 'react-redux';

import { 
  verifyCoverageCity,
  verifyCoverageZipcode,
  insertContractDataDigital,
  getCity
} from 'redux/reducers/contractsReducer';

import locale from 'antd/es/date-picker/locale/it_IT';

import CustomerFields from 'components/Form/CustomerFields/CustomerFields';
import ContractFields from 'components/Form/ContractFields/ContractFields';
import ReferenceFields from 'components/Form/ReferenceFields/ReferenceFields';
import { selectNations } from 'redux/selectors/common';

function DigitalData({ onSuccess }) {
  
  const { newContract, loading } = useSelector(state => state.contracts);
  const dispatch = useDispatch();
  const nations = useSelector(selectNations);
  //let history = useHistory();
  const [formContractData] = Form.useForm();

  const initialValues = {
    activation_address: {
      street_name: newContract.coverage ? (newContract.coverage.toponymic_particle + ' ' +  newContract.coverage.street_name) : (newContract?.address?.streetType + ' ' +  newContract?.address?.name || ''),
      address_number: newContract.coverage ? newContract.coverage.address_number : (newContract.addressNumber || ''),
      city: newContract.coverage ? newContract.coverage.city : (newContract.city && newContract.city.name || ''),
      city_code: newContract.coverage ? newContract.coverage.city : (newContract.city && newContract.city.cod || ''),
      province: newContract.coverage ? newContract.coverage.province : (newContract.city?.province || ''),
      postal_code: '',
    },
    delivery_address: {
      street_name: newContract.coverage ? (newContract.coverage.toponymic_particle + ' ' +  newContract.coverage.street_name) : (newContract?.address?.streetType + ' ' +  newContract?.address?.name || ''),
      address_number: newContract.coverage ? newContract.coverage.address_number : (newContract.addressNumber || ''),
      city: newContract.coverage ? newContract.coverage.city : (newContract.city && newContract.city.name || ''),
      city_code: newContract.coverage ? newContract.coverage.city : (newContract.city && newContract.city.cod || ''),
      province: newContract.coverage ? newContract.coverage.province : (newContract.city?.province || ''),
      postal_code: '',
    },
    billing_address: {
      street_name: newContract.address && newContract.address.streetType + ' ' +  newContract.address.name || '',
      address_number: newContract.addressNumber || '',
      city: newContract.city && newContract.city.name || '',
      city_code: newContract.coverage ? newContract.coverage.city : (newContract.city && newContract.city.cod || ''),
      province: newContract.city && newContract.city.province || '',
      postal_code: ''
    },
    referent: {
      first_name: newContract.customer ? newContract.customer.first_name : '',
      last_name: newContract.customer ? newContract.customer.last_name : '',
      email: newContract.customer ? newContract.customer.email : '',
      phone_number: newContract.customer ? newContract.customer.phone_number : ''
    }
  };

  const [zipcodeOptions, setZipcodeOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([])
  const [validating, setValidating] = useState({
    city: false
  })
  
  useEffect(()=>{
    const loadZipcode = async () => {
      try{
        const result = await dispatch(verifyCoverageZipcode({
          city: newContract.coverage ? newContract.coverage.city : (newContract?.city?.name || ''),
          province:  newContract.coverage ? newContract.coverage.province : (newContract.city.province || ''),
          istat: newContract.coverage ? newContract.coverage.istat : (newContract.city.cod || '')
        }))
        // if(result.payload && result.payload.ESITO === 'KO') {
        //   setShowLead(true)
        //   return false
        // }
        //console.log('return > ',result)
        const zipcodes = result && result.payload && result.payload.map(city => ({label: city.label, value: city.CAP})) || []
        setZipcodeOptions(zipcodes)
      }
      catch(err) {
        console.log('error ',err)
      }
    }

    loadZipcode();

  },[])

  // const onCopyValues = (from, to) => {
  //   const values = formContractData.getFieldsValue();
  //   formContractData.setFieldsValue({
  //     [to]: values[from]
  //   })
    
  // }

  const onCopyValues = (from, to) => {
    let values = Object.assign({},formContractData.getFieldValue(from));
    if(to === 'billing_address') {
      values.intercom_name = ''
    }
    
    formContractData.setFieldsValue({
      [to]: values
    })
  }

  const handleSearchCity = async (text) => {
    if(text.length > 3) {
      try{
        setValidating({ ...validating, city: true })
        const result = await dispatch(getCity(text))        
        const optionsParsed = result.payload && result.payload.reduce((acc,city) => {
          acc.push({
            label: city.label,
            value: city.COMUNE,
            sigla: city.Sigla || '',
            code: city.CODICE_CATASTALE || ''
          })
           return acc
        },[])

        setValidating({ ...validating, city: false })
        return setCityOptions(optionsParsed)
        
      }
      catch(err) {
        console.log('error ',err)
      }
    }
  }

  const saveProvince = (prefix, values) => {
    const section = formContractData.getFieldValue(prefix)
    Object.keys(values).map((key) => section[key] = values[key])
    //section[field] = value
    console.log("SAVE PROVINCE >>> ",values, section)
    formContractData.setFieldsValue({
      [prefix]: section
    })
  }

  const onFinish = async (values) => {
    const nation = nations.find(n => n.name === values.customer.born_country);

    const result = await dispatch(insertContractDataDigital({ ...values, 
      customer: { ...values.customer, born_country_abbr: nation.abbr, born_country_code: nation.code }
     }));

    if (result.payload.status !== 422 && result.payload.data) {
      onSuccess();
    } else {
      message.error(`Errore nella compilazione del modulo`);
      const errors = Object.keys(result.payload.errors).map((errorKey, i) => {
        return {
          name: errorKey.split("."),
          errors: result.payload.errors[errorKey],
        };
      });
      formContractData.setFields(errors);
      formContractData.scrollToField(errors[0].name, { behavior: "smooth" });
    }
  };

  
  return (
    <div className="pageContainer">

      <Form 
        form={formContractData}
        name="formContractData"
        layout="vertical" 
        colon={false} 
        onFinish={onFinish}
        // onFinish={(payload) => console.log(payload)}
        initialValues={initialValues}
        scrollToFirstError={true}
        validateTrigger="onBlur"
      >
         <section  style={{ marginBottom: 40, marginTop: 10 }}>
          {newContract.alerts?.map(message => (

          <Alert message={message} type="warning" style={{ marginBottom: 20 }} />
          ))}
        </section>
        <h3 style={{ marginBottom: 16, color: '#0a3e4b' }}>ANAGRAFICA CLIENTE</h3>
        <CustomerFields 
          initialValues={initialValues} 
          prefix="customer"
          mode="second_half"
          locale={locale}
          onSearchCity={handleSearchCity}
          onSelectedCity={saveProvince}
          cityOptions={cityOptions}
          validating={validating}
        />

        <h3 style={{ marginBottom: 16, color: '#0a3e4b' }}>REFERENTE</h3>
        <ReferenceFields prefix="referent" />
        
        <ContractFields 
          locale={locale}
          initialValues={initialValues}
          zipcodeOptions={zipcodeOptions}
          onCopyValues={(from,to) => onCopyValues(from,to)}
        />
        <div>
          <h3 style={{ marginTop: 32, marginBottom: 16, color: '#0a3e4b' }}>SERVIZI AGGIUNTIVI</h3>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item 
                name="gen_work_order"
                label="ODL Just Premium"
                rules={[
                  {
                    required: true,
                    message: 'Campo obbligatorio'
                  }
                ]}
                // initialValue={false}
                // valuePropName="checked"
              >
                <Select
                  size="large"
                  options={[
                    { label: 'Si', value: 1 },
                    { label: 'No', value: 0 }
                  ]}
                />
                {/* <Switch onChange={(checked) => {formContractData.setFieldsValue({'gen_work_order': checked })}} />
                  &nbsp;&nbsp;&nbsp;
                  <strong>Just Premium</strong> -  */}
              </Form.Item>
            </Col>
            <Col span={12}>
              <p style={{ fontSize: 12, textAlign: 'justify', paddingTop: 24 }}>
                <strong>Servizio GRATUITO in promozione anzichè 69,90€</strong>
                <br/>
                Scegliendo il servizio opzionale Just Premium il cliente ha la possibilità di ottimizzare la rete Wi-Fi della sua abitazione e di configurare i suoi dispositivi Smart grazie all'intervento direttamente a casa sua di un tecnico JustSpeed. 
              </p>
            </Col>
          </Row>
        </div>
  
        <Row justify="center" align="middle">
          <Col>
            <Space 
              align="center"
              size="large"
              //className="actionBar" 
              //{...tailLayout}
            >
              <Button 
                type="primary" 
                size="large"
                htmlType="submit"
                disabled={loading === 'insertContractDataDigitalStatus'}
                loading={loading === 'insertContractDataDigitalStatus'}
              >
                Salva
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

DigitalData.propTypes = {
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func
}

export default DigitalData;
