import React, { useEffect } from 'react';
//import PropTypes from 'prop-types';
import { 
  //Link, 
  Switch, 
  Route,
  Redirect 
  //useLocation 
} from 'react-router-dom';
import Login from 'views/Login/Login'
import { Spin } from 'antd';
// import {
//   MenuUnfoldOutlined,
//   MenuFoldOutlined
// } from '@ant-design/icons';
//import { menuLinks } from 'routes'
import { useSelector, useDispatch } from 'react-redux';
import { 
  fetchUserAuthenticated
} from 'redux/reducers/authReducer';

function LoggedOutLayout() {
  
  const { user } = useSelector(state => state.auth)
  const dispatch = useDispatch()
  
  useEffect(() => {
    const getUser = async () => {
       await dispatch(fetchUserAuthenticated())
    }
    getUser()
    console.log('logged out')
  },[])
  //console.log('auth > ',user)
  return (
    user && 
      (user.isAuthenticated ?
        <Redirect to="/contratti" /> :
        <Switch>
          <Route path="/login" component={Login} exact />
          <Route path="/" component={Login} />
        </Switch>)
     || <div className="loadingFullwidthPage"><Spin size="large"/></div>
  )
}

// LoggedOutLayout.propTypes = {
//   routes: PropTypes.array
// }

export default LoggedOutLayout;