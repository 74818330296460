import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { Provider } from 'react-redux';
import App from './App';
import store from 'redux/store'
import * as serviceWorker from './serviceWorker';
//import 'antd/dist/antd.css'
import 'assets/style/theme.css';
import 'assets/style/index.css';
import "fontsource-roboto/latin.css";

if(process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: "https://8c8ef38bb35844ca95dbafdfb71d0b70@o262312.ingest.sentry.io/5563913",
    autoSessionTracking: true,
    integrations: [
      new Integrations.BrowserTracing(),
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
