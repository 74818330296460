import React, { useEffect } from 'react';
import { 
  Tabs,
  Table,
  Skeleton,
  PageHeader,
  Tag,
  Row,
  Col,
  Statistic,
  Alert,
  Button,
  Dropdown,
  Menu,
  message
} from 'antd';
import moment from 'moment';
//import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { DownOutlined } from '@ant-design/icons';
import { 
  getCustomerDetails,
  getCustomerContracts,
  getCustomerTickets,
  getCustomerInvoices,
  switchVip
} from 'redux/reducers/customersReducer';
import {
  getTicketEdit
} from 'redux/reducers/ticketsReducer';
import {
  useParams,
  useHistory,
  Link
} from "react-router-dom";
import LoadingFullwidth from 'components/Loading/Fullwidth';
import { DescriptionsContainer, Item } from 'components/Descriptions/Description';
import FitlerTable from 'views/Tickets/TicketsList/_Table';

function CustomersDetails() {

  const { selected, loading, error } = useSelector(state => state.customers);
  const { newTicket } = useSelector(state => state.tickets);
  const { states } = useSelector(state => state.auth);
  
  const dispatch = useDispatch();
  let history = useHistory();
  const params = useParams();
  const customerId = params.id;
  
  useEffect(() => {
    const getDetails = async() => {
      try {
        //console.log('DOCID MOUNT > ',docId)
        const result = await dispatch(getCustomerDetails(params.id))
      } catch (error) {
        
      }
    }

    getDetails()
  },[])

  const columnsTickets = [
    {
      title: 'Numero',
      dataIndex: 'ticket_code',
      search: true
      //render: (value,row) => <a onClick={() => dispatch(getTicketEdit(row.id))}>{value}</a>,
    },
    {
      title: 'Tipologia - Famiglia - Sottofamiglia',
      title_name: 'Tripletta',
      dataIndex: 'support_cat',
      render: (value,row) => {
        return (
          <>
            <Tag color="geekblue" size="small">{row.support_cat_first.name}</Tag>
            <Tag color="green" size="small">{row.support_cat_second.name}</Tag>
            <Tag color="volcano" size="small">{row.support_cat_third.name}</Tag>
          </>
        )
      },
      search: true,
      filterType: 'tree',
      filterOptions: newTicket?.triplets
    },
    {
      title: 'Cod. contratto',
      dataIndex: ['contract','contract_code'],
      render: (value,row) => value || '---',
      search: true
    },
    {
      title: 'Stato',
      dataIndex: 'state_name',
      search: true,
      filterType: 'radio',
      filterOptions: [
        { text: 'Aperto', value: 'open' },
        { text: 'Chiuso', value: 'closed' },
        { text: 'Sospeso', value: 'suspended' }
      ]
    },
    {
      title: 'Ocs',
      dataIndex: 'ocs',
      render: (value,row) => value ? 'Si' : 'No',
    },
    {
      title: 'Origine',
      dataIndex: 'origin_name',
      search: true,
      filterType: 'radio',
      filterOptions: [
        { text: 'Chiamata', value: 'call' },
        { text: 'Segnalazione esterna', value: 'external' },
        { text: 'Business partner', value: 'bp' },
        { text: 'Chat', value: 'chat' },
        { text: 'Raccomandata', value: 'reg_letter' },
        { text: 'E-mail', value: 'mail' },
        { text: 'Web', value: 'web' }
      ]
    },
    {
      title: 'Data creazione',
      dataIndex: 'created_at',
      render: value => moment(value).format('D MMM YYYY HH:mm'),
      search: true,
      filterType: 'dateRange'
    },
    {
      //title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Button 
          size="small" 
          type="link"
          onClick={() => handleEdit(record.id)}
        >
          Lavora
        </Button>
      ),
    },
  ];

  const columnsContracts =[
      {
        title: 'Codice contratto',
        dataIndex: 'contract_code',
        search: true,
        //sorter: true,
        render: (value,row) => <Link to={`/contratti/${row.id}`}>{value}</Link>,
        //width: '20%',
      },
      {
        title: 'Canale',
        dataIndex: 'channel_type'
      },
      {
        title: 'Indirizzo',
        dataIndex: ['coverage','street_name'],
        render: (value,row) => `${row.coverage.toponymic_particle} ${row.coverage.street_name}, ${row.coverage.address_number}`,
        search: true
      },
      {
        title: 'Città',
        dataIndex: ['coverage','city'],
        search: true
      },
      {
        title: 'Offerta',
        dataIndex: ['commercial_offer','name'],
      },
      {
        title: 'Connessione',
        dataIndex: ['connection_type','connection_type_key'],
      },
      {
        title: 'Data sottoscrizione',
        dataIndex: 'created_at',
        render: value => value ? moment(value).format('D MMM YYYY HH:mm') : '---',
        search: true,
        filterType: 'dateRange'
      },
      {
        title: 'Data attivazione',
        dataIndex: 'activated_at',
        render: value => value ? moment(value).format('D MMM YYYY HH:mm') : '---',
        search: true,
        filterType: 'dateRange',
      },
      {
        title: 'Stato provisioning',
        dataIndex: 'state_provisioning_name',
        render: value => value ? value : '---'
      },
      {
        title: 'In recesso',
        dataIndex: 'recess_requested',
        render: value => value ? 'Si' : 'No',
        search: true,
        filterType: 'radio',
        filterOptions: [{text: 'Si', value: '1' }, {text: 'No', value: '0' }]
      },
      {
        title: 'In cancellazione',
        dataIndex: 'cancel_requested',
        render: value => value ? 'Si' : 'No',
        search: true,
        filterType: 'radio',
        filterOptions: [{text: 'Si', value: '1' }, {text: 'No', value: '0' }]
      },
      {
        title: 'Stato',
        dataIndex: 'state_name',
        search: true,
        filterType: 'radio',
        filterOptions: states?.contracts
      }
  ];
  
  const columnsInvoices = [
    {
      title: 'N. fattura',
      dataIndex: 'invoice_proof',
      search: true
    },
    {
      title: 'Tipologia',
      dataIndex: 'document_type',
      search: true,
      render: (value, row) => {
        if(value === 1) return 'Fattura'
        return 'NC'
      },
      filterType: 'radio',
      filterOptions: [
        {
          text: 'Fattura',
          value: '1'
        },
        {
          text: 'Nota di credito',
          value: '2'
        },
      ]
    },
    {
      title: 'Data emissione',
      dataIndex: 'document_date',
      render: value => value ? moment(value).format('D MMM YYYY HH:mm') : '---',
      search: true,
      filterType: 'dateRange'
    },
    {
      title: 'Data scadenza',
      dataIndex: 'expires_at',
      render: value => value ? moment(value).format('D MMM YYYY HH:mm') : '---',
      search: true,
      filterType: 'dateRange'
    },
    {
      title: 'Importo',
      dataIndex: 'total_amount',
      render: (value,row) => (
        <Statistic
          //title="Costo mensile"
          decimalSeparator=","
          prefix="€"
          precision={2}
          value={value || '---'}
          valueStyle={{
            fontSize: 13
          }}
        />
      )
    },
    {
      title: 'Cod. contratto',
      dataIndex: ['contract','contract_code'],
      search: true,
      //sorter: true,
      render: (value,row) => <Link to={`/contratti/${row.contract.id}`}>{value}</Link>,
      //width: '20%',
    },
    {
      title: 'Offerta',
      dataIndex: ['commercial_offer','name'],
    },
    {
      title: 'Esito pagamento',
      dataIndex: 'result',
      search: true,
      render: (value, row) => {
        if(value === 0) return 'Non pagata'
        if(value === 1) return 'Pagata parzialmente'
        return 'Pagata'
      },
      filterType: 'radio',
      filterOptions: [
        {
          text: 'Non pagata',
          value: '0'
        },
        {
          text: 'Pagata parzialmente',
          value: '1'
        },
        {
          text: 'Pagata',
          value: '2'
        },
      ]
    },
    {
      title: 'Stato pagamento',
      dataIndex: 'payment_state',
      search: false,
    },
    {
      title: 'AC abilitata',
      dataIndex: 'payable_from_ca',
      search: true,
      render: (value, row) => {
        if(value === false) return 'No'
        return 'Sì'
      },
      filterType: 'radio',
      filterOptions: [
        {
          text: 'Sì',
          value: '1'
        },
        {
          text: 'No',
          value: '0'
        }
      ]
    },
  ]

  const handleEdit = (id) => {
    
    if(newTicket?.draft) {
      if(newTicket?.draft.id !== id) {
        return dispatch({
          type:"tickets/setshowAlertWorking", 
          options: {
            value: true,
            actionName: 'EDIT',
            params: {
              id
            }
          }
        })
      }
      else {
        return dispatch({ type: 'tickets/setIsDrawerOpen', value: true })
      }
    }
    //localStorage.setItem('ticketId', id)
    dispatch(getTicketEdit(id))
    //dispatch({type:"tickets/setShowNewTicket", value: true})
  }

  const handleChangeTab = async (tab) => {
    console.log('handlle change tab > ',tab)
    const tabMap = {
      //'customer': getCustomerDetailsCustomer,
      //'tickets': getCustomerTickets,
      //'contracts': getCustomerContracts,
      //'notifications_fw': getCustomerDetailsNotifications
    }

    try {
      await tabMap[tab] && dispatch(tabMap[tab](customerId))
      return true
    } catch (error) {
      console.log('Errore handle change tab')
    }
  }



  const actionsMenu = (
    <Menu>
      {
        //selected?.recessable &&
          <Menu.Item key="switch_vip">
            <a onClick={
              () => dispatch(
                switchVip()
              )
                .then(() => message.success('Stato Vip aggiornato con successo!'))
                .catch(e => message.error('Si è verificato un errore'))
            }>
              Cambia stato vip
            </a>
          </Menu.Item>
      }
      {/* {
        selected?.recessable &&
        selected?.recess_requested && 
          <Menu.Item key="recess_cancel">
            <a onClick={() => console.log('annulla recesso')}>Annulla recesso</a>
          </Menu.Item>
      }
      {
        selected?.cancelable && 
        !selected?.cancel_requested &&
          <Menu.Item key="order_cancel">
            <a onClick={() => console.log('order cancel')}>Annulla ordine</a>
          </Menu.Item>
      }
      {
        selected?.state === 'pending' &&
        !selected?.pending_action_requested &&
          <Menu.Item key="resume_request">
            <a onClick={() => console.log('request resume')}>Richiedi resume</a>
          </Menu.Item>
      }
      {
        !selected?.rid_uploaded &&
          <Menu.Item key="rid_upload">
            <a onClick={() => console.log('rid upload')}>Carica modulo RID</a>
          </Menu.Item>
      }
      {
        selected?.terminatable &&
          <Menu.Item key="termination_request">
            <a onClick={() => console.log('termination request')}>Richiedi cessazione</a>
          </Menu.Item>
      } */}
    </Menu>
  )

  // const tagMap = {
  //   'info': <Tag color="green">Info</Tag>,
  //   'warning': <Tag color="gold">Avviso</Tag>,
  //   'error': <Tag color="red">Errore</Tag>
  // }
  
  // const columnsNotifications = [
  //   { title: 'Tipologia', dataIndex: 'type_name', key: 'type_name' },
  //   { title: 'Stato', dataIndex: 'state_name', key: 'state_name' },
  //   { title: 'Codice CRM', dataIndex: 'crm_code', key: 'crm_code' },
  //   { title: 'Codice FW', dataIndex: 'fw_code', key: 'fw_code' },
  //   { title: 'Codice E2E', dataIndex: 'e2e_code', key: 'e2e_code' },
  //   { title: 'Data invio', dataIndex: 'created_at', key: 'created_at', render: (value) => moment(value).format('DD/MM/YYYY HH:mm') }
  // ];

  // const columnsLogs = [
  //   { title: 'Utente', dataIndex: ['creator','first_name'], key: 'user', render: (value,row) => row.creator_type === null ? 'Sistema' : <>{row.creator.first_name} {row.creator.last_name} <Tag color="cyan">{row.creator_type}</Tag></>  },
  //   { title: 'Nota', dataIndex: 'notes', key: 'notes', render: (value,row) => (<>{tagMap[row.type]} {value}</>) },
  //   { title: 'Data inserimento', dataIndex: 'created_at', key: 'created_at', render: (value) => moment(value).format('DD/MM/YYYY HH:mm') }
  // ];

  // const expandedRowRender = (record) => {
  //   const columns = [
  //     { title: 'Notifica', dataIndex: 'object_type', key: 'object_type' },
  //     { title: 'Codice OLO', dataIndex: 'olo_notification_id', key: 'olo_notification_id' },
  //     { title: 'Stato', dataIndex: ['object','provisioning_state_name'], key: 'provisioning_state_name' },
  //     { title: 'Descrizione', dataIndex: ['object','description'], key: 'description' },
  //     { title: 'Data ricezione', dataIndex: 'created_at', key: 'created_at', render: (value) => moment(value).format('DD/MM/YYYY HH:mm') }
  //   ];
    
  //   return (
  //     <Table 
  //       columns={columns} 
  //       dataSource={record.olo_notifications} 
  //       pagination={false} 
  //       rowKey={(row) => row.id}
  //     />
  //   )
  // }

  return (
    <div className="main-container">
      {
        (loading.includes('getCustomerDetailsStatus') || selected === null) ? 
        <Skeleton active /> :
        <div className="main-content">
          <PageHeader
            //className="site-page-header-responsive"
            onBack={() => history.goBack()}
            title={`Cliente: ${selected?.first_name} ${selected?.last_name} - ${selected?.customer_code}`}
            tags={<Tag color="blue" size="large" >{selected?.state_name}</Tag>}
            //subTitle="This is a subtitle"
            extra={[
              <Dropdown 
                overlay={actionsMenu} 
                key="customer-details-actions"
                trigger={["click"]}
                style={{ minWidth: 250 }}
              >
                <Button 
                  type="primary" 
                >
                  Azioni
                  <DownOutlined />
                </Button>
              </Dropdown>
              // <Button key="1" type="primary">
              //   Azioni
              // </Button>,
            ]}
          >
            <Row style={{ marginBottom: 16 }}>
              {/* {
                selected?.recess_requested &&
                  <Alert
                    message="È stata effettuata una richiesta di recesso"
                    type="warning"
                    style={{ marginBottom: 8, width: '100%' }}
                    // closable
                    // onClose={onClose}
                  />
              }
              {
                selected?.cancel_requested &&
                  <Alert
                    message="È stata effettuata una richiesta di cancellazione"
                    type="warning"
                    style={{ marginBottom: 8, width: '100%' }}
                    // closable
                    // onClose={onClose}
                  />
              }
              {
                !selected?.read_uploaded &&
                  <Alert
                    message="Il cliente non ha ancora inviato il modulo RID"
                    type="warning"
                    style={{ marginBottom: 8, width: '100%' }}
                    // closable
                    // onClose={onClose}
                  />
              } */}
            </Row>
            <Row>
              <Col 
                span={20} 
                style={{ 
                  display: 'flex',
                  justifyContent: 'space-between'
                }}>
                <Statistic 
                  title="Canale" 
                  value={selected?.channel_type} 
                />
                <Statistic
                  title="Vip"
                  //prefix="€"
                  //precision={2}
                  value={selected?.vip ? 'Si' : 'No'}
                  // style={{
                  //   margin: '0 32px',
                  // }}
                />
                <Statistic 
                  title="Moroso" 
                  value={selected?.defaulting ? 'Si' : 'No'} 
                />
                <Statistic 
                  title="Data sottoscrizione" 
                  value={selected?.created_at ? moment(selected?.created_at).format("DD/MM/YYYY"): '---'} 
                />
                {/* <Statistic title="Codice migrazione" value={selected?.migration_code || '---'} /> */}
              </Col>
            </Row>
          </PageHeader>
          
          <Tabs type="card" defaultActiveKey="1" onChange={handleChangeTab}>
            <Tabs.TabPane tab="Cliente" key="details">
              {
                loading.includes('getCustomerDetailsCustomerStatus') 
                ? <LoadingFullwidth />
                : <Row gutter={[24,24]}>
                    <Col span={8}>
                      <DescriptionsContainer title="Dati anagrafica" type="card">
                        <Item label="Codice cliente" value={selected?.customer_code || '---'}/>
                        <Item label="Codice cliente Linkem" value={selected?.linkem_customer_code || '---'}/>
                        <Item label="Nome" value={selected?.first_name || '---'}/>
                        <Item label="Cognome" value={selected?.last_name || '---'}/>
                        <Item label="Genere" value={selected?.gender_name || '---'}/>
                        <Item label="Cellulare" value={selected?.phone_number || '---'}/>
                        <Item label="Telefono fisso" value={selected?.home_phone_number || '---'}/>
                        <Item label="E-mail" value={selected?.email || '---'}/>
                        <Item label="Data di nascita" value={moment(selected?.born_at).format('DD/MM/YYYY') || '---'}/>
                        <Item label="Luogo di nascita" value={selected?.born_city || '---'}/>
                        <Item label="Provincia di nascita" value={selected?.born_province || '---'}/>
                        <Item label="Nazione di nascita" value={selected?.born_country || '---'}/>
                        <Item label="Codice fiscale" value={selected?.fiscal_code || '---'}/>
                      </DescriptionsContainer>
                    </Col>
                    <Col span={8}>
                      <DescriptionsContainer title="Documento di riconoscimento" type="card">
                        <Item label="Nazionalità" value={selected?.document?.nationality || '---'}/>
                        <Item label="Tipologia" value={selected?.document?.type_name || '---'}/>
                        <Item label="Numero" value={selected?.document?.number || '---'}/>
                        <Item label="Data di rilascio" value={moment(selected?.document?.issued_at).format('DD/MM/YYYY') || '---'}/>
                        <Item label="Rilasciato dal " value={selected?.document?.entity || '---'}/>
                      </DescriptionsContainer>
                    </Col>
                  </Row>
              }
            </Tabs.TabPane>
            <Tabs.TabPane tab="Contratti" key="contracts">
              <FitlerTable
                columns={columnsContracts}
                dataSource={selected?.contracts?.list}
                loading={loading}
                pagination={selected?.contracts?.pagination}
                filters={selected?.contracts?.filters}
                getList={(data) => getCustomerContracts({data: data,customerId: params.id})} 
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Tickets" key="tickets">
              <FitlerTable
                columns={columnsTickets}
                dataSource={selected?.tickets?.list}
                loading={loading}
                pagination={selected?.tickets?.pagination}
                filters={selected?.tickets?.filters}
                getList={(data) => getCustomerTickets({data: data,customerId: params.id})} 
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Fatture" key="invoices">
              <FitlerTable
                columns={columnsInvoices}
                dataSource={selected?.invoices?.list}
                loading={loading}
                pagination={selected?.invoices?.pagination}
                filters={selected?.invoices?.filters}
                getList={(data) => getCustomerInvoices({data: data,customerId: params.id})} 
              />
            </Tabs.TabPane>
          </Tabs>
        </div>
      }
    </div>
  )
}

// CustomersDetails.PropTypes = {
  
// }

export default CustomersDetails;