import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col, 
  Form,
  Tag,
  Button,
  Space,
  message,
  Tooltip
} from 'antd';
import {
  WarningOutlined
} from '@ant-design/icons';

import { 
  useHistory
} from 'react-router-dom';
import { 
  useSelector, 
  useDispatch 
} from 'react-redux';
import { 
  getTicketMotivations,
  updateTicket
} from 'redux/reducers/ticketsReducer';

import TicketAddNote from 'components/Form/TicketAddNote/TicketAddNote';
import TicketsDetails from 'views/Tickets/TicketsDetails/TicketsDetails';
import { selectTicketMotivations } from 'redux/selectors/tickets';


function TicketEdit({ onLeave }) {
  const { newTicket, loading, error } = useSelector(state => state.tickets);
  const { draft, triplets } = newTicket;

  const ticketMotivations = useSelector(selectTicketMotivations);

  const dispatch = useDispatch();
  let history = useHistory();
  const [formTicketEdit] = Form.useForm();


  useEffect(() => {
    dispatch(getTicketMotivations());
  }, []);

  const panelStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%'
  }

  useEffect(() => {
    return () => {
      formTicketEdit.resetFields()
    }
  }) 

  const onFinish = async (values) => {
    
    const result = await dispatch(updateTicket({ticketId: draft.id ,values: values}))

    if(result.payload.status !== 422 && result.payload.data) {
      message.success(`Ticket aggiornato con successo!`);
      formTicketEdit.resetFields();
      //onSuccess();
    }
    else {
      message.error(`Errore nella compilazione del ticket`);

      const errors = Object.keys(result.payload.errors).map((errorKey,i) => {
        return {
          name: errorKey.split('.'),
          errors: result.payload.errors[errorKey]
        }
      })
      formTicketEdit.setFields(errors)
      formTicketEdit.scrollToField(errors[0].name,{behavior: 'smooth'})

    }
  };

  const TitleNote = ({note}) => {
    if(note) {
      return (
        <div style={{display:'flex', justifyContent:'space-between'}}>
          <div style={{paddingRight:8}}>
            {`${note.creator?.first_name} ${note.creator?.last_name} [${note.creator_type}]`} 
          </div>
          <div>
            { note.reminder && 
              <Tooltip title="Sollecito">
                <WarningOutlined style={{ marginRight: 8, color: '#faad14'}} /> 
              </Tooltip>
            }
            <Tag color="blue">{note.state_name}</Tag>
          </div>
        </div>
      )
    }
    return <div>Anonimo</div>
  }

  console.log({  })

  return (
    !draft ? 'Caricamento dati...' :
      <div style={panelStyle}>
        <TicketsDetails 
          data={draft} 
          goTo={(route) => history.push(route)}
        />
      
      <div style={{ paddingTop: 8, borderTop: '1px solid lightgrey' }}>
        <Form 
          form={formTicketEdit}
          autoComplete="off"
          name="formTicketEdit"
          size="small"
          layout="vertical" 
          colon={false} 
          onFinish={onFinish}
          initialValues={{ 
            state: draft?.state !== 'draft' ? draft?.state : 'open',
            support_cat_third_id: draft?.support_cat_third_id 
          }}
          // scrollToFirstError={true}
          validateTrigger="onBlur"
        >
          <TicketAddNote 
            ticketStatus={draft?.state}
            // showReminder={draft?.state !== 'draft'}
            showReminder
            triplets={triplets}
            motivations={ticketMotivations}
            size="small"
          />
          <Row justify="center" align="middle">
            <Col>
              <Space 
                align="center"
              >
                <Button 
                  size="large"
                  onClick={onLeave}
                  disabled={loading !== ''}
                >
                  Lascia
                </Button>
                <Button 
                  type="primary" 
                  size="large"
                  htmlType="submit"
                  disabled={loading !== ''}
                  loading={loading === 'updateTicketStatus'}
                >
                  Salva
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  )
}

TicketEdit.propTypes = {
  onLeave: PropTypes.func
}

export default TicketEdit;