import React, { useEffect, useState } from 'react';
//import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from "react-router-dom";
import { 
  useSelector,
  useDispatch 
} from 'react-redux';
import { 
  PageHeader,
  Space,
  Modal,
  Button,
  message
} from 'antd';
import { useHistory, useLocation } from "react-router-dom";
import Table from 'components/Table/Table'

import { 
  getContractsRidMissingList,
  submitRidProcess
} from 'redux/reducers/contractsReducer';

function ContractsRidMissing() {
  
  const [showModal, setShowModal] = useState('')
  const { list, pagination, loading } = useSelector(state => state.contracts);
  const dispatch = useDispatch();
  let history = useHistory();

  const columns = [
    {
      title: 'Codice contratto',
      dataIndex: ['object','contract_code'],
      //search: true,
      //sorter: true,
      render: (value,row) => <Link to={`/contratti/${row?.object?.id}`}>{value}</Link>,
      //width: '20%',
    },
    {
      title: 'Nome',
      dataIndex: ['object','customer','first_name'],
    },
    {
      title: 'Cognome',
      dataIndex: ['object','customer','last_name'],
    },
    {
      title: 'Offerta',
      dataIndex: ['object','commercial_offer','name'],
    },
    {
      title: 'Data sottoscrizione',
      dataIndex: 'created_at',
      render: value => value ? moment(value).format('D MMM YYYY HH:mm') : '---'
    },
    {
      title: 'Stato',
      dataIndex: ['object','state_name'],
    },
    {
      title: 'Azioni',
      key: 'action',
      render: (value, row) => (
        <Space size="middle">
          <Button size="small" onClick={() => setShowModal(row.id)}>Chiudi</Button>
        </Space>
      ),
    },
  ];

  const params = new URLSearchParams(useLocation().search)

  useEffect(() => {
    const getList = async() => {
      try {
        let current = params.get('page') || 1
        const result = await dispatch(getContractsRidMissingList({
          current: current
        }))
        
        if(result.payload.data) {
          const verifyResult = verifiyLastPage({
            lastPage: result.payload.meta.last_page, 
            current: params.get('page') || 1
          })
          if(verifyResult) {
            history.replace({
              search: "?" + new URLSearchParams({page: current}).toString()
            })
          }
          else updateList({ current: result.payload.meta.last_page})
          
        }
      } catch (error) {
        
      }
    }

    getList()
  },[])

  const updateList = async(pagination) => {
    try {
      const result = await dispatch(getContractsRidMissingList(pagination))
      let current = pagination.current;

      
      if(result.payload.data) {
        const verifyResult = verifiyLastPage({
          lastPage: result.payload.meta.last_page,
          current: result.payload.meta.current
        })
        if(verifyResult) {
          history.replace({
            search: "?" + new URLSearchParams({page: current}).toString()
          })
        }
        else updateList({ current: result.payload.meta.last_page})
        
      }
    } catch (error) {
      
    }
  }

  const verifiyLastPage = ({lastPage, current}) => {
    if(lastPage < current) {
      return false
    }
    return true
  }

  const handleConfirm = async (id) => {
    try {
      const result = await dispatch(submitRidProcess(id))
      if(result.payload.data) {
        setShowModal('')
        updateList(pagination)
        message.success('Lavorazione chiusa con successo!')
      }
    } catch (error) {
      message.error('Errore nella lavorazione.')
      setShowModal('')
    }
  }


  return (
    <div className="main-container">
      <PageHeader
        //className="site-page-header-responsive"
        //onBack={() => window.history.back()}
        title="Lista contratti SDD mancante"
        //subTitle="This is a subtitle"
        // extra={[
        //   <Dropdown overlay={menu} key="crea-contratto-button">
        //     <Button 
        //       type="primary" 
        //       //size="large"
        //     >
        //       Crea contratto <DownOutlined />
        //     </Button>
        //   </Dropdown>
        // ]}
      >
        {/* <Content extra={extraContent}>{renderContent()}</Content> */}
      </PageHeader>
      <Table
        size="small"
        columns={columns}
        rowKey={record => record.id}
        dataSource={list}
        //pagination={pagination}
        loading={loading !== ''}
        locale={{
          emptyText: 'Nessun dato'
        }}
        pagination={pagination}
        onChange={(pagination) => updateList(pagination)}
      />
      <Modal
        title="Sei sicuro?"
        visible={showModal !== ''}
        // onOk={handleBackButton}
        onCancel={() => setShowModal('')}
        footer={[
          <Button 
            key="back" 
            onClick={() => setShowModal('')}
            disabled={loading}
          >
            Annulla
          </Button>,
          <Button 
            key="submit" 
            type="primary" 
            onClick={() => handleConfirm(showModal)}
            loading={loading}
            disabled={loading}
          >
            Confermo
          </Button>
        ]}
      >
        <p>Confermi di voler chiudere la lavorazione?</p>
      </Modal>
    </div>
  )
}

// ContractsRidMissing.PropTypes = {
  
// }

export default ContractsRidMissing;