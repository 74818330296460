import React, { useEffect } from 'react';
//import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from "react-router-dom";
import { 
  useSelector,
  useDispatch 
} from 'react-redux';
import { 
  PageHeader,
  Space
} from 'antd';
import { useHistory, useLocation } from "react-router-dom";
//import { DownOutlined } from '@ant-design/icons';
import Table from 'components/Table/Table'

import { 
  getContractsToValidateList
} from 'redux/reducers/contractsReducer';

function ContractsListToValidate() {
  
  const { list, pagination, loading } = useSelector(state => state.contracts);
  const dispatch = useDispatch();
  let history = useHistory();

  const columns = [
    // {
    //   title: 'Codice',
    //   dataIndex: 'contract_code',
    //   search: true,
    //   //sorter: true,
    //   render: (value,row) => <Link to={`/contratti/${row.id}`}>{value}</Link>,
    //   //width: '20%',
    // },
    {
      title: 'Nome',
      dataIndex: ['customer','first_name'],
    },
    {
      title: 'Cognome',
      dataIndex: ['customer','last_name'],
    },
    {
      title: 'Offerta',
      dataIndex: ['commercial_offer','name'],
    },
    {
      title: 'Data sottoscrizione',
      dataIndex: 'created_at',
      render: value => value ? moment(value).format('D MMM YYYY HH:mm') : '---'
    },
    {
      title: 'Azioni',
      key: 'action',
      render: (value, row) => (
        <Space size="middle">
          <Link to={`/contratti/validazione/${row.id}`}>Apri</Link>
        </Space>
      ),
    },
  ];

  const params = new URLSearchParams(useLocation().search)

  useEffect(() => {
    const getList = async() => {
      try {
        await dispatch(getContractsToValidateList({
          current: params.get('page') || 1
        }))
      } catch (error) {
        
      }
    }

    getList()
  },[])

  const updateList = async(pagination) => {
    try {
      const result = await dispatch(getContractsToValidateList(pagination))
      
      if(result.payload.data) {
        
        history.replace({
          search: "?" + new URLSearchParams({page: pagination.current}).toString()
        })
      }
    } catch (error) {
      
    }
  }


  return (
    <div className="main-container">
      <PageHeader
        //className="site-page-header-responsive"
        //onBack={() => window.history.back()}
        title="Contratti da validare"
        //subTitle="This is a subtitle"
        // extra={[
        //   <Dropdown overlay={menu} key="crea-contratto-button">
        //     <Button 
        //       type="primary" 
        //       //size="large"
        //     >
        //       Crea contratto <DownOutlined />
        //     </Button>
        //   </Dropdown>
        // ]}
      >
        {/* <Content extra={extraContent}>{renderContent()}</Content> */}
      </PageHeader>
      <Table
        size="small"
        columns={columns}
        rowKey={record => record.id}
        dataSource={list}
        //pagination={pagination}
        loading={loading !== ''}
        locale={{
          emptyText: 'Nessun dato'
        }}
        pagination={pagination}
        onChange={(pagination) => updateList(pagination)}
      />
    </div>
  )
}

// ContractsListToValidate.PropTypes = {
  
// }

export default ContractsListToValidate;