import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col, 
  Form,
  Input,
  Select
} from 'antd';
import Triplets from 'components/Form/Triplets/Triplets';

function TicketAddNote({ 
  ticketStatus,
  hasTriplets,
  showReminder,
  triplets,
  motivations,
  size
}) {

  const [localTicketStatus, setLocalTicketStatus] = useState();

  useEffect(() => {
    setLocalTicketStatus(ticketStatus);
  }, [ticketStatus])

  return (
    <>
      <Row 
        gutter={[16, 16]}
      >
        <Col span={24}>
          <Form.Item 
            name="note"
            label="Note"
            rules={[
              {
                required: true,
                message: 'Campo obbligatorio'
              }
            ]}
          >
            <Input.TextArea
              rows={size === 'small' ? 3 : 6}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row 
        gutter={[16, 16]}
      >
        <Col span={showReminder ? 6 : 12}>
          <Form.Item 
            name="state"
            label="Stato"
            rules={[
              {
                required: true,
                message: 'Campo obbligatorio'
              }
            ]}
          >
            <Select 
              size={size}
              onChange={(value) => setLocalTicketStatus(value)}
            >
              <Select.Option value="open">Aperto</Select.Option>
              <Select.Option value="closed">Chiuso</Select.Option>
              <Select.Option value="suspended">Sospeso</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        {
          localTicketStatus === 'closed' &&
            <Col span={18}>
              <Form.Item 
                name="support_ticket_motivation_id"
                label="Motivazione"
                // rules={[
                //   {
                //     required: true,
                //     message: 'Campo obbligatorio'
                //   }
                // ]}
              >
                <Select 
                  size={size}
                  placeholder="Seleziona motivazione"
                >
                  {motivations?.map((v) => (
                    <Select.Option key={v.id} value={v.id}>{v.name}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
        }
        {
          showReminder &&
          <>
           <Col span={12}>
              <Form.Item 
                name="inbound_call"
                label="Chiamata inbound"
                initialValue={1}
                //label="E-mail"
                //hidden={!}
                //valuePropName="checked"
                rules={[
                  {
                    required: true,
                    message: 'Campo obbligatorio'
                  }
                ]}
              >
                {/* <Checkbox>Sollecito</Checkbox> */}
                <Select 
                  size={size}
                >
                  <Select.Option value={1}>Sì</Select.Option>
                  <Select.Option value={0}>No</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item 
                name="reminder"
                label="Sollecito"
                initialValue={0}
                //label="E-mail"
                //hidden={!}
                //valuePropName="checked"
              >
                {/* <Checkbox>Sollecito</Checkbox> */}
                <Select 
                  size={size}
                >
                  <Select.Option value={0}>No</Select.Option>
                  <Select.Option value={1}>Si</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            </>
        }
        {
          hasTriplets && (
            <Col span={12}>
              <Form.Item 
                name="support_cat_third_id"
                label="Tripletta"
                //trigger="onSelect"
                rules={[
                  {
                    required: true,
                    message: 'Campo obbligatorio'
                  }
                ]}
              >
                <Triplets 
                  data={triplets} 
                  size={size} 
                />
              </Form.Item>
            </Col>
          )
        }
      </Row>
    </>
  )
}

TicketAddNote.defaultProps = {
  size: 'large',
  hasTriplets: true,
  showReminder: false
}

TicketAddNote.propTypes = {
  ticketStatus: PropTypes.string,
  showReminder: PropTypes.bool, 
  hasTriplets: PropTypes.bool,
  data: PropTypes.array,
  size: PropTypes.string
}

export default TicketAddNote;