// const BASE_SITE_URL = process.env.REACT_APP_BUILD_ENV === 'production' ? "http://www.superfibra.nexev.it/" : "http://superfibra.nexev.it:3000/";
// const BASE_API_URL = process.env.REACT_APP_BUILD_ENV === 'production' ? "https://api.test.superfibra.nexev.it": "https://api.superfibra.nexev.it/";
const BASE_SITE_URL = process.env.REACT_APP_SITE_URL || "http://superfibra.nexev.it:3000/";
const BASE_API_URL = process.env.REACT_APP_API_URL || "https://api.superfibra.nexev.it/";
const BASE_PAYMENT_URL = process.env.REACT_APP_PAYMENT_URL || "https://api.linkem.sviluppo.key-biz.cloud/api";

export  {
  BASE_SITE_URL,
  BASE_API_URL,
  BASE_PAYMENT_URL
}