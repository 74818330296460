import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios";
import queryString from "query-string";
import { BASE_API_URL } from "config/constants";

axios.defaults.withCredentials = true;
axios.defaults.baseURL = BASE_API_URL;


export const getCustomerDetails = createAsyncThunk(
  'customers/getCustomerDetailsStatus',
  async (docId) => {
    try {
      const response = await axios.get(`/customers/${docId}`)
      console.log("RESPONSE > > >",response)
      return response.data
    } catch (error) {
      console.log("ERROR RESPONSE > > >",error.response)
      //return rejectWithValue(error.response.data)
      return {
        status: error.response.status
      }
    }
    
  }
)

export const getCustomerInvoices = createAsyncThunk(
  'customers/getCustomerInvoicesStatus',
  async ({data,customerId}) => {
    try {
      //console.log('customerId',data, customerId)
      const query = {
        page: data?.page?.current || 1,
        'filter[invoice_proof]': data?.filters?.invoice_proof?.data,
        'filter[document_date_between]': data?.filters?.document_date?.data,
        'filter[expires_at_between]': data?.filters?.expires_at?.data,
        'filter[contract.contract_code]': data?.filters?.['contract.contract_code']?.data,
        'filter[result]': data?.filters?.result?.data.value
      }
      //console.log("BEFORE RESPONSE > > >")
      const response = await axios.get(`/customers/${customerId}/invoices?${queryString.stringify(query)}`)
      response.data.filters = data.filters
      //console.log("RESPONSE > > >",response)
      return response.data
    } catch (error) {
      //console.log("ERROR RESPONSE > > >",error.response)
      //return rejectWithValue(error.response.data)
      return {
        status: error.response.status
      }
    }
  }
)

export const getCustomerTickets = createAsyncThunk(
  'customers/getCustomerTicketsStatus',
  async ({data,customerId}) => {
    try {
      //console.log('customerId',data, customerId)
      const query = {
        page: data?.page?.current || 1,
        'filter[ticket_code]': data?.filters?.ticket_code?.data,
        //'filter[customer.first_name]': data?.filters?.['customer.first_name']?.data,
        //'filter[customer.last_name]': data?.filters?.['customer.last_name']?.data,
        'filter[contract.contract_code]': data?.filters?.['contract.contract_code']?.data,
        'filter[state]': data?.filters?.state_name?.data.value,
        'filter[created_between]': data?.filters?.created_at?.data,
        'filter[origin]': data?.filters?.origin_name?.data.value,
        'filter[triplets]': data?.filters?.support_cat?.data.value,
      }
      //console.log("BEFORE RESPONSE > > >")
      const response = await axios.get(`/customers/${customerId}/tickets?${queryString.stringify(query)}`)
      response.data.filters = data.filters
      //console.log("RESPONSE > > >",response)
      return response.data
    } catch (error) {
      //console.log("ERROR RESPONSE > > >",error.response)
      //return rejectWithValue(error.response.data)
      return {
        status: error.response.status
      }
    }
  }
)

export const getCustomerContracts = createAsyncThunk(
  'customers/getCustomerContractsStatus',
  async ({data,customerId}) => {
    try {
      //console.log('customerId',data, customerId)
      const query = {
        page: data?.page?.current || 1,
        'filter[contract_code]': data?.filters?.contract_code?.data,
        'filter[coverage.city]': data?.filters?.['coverage.city']?.data,
        'filter[coverage.street_name]': data?.filters?.['coverage.street_name']?.data,
        'filter[state]': data?.filters?.state_name?.data.value,
        'filter[recess_requested]': data?.filters?.recess_requested?.data.value,
        'filter[cancel_requested]': data?.filters?.cancel_requested?.data.value,
        'filter[created_between]': data?.filters?.created_at?.data,
        'filter[activated_between]': data?.filters?.activated_at?.data,
      }
      //console.log("BEFORE RESPONSE > > >")
      const response = await axios.get(`/customers/${customerId}/contracts?${queryString.stringify(query)}`)
      response.data.filters = data.filters
      //console.log("RESPONSE > > >",response)
      return response.data
    } catch (error) {
      //console.log("ERROR RESPONSE > > >",error.response)
      //return rejectWithValue(error.response.data)
      return {
        status: error.response.status
      }
    }
  }
)

export const getCustomersList = createAsyncThunk(
  'customers/getCustomersListStatus',
  async (data) => {
    try {
      const query = {
        page: data?.page?.current || 1,
        'filter[customer_code]': data?.filters?.customer_code?.data,
        'filter[first_name]': data?.filters?.first_name?.data,
        'filter[last_name]': data?.filters?.last_name?.data,
        'filter[phone_number]': data?.filters?.phone_number?.data,
        'filter[email]': data?.filters?.email?.data,
        'filter[fiscal_code]': data?.filters?.fiscal_code?.data,
        'filter[channel_type]': data?.filters?.channel_type?.data.value
      }
      //console.log("BEFORE RESPONSE > > >")
      const response = await axios.get(`/customers?${queryString.stringify(query)}`)
      response.data.filters = data.filters
      return response.data
    } catch (error) {
      console.log("ERROR RESPONSE > > >",error.response)
      //return rejectWithValue(error.response.data)
      return {
        status: error.response.status
      }
    }
  }
)

export const switchVip = createAsyncThunk(
  'contracts/switchVipStatus',
  async (values, { getState }) => {
    try {
      const customertId = getState().customers.selected.id;
      
      const response = await axios({
        method: 'POST',
        url: `customers/${customertId}/toggle-vip`
      })
      return response.data
    } catch (error) {
      return {
        status: error.response.status,
        message: error.response.data.message
      }
    }
  }
)

function isFulfilledAction(action) {
  return action.type.endsWith('/fulfilled')
}

function isPendingAction(action) {
  return action.type.endsWith('/pending')
}

function isRejectedAction(action) {
  return action.type.endsWith('/rejected')
}


const initialState = {
  loading: '',
  error: null,
  selected: null,
  list: []
}


const customersSlice = createSlice({
  name: 'customers',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCustomersList.fulfilled, (state, action) => {
      if(action.payload) {
        const pagination = action.payload.meta
        state.list = action.payload.data
        state.pagination = {
          pageSize: pagination.per_page,
          current: pagination.current_page,
          total: pagination.total
        }
        state.filters = action.payload.filters
      }
    })
    builder.addCase(getCustomerDetails.fulfilled, (state, action) => {
      if(action.payload) {
        state.selected = action.payload.data
      }
    })
    builder.addCase(switchVip.fulfilled, (state, action) => {
      if(action.payload) {
        state.selected = {...state.selected, ...action.payload.data}
      }
    })
    builder.addCase(getCustomerInvoices.fulfilled, (state, action) => {
      if(action.payload) {
        const pagination = action.payload.meta

        state.selected = {
          ...state.selected,
          invoices: {
            list: action.payload.data,
            pagination: {
              pageSize: pagination.per_page,
              current: pagination.current_page,
              total: pagination.total
            },
            filters: action.payload.filters
          }
        }
      }
    })
    builder.addCase(getCustomerTickets.fulfilled, (state, action) => {
      if(action.payload) {
        const pagination = action.payload.meta

        state.selected = {
          ...state.selected,
          tickets: {
            list: action.payload.data,
            pagination: {
              pageSize: pagination.per_page,
              current: pagination.current_page,
              total: pagination.total
            },
            filters: action.payload.filters
          }
        }
      }
    })
    builder.addCase(getCustomerContracts.fulfilled, (state, action) => {
      if(action.payload) {
        const pagination = action.payload.meta

        state.selected = {
          ...state.selected,
          contracts: {
            list: action.payload.data,
            pagination: {
              pageSize: pagination.per_page,
              current: pagination.current_page,
              total: pagination.total
            },
            filters: action.payload.filters
          }
        }
      }
    })
    builder.addMatcher(isFulfilledAction, (state, action) => {
      state.loading = ''
      state.error = false
    })
    builder.addMatcher(isPendingAction, (state, action) => {
      const id = typeof action.meta.arg === 'object' ? action.meta.arg.id : action.meta.arg
      state.loading = action.type.split('/')[1] + (id ? '_' +id : '')
      state.error = false
    })
    builder.addMatcher(isRejectedAction, (state, action) => {
      state.loading = ''
      state.error = action.error
    })
  }
})

const { reducer } = customersSlice


export default reducer