import React from 'react';
import moment from 'moment';

import {
  Button,
  DatePicker
} from 'antd';

import { SearchOutlined } from '@ant-design/icons';
import locale from 'antd/es/date-picker/locale/it_IT';

export default function({
  options,
  dataIndex,
  placeholder,
  title,
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
  handleSearch,
  handleReset
}) {

  const formatRangeDate = (dateRange) => {
    console.log('FORMAT RANGE DATA',dateRange, moment(dateRange[0]))
    const range = `${moment(dateRange[0]).format('YYYY-MM-DD')},${moment(dateRange[1]).format('YYYY-MM-DD')}`
    console.log('FORMAT RANGE DATA',range)
    return range
  }
  
  return (
    <div style={{ 
      padding: 8,
      // display: 'flex',
      // flexDirection: 'column',
      // width: 250,
      // overflow: 'hidden'
    }}>
      <DatePicker.RangePicker
        // ref={node => {
        //   searchInput = node;
        // }}
        locale={locale}
        format={'DD/MM/YYYY'}
        placeholder={placeholder}
        value={selectedKeys}
        onChange={dateRange => setSelectedKeys(dateRange)}
        //onPressEnter={() => handleSearch(formatRangeDate(selectedKeys), confirm, dataIndex, title)}
        //style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      {/* <Radio.Group 
        value={selectedKeys?.[0]?.value}
        onChange={e => setSelectedKeys(e.target.value ? [{label: e.target.label, value:e.target.value}] : [{}])}
      >
        {
          options.map(option => (
            <Radio 
              key={option.value} 
              value={option.value}
              label={option.text}
              style={radioStyle}
              >
                {option.text}
              </Radio>
          ))
        }
      </Radio.Group> */}
      <div style={{
        marginTop: 16,
        display: 'flex',
        justifyContent: 'space-between'
      }}>
        <Button
          type="primary"
          onClick={() => handleSearch([formatRangeDate(selectedKeys)], confirm, dataIndex, title)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Filtra
        </Button>
        <Button 
          onClick={() => handleReset(clearFilters,dataIndex)} 
          size="small" 
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    </div>
  )
}