import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { 
  Form,
  Select, 
  Button,
  Typography,
  Space,
  Result,
  message
} from 'antd';
import debounce from 'lodash/debounce';
import { useSelector, useDispatch } from 'react-redux';
import { 
  verifyCoverageCity,
  verifyCoverageAddress,
  verifyCoverageAddressNumber,
  verifyCoverageFinal,
  //verifyCoverageFinalMock,
  addLeadFinal
} from 'redux/reducers/contractsReducer';
import LeadFields from 'components/Form/LeadFields/LeadFields';
import operators from 'data/operators.json';
import { useParams, useLocation } from 'react-router-dom';

function Coverage({ onSuccess, onLeadSubmit }) {
  
  const [state, setState] = useState({
    city: {
      options: [],
      extra: ''
    },
    address: {
      options: [],
      extra: ''
    },
    number: {
      options: [],
      extra: ''
    },
    activeLine: ''
  })
  const [showLead, setShowLead] = useState(false)
  
  const { newContract, loading } = useSelector(state => state.contracts)
  const dispatch = useDispatch()
  const [formCoverage] = Form.useForm();
  const [formLead] = Form.useForm();
    
  const handleSearchCity = async (text) => {
    if(text.length > 3) {
      showLead && setShowLead(false)
      try{
        const result = await dispatch(verifyCoverageCity(text))
        if(result.payload && result.payload.ESITO === 'KO') {
          formCoverage.setFieldsValue({
            city: text
          })
          storeCity()
          return setState({
            city: {
              options: [],
              extra: 'Città non incontrata'
            },
            address: {
              options: [],
              extra: ''
            },
            number: {
              options: [],
              extra: ''
            }
          })
        }
        
        const optionsParsed = result.payload && result.payload.reduce((acc,city) => {
          acc.push({
            label: city.label,
            value: city.COMUNE,
            sigla: city.Sigla || '',
            cod: city.CODICE_COMUNE
          })
           return acc
        },[])
  
        return setState({
          city: {
            options: optionsParsed,
            extra: ''
          },
          address: {
            options: [],
            extra: ''
          },
          number: {
            options: [],
            extra: ''
          }
        })
        
        
      }
      catch(err) {
        console.log('error ',err)
      }
    }
  }

  const handleSearchAddress = async (text) => {
    if(text.length > 1) {
      showLead && setShowLead(false)
      try{
        const result = await dispatch(verifyCoverageAddress({
          city: newContract.city.name,
          province: newContract.city.province,
          text
        }))
        if(result.payload && result.payload.ESITO === 'KO') {
          setState({
            ...state,
            address: {
              options: [],
              extra: 'Indirizzo non incontrato'
            },
            number: {
              options: [],
              extra: ''
            }
            
          })
          formCoverage.setFieldsValue({
            address: text
          })
          storeAddress()
          // setExtra({address: 'Indirizzo non incontrato'})
          // return setOptionsAddress([])
        }
        const options = result.payload
        console.log({options})
        const optionsParsed = options.reduce((acc,address) => {
          acc.push({
            key: address.key +'_'+ address.ParticellaToponomastica,
            label: address.label,
            value: address.Indirizzo +'_'+ address.ParticellaToponomastica,
            streettype: address.ParticellaToponomastica
          })
           return acc
        },[])
        // setExtra({address: ''})
        // setOptionsAddress(optionsParsed)
        setState({
          ...state,
          address: {
            options: optionsParsed,
            extra: ''
          },
          number: {
            options: [],
            extra: ''
          }
        })
        
      }
      catch(err) {
        console.log('error ',err)
      }
    }
  }

  const handleSearchAddressNumber = async (text) => {
    if(text.length > 0) {
      showLead && setShowLead(false)
      try{
        const result = await dispatch(verifyCoverageAddressNumber({
          city: newContract.city.name,
          province: newContract.city.province,
          address: newContract.address.name,
          streetType: newContract.address.streetType,
          text
        }))
        if(result.payload && result.payload.ESITO === 'KO') {
          formCoverage.setFieldsValue({
            number: text
          })
          storeAddressNumber(null,{
            value:'',
            egon: ''
          })
          return setState({
            ...state,
            number: {
              options: [],
              extra: 'Numero non incontrato'
            }
          })
        }
        
        const optionsParsed = result.payload.reduce((acc,address) => {
          acc.push({
            key: address.key,
            label: address.label,
            value: address.Civico,
            egon: address.EGON
          })
           return acc
        },[])
        
        // setExtra({number: ''})
        // setOptionsAddressNumber(optionsParsed)
        setState({
          ...state,
          number: {
            options: optionsParsed,
            extra: ''
          }
        })
      }
      catch(err) {
        console.log('error ',err)
      }
    }
  }

  const params = new URLSearchParams(useLocation().search);

  const handleSubmit = async () => {
    try{
      const lead_id = params.get("lead_id");

      const result = await dispatch(verifyCoverageFinal({
        city: newContract.city.name,
        province: newContract.city.province,
        cod: newContract.city.cod,
        address: newContract.address.name,
        streetType: newContract.address.streetType,
        addressNumber: newContract.addressNumber,
        addressNumberEgon: newContract.addressNumberEgon,
        leadId: lead_id
      }))
      if(result.payload && result.payload.ESITO === 'KO') {
        setShowLead(true)
        return false
      }
      onSuccess()
    }
    catch(err) {
      console.log('error ',err)
    }
  }


  const handleSubmitLead = async (values) => {
    try{
      const result = await dispatch(addLeadFinal({
        firstName: values.lead_firstname,
        lastName: values.lead_lastname,
        phone: values.lead_phone,
        email: values.lead_email,
        privacy_accepted: values.lead_privacy ? 1 : 0
      }))
      if(result?.payload?.status !== 422 && result?.payload?.data) {
        message.success('Dati inviati con successo!')
        onLeadSubmit()
      }
      else {
        const errors = Object.keys(result?.payload?.errors || {}).map((errorKey,i) => {
          return {
            name: errorKey.split('.'),
            errors: result?.payload?.errors?.[errorKey]
          }
        })
        formLead.setFields(errors)
        message.error(`Errore nella compilazione del modulo`);
      }

    }
    catch(err) {
      console.log('ERRORE CARICAMENTO LEAD',err)
    }
  }

  const storeActiveLine = (value,option) => {
    dispatch({ type: 'contracts/updateActiveLine', activeLine: option.value })
    setState({
      ...state,
      activeLine: option.value
    })
  }

  const storeCity = (value,option) => {
    formCoverage.setFieldsValue({
      address: undefined,
      number: undefined
    })
    const city = option ? {
      province: option.sigla,
      name: option.value,
      cod: option.cod
    } : null
    dispatch({ type: 'contracts/updateContractCity', city:city})
  }

  const storeAddress = (value,option) => {
    formCoverage.setFieldsValue({
      number: undefined
    })
    const address = option ? {
      name: option.value.split('_')[0],
      streetType: option.streettype
    } : null
    dispatch({ type: 'contracts/updateContractAddress', address:address })
  }

  const storeAddressNumber = (value,option) => {
    dispatch({ type: 'contracts/updateContractAddressNumber', 
    addressNumber: option.value,
    addressNumberEgon: option.egon
   })
  }

  const validating = (loading, success, error) => {
    if(loading) return 'validating'
    if(error) return 'error'
    if(success) return 'success' 
    else return ''
  }

  const extraEl = (text) => {
    return React.createElement(
      Typography.Text,
      { 
        type: 'danger'
      },
      text
    )
  }

  
  return (
    <div className="stepContainer alignItemsCenter">
      <Form 
        form={formCoverage} 
        layout="vertical" 
        name="formCoverage"
        colon={false}
      >
        <Form.Item 
          name="city"
          label="Comune"
          required
          hasFeedback
          extra={state.city && extraEl(state.city.extra)}
          validateStatus={
            validating(
              loading.includes('verifyCoverageCityStatus'), 
              newContract.city && newContract.city.name,
              state.city && state.city.extra
            )
          }
        >
          <Select
            showSearch
            showArrow={false}
            //style={{width: 400}}
            size="large"
            defaultActiveFirstOption={false}
            onSearch={debounce(handleSearchCity, 700)}
            placeholder="Cerca il comune"
            onSelect={(value,option) => storeCity(value,option)}
            options={state.city.options}
            notFoundContent={null}
          />
        </Form.Item>
        <Form.Item
          name="address"
          label="Indirizzo"
          required
          hasFeedback
          // extra={state.address && state.address.extra}
          extra={state.address && extraEl(state.address.extra)}
          validateStatus={
            validating(
              loading.includes('verifyCoverageAddressStatus'), 
              newContract.address && newContract.address.name,
              state.address && state.address.extra
            )
          }
        >
          <Select
            showSearch
            showArrow={false}
            //style={{width: 400}}
            size="large"
            defaultActiveFirstOption={false}
            onSearch={debounce(handleSearchAddress, 700)}
            placeholder="Inserisci l'indirizzo senza particella toponomastica"
            onSelect={(value,option) => storeAddress(value,option)}
            options={state.address.options}
            disabled={newContract.city === null}
            notFoundContent={null}
          />
        </Form.Item>
        <Form.Item
          name="number"
          label="Civico"
          required
          hasFeedback
          //extra={state.number.extra}
          extra={state.number && extraEl(state.number.extra)}
          validateStatus={
            validating(
              loading.includes('verifyCoverageAddressNumberStatus'), 
              newContract.addressNumber,
              state.number && state.number.extra
            )
          }
        >
          <Select
            showSearch
            showArrow={false}
            //style={{width: 400}}
            size="large"
            onSearch={debounce(handleSearchAddressNumber, 700)}
            placeholder="Inserisci il numero civico"
            onSelect={(value,option) => storeAddressNumber(value,option)}
            options={state.number.options}
            disabled={newContract.city === null || newContract.address === null}
            notFoundContent={null}
          />
        </Form.Item>
        <Form.Item
          name="linea"
          label="Operatore"
          //valuePropName="checked"
          //onChange={(e) => storeActiveLine(e.target.checked)}
        >
          {/* <Checkbox>Linea già attiva</Checkbox> */}
          <Select
            showSearch
            size="large"
            placeholder="Seleziona un operatore"
            //optionFilterProp="children"
            //onChange={onChange}
            //onFocus={onFocus}
            //onBlur={onBlur}
            //onSearch={onSearch}
            onChange={(value,option) => storeActiveLine(value,option)}
            options={operators}
          />
        </Form.Item>
      </Form>
      <Space align="center" size="large">
        <Button 
          type="primary" 
          size="large"
          onClick={handleSubmit} 
          disabled={
            newContract.city === null ||
            newContract.address === null ||
            newContract.addressNumber === '' ||
            loading
          }
          loading={loading.includes('verifyCoverageStatus')}
        >
          Verifica copertura
        </Button>
        {/* <Button type="link" onClick={handleMock} >MOCK</Button> */}
      </Space>
      {
        showLead &&
        <Result
          status="error"
          title="Indirizzo non coperto!"
          subTitle="Inserisci i dati del cliente per un successivo ricontatto"
        >
          <Form
            form={formLead} 
            layout="vertical" 
            colon={false} 
            name="formLead"
            onFinish={handleSubmitLead}
            //onFinishFailed={onFinishFailed}
          >
            <LeadFields prefix="lead" />
            <div className="actionBar">
              <Button 
                type="primary" 
                size="large"
                htmlType="submit"
                //onClick={handleSubmitLead} 
                loading={loading.includes('addLeadStatus')}
              >
                Conferma
              </Button>
            </div>
          </Form>
        </Result>
      }
    </div>
  )
}

Coverage.propTypes = {
  onSuccess: PropTypes.func,
  onLeadSubmit: PropTypes.func
}

export default Coverage;