import React, { useState, useEffect, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { 
  Link, 
  Switch, 
  Route, 
  useLocation, 
  Redirect,
  useHistory
} from 'react-router-dom';

import { 
  Spin,
  Avatar,
  Layout, 
  Menu,
  Dropdown,
  Button,
  Drawer,
  Modal
} from 'antd';
import { 
  DownOutlined, 
  PlusOutlined,
  ArrowRightOutlined
} from '@ant-design/icons';

import TicketsCreate from 'views/Tickets/TicketsEdit/TicketsCreate';
import TicketEdit from 'views/Tickets/TicketsEdit/_Edit/TicketEdit';
//import FullscreenModal from 'components/FullscreenModal/FullscreenModal';
import SideMenu from 'components/SideMenu';
import { menuLinks } from 'routes'

import { 
  useSelector, 
  useDispatch 
} from 'react-redux';

import { 
  fetchUserAuthenticated,
  logOutUser,
  getParametersStates
} from 'redux/reducers/authReducer';

import { 
  fetchTriplets,
  getTicketEdit,
} from 'redux/reducers/ticketsReducer';

// import { 
//   getContractsStates
// } from 'redux/reducers/contractsReducer';

import {
  getLeadMotivations
} from 'redux/reducers/leadsReducer';

import {
  getTicketMotivations
} from 'redux/reducers/ticketsReducer';

import getInitials from 'utils/getInitials';
import logo from 'assets/img/logo-header.svg';


function MainLayoutAuthenticated({ routes }, props) {
  const { 
    Header, 
    //Sider, 
    Content 
  } = Layout;

  //const [isCollapsed, setIsCollapsed] = useState(false);
  //const [showAlertModal, setShowAlertModal] = useState(false);
  
  const { user, userRole } = useSelector(state => state.auth);
  const { 
    isDrawerOpen, 
    showNewTicket, 
    newTicket,
    showAlertWorking,
    actionBeforeAlert,
    workingTicketId,
    loading
  } = useSelector(state => state.tickets);
  
  const dispatch = useDispatch();
  const initialPath = useLocation().pathname;
  const draftId = localStorage.getItem('ticketId');

  // const actionsMap = {
  //   'CREATE': dispatch({ type: 'tickets/setShowNewTicket', value: true }),
  //   'LEAVE': dispatch({ type: 'tickets/leaveDraft' })
  // }

  useEffect(() => {
    const getUser = async () => {
      const result = await dispatch(fetchUserAuthenticated());
      //console.log('RESULT > > >',result, userRole)
      if(result?.payload?.data?.level?.toUpperCase()==='CUSTOMER_CARE') {
        getTriplets();
        fetchMotivations();
        // fetchTicketMotivations();
      }
    }
    const getTriplets = async () => {
      await dispatch(fetchTriplets());
    }
    const fetchParametersStates = async () => {
      await dispatch(getParametersStates());
    }
    const getDraft = async (id) => {
      await dispatch(getTicketEdit(id));
    }
    const fetchMotivations = async () => {
      await dispatch(getLeadMotivations());
    }

    const fetchTicketMotivations = async () => {
      await dispatch(getTicketMotivations());
    }

    //console.log('MONTO MAIN LAYOUT ')
   if(draftId) {
    getDraft(draftId);
    //console.log('SETTO IS DRAWER OPEN ')
    dispatch({type:"tickets/setIsDrawerOpen", value: true});
   } 

    getUser();
    fetchParametersStates();
    // console.log('userRole >',userRole)
    // if(userRole==='CUSTOMER_CARE') {
    //   getTriplets();
    //   fetchMotivations();
    // }
  },[])

 

  const userMenu = (
    <Menu style={{ minWidth: 100, textAlign: 'center' }}>
      <Menu.Item key="1">
        <a onClick={() => dispatch(logOutUser())}>Logout</a>
      </Menu.Item>
    </Menu>
  );

  const handleNewTicket = () => {
    if(newTicket.draft) {
      return dispatch({
        type:"tickets/setshowAlertWorking", 
        options: {
          value: true,
          actionName: 'CREATE'
        }
      })
    }
    dispatch({type:"tickets/setShowNewTicket", value: true})
  }

  const menu = (
    <Menu>
      {
        userRole === 'CUSTOMER_CARE' && 
        <Menu.Item key="1" >
          <a onClick={handleNewTicket}>Ticket</a>
        </Menu.Item>
      }
      {/* {
        userRole === 'PARTNER' && 
        <Menu.Item key="2" >
          <Link to={{ 
            pathname: "/contratti/nuovo", 
            state:{
              format: 'on_paper'
            }
          }}>Contratto Cartaceo</Link>
        </Menu.Item>
      } */}
      <Menu.Item key="3" >
        <Link to={{ 
          pathname: "/contratti/nuovo", 
          state:{
            format: 'digital'
          }
        }}>Contratto Digitale</Link>
      </Menu.Item>
    </Menu>
  );

  
  return (
    user && (
      user.isAuthenticated ?
        <Layout style={{minHeight:"100vh"}}>
          <Layout className="site-layout">
            <Header 
              style={{ position: 'fixed', zIndex: 1, width: '100%', padding: '0 20px' }}
              className="site-layout-background" 
              // style={{ padding: '0 20px' }}
            >
              <div className="logo">
                <img src={logo} alt="Logo" style={{width: '100%'}} />
              </div>
              <div style={{ float: 'left' }}>
                <SideMenu 
                  userRole={userRole}
                  routePath={initialPath}
                  menuLinks={menuLinks}
                  mode="horizontal"
                  theme="dark"
                />
              </div>
              <div style={{ float: 'right', display: 'flex' }}>
                {
                  (draftId || newTicket?.draft?.id) && 
                    <div style={{ marginLeft: 24 }}>
                      <Button onClick={() => dispatch({type:"tickets/setIsDrawerOpen", value: true})}>
                        Continua lavorazione
                      </Button>
                    </div>
                }
                <div style={{ marginLeft: 24 }}>
                  <Dropdown overlay={menu} key="crea-contratto-button">
                    <Button 
                      type="primary" 
                      //size="large"
                    >
                      <PlusOutlined />
                      Nuovo
                    </Button>
                  </Dropdown>
                  {/* <Button 
                    type="primary" 
                    onClick={handleNewTicket}>
                    <PlusOutlined />
                    Nuovo Ticket
                  </Button> */}
                </div>
                <div style={{ marginLeft: 24 }}>
                  <Dropdown overlay={userMenu} trigger={['click']}>
                    <div className='site-userMenu-container'>
                      <Avatar size={40} >{getInitials(user.name.toUpperCase())}</Avatar> <DownOutlined style={{color:"white"}}/>
                    </div>
                  </Dropdown>
                </div>
              </div>
            </Header>
            <Content style={{ marginTop: 64 }}>
              <Switch>
                {routes.map((route, i) => (
                  <Route
                    key={i}
                    path={route.path}
                    exact={route.exact}
                    render={props => (
                      <route.component {...props} routes={route.routes} />
                    )}
                  />
                ))}
              </Switch>
            </Content>
            <Drawer
              width={500}
              placement="right"
              //closable={false}
              mask={false}
              onClose={() => dispatch({type:"tickets/setIsDrawerOpen", value: false})}
              visible={isDrawerOpen}
              closeIcon={<ArrowRightOutlined />}
            >
              <TicketEdit 
                onLeave={() => dispatch({
                  type:"tickets/setshowAlertWorking", 
                  options: {
                    value: true,
                    actionName: 'LEAVE'
                  }
                })}
              />
            </Drawer>
            {/* <FullscreenModal
              //title="Nuovo ticket"
              visible={showNewTicket}
              //onOk={() => setShowNewTicket(false)}
              onCancel={() => dispatch({type:"tickets/setShowNewTicket", value: false})}
            > */}
            <Modal
              visible={showNewTicket}
              width={900}
              footer={false}
              destroyOnClose
              onCancel={() => dispatch({type:"tickets/setShowNewTicket", value: false})}
            >
              <TicketsCreate onSuccess={() => dispatch({type:"tickets/setShowNewTicket", value: false})}
              />
            </Modal>
            {/* </FullscreenModal> */}
            <Modal
              title="Attenzione!"
              visible={showAlertWorking}
              closable={false}
              footer={[
                <Button 
                  key="back" 
                  disabled={loading !== ''}
                  onClick={() => dispatch({ 
                    type: 'tickets/setshowAlertWorking', 
                    options: {
                      value: false, 
                      actionName: '' 
                    } 
                  })}
                >
                  Annulla
                </Button>,
                <Button 
                  key="submit" 
                  type="primary" 
                  disabled={loading !== ''}
                  loading={loading !== ''}
                  onClick={() => { 
                    actionBeforeAlert === 'EDIT' 
                    ? dispatch(getTicketEdit(workingTicketId))
                    : dispatch({ type: 'tickets/handleSuccesAlertModal' }) 
                  }}
                >
                  Continua
                </Button>
              ]}
            >
              <p>Esiste un ticket in lavorazione, continuando perderai automaticamente le informazioni non salvate.</p>
            </Modal>
            {/* <Footer>Footer</Footer> */}
          </Layout>
        </Layout> :
        <Redirect to="/login" />
    ) || <div className="loadingFullwidthPage"><Spin size="large"/></div>
  )
}

MainLayoutAuthenticated.propTypes = {
  routes: PropTypes.array
}

export default MainLayoutAuthenticated;