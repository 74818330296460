import React from 'react';

import {
  Radio,
  Button,
  Space
} from 'antd';

import { SearchOutlined } from '@ant-design/icons';

export default function({
  options,
  dataIndex,
  placeholder,
  title,
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
  handleSearch,
  handleReset
}) {
  const radioStyle = {
    display: 'block',
    whiteSpace: 'break-spaces',
    paddingBottom: 8,
    margin: 0
  };
  return (
    <div style={{ 
      padding: 8,
      display: 'flex',
      flexDirection: 'column',
      width: 250,
      overflow: 'hidden'
    }}>
      {/* <Input
        // ref={node => {
        //   searchInput = node;
        // }}
        placeholder={placeholder}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex, title)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      /> */}
      <Radio.Group 
        value={selectedKeys?.[0]?.value}
        onChange={e => setSelectedKeys(e.target.value ? [{label: e.target.label, value:e.target.value}] : [{}])}
      >
        {
          options.map(option => (
            <Radio 
              key={option.value} 
              value={option.value}
              label={option.text}
              style={radioStyle}
              >
                {option.text}
              </Radio>
          ))
        }
      </Radio.Group>
      <div style={{
        marginTop: 16,
        display: 'flex',
        justifyContent: 'space-between'
      }}>
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex, title)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Filtra
        </Button>
        <Button 
          onClick={() => handleReset(clearFilters,dataIndex)} 
          size="small" 
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    </div>
  )
}