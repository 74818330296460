import React from 'react';
import moment from 'moment';
//import PropTypes from 'prop-types';
import { 
  useSelector,
} from 'react-redux';
import { Link } from "react-router-dom";
import { 
  PageHeader
} from 'antd';
import Table from 'views/Tickets/TicketsList/_Table';
import './ContractsList.css';

import { 
  getContractsListRecess
} from 'redux/reducers/contractsReducer';

function ContractsList() {
  
  const { list, pagination, filters, loading } = useSelector(state => state.contracts);
  //const { states } = useSelector(state => state.auth);

  const columns = [
    {
      title: 'Cod. contratto',
      dataIndex: 'contract_code',
      search: true,
      //sorter: true,
      render: (value,row) => <Link to={`/contratti/${row.id}`}>{value}</Link>,
      //width: '20%',
    },
    {
      title: 'Canale',
      dataIndex: 'channel_type'
    },
    {
      title: 'BP',
      dataIndex: 'bp_code',
      search: true,
      render: value => value || '---',
      hideForUsers: ['PARTNER']
    },
    {
      title: 'Nome',
      dataIndex: ['customer','first_name'],
      search: true
    },
    {
      title: 'Cognome',
      dataIndex: ['customer','last_name'],
      search: true
    },
    {
      title: 'Indirizzo',
      dataIndex: ['coverage','street_name'],
      render: (value,row) => `${row.coverage?.toponymic_particle} ${row.coverage?.street_name}, ${row.coverage?.address_number}`,
      search: true
    },
    {
      title: 'Città',
      dataIndex: ['coverage','city'],
      search: true
    },
    {
      title: 'Offerta',
      dataIndex: ['commercial_offer','name'],
    },
    {
      title: 'Carrier',
      dataIndex: ['carrier'],
      hideForUsers: ['PARTNER']
    },
    {
      title: 'Velocità',
      dataIndex: ['connection_type', 'speed']
    },
    {
      title: 'Zona',
      dataIndex: ['connection_type','zone'],
      hideForUsers: ['PARTNER'],
      search: true,
      filterType: 'radio',
      filterOptions: [{text: 'A&B', value: 'A&B' }, {text: 'C&D', value: 'C&D' }]
    },
    {
      title: 'Sottoscrizione',
      dataIndex: 'created_at',
      render: value => value ? moment(value).format('D MMM YYYY') : '---',
      search: true,
      filterType: 'dateRange'
    },
    {
      title: 'Stato provisioning',
      dataIndex: 'state_provisioning_name',
      render: value => value ? value : '---'
    },
    {
      title: 'Scadenza',
      dataIndex: 'recess_request_expires_at',
      render: value => value ? moment(value).format('D MMM YYYY') : '---',
      // search: true,
      // filterType: 'dataRange'
    },
    {
      title: 'Stato',
      dataIndex: 'state_name',
      //search: true,
      // filterType: 'radio',
      // filterOptions: states?.contracts
    }
  ];

  return (
    <div className="main-container">
      <PageHeader
        title="Elenco contratti in recesso"
        style={{ paddingBottom: 0 }}
      >
      </PageHeader>
      <Table
        columns={columns}
        dataSource={list}
        pagination={pagination}
        filters={filters}
        loading={loading}
        getList={(data) => getContractsListRecess(data)}
      />
    </div>
  )
}

// ContractsList.PropTypes = {
  
// }

export default ContractsList;