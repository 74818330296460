import React, { 
  useState,
  useEffect
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Row,
  Col, 
  Form,
  Select, 
  Button,
  Space,
  Modal,
  message
} from 'antd';

//import { useHistory } from 'react-router-dom';
import { 
  useSelector, 
  useDispatch 
} from 'react-redux';
//import './Contract.css';
import { 
  insertPaymentMethod,
  createPaymentCC,
  submitPaymentCC
} from 'redux/reducers/contractsReducer';

import locale from 'antd/es/date-picker/locale/it_IT';

import CreditCardFields from 'components/Form/CreditCardFields/CreditCardFields';
import RidFields from 'components/Form/RidFields/RidFields';


function Payment({ onCancel, onSuccess, contractFormat }) {
  
  const { newContract, loading } = useSelector(state => state.contracts);
  const dispatch = useDispatch();
  //let history = useHistory();
  const [formPayment] = Form.useForm();

  const initialValues = {
    payment_method: 'rid'
  };

  const [paymentMethod, setPaymentMethod] = useState('rid');
  const [showModal, setShowModal] = useState(false);

  const onFinish = async (values) => {
    
    const result = await dispatch(
      insertPaymentMethod({
        paymentMethod: paymentMethod,
        paymentValues: values, 
        contractFormat:contractFormat 
      }))

    if(result.payload.status !== 422 && result.payload.data) {
      onSuccess()
    }
    else {
      message.error(`Errore nella compilazione del modulo`);

      const errors = Object.keys(result.payload.errors).map((errorKey,i) => {
        return {
          name: errorKey.split('.'),
          errors: result.payload.errors[errorKey]
        }
      })
      formPayment.setFields(errors)
      formPayment.scrollToField(errors[0].name,{behavior: 'smooth'})
    }
  };


  const onPaymentCreateCC = async (values) => {
    
    const result = await dispatch(
      createPaymentCC({
        shopLogin: 'GESPAY82293',
        amount: '29.99', 
        currency: '242',
        shopTransactionID: 'testing',
        requestToken: 'MASKEDPAN',
        transDetails: {
          type: '08',
          authenticationAmount: '250',
          recurringTransaction: {
            expiry: moment().add(5, 'years').endOf('months').format('YYYYMMDD')            
          }
        }
      }))

    if(result.payload.payload) {
      onPaymentSubmitCC(values, result.payload.payload)
    }
    else {
      message.error(result.payload.message);
    }
  };

  const onPaymentSubmitCC = async (values, payload) => {
    const formattedData = values.creditCard_expiration.format('MM/YY')
    const result = await dispatch(
      submitPaymentCC({
        token: payload.paymentToken,
        data: {
          shopLogin: 'GESPAY82293',
          buyer: {
            email: newContract.customer.email,
            name: newContract.customer.first_name + ' ' + newContract.customer.last_name
          },
          paymentTypeDetails: {
            creditcard: {
              number: values.creditCard_number,
              expMonth: formattedData.split('/')[0],
              expYear: formattedData.split('/')[1],
              CVV: values.creditCard_cvv,
              DCC: null
            }
          }
        }
      }))

    if(result.payload.payload) {
      const { token, tokenExpiryMonth, tokenExpiryYear } = result.payload.payload
      onFinish({
        token, 
        token_expiration_month: tokenExpiryMonth, 
        token_expiration_year: tokenExpiryYear,
        cc_last_numbers: values?.creditCard_number?.slice(-3),
        cc_method: '08'
      })
    }
    else {
      message.error(result.payload.message);
    }
  };


  
  return (
    <div className="pageContainer">
      <Form 
        form={formPayment}
        name="formPayment"
        layout="vertical" 
        colon={false} 
        onFinish={(values) => paymentMethod === 'rid' ? onFinish(values) : onPaymentCreateCC(values)}
        initialValues={initialValues}
        scrollToFirstError={true}
        validateTrigger="onBlur"
      >      
      {/* <h3 style={{ marginBottom: 16, color: '#0a3e4b' }}>PAGAMENTO</h3> */}
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Form.Item 
            name={"payment_method"}
            label="Tipo sistema di pagamento"
            rules={[
              {
                required: true,
                message: 'Campo obbligatorio'
              },
            ]}
          >
            <Select
              size="large"
              onSelect={(data) => setPaymentMethod(data)}
              options={[
                { label: 'Carta di credito', value: 'cc' },
                { label: 'RID-SDD', value: 'rid' }
              ]}
            />
          </Form.Item>
        </Col>
      </Row>
      {
        paymentMethod === 'cc' &&
        <CreditCardFields locale={locale} /> ||
        <RidFields />
      }
        
        
        <Row justify="center" align="middle">
          <Col>
            <Space 
              align="center"
              size="large"
            >
              <Button 
                type="primary" 
                size="large"
                htmlType="submit"
                disabled={loading === 'createNewContractStatus'}
                loading={loading === 'createNewContractStatus'}
              >
                Salva
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
      <Modal
        title="Sei sicuro?"
        visible={showModal}
        // onOk={handleBackButton}
        onCancel={() => setShowModal(false)}
        footer={[
          <Button 
            key="back" 
            onClick={() => setShowModal(false)}
          >
            Annulla
          </Button>,
          <Button 
            key="submit" 
            type="primary" 
            //onClick={handleBackButton}
          >
            Confermo
          </Button>
        ]}
      >
        <p>Tornando indietro andranno i dati inseriti andranno persi</p>
      </Modal>
    </div>
  )
}

Payment.propTypes = {
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func,
  contractFormat: PropTypes.string
}

export default Payment;