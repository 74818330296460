import React, { 
  useState,
  useEffect
} from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col, 
  Space,
  Form,
  Button,
  message 
} from 'antd';
import { 
  useSelector, 
  useDispatch 
} from 'react-redux';

import { 
  uploadDocument,
  downloadDocument,
  removeDocument,
  confirmCreatingContract
} from 'redux/reducers/contractsReducer';
import DocBox from 'components/DocBox/DocBox';

function Signature({ onSuccess }) {
  const [enableSubmit, setEnableSubmit] = useState(false)
  const { newContract, loading } = useSelector(state => state.contracts);

  const dispatch = useDispatch();

  useEffect(() => {
    const canEnableSubmit = verifyAllDocuments(newContract.uploaded_documentation);
      
    canEnableSubmit && setEnableSubmit(true)
  },[])

  const uploadFile = async (option, id) => {
    const { onSuccess, file, onProgress } = option;
    const result = await dispatch(
      uploadDocument({
        id: id, 
        file: file, 
        onProgress: onProgress
      }))
    //console.log(result)
    if(result.payload.data) {
      onSuccess(result.payload.data)
      const canEnableSubmit = verifyAllDocuments(result.payload.data.uploaded_documentation);
      
      canEnableSubmit && setEnableSubmit(true)
    }
  }

  const downloadAndOpen = async (type,id) => {
    const result = await dispatch(downloadDocument({type, id}))
    window.open(result.payload.url, '_blank')
  }

  const deleteDocument = async (id) => {
    const result = await dispatch(removeDocument(id))
    if(result.status === 200) {
      message.success(`File rimosso con successo.`);
      setEnableSubmit(false)
    }
  }

  const confirmCreate = async (id) => {
    const result = await dispatch(confirmCreatingContract(id))
    if(result.payload === 200) {
      message.success(`Contratto creato con successo.`);
      onSuccess()
    }
  }

  const verifyAllDocuments = (data) => {
    const hasToLoad = data.some(function(el){
      return el.state === 'to_load'
    })
    return !hasToLoad;
  }

  return (
    <div className="stepContainer">
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <h3 style={{marginBottom: 24}}>DOCUMENTI SCARICABILI</h3>
          {
            newContract.generated_documentation.map((doc,i) => (
              <Row gutter={[16, 16]} key={doc.id}>
                <Col span={20}>
                  <DocBox 
                    docId={doc.id}
                    docName={doc.type_name}
                    category="generated"
                    loading={loading}
                    onDownload={downloadAndOpen}
                  />
                </Col>
              </Row>
            ))
          }
        </Col>
        <Col span={12}>
          <h3 style={{marginBottom: 24}}>DOCUMENTI DA CARICARE</h3>
          <Form
            name="formSignature"
          >
            {
              newContract.uploaded_documentation.map((doc,i) => (
                <Row gutter={[16, 16]} key={doc.id}>
                  <Col span={20}>
                    <DocBox 
                      docId={doc.id}
                      docName={doc.type_name}
                      state={doc.state}
                      category="uploaded"
                      loading={loading}
                      onDownload={downloadAndOpen}
                      onRemove={deleteDocument}
                      onUpload={uploadFile}
                    />
                  </Col>
                </Row>
              ))
            }
          </Form>
        </Col>
      </Row>

      <Row justify="center" align="middle">
        <Col>
          <Space 
            align="center"
            size="large"
          >
            <Button 
              type="primary" 
              size="large"
              onClick={confirmCreate}
              disabled={!enableSubmit}
              loading={loading === 'confirmCreatingContractStatus'}
            >
              Conferma e concludi
            </Button>
          </Space>
        </Col>
      </Row>
    </div>
  )
}

Signature.propTypes = {
  onSuccess: PropTypes.func
}

export default Signature;