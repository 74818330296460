import { configureStore } from '@reduxjs/toolkit'

import rootReducer from "./reducers";

// const preloadedState = {
//   auth: {
//     name: '123'
//   }
// }

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),//.concat(logger),
  devTools: process.env.NODE_ENV !== 'production',
  //preloadedState,
  //enhancers: [reduxBatch],
})
export default store;
