import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Tooltip,
  Tag
} from 'antd';

import {
  //CloudDownloadOutlined,
  CheckCircleFilled,
  ClockCircleFilled,
  DeleteOutlined,
  FileDoneOutlined,
  EyeOutlined
} from '@ant-design/icons';

import Uploader from 'components/Uploader/Uploader';

function DocBox({ 
  docId, 
  state, 
  category,
  isReadOnly,
  docName, 
  onDownload, 
  onUpload, 
  onRemove,
  onApprove,
  loading,
  tagColor,
  tagName,
  subtitle
}) {
  //color: '#52c41a',
  return (
    <div className="documentRow">
      <div className="documentTitle">
        {
          state && state === 'to_load' && <ClockCircleFilled style={{ color: 'grey', marginRight: 8, fontSize: 20}} />
        }
        {
          state && state === 'pending' && <FileDoneOutlined style={{ marginRight: 8, fontSize: 20 }} />
        }
        {
          state && state === 'approved' && <CheckCircleFilled style={{ color: '#52c41a', marginRight: 8, fontSize: 20 }} />
        }
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h3>{ docName }</h3>
          {tagName && <Tag color={tagColor} style={{marginLeft: 8}}>{tagName}</Tag>}
          {subtitle && <span style={{ fontStyle: 'italic', fontSize: 13, marginLeft: tagName ? 0 : 8 }}> - {subtitle}</span>}
        </div>
      </div>
      {
        category === 'generated' && (
          <Tooltip title="Visualizza">
            <Button 
              shape="circle"
              icon={<EyeOutlined />} 
              style={{ marginLeft: 8}}
              disabled={loading !== ''}
              onClick={() => onDownload(category,docId)}
              //loading={loading === 'downloadDocumentStatus_'+docId}
            />
          </Tooltip>
        ) || (
          (state === 'pending' || state === 'approved') ? (
            <div className="">
              <Tooltip title="Visualizza">
                <Button 
                  shape="circle"
                  icon={<EyeOutlined />} 
                  //style={{ marginRight: 8}}
                  disabled={loading !== ''}
                  onClick={() => onDownload(category,docId)}
                  loading={loading === 'downloadDocumentStatus_'+docId}
                />
              </Tooltip>
              {
                state !== 'approved' && !isReadOnly && (
                  <Tooltip title="Rimuovi">
                    <Button 
                      shape="circle"
                      icon={<DeleteOutlined />} 
                      style={{ marginLeft: 8}}
                      onClick={() => onRemove(docId)}
                      disabled={loading !== ''}
                      loading={loading === 'removeDocumentStatus_'+docId}
                    />
                  </Tooltip>
                )
              }
              {
                onApprove && state !== 'approved'  && (
                  <Tooltip title="Approva">
                    <Button 
                      shape="circle"
                      icon={<CheckCircleFilled/>} 
                      style={{ marginLeft: 8}}
                      onClick={() => onApprove(docId)}
                      disabled={loading !== ''}
                      loading={loading === 'approveDocumentStatus_'+docId}
                    />
                  </Tooltip>
                )
              }
            </div>
          ) : (
            <>
              {
                onUpload && 
                  <Uploader
                    name="file"
                    loading={loading === 'uploadDocumentStatus_'+docId}
                    customRequest={(props) => onUpload(props,docId)}
                    showUploadList={false}
                  />
              }
            </>
          )
        )
      }
    </div>
  )
}

DocBox.defaultProps = {
  isReadOnly: false
}

DocBox.propTypes = {
  docId: PropTypes.number,
  docName: PropTypes.string,
  category: PropTypes.oneOf(['generated','uploaded']),
  state: PropTypes.string,
  isReadOnly: PropTypes.bool,
  loading: PropTypes.string,
  onApprove: PropTypes.func, 
  onUpload: PropTypes.func,
  onDownload: PropTypes.func,
  onRemove: PropTypes.func,
}

export default DocBox;