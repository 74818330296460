import React, { useState, useEffect } from 'react';
import { 
  Row,
  Col,
  Space,
  Button,
  message,
  Modal
} from 'antd';
import PropTypes from 'prop-types';
import { 
  useDispatch 
} from 'react-redux';

import { 
  approveDocument,
  uploadDocument,
  downloadDocument,
  removeDocument,
  confirmApproveContract
} from 'redux/reducers/contractsReducer';

import DocBox from 'components/DocBox/DocBox';


function Validate({
  contract,
  loading,
  onSubmit
}) {
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [stateConfirm, setStateConfirm] = useState({
    type: '',
    docId: '',
    message: ''
  });
  const [modalConfirm, setModalConfirm] = useState({
    show: false,
    docId: ''
  });
  const dispatch = useDispatch();
  
  useEffect(() => {
    const canEnableSubmit = verifyAllDocuments(contract.uploaded_documentation);
      
    canEnableSubmit && setEnableSubmit(true)
  },[])

  const handleConfirm = async () => {
    //console.log(stateConfirm)
    const actionsMap = {
      'DELETE': deleteDocument,
      'VALIDATE': validateDocument
    }
    actionsMap[stateConfirm.type](stateConfirm.docId);
  }
  
  const verifyAllDocuments = (data) => {
    //if(data) {
      const canILoad = data.some(function(el) {
        return el.state === 'pending' 
      })
      
      return !canILoad;
    //}
  }

  const uploadFile = async (option, id) => {
    const { onSuccess, file, onProgress } = option;
    const result = await dispatch(
      uploadDocument({
        id: id, 
        file: file, 
        onProgress: onProgress
      }))
    //console.log(result)
    if(result.payload.data) {
      onSuccess(result.payload.data)
      const canEnableSubmit = verifyAllDocuments(result.payload.data.uploaded_documentation);
      
      canEnableSubmit && setEnableSubmit(true)
    }
  }
  
  const downloadAndOpen = async (type,id) => {
    const result = await dispatch(downloadDocument({type, id}))
    //console.log(result)
    if(result?.payload?.url) {
      return window.open(result.payload.url, '_blank')
    }
    return message.error('File non trovato')
  }
  
  const deleteDocument = async (id) => {
    const result = await dispatch(removeDocument(id))
    //console.log(result)
    if(result?.payload?.data) {
      setEnableSubmit(false)
      setStateConfirm({ message: '', type: '', docId: ''})
      return message.success(`File rimosso con successo.`);
    }
    return message.error('Problemi con la rimozione del file');
  }
  
  const validateDocument = async (id) => {
    const result = await dispatch(approveDocument(id))
    //console.log(result)
    if(result?.payload?.data) {
      const canEnableSubmit = verifyAllDocuments(result.payload.data.uploaded_documentation);
      canEnableSubmit && setEnableSubmit(true);
      setStateConfirm({ message: '', type: '', docId: ''})
      return message.success(`File approvato con successo.`);
    }
    return message.error('Problemi con l\'approvazione del documento.');
  }
  
  const confirmValidation = async (id) => {
    const result = await dispatch(confirmApproveContract(id))
    // console.log(result)
    if(result.payload.status >= 200 && result.payload.status <= 210) {
      const messageConfirm = result.payload.data.data.state !== 'papers_generated' ? 'Contratto creato con successo.' : 'Il contratto è tornato in gestione al partner.'
      message.success(messageConfirm);
      return onSubmit()
    }
    return message.error('Problemi con la conferma creazione documento.');
  }

  return (
    <div className="pageContainer">
      <h3 style={{marginBottom: 24}}>DOCUMENTI DA APPROVARE</h3>
      {
        contract && contract.uploaded_documentation.map((doc,i) => (
          <Row gutter={[16, 16]} key={doc.id}>
            <Col span={24}>
              <DocBox 
                docId={doc.id}
                docName={doc.type_name}
                state={doc.state}
                category="uploaded"
                loading={loading}
                tagName={doc.state === 'to_load' && 'Rifiutato'}
                tagColor={doc.state === 'to_load' && 'red'}
                onApprove={(id) => setStateConfirm({ type: 'VALIDATE', docId: id, message: 'Confermi l\'approvazione del documento?'})}
                onDownload={downloadAndOpen}
                onRemove={(id) => setStateConfirm({ type: 'DELETE', docId: id, message: 'Confermi la rimozione del documento?'})}
                onUpload={uploadFile}
              />
            </Col>
          </Row>
        ))
      }
      <Row justify="center" align="middle">
        <Col>
          <Space 
            align="center"
            size="large"
          >
            <Button 
              type="primary" 
              size="large"
              onClick={confirmValidation}
              disabled={!enableSubmit}
              loading={loading === 'confirmApproveContractStatus'}
            >
              Conferma
            </Button>
          </Space>
        </Col>
      </Row>
      <Modal
        title="Richiesta conferma"
        visible={stateConfirm.type !== ''}
        // onOk={handleBackButton}
        onCancel={() => setStateConfirm({ message: '', type: ''})}
        footer={[
          <Button 
            key="back" 
            onClick={() => setStateConfirm({ message: '', type: ''})}
          >
            Annulla
          </Button>,
          <Button 
            key="submit" 
            type="primary" 
            onClick={handleConfirm}
          >
            Confermo
          </Button>
        ]}
      >
        <p>{stateConfirm.message}</p>
      </Modal>
    </div>
  )
}

Validate.propTypes = {
  contract: PropTypes.object,
  loading: PropTypes.string,
  onSubmit: PropTypes.func
}

export default Validate;