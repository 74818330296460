import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from "axios";
import queryString from "query-string";
import { BASE_API_URL } from "config/constants";


axios.defaults.withCredentials = true;
axios.defaults.baseURL = BASE_API_URL;

export const getPortedOutList = createAsyncThunk(
  'portedout/getPortedOutListStatus',
  async (data) => {
    try {
      const query = {
        page: data?.page?.current || 1,
        'filter[id]': data?.filters?.id?.data,
        'filter[resource]': data?.filters?.resource?.data,
        'filter[service]': data?.filters?.service?.data,
        'filter[recipient.name]': data?.filters?.['recipient.name']?.data,
        'filter[recipient_code]': data?.filters?.recipient_code?.data,
        'filter[donating_code]': data?.filters?.donating_code?.data,
        'filter[expires_between]': data?.filters?.expires_at?.data,
        'filter[created_between]': data?.filters?.created_at?.data,
        'filter[state]': data?.filters?.state_name?.data?.value,
        'filter[contract.contract_code]': data?.filters?.['contract.contract_code']?.data
      }
      //console.log("BEFORE RESPONSE > > >")
      const response = await axios.get(`/migrations/outbound?${queryString.stringify(query)}`)
      response.data.filters = data.filters
      //console.log("RESPONSE > > >",response)
      return response.data
    } catch (error) {
      console.log("ERROR RESPONSE GET OUTBOUND MIGRATIONS LIST > > >",error)
      //return rejectWithValue(error.response.data)
      return {
        status: error.response.status
      }
    }
  }
)

function isFulfilledAction(action) {
  return action.type.endsWith('/fulfilled')
}

function isPendingAction(action) {
  return action.type.endsWith('/pending')
}

function isRejectedAction(action) {
  return action.type.endsWith('/rejected')
}

const initialState = {
  loading: '',
  error: null,
  // newinvoice: {
  //   city: null,
  //   address: null,
  //   addressNumber: '',
  //   //...mock,
  //   speedValues: {},
  //   activeLine: ''
  // },
  selected: null,
  list: [],
  filters: {},
  portedout_states: []
}


const portedOutSlice = createSlice({
  name: 'portedout',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPortedOutList.fulfilled, (state, action) => {
      if(action.payload) {
        const pagination = action.payload.meta
        state.list = action.payload.data
        state.pagination = {
          pageSize: pagination.per_page,
          current: pagination.current_page,
          total: pagination.total
        }
        state.filters = action.payload.filters
      }
    })
    builder.addMatcher(isFulfilledAction, (state, action) => {
      state.loading = ''
      state.error = false
    })
    builder.addMatcher(isPendingAction, (state, action) => {
      const id = typeof action.meta.arg === 'object' ? action.meta.arg.id : action.meta.arg
      state.loading = action.type.split('/')[1] + (id ? '_' +id : '')
      state.error = false
    })
    builder.addMatcher(isRejectedAction, (state, action) => {
      state.loading = ''
      state.error = action.error
    })
  }
})


const { 
  //actions, 
  reducer 
} = portedOutSlice

// export const { 
// } = actions

export default reducer