import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
//import { getUser, submitLogin } from '../../api/authAPI'
import axios from "axios";
import { BASE_API_URL } from "config/constants";
import { userRoles } from 'config/definitions';
axios.defaults.withCredentials = true;
axios.defaults.baseURL = BASE_API_URL;

export const fetchUserAuthenticated = createAsyncThunk(
  'auth/fetchByAuthenticatedStatus',
  async (userId, { rejectWithValue }) => {
    //console.log('fetch USER ACTION')
    try{
      const response = await axios.get('crm/info')//await authAPI.fetchById(userId)
      //console.log('fetchUser >' , response)
      return response.data
    } catch(err) {
      console.log('ERROR > >',err.response.status)
      return rejectWithValue(err.response.data)
    }
  }
)

export const verifyCookie = createAsyncThunk(
  'auth/verifyCookieStatus',
  async () => {
    try {
      const result = await axios.get('sanctum/csrf-cookie')
      return result.status
    } catch (error) {
      console.log('error verifyCookie > ',error.response)
      return error.response.status
    }
  }
)

export const signInUser = createAsyncThunk(
  'auth/signInUserStatus',
  async (user, {dispatch,rejectWithValue}) => {
    try {
      const result = await dispatch(verifyCookie())
      //console.log('result', result)
      if(result.payload === 204) {
        const response = await axios.post('login', {
          email: user.username,
          password: user.password
        })

        return response.data
      }
      else {
        rejectWithValue({
          status: result.payload,
          message: 'Accesso non autorizzato'
        })
      }
      
    } catch (error) {
      return rejectWithValue({
        status: error.response.status,
        message: error.response.data.errors
      })
    }
  }
)

export const logOutUser = createAsyncThunk(
  'auth/logOutStatus',
  async (user, thunkAPI) => {
    const response = await axios.post('logout')
    return response.data
  }
)

export const getParametersStates = createAsyncThunk(
  'auth/getParametersStatesStatus',
  async () => {
    try {
      const response = await axios.get(`/parameters/states`)
      return response.data
    } catch (error) {
      console.log('errore nel ricevere gli stati',error)
    }
  }
)

function isPendingAction(action) {
  return action.type.endsWith('/pending')
}

function isRejectedAction(action) {
  return action.type.endsWith('/rejected')
}

const authRecuder = createSlice({
  name: 'auth',
  initialState: {
    loading: false,
    error: null,
    user: null,
    userRole: null
  },
  reducers: {
    setUserRole: (state, action) => {
      state.userRole = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserAuthenticated.fulfilled, (state, action) => {
      //console.log('fetchUserAuthenticated fulfilled')
      state.loading = false
      state.error = false
      state.user = {
        ...action.payload.data,
        isAuthenticated: true
      }
      state.userRole =  action.payload.data.level.toUpperCase();
    })
    builder.addCase(fetchUserAuthenticated.rejected, (state, action) => {
      //console.log('fetchUserAuthenticated rejected')
      state.loading = false
      state.error = false
      state.user = {
        isAuthenticated: false
      }
    })
    builder.addCase(signInUser.fulfilled, (state, action) => {
      if(action.payload) {
        state.loading = false
        state.error = false
        state.user = {
          ...action.payload.data,
          isAuthenticated: true
        }
      }
    })
    builder.addCase(logOutUser.fulfilled, (state, action) => {
      state.loading = false
      state.error = false
      state.user = {
        isAuthenticated: false
      }
    })
    builder.addCase(getParametersStates.fulfilled, (state,action) => {
      if(action.payload) {
        state.states = action.payload.data
      }
    })
    builder.addMatcher(isPendingAction, (state, action) => {
      state.loading = true
      state.error = false
    })
    builder.addMatcher(isRejectedAction, (state, action) => {
      //console.log('isRejectedAction > ',action)
      state.loading = false
      state.error = action.error
    })
    // builder.addCase(fetchUserAuthenticated.pending, (state, action) => {
    //   state.loading = true
    //   state.error = false
    // })
    // builder.addCase(fetchUserAuthenticated.rejected, (state, action) => {
    //   state.loading = false
    //   state.error = action.error
    // })
  }
})

const { reducer } = authRecuder

// export const { 
//   fetchUserAuthenticated
// } = actions

export default reducer