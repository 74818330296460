import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios";
import queryString from "query-string";
import { BASE_API_URL } from "config/constants";

axios.defaults.withCredentials = true;
axios.defaults.baseURL = BASE_API_URL;


export const getOdlsList = createAsyncThunk(
  'odls/getOdlsListStatus',
  async (data) => {
    try {
      const query = {
        page: data?.page?.current || 1,
        'filter[code]': data?.filters?.code?.data,
        'filter[customer.first_name]': data?.filters?.['customer.first_name']?.data,
        'filter[customer.last_name]': data?.filters?.['customer.last_name']?.data,
        'filter[coverage.city]': data?.filters?.['coverage.city']?.data,
        'filter[created_between]': data?.filters?.created_at?.data,
        'filter[state]': data?.filters?.state_name?.data.value
      }
      //console.log("BEFORE RESPONSE > > >",queryString.stringify(query), data, query)
      const response = await axios.get(`/work-orders?${queryString.stringify(query)}`)
      response.data.filters = data.filters
      return response.data
    } catch (error) {
      console.log("ERROR RESPONSE > > >",error.response)
      //return rejectWithValue(error.response.data)
      return {
        status: error.response.status
      }
    }
  }
)

export const downloadDocumentOdls = createAsyncThunk(
  'contracts/downloadDocumentOdlsDocumentStatus',
  async ({odlId}) => {
    //const contractId = getState().odls.selected.id
    const response = await axios.get(`/work-orders/${odlId}/download/generated`)
    //console.log(response.data.data)
    return response.data.data
  }
)


function isFulfilledAction(action) {
  return action.type.endsWith('/fulfilled')
}

function isPendingAction(action) {
  return action.type.endsWith('/pending')
}

function isRejectedAction(action) {
  return action.type.endsWith('/rejected')
}


const initialState = {
  loading: '',
  error: null,
  selected: null,
  list: []
}


const odlsSlice = createSlice({
  name: 'odls',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOdlsList.fulfilled, (state, action) => {
      if(action.payload) {
        const pagination = action.payload.meta
        state.list = action.payload.data
        state.pagination = {
          pageSize: pagination.per_page,
          current: pagination.current_page,
          total: pagination.total
        }
        state.filters = action.payload.filters
      }
    })
    builder.addMatcher(isFulfilledAction, (state, action) => {
      state.loading = ''
      state.error = false
    })
    builder.addMatcher(isPendingAction, (state, action) => {
      const id = typeof action.meta.arg === 'object' ? action.meta.arg.id : action.meta.arg
      state.loading = action.type.split('/')[1] + (id ? '_' +id : '')
      state.error = false
    })
    builder.addMatcher(isRejectedAction, (state, action) => {
      state.loading = ''
      state.error = action.error
    })
  }
})

const { reducer } = odlsSlice


export default reducer