import React from 'react';
import {
  Spin
} from 'antd';



function LoadingFullwidth() {
  return (
    <div className="loadingFullwidthPage">
      <Spin size="large"/>
    </div>
  )
}

export default LoadingFullwidth