import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Space,
  message,
  DatePicker,
  Switch,
} from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  submitChangeStartBillingDate,
  submitLinkemContractCode,
} from "redux/reducers/contractsReducer";
import moment from "moment";
import locale from "antd/es/date-picker/locale/it_IT";

const InsertLinkemContractCode = ({
  loading,
  onSuccess,
  onCancel,
  initialValues,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [enabled, setEnabled] = useState(initialValues.startDate._isValid);

  const onFinish = async (values) => {
    const result = await dispatch(
      submitChangeStartBillingDate({
        date: typeof values.enabled != 'undefined' && values.enabled === false ? null : values.startDate,
      })
    );

    if (result.payload.status !== 422 && result.payload.data) {
      message.success("Richiesta di recesso inoltrata con successo!");
      onSuccess();
    } else {
      message.error(`Errore nella compilazione del modulo`);
    }
  };

  return (
    <Form
      style={{
        width: 400,
        margin: "16px auto",
      }}
      form={form}
      name="formChangeBillingStartDate"
      layout="vertical"
      colon={false}
      onFinish={onFinish}
      initialValues={{ ...initialValues, startDate: !initialValues.startDate._isValid ? undefined : initialValues.startDate }}
      //scrollToFirstError={true}
      validateTrigger="onBlur"
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Form.Item
            name="startDate"
            label="Data inizio fatturazione"
            rules={enabled && [
              {
                required: true,
                message: "Campo obbligatorio",
              },
            ]}
          >
            <DatePicker
              size="large"
              style={{ width: "100%" }}
              locale={locale}
              format={"DD/MM/YYYY"}
              disabled={!enabled}
              // disabledDate={(current) => current > moment()}
              placeholder={"DD/MM/YYYY"}
            />
          </Form.Item>
          <Form.Item
            name="enabled"
            label="Rendi disponibile nel contratto"
            
            style={{ flexDirection: "row" }}
          >
            <Switch defaultChecked={enabled} style={{ marginLeft: 10, float: "right" }} onChange={setEnabled} />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="center" align="middle">
        <Col>
          <Space align="center" size="large">
            <Button
              //type="primary"
              //htmlType="submit"
              size="large"
              onClick={onCancel}
              disabled={loading === "submitUploadWithdrawalStatus"}
              //loading={loading === 'confirmApproveContractStatus'}
            >
              Annulla
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              //onClick={confirmValidation}
              disabled={loading === "submitUploadWithdrawalStatus"}
              loading={loading === "submitUploadWithdrawalStatus"}
            >
              Conferma
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
};

export default InsertLinkemContractCode;
