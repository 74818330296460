import React from 'react';
import moment from 'moment';
//import PropTypes from 'prop-types';
import { 
  useSelector,
  //useDispatch 
} from 'react-redux';
import { 
  Link, 
  useLocation 
} from "react-router-dom";
import { 
  Tag,
  PageHeader,
} from 'antd';
import Table from 'views/Tickets/TicketsList/_Table';

import { 
  getLeadsList
} from 'redux/reducers/leadsReducer';

function LeadsList() {

  const { list, pagination, filters, loading } = useSelector(state => state.leads);
  // const dispatch = useDispatch()
  // let history = useHistory();

  //const params = new URLSearchParams(useLocation().search)
  const states = useLocation().pathname.split('/');
  states.shift();
  const stateFilter = states[1] || '';
  // console.log({stateFilter})
  
  const columns = [
    {
      title: 'Categoria',
      dataIndex: 'type_name',
      render: (value,row) => <Link to={`/leads/${row.id}`}>{value}</Link>,
      search: true,
      filterType: 'radio',
      filterOptions: [
        { text: 'Richiamami', value: 'call_me' },
        { text: 'Copertura OK', value: 'coverage_ok' },
        { text: 'Copertura KO', value: 'coverage_ko' },
        { text: 'Indirizzo non trovato', value: 'address_not_found' },
        { text: 'Acquisto interrotto', value: 'subscribing' },
        { text: 'Linea attiva', value: 'active_line' },
        { text: 'Mktg sul web', value: 'from_marketing' }
      ]
    },
    {
      title: 'Origine',
      dataIndex: 'lead_origin_name',
      search: true,
      filterType: 'radio',
      filterOptions: [
        { text: 'Sito web', value: 'website' },
        { text: 'Pagina landing', value: 'landing' },
        { text: 'Facebook', value: 'facebook' },
        { text: 'Google', value: 'google' },
        { text: 'Partner', value: 'partner' },
        { text: 'Customer care', value: 'customer_care' }
      ]
    },
    {
      title: 'Stato',
      dataIndex: 'state_name',
      search: true,
      filterType: 'radio',
      filterOptions: [
        { text: 'Qualificato', value: 'qualified' },
        { text: 'Non qualificato', value: 'not_qualified' },
        { text: 'Aperto', value: 'open' }
      ]
    },
    {
      title: 'Nome',
      dataIndex: 'first_name',
      search: true
    },
    {
      title: 'Cognome',
      dataIndex: 'last_name',
      search: true
    },
    {
      title: 'Telefono',
      dataIndex: 'phone_number',
      search: true
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      search: true
    },
    {
      title: 'Non risponde',
      dataIndex: 'doesnt_answer',
      search: true,
      filterType: 'radio',
      filterOptions: [
        {
          text: '0',
          value: '0'
        },
        {
          text: '1',
          value: '1'
        },
        {
          text: '2',
          value: '2'
        },
        {
          text: '3',
          value: '3',
        },
        {
          text: '4',
          value: '4'
        }
      ]
    },
    {
      title: 'Appuntamenti',
      dataIndex: 'appointment'
    },
    {
      title: 'Note',
      dataIndex: 'notes'
    },
    {
      title: 'Modificato da',
      dataIndex: ['managed_by','fullname']
    },
    {
      title: 'Ultima modifica',
      dataIndex: 'updated_at',
      render: value => moment(value).format('D MMM YYYY HH:mm')
    },
    // {
    //   title: 'Data acquisizione',
    //   dataIndex: 'active_at',
    //   render: value => moment(value).format('D MMM YYYY HH:mm'),
    //   search: true,
    //   filterType: 'dateRange'
    // }
  ];


  if(stateFilter === 'lavorate') {
    columns.splice(3,0,{
      title: 'Motivazione',
      dataIndex: 'not_qualified_motivation_name',
      search: true
    })
  }
  else if(stateFilter === 'appuntamenti') {
    columns.splice(2,1);
    columns.splice(5,1);
    columns.splice(7,0,{
      title: 'Data appuntamento',
      dataIndex: 'appointment_at',
      render: value => value ? moment(value).format('D MMM YYYY HH:mm') : '---',
      search: true,
      filterType: 'dateRange'
    })
  }
  

  return (
    <div className="main-container">
      <PageHeader 
        title="Elenco leads" 
        style={{ paddingBottom: 0 }}
        tags={stateFilter && <Tag color="blue" size="large" >{stateFilter.replace('-',' ')}</Tag>}
      ></PageHeader>
      <Table
        columns={columns}
        dataSource={list}
        pagination={pagination}
        filters={filters}
        loading={loading}
        size="small"
        getList={(data) => getLeadsList({data, stateFilter})}
      />
    </div>
  )
}

// ContractsList.PropTypes = {
  
// }

export default LeadsList;