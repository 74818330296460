import { Button, Col, Form, Input, Row, Space, message, DatePicker, TimePicker, Select } from "antd";
import locale from 'antd/es/date-picker/locale/it_IT';
import moment from "moment";
import business from 'moment-business';
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { submitLinkemContractCode, submitRescheduleAppointment } from "redux/reducers/contractsReducer";

const RescheduleAppointment = ({ loading, onSuccess, onCancel, initialValues }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [workflowSelection, setWorkflowSelection] = useState('know');

  const onFinish = async (values) => {
    let result;

    if(workflowSelection === 'know') {
      result = await dispatch(
        submitRescheduleAppointment({
          appointment_date: moment(values.date).hours(moment(values.time).get('hours')).minutes(moment(values.time).get('minutes')).format('YYYY-MM-DD HH:mm'),
          notes: values.notes,
          from_client: values.customerRequest == 'SI' && 1 || 0
        })
      );
    } else {
      result = await dispatch(
        submitRescheduleAppointment({
          appointment_date: business.addWeekDays(moment(), 10).format('YYYY-MM-DD 00:00'),
          from_client: 0
        })
      );
    }

    if (result.payload.status !== 422 && result.payload.data) {
      message.success("Operazione inoltrata con successo!");
      onSuccess();
    } else {
      //console.log(result)
      message.error(`Errore nella compilazione del modulo`);
    }
  };

  return (
    <Form
      style={{
        width: 400,
        margin: "16px auto",
      }}
      form={form}
      name="formRescheduleAppointment"
      layout="vertical"
      colon={false}
      onFinish={onFinish}
      initialValues={initialValues}
      //scrollToFirstError={true}
      validateTrigger="onBlur"
    >
      <Row gutter={[16, 16]}>
      <Col span={24}>
          <Form.Item 
            name="workflow"
            label="Workflow"
            initialValue="know"
            rules={[
              {
                required: true,
                message: 'Campo obbligatorio'
              },
            ]}
          >
            <Select 
              size="large"
              // defaultValue="know"
              onSelect={setWorkflowSelection}
            >
              <Select.Option value="know">Il cliente sa indicare una data precisa e realistica</Select.Option>
              <Select.Option value="carrier-recall">Il cliente deve essere ricontattato dal carrier</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        {
          workflowSelection === 'know' && (
            <>
            <Col span={24}>
            <Form.Item
              name="date"
              label="Data"
              rules={[
                {
                  required: true,
                  message: "Campo obbligatorio",
                },
              ]}
            >
               <DatePicker
                size="large"
                style={{width: '100%'}}
                locale={locale}
                format={'DD/MM/YYYY'}
                disabledDate={(current) => current < moment()}
                placeholder={'DD/MM/YYYY'}
              />
            </Form.Item>
            </Col>
            <Col span={24}>
            <Form.Item
              name="time"
              label="Ora"
              rules={[
                {
                  required: true,
                  message: "Campo obbligatorio",
                },
              ]}
            >
              <TimePicker 
              size="large"
              style={{width: '100%'}}
              locale={locale}
              format="HH:mm"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="notes"
              label="Note"
              rules={[
                {
                  required: true,
                  message: 'Campo obbligatorio'
                },
              ]}
            >
              <Input 
                size="large"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item 
              name="customerRequest"
              label="Richiesto da cliente"
              initialValue="SI"
              rules={[
                {
                  required: true,
                  message: 'Campo obbligatorio'
                },
              ]}
            >
              <Select 
                size="large"
              >
                <Select.Option value="SI">Si</Select.Option>
                <Select.Option value="NO">No</Select.Option>
              </Select>
            </Form.Item>
          </Col>
       </>
          )
        }
        </Row>
        <Row justify="center" align="middle">
          <Col>
            <Space align="center" size="large">
              <Button
                //type="primary"
                //htmlType="submit"
                size="large"
                onClick={onCancel}
                disabled={loading === "submitUploadWithdrawalStatus"}
                //loading={loading === 'confirmApproveContractStatus'}
              >
                Annulla
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                //onClick={confirmValidation}
                disabled={loading === "submitUploadWithdrawalStatus"}
                loading={loading === "submitUploadWithdrawalStatus"}
              >
                Conferma
              </Button>
            </Space>
          </Col>
      </Row>
    </Form>
  );
};

export default RescheduleAppointment;
