import React from 'react';
import moment from 'moment';
//import PropTypes from 'prop-types';
import { 
  useSelector,
  useDispatch 
} from 'react-redux';
import { Link } from "react-router-dom";
import { 
  //Menu,
  //Dropdown,
  PageHeader,
  Space,
  message
  //Button
} from 'antd';
//import { DownOutlined } from '@ant-design/icons';
import Table from 'views/Tickets/TicketsList/_Table';
import './OdlsList.css';

import { 
  getOdlsList,
  downloadDocumentOdls
} from 'redux/reducers/odlsReducer';

function OdlsList() {
  
  const { list, pagination, filters, loading } = useSelector(state => state.odls);
  const { userRole, states } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  console.log({states})
  const columns = [
    {
      title: 'Cod.',
      dataIndex: 'code',
      search: true,
      //sorter: true,
      //render: (value,row) => <Link to={`/contratti/${row.id}`}>{value}</Link>,
      //width: '20%',
    },
    // {
    //   title: 'Canale',
    //   dataIndex: 'channel_type',
    //   hideForUsers: ['PARTNER']
    // },
    // {
    //   title: 'BP',
    //   dataIndex: 'bp_code',
    //   search: true,
    //   //render: value => value || '---',
    //   hideForUsers: ['PARTNER']
    // },
    {
      title: 'Nome',
      dataIndex: ['customer','first_name'],
      search: true
    },
    {
      title: 'Cognome',
      dataIndex: ['customer','last_name'],
      search: true
    },
    {
      title: 'Indirizzo',
      dataIndex: ['contract','coverage','street_name'],
      render: (value,row) => `${row.contract?.coverage?.toponymic_particle} ${row.contract?.coverage?.street_name}, ${row.contract?.coverage?.address_number}`,
      //search: true
    },
    {
      title: 'Città',
      dataIndex: ['contract','coverage','city'],
      search: true
    },
    {
      title: 'Offerta',
      dataIndex: ['contract','commercial_offer','name'],
    },
    // {
    //   title: 'Connessione',
    //   dataIndex: ['connection_type','connection_type_key'],
    //   hideForUsers: ['PARTNER']
    // },
    {
      title: 'Sottoscrizione',
      dataIndex: 'created_at',
      render: value => value ? moment(value).format('D MMM YYYY') : '---',
      search: true,
      filterType: 'dateRange'
    },
    {
      title: 'Attivazione',
      dataIndex: ['contract','activated_at'],
      render: value => value ? moment(value).format('D MMM YYYY') : '---',
      search: true,
      filterType: 'dateRange',
    },
    {
      title: 'Stato odl',
      dataIndex: 'state_name',
      search: true,
      filterType: 'radio',
      filterOptions: states?.work_orders
    },
    {
      title: 'Azioni',
      key: 'action',
      render: (value, row) => (
        <Space size="middle">
          <a onClick={() => downloadAndOpen(row.id)}>Apri</a>
        </Space>
      ),
    },
  ];
  
  const downloadAndOpen = async (id) => {
    const result = await dispatch(downloadDocumentOdls({odlId:id}))
    if(result.payload) {
      window.open(result.payload.url, '_blank')
    }
    else {
      message.error('Errore nello scaricare il file')
    }
  }

  return (
    <div className="main-container">
      <PageHeader
        title="Elenco odl"
        style={{ paddingBottom: 0 }}
        // extra={[
        //   <Dropdown overlay={menu} key="crea-contratto-button">
        //     <Button 
        //       type="primary" 
        //       //size="large"
        //     >
        //       Crea contratto <DownOutlined />
        //     </Button>
        //   </Dropdown>
        // ]}
      >
      </PageHeader>
      <Table
        columns={columns.filter(col => !col.hideForUsers?.includes(userRole))}
        dataSource={list}
        pagination={pagination}
        filters={filters}
        loading={loading}
        getList={(data) => getOdlsList(data)}
      />
    </div>
  )
}

// OdlsList.PropTypes = {
  
// }

export default OdlsList;