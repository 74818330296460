import React from 'react';
import moment from 'moment';
import { 
  Tag,
  Tabs,
  Collapse
} from 'antd';

import TitleNote from 'components/TitleNote/TitleNote';
import { DescriptionsContainer, Item } from 'components/Descriptions/Description';

function TicketsDetails({
  data,
  loading,
  goTo,
}) {


  return (
    <div className="main-containerOLD">
      {
        loading === 'getTicketDetailsStatus' 
        ? 'Loading' : 
        <>
          <Tabs type="card" defaultActiveKey="1" >
            <Tabs.TabPane tab="Ticket" key="1">
              <DescriptionsContainer>
                <Item label="Stato" value={<Tag color="blue">{data?.state_name}</Tag>} />
                <Item label="Ticket ID" value={data?.ticket_code} />
                <Item label="Tipologia" value={data?.support_cat_first?.name }/>
                <Item label="Famiglia" value={data?.support_cat_second?.name }/>
                <Item label="Sottofamiglia" value={data?.support_cat_third?.name }/>
                <Item label="Creato da" value={data?.creator_type } />
                <Item label="Creato il" value={data?.created_at && moment(data?.created_at).format('D MMM YYYY') || '---'} />
                <Item label="Solleciti" value={data?.reminders }/>
                <Item label="Motivazione chiusura" value={data.support_ticket_motivation?.name || '---'} />
              </DescriptionsContainer>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Contratto" key="2">
              <DescriptionsContainer>
                <Item label="Contratto ID" value={data?.contract?.contract_code && <a onClick={() => goTo(`/contratti/${data?.contract_id}`)}>{data?.contract?.contract_code}</a>} />
                <Item label="Stato contratto" value={data?.contract?.state_name && <Tag color="blue">{data?.contract?.state_name}</Tag>}/>
              </DescriptionsContainer>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Cliente" key="3">
              <DescriptionsContainer>
                <Item label="Origine" value={data?.origin_name} />
                <Item label="Cliente ID" value={data?.customer?.customer_code && <a onClick={() => goTo(`/clienti/${data?.customer_id}`)}>{data?.customer?.customer_code}</a>}/>                
                <Item label="Nome cliente" value={data?.customer?.first_name }/>
                <Item label="Cognome cliente" value={data?.customer?.last_name }/>
                <Item label="Moroso" value={data?.customer?.defaulting ? 'Si' : 'No'}/>
                <Item label="Vip" value={data?.customer?.vip ? 'Si' : 'No'}/>
                <Item label="Verifica anagrafica" value={data?.ticket?.strong_identity ? 'Si' : 'No'}/>
              </DescriptionsContainer>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Note" key="4">
              <Collapse
                defaultActiveKey={data?.updates?.reduce((acc, item) => acc.concat(item.id),[])} 
              >
                {
                  data?.updates?.map((note,i) => (
                    <Collapse.Panel 
                      key={note.id} 
                      header={<TitleNote note={note} />}
                      // header={note?.creator && (`${note.creator?.first_name} ${note.creator?.last_name} - [${note.creator_type}]`) || 'Anonimo'} 
                      //extra={<Tag color="blue">{note?.state_name}</Tag>}
                    >
                      <p>{note?.note}</p>
                      <div style={{textAlign: 'right'}}>
                        <small>{note?.created_at && moment(note?.created_at).format('D MMM YYYY, HH:mm')}</small>
                      </div>
                    </Collapse.Panel>
                  ))
                }
              </Collapse>
            </Tabs.TabPane>
          </Tabs>
        </>
      }
    </div>
  )
}


export default TicketsDetails;