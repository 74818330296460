import React, { useEffect } from 'react';
//import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from "react-router-dom";
import { 
  useSelector 
} from 'react-redux';
import { 
  PageHeader,
  Space
} from 'antd';
import Table from 'views/Tickets/TicketsList/_Table';

import { 
  getContractsCreatingList
} from 'redux/reducers/contractsReducer';

function ContractsListCreating() {
  
  const { list, pagination, filters, loading } = useSelector(state => state.contracts);
  const { userRole } = useSelector(state => state.auth);

  const columns = [
    {
      title: 'BP',
      dataIndex: ['creator', 'bp_code'],
      search: true,
      render: (value, row) => value || '---',
      hideForUsers: ['PARTNER']
    },
    {
      title: 'Nome',
      dataIndex: ['customer','first_name'],
      search: true
    },
    {
      title: 'Cognome',
      dataIndex: ['customer','last_name'],
      search: true
    },
    {
      title: 'Tipologia',
      dataIndex: 'type_name'
    },
    {
      title: 'Offerta',
      dataIndex: ['commercial_offer','name'],
    },
    {
      title: 'Indirizzo',
      dataIndex: ['coverage','street_name'],
      render: (value,row) => `${row.coverage?.toponymic_particle} ${row.coverage?.street_name}, ${row.coverage?.address_number}`,
      search: true
    },
    {
      title: 'Città',
      dataIndex: ['coverage','city'],
      search: true
    },
    {
      title: 'Carrier',
      dataIndex: ['connection_type', 'carrier'],
      hideForUsers: ['PARTNER']
    },
    {
      title: 'Velocità',
      dataIndex: ['connection_type', 'speed']
    },
    {
      title: 'Zona',
      dataIndex: ['connection_type','zone'],
      hideForUsers: ['PARTNER'],
      search: true,
      filterType: 'radio',
      filterOptions: [{text: 'A&B', value: 'A&B' }, {text: 'C&D', value: 'C&D' }]
    },
    {
      title: 'Data sottoscrizione',
      dataIndex: 'created_at',
      render: value => value ? moment(value).format('D MMM YYYY HH:mm') : '---'
    },
    {
      title: 'Canale',
      dataIndex: 'channel_type',
      hideForUsers: ['PARTNER']
    },
    {
      title: 'Stato',
      dataIndex: 'state_name'
    },
    {
      title: 'Azioni',
      key: 'action',
      render: (value, row) => (
        <Space size="middle">
          <Link to={`/contratti/in-acquisizione/${row.id}`}>Apri</Link>
        </Space>
      ),
    },
  ];



  return (
    <div className="main-container">
      <PageHeader
        //className="site-page-header-responsive"
        //onBack={() => window.history.back()}
        title="Contratti in fase di caricamento"
        //subTitle="This is a subtitle"
        // extra={[
        //   <Dropdown overlay={menu} key="crea-contratto-button">
        //     <Button 
        //       type="primary" 
        //       //size="large"
        //     >
        //       Crea contratto <DownOutlined />
        //     </Button>
        //   </Dropdown>
        // ]}
      >
        {/* <Content extra={extraContent}>{renderContent()}</Content> */}
      </PageHeader>
      <Table
        //columns={columns}
        columns={columns.filter(col => !col.hideForUsers?.includes(userRole))}
        dataSource={list}
        pagination={pagination}
        filters={filters}
        loading={loading}
        getList={(data) => getContractsCreatingList(data)}
      />
      {/* <Table
        columns={columns}
        rowKey={record => record.id}
        dataSource={list}
        //pagination={pagination}
        loading={loading !== ''}
        locale={{
          emptyText: 'Nessun dato'
        }}
        pagination={pagination}
        onChange={(pagination) => updateList(pagination)}
      /> */}
    </div>
  )
}

// ContractsListCreating.PropTypes = {
  
// }

export default ContractsListCreating;