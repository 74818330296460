import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from "axios";
import queryString from "query-string";
import { BASE_API_URL } from "config/constants";


axios.defaults.withCredentials = true;
axios.defaults.baseURL = BASE_API_URL;

export const getInvoicesList = createAsyncThunk(
  'invoices/getInvoicesListStatus',
  async (data) => {
    try {
      const query = {
        page: data?.page?.current || 1,
        'filter[invoice_proof]': data?.filters?.invoice_proof?.data,
        'filter[document_date_between]': data?.filters?.document_date?.data,
        'filter[expires_at_between]': data?.filters?.expires_at?.data,
        'filter[customer.first_name]': data?.filters?.['customer.first_name']?.data,
        'filter[customer.last_name]': data?.filters?.['customer.last_name']?.data,
        'filter[contract.contract_code]': data?.filters?.['contract.contract_code']?.data,
        'filter[result]': data?.filters?.result?.data.value,
        'filter[payable_from_ca]': data?.filters?.payable_from_ca?.data.value,
        'filter[document_type]': data?.filters?.document_type?.data.value
      }
      //console.log("BEFORE RESPONSE > > >")
      const response = await axios.get(`/invoices?${queryString.stringify(query)}`)
      response.data.filters = data.filters
      //console.log("RESPONSE > > >",response)
      return response.data
    } catch (error) {
      console.log("ERROR RESPONSE GET INVOICE LIST > > >",error)
      //return rejectWithValue(error.response.data)
      return {
        status: error.response.status
      }
    }
  }
)

function isFulfilledAction(action) {
  return action.type.endsWith('/fulfilled')
}

function isPendingAction(action) {
  return action.type.endsWith('/pending')
}

function isRejectedAction(action) {
  return action.type.endsWith('/rejected')
}

const initialState = {
  loading: '',
  error: null,
  // newinvoice: {
  //   city: null,
  //   address: null,
  //   addressNumber: '',
  //   //...mock,
  //   speedValues: {},
  //   activeLine: ''
  // },
  selected: null,
  list: [],
  filters: {},
  invoice_states: []
}


const invoicesSlice = createSlice({
  name: 'invoices',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getInvoicesList.fulfilled, (state, action) => {
      if(action.payload) {
        const pagination = action.payload.meta
        state.list = action.payload.data
        state.pagination = {
          pageSize: pagination.per_page,
          current: pagination.current_page,
          total: pagination.total
        }
        state.filters = action.payload.filters
      }
    })
    builder.addMatcher(isFulfilledAction, (state, action) => {
      state.loading = ''
      state.error = false
    })
    builder.addMatcher(isPendingAction, (state, action) => {
      const id = typeof action.meta.arg === 'object' ? action.meta.arg.id : action.meta.arg
      state.loading = action.type.split('/')[1] + (id ? '_' +id : '')
      state.error = false
    })
    builder.addMatcher(isRejectedAction, (state, action) => {
      state.loading = ''
      state.error = action.error
    })
  }
})


const { 
  //actions, 
  reducer 
} = invoicesSlice

// export const { 
// } = actions

export default reducer