import React from 'react';

import {
  Input,
  Button,
  Space
} from 'antd';

import { SearchOutlined } from '@ant-design/icons';

const InputFilter = React.forwardRef((
  {
    dataIndex,
    placeholder,
    title,
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    handleSearch,
    handleReset
  },ref) => {
  
  return (
    <div style={{ padding: 8 }}>
      <Input
        ref={ref}
        placeholder={placeholder}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex, title)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex, title)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Filtra
        </Button>
        <Button 
          onClick={() => handleReset(clearFilters,dataIndex)} 
          size="small" 
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </Space>
    </div>
  )
})

export default InputFilter