import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Steps,
  Layout
} from 'antd';

import TicketDraft from './_Draft/TicketDraft';
import TicketConcusion from './_Conclusion/TicketConclusion';

function TicketsNew({ onSuccess }) {
  const { Step } = Steps;
  const [currentStep, setCurrentStep] = useState(0)

  const steps = [
    {
      title: 'Crea',
      content: (
        <TicketDraft 
          onSuccessDraft={onSuccess}
          onSuccess={() => next()} 
          onCancel={() => prev()} />
      ),
    },
    {
      title: 'Concludi',
      content: (
        <TicketConcusion 
          onSuccess={onSuccess} 
          onCancel={() => prev()} />
      )
    }
  ];


  const next = () => {
    setCurrentStep(currentStep + 1);
  };

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  
  return(
    <div className="pageContainer" style={{height: '100%'}}>
      <Steps current={currentStep}>
        {steps.map(item => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <Layout style={{ backgroundColor: 'white', height: '100%' }}>
        {steps[currentStep].content} 
      </Layout>
    </div>
  )
}

TicketsNew.propTypes = {
  onSuccess: PropTypes.func,
  onSuccessDraft: PropTypes.func
}

export default TicketsNew;