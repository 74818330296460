import React from 'react';
import moment from 'moment';
//import PropTypes from 'prop-types';
import { 
  useSelector,
} from 'react-redux';
import { Link } from "react-router-dom";
import { 
  PageHeader
} from 'antd';
import Table from 'views/Tickets/TicketsList/_Table';
import './ContractsList.css';

import { 
  getContractsListPending
} from 'redux/reducers/contractsReducer';

function ContractsList() {
  
  const { list, pagination, filters, loading } = useSelector(state => state.contracts);
  
  const columns = [
    {
      title: 'Cod. contratto',
      dataIndex: ['object','contract_code'],
      search: true,
      //sorter: true,
      render: (value,row) => <Link to={`/contratti/${row.object?.id}`}>{value}</Link>,
      //width: '20%',
    },
    {
      title: 'Canale',
      dataIndex: ['object','channel_type']
    },
    {
      title: 'BP',
      dataIndex: ['object','bp_code'],
      search: true,
      render: value => value || '---',
      hideForUsers: ['PARTNER']
    },
    {
      title: 'Nome',
      dataIndex: ['object','customer','first_name'],
      search: true
    },
    {
      title: 'Cognome',
      dataIndex: ['object','customer','last_name'],
      search: true
    },
    {
      title: 'Indirizzo',
      dataIndex: ['object','coverage','street_name'],
      render: (value,row) => `${row.object?.coverage?.toponymic_particle} ${row.object?.coverage?.street_name}, ${row.object?.coverage?.address_number}`,
      search: true
    },
    {
      title: 'Città',
      dataIndex: ['object','coverage','city'],
      search: true
    },
    {
      title: 'Offerta',
      dataIndex: ['object','commercial_offer','name'],
    },
    {
      title: 'Carrier',
      dataIndex: ['object', 'carrier'],
      hideForUsers: ['PARTNER']
    },
    {
      title: 'Velocità',
      dataIndex: ['object', 'connection_type', 'speed']
    },
    {
      title: 'Zona',
      dataIndex: ['object', 'connection_type','zone'],
      hideForUsers: ['PARTNER'],
      search: true,
      filterType: 'radio',
      filterOptions: [{text: 'A&B', value: 'A&B' }, {text: 'C&D', value: 'C&D' }]
    },
    {
      title: 'Sottoscrizione',
      dataIndex: ['object','created_at'],
      render: value => value ? moment(value).format('D MMM YYYY') : '---',
      search: true,
      filterType: 'dateRange'
    },
    {
      title: 'Stato provisioning',
      dataIndex: ['object','state_provisioning_name'],
      render: value => value ? value : '---'
    },
    {
      title: 'Stato coda',
      dataIndex: 'state_name'
      //render: value => value ? value : '---'
    },
    // {
    //   title: 'In recesso',
    //   dataIndex: ['object','recess_requested'],
    //   render: value => value ? 'Si' : 'No',
    //   search: true,
    //   filterType: 'radio',
    //   filterOptions: [{text: 'Si', value: '1' }, {text: 'No', value: '0' }]
    // },
    // {
    //   title: 'In cancellazione',
    //   dataIndex: ['object','cancel_requested'],
    //   render: value => value ? 'Si' : 'No',
    //   search: true,
    //   filterType: 'radio',
    //   filterOptions: [{text: 'Si', value: '1' }, {text: 'No', value: '0' }]
    // },
    // {
    //   title: 'Stato',
    //   dataIndex: 'state_name',
    //   search: true,
    //   filterType: 'radio',
    //   filterOptions: contract_states
    // }
  ];


  return (
    <div className="main-container">
      <PageHeader
        title="Elenco pending cliente"
        style={{ paddingBottom: 0 }}
      >
      </PageHeader>
      <Table
        columns={columns}
        dataSource={list}
        pagination={pagination}
        filters={filters}
        loading={loading}
        getList={(data) => getContractsListPending(data)}
      />
    </div>
  )
}

// ContractsList.PropTypes = {
  
// }

export default ContractsList;
