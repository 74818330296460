import React, { useState, useEffect } from "react";
import {
  Tabs,
  Table,
  Skeleton,
  PageHeader,
  Tag,
  Row,
  Col,
  Statistic,
  Alert,
  Button,
  Dropdown,
  Menu,
  Modal,
  message,
} from "antd";
import moment from "moment";
//import PropTypes from 'prop-types';
import { useSelector, useDispatch } from "react-redux";
import { DownOutlined } from "@ant-design/icons";
import {
  getContractDetails,
  getContractDetailsCustomer,
  getContractDetailsNotifications,
  getContractDetailsHistory,
  getContractTickets,
  getContractInvoices,
  getContractPortedOut,
  getDocumentation,
  downloadDocumentContracts,
  handleActionConfirmation,
  getContractDetailsShippings,
  getContractDetailsNetwork,
} from "redux/reducers/contractsReducer";
import { getTicketEdit } from "redux/reducers/ticketsReducer";
import { useParams, useHistory } from "react-router-dom";
import LoadingFullwidth from "components/Loading/Fullwidth";
import DocBox from "components/DocBox/DocBox";
import {
  DescriptionsContainer,
  Item,
} from "components/Descriptions/Description";
import FitlerTable from "views/Tickets/TicketsList/_Table";

//import RecessRequest from './_actions/RecessRequest';
import ResumeRequest from "./_actions/ResumeRequest";
import UploadWithDrawal from "./_actions/UploadWithdrawal";
import InsertLinkemContractCode from "./_actions/InsertLinkemContractCode";
import ChangeStartBillingDate from "./_actions/ChangeStartBillingDate";
//import CancelOrder from './_actions/CancelOrder';

import { setDate } from "utils/formateDate";
import { BoxItem } from "components/Descriptions/Description";
import RescheduleAppointment from "./_actions/RescheduleAppointment";

function ContractsDetails() {
  const { userRole } = useSelector((state) => state.auth);
  const [stateActions, setStateActions] = useState({
    type: "",
    title: "",
  });
  const [stateConfirm, setStateConfirm] = useState({
    type: "",
    message: "",
    messageSuccess: "",
  });
  const { selected, loading, error } = useSelector((state) => state.contracts);
  const { newTicket } = useSelector((state) => state.tickets);
  const dispatch = useDispatch();
  let history = useHistory();
  const params = useParams();

  useEffect(() => {
    const getDetails = async () => {
      try {
        //console.log('DOCID MOUNT > ',params)
        await dispatch(getContractDetails(params.id));
      } catch (error) {}
    };

    getDetails();
  }, []);

  const downloadAndOpen = async (type, id) => {
    const result = await dispatch(
      downloadDocumentContracts({ type, docId: id })
    );
    if (result.payload) {
      window.open(result.payload.url, "_blank");
    }
  };

  const handleEdit = (id) => {
    if (newTicket?.draft) {
      if (newTicket?.draft.id !== id) {
        return dispatch({
          type: "tickets/setshowAlertWorking",
          options: {
            value: true,
            actionName: "EDIT",
            params: {
              id,
            },
          },
        });
      } else {
        return dispatch({ type: "tickets/setIsDrawerOpen", value: true });
      }
    }
    //localStorage.setItem('ticketId', id)
    dispatch(getTicketEdit(id));
    //dispatch({type:"tickets/setShowNewTicket", value: true})
  };

  const handleChangeTab = async (tabKey) => {
    const tabMap = {
      customer: getContractDetailsCustomer,
      logs: getContractDetailsHistory,
      shippings: getContractDetailsShippings,
      network: getContractDetailsNetwork,
      notifications_fw: getContractDetailsNotifications,
      documentation: getDocumentation,
      commercialOffer: () => {},
    };
    //console.log('handlle change tab > ',tabKey, tabMap ,tabMap[tabKey])

    try {
      await dispatch(tabMap[tabKey](params.id));
      return true;
    } catch (error) {}
  };

  const actionsContentMap = ({ actionName, onSuccess, onCancel }) => {
    const actions = {
      // 'RECESS_REQUEST': <RecessRequest loading={loading} onSuccess={onSuccess} onCancel={onCancel} />,
      RESUME_REQUEST: (
        <ResumeRequest
          loading={loading}
          onSuccess={onSuccess}
          onCancel={onCancel}
        />
      ),
      UPLOAD_WITHDRAWAL: (
        <UploadWithDrawal
          loading={loading}
          onSuccess={onSuccess}
          onCancel={onCancel}
        />
      ),
      INSERT_LINKEM_CONTRACT_CODE: (
        <InsertLinkemContractCode
          initialValues={{ code: selected?.linkem_contract_code }}
          loading={loading}
          onSuccess={onSuccess}
          onCancel={onCancel}
        />
      ),
      CHANGE_START_BILLING_DATE: (
        <ChangeStartBillingDate
          initialValues={{ startDate: moment(selected?.invoiceable_at) }}
          onSuccess={onSuccess}
          onCancel={onCancel}
        />
      ),
      RESCHEDULE_APPOINTMENT: (
        <RescheduleAppointment
          // initialValues={{ code: selected?.linkem_contract_code }}
          loading={loading}
          onSuccess={onSuccess}
          onCancel={onCancel}
        />
      ),
      // 'CANCEL_ORDER': <CancelOrder loading={loading} onSuccess={onSuccess} onCancel={onCancel} />,
    };

    return actions[actionName];
  };

  const handleConfirm = async () => {
    try {
      const messageSuccess = stateConfirm.messageSuccess;
      const result = await dispatch(
        handleActionConfirmation({ action: stateConfirm.type })
      );
      //const result = await dispatch(actionsMap[stateConfirm.type]());
      if (result.payload.data) {
        message.success(messageSuccess);
        setStateConfirm({
          type: "",
          message: "",
          messageSuccess: "",
        });
      }
    } catch (error) {
      setStateConfirm({
        type: "",
        message: "",
        messageSuccess: "",
      });
      message.error(error.message);
    }
  };

  const actionsMap = {
    manage_pending: {
      title: "Gestisci pending cliente",
      items: [
        {
          label: "Richiedi resume",
          action: () =>
            setStateActions({
              type: "RESUME_REQUEST",
              title: "Richiesta di resume",
            }),
        },
        {
          label: "Resume rimandato",
          action: () =>
            setStateConfirm({
              type: "postponed-resume",
              message: "Procedere con la richiesta?",
              messageSuccess: "Richiesta inoltrata con successo!",
            }),
        },
        {
          label: "Cliente sceglie rinuncia da pending",
          action: () =>
            setStateConfirm({
              type: "pending-withdrawal",
              message: "Procedere con la richiesta?",
              messageSuccess: "Richiesta inoltrata con successo!",
            }),
        },
      ],
    },
    manage_ko: {
      title: "Gestisci KO Provisioning",
      items: [
        {
          label: "Nuovo ordine di provisioning",
          action: () =>
            setStateConfirm({
              type: "resend-activation-order",
              message: "Procedere con la richiesta?",
              messageSuccess: "Richiesta inoltrata con successo!",
            }),
        },
        {
          label: "Nuovo provisioning rimandato",
          action: () =>
            setStateConfirm({
              type: "postponed-activation-order",
              message: "Procedere con la richiesta?",
              messageSuccess: "Richiesta inoltrata con successo!",
            }),
        },
        {
          label: "Cliente sceglie rinuncia da pending",
          action: () =>
            setStateConfirm({
              type: "pending-withdrawal",
              message: "Procedere con la richiesta?",
              messageSuccess: "Richiesta inoltrata con successo!",
            }),
        },
      ],
    },
    manage_early_withdrawal: {
      title: "Gestisci rinuncia o recesso anticipato",
      items: [
        {
          label: "Cliente contattato, vuole rinunciare",
          action: () =>
            setStateConfirm({
              type: "send-early-withdrawal",
              message: "Procedere con la richiesta?",
              messageSuccess: "Richiesta inoltrata con successo!",
            }),
        },
        {
          label: "Cliente contattato, non vuole più procedere",
          action: () =>
            setStateConfirm({
              type: "cancel-early-withdrawal",
              message: "Procedere con la richiesta?",
              messageSuccess: "Richiesta inoltrata con successo!",
            }),
        },
        {
          label: "Cliente da ricontattare",
          action: () =>
            setStateConfirm({
              type: "contact-for-ealry-withdrawal",
              message: "Procedere con la richiesta?",
              messageSuccess: "Richiesta inoltrata con successo!",
            }),
        },
      ],
    },
    manage_explicit_withdrawal: {
      title: "Gestisci rinuncia esplicita",
      items: [
        {
          label: "Cliente contattato, vuole rinunciare",
          action: () =>
            setStateConfirm({
              type: "send-explicit-withdrawal",
              message: "Procedere con la richiesta?",
              messageSuccess: "Richiesta inoltrata con successo!",
            }),
        },
        {
          label: "Cliente contattato, non vuole più procedere",
          action: () =>
            setStateConfirm({
              type: "cancel-explicit-withdrawal",
              message: "Procedere con la richiesta?",
              messageSuccess: "Richiesta inoltrata con successo!",
            }),
        },
        {
          label: "Cliente da ricontattare",
          action: () =>
            setStateConfirm({
              type: "contact-for-explicit-withdrawal",
              message: "Procedere con la richiesta?",
              messageSuccess: "Richiesta inoltrata con successo!",
            }),
        },
      ],
    },
    manage_post_withdrawal: {
      title: "Gestisci recesso poticipato",
      items: [
        {
          label: "Cliente contattato, vuole recedere",
          action: () =>
            setStateConfirm({
              type: "send-post-withdrawal",
              message: "Procedere con la richiesta?",
              messageSuccess: "Richiesta inoltrata con successo!",
            }),
        },
        {
          label: "Cliente contattato, non vuole più procedere",
          action: () =>
            setStateConfirm({
              type: "cancel-post-withdrawal",
              message: "Procedere con la richiesta?",
              messageSuccess: "Richiesta inoltrata con successo!",
            }),
        },
        {
          label: "Cliente da ricontattare",
          action: () =>
            setStateConfirm({
              type: "contact-for-post-withdrawal",
              message: "Procedere con la richiesta?",
              messageSuccess: "Richiesta inoltrata con successo!",
            }),
        },
      ],
    },
    manage_contract_withdrawal: {
      title: "Gestisci disdetta",
      items: [
        {
          label: "Cliente contattato, vuole disdire",
          action: () =>
            setStateConfirm({
              type: "send-contract-withdrawal",
              message: "Procedere con la richiesta?",
              messageSuccess: "Richiesta inoltrata con successo!",
            }),
        },
        {
          label: "Cliente contattato, non vuole più procedere",
          action: () =>
            setStateConfirm({
              type: "cancel-contrat-withdrawal",
              message: "Procedere con la richiesta?",
              messageSuccess: "Richiesta inoltrata con successo!",
            }),
        },
        {
          label: "Cliente da ricontattare",
          action: () =>
            setStateConfirm({
              type: "contact-for-contract-withdrawal",
              message: "Procedere con la richiesta?",
              messageSuccess: "Richiesta inoltrata con successo!",
            }),
        },
      ],
    },
  };

  const actionsMenu = (
    <Menu>
      {selected?.actions &&
        Object.keys(selected?.actions).map(function (key, index) {
          return (
            actionsMap[key] &&
            selected?.actions[key] && (
              <Menu.SubMenu
                title={actionsMap[key].title}
                key={key}
                overlayClassName="submenu-left-open"
              >
                {actionsMap[key].items.map((item, j) => (
                  <Menu.Item key={j}>
                    <a onClick={item.action}>{item.label}</a>
                  </Menu.Item>
                ))}
              </Menu.SubMenu>
            )
          );
        })}
      {selected?.actions?.upload_withdrawal && (
        <Menu.Item key="upload_withdrawal">
          <a
            onClick={() =>
              setStateActions({
                type: "UPLOAD_WITHDRAWAL",
                title: "Carica rinuncia, recesso, disdetta",
              })
            }
          >
            Carica rinuncia, recesso, disdetta
          </a>
        </Menu.Item>
      )}
      {selected?.actions?.send_contract_mail && (
        <Menu.Item key="send_contract_mail">
          <a
            onClick={() =>
              setStateConfirm({
                type: "send-contract-mail",
                message:
                  "Procedere con l'invio del contratto via e-mail al cliente?",
                messageSuccess: "E-mail inviata con sucesso!",
              })
            }
          >
            Spedisci contratto via e-mail
          </a>
        </Menu.Item>
      )}
      {selected?.actions?.send_credentials && (
        <Menu.Item key="send_credentials">
          <a
            onClick={() =>
              setStateConfirm({
                type: "send-credentials",
                message:
                  "Procedere con l'invio del credenziali via e-mail al cliente?",
                messageSuccess: "E-mail inviata con sucesso!",
              })
            }
          >
            Spedisci credenziali via e-mail
          </a>
        </Menu.Item>
      )}
      {userRole == "CUSTOMER_CARE" && (
        <Menu.Item key="insert_linkem_contract_code">
          <a
            onClick={() =>
              setStateActions({
                type: "INSERT_LINKEM_CONTRACT_CODE",
                title: "Inserisci codice contratto Linkem",
              })
            }
          >
            Inserisci codice contratto Linkem
          </a>
        </Menu.Item>
      )}
      {userRole == 'CUSTOMER_CARE' &&
        selected?.actions?.add_premium_odl && (
        <Menu.Item key="add_premium_odl">
          <a
            onClick={() =>
              setStateConfirm({
                type: "create-work-order",
                message:
                  "Aggiungere l'ODL premium al contratto?",
                messageSuccess: "ODL Premium associato con sucesso",
              })
            }
          >
            Aggiungi ODL Premium
          </a>
        </Menu.Item>
      )}
      {userRole == "CUSTOMER_CARE" &&
        selected?.actions?.change_invoiceable_date && (
          <Menu.Item key="change_start_billing_date">
            <a
              onClick={() =>
                setStateActions({
                  type: "CHANGE_START_BILLING_DATE",
                  title: "Cambia data inizio fatturazione",
                })
              }
            >
              Cambia data inizio fatturazione
            </a>
          </Menu.Item>
        )}
        {userRole == "CUSTOMER_CARE" && selected?.actions?.reschedule && (
        <Menu.Item key="reschedule_appointment">
          <a
            onClick={() =>
              setStateActions({
                type: "RESCHEDULE_APPOINTMENT",
                title: "Ripianifica appuntamento",
              })
            }
          >
            Ripianifica appuntamento
          </a>
        </Menu.Item>
      )}
    </Menu>
  );

  const tagMap = {
    info: <Tag color="green">Info</Tag>,
    warning: <Tag color="gold">Avviso</Tag>,
    error: <Tag color="red">Errore</Tag>,
  };

  const parsePaymentMethodType = {
    cc: "Carta di credito",
    rid: "RID/SDD",
  };

  const columnsTickets = [
    {
      title: "Numero",
      dataIndex: "ticket_code",
      search: true,
      //render: (value,row) => <a onClick={() => dispatch(getTicketEdit(row.id))}>{value}</a>,
    },
    {
      title: "Tipologia - Famiglia - Sottofamiglia",
      title_name: "Tripletta",
      dataIndex: "support_cat",
      render: (value, row) => {
        return (
          <>
            <Tag color="geekblue" size="small">
              {row.support_cat_first.name}
            </Tag>
            <Tag color="green" size="small">
              {row.support_cat_second.name}
            </Tag>
            <Tag color="volcano" size="small">
              {row.support_cat_third.name}
            </Tag>
          </>
        );
      },
      search: true,
      filterType: "tree",
      filterOptions: newTicket?.triplets,
    },
    // {
    //   title: 'Famiglia',
    //   dataIndex: ['support_cat_second','name'],
    // },
    // {
    //   title: 'Sottofamiglia',
    //   dataIndex: ['support_cat_third','name'],
    // },
    {
      title: "Stato",
      dataIndex: "state_name",
      search: true,
      filterType: "radio",
      filterOptions: [
        { text: "Aperto", value: "open" },
        { text: "Chiuso", value: "closed" },
        { text: "Sospeso", value: "suspended" },
      ],
    },
    {
      title: "Ocs",
      dataIndex: "ocs",
      render: (value, row) => (value ? "Si" : "No"),
    },
    {
      title: "Origine",
      dataIndex: "origin_name",
      search: true,
      filterType: "radio",
      filterOptions: [
        { text: "Chiamata", value: "call" },
        { text: "Segnalazione esterna", value: "external" },
        { text: "Business partner", value: "bp" },
        { text: "Chat", value: "chat" },
        { text: "Raccomandata", value: "reg_letter" },
        { text: "E-mail", value: "mail" },
        { text: "Web", value: "web" },
      ],
    },
    {
      title: "Data creazione",
      dataIndex: "created_at",
      render: (value) => moment(value).format("D MMM YYYY HH:mm"),
      search: true,
      filterType: "dateRange",
    },
    {
      //title: 'Action',
      key: "action",
      render: (text, record) => (
        <Button size="small" type="link" onClick={() => handleEdit(record.id)}>
          Lavora
        </Button>
      ),
    },
  ];

  const columnsInvoices = [
    {
      title: "N. fattura",
      dataIndex: "invoice_proof",
      search: true,
    },
    {
      title: 'Tipologia',
      dataIndex: 'document_type',
      search: true,
      render: (value, row) => {
        if(value === 1) return 'Fattura'
        return 'NC'
      },
      filterType: 'radio',
      filterOptions: [
        {
          text: 'Fattura',
          value: '1'
        },
        {
          text: 'Nota di credito',
          value: '2'
        },
      ]
    },
    {
      title: "Data emissione",
      dataIndex: "document_date",
      render: (value) =>
        value ? moment(value).format("D MMM YYYY HH:mm") : "---",
      search: true,
      filterType: "dateRange",
    },
    {
      title: "Data scadenza",
      dataIndex: "expires_at",
      render: (value) =>
        value ? moment(value).format("D MMM YYYY HH:mm") : "---",
      search: true,
      filterType: "dateRange",
    },
    {
      title: "Importo",
      dataIndex: "total_amount",
      render: (value, row) => (
        <Statistic
          //title="Costo mensile"
          decimalSeparator=","
          prefix="€"
          precision={2}
          value={value || "---"}
          valueStyle={{
            fontSize: 13,
          }}
        />
      ),
    },
    {
      title: "Esito pagamento",
      dataIndex: "result",
      search: true,
      render: (value, row) => {
        if (value === 0) return "Non pagata";
        if (value === 1) return "Pagata parzialmente";
        return "Pagata";
      },
      filterType: "radio",
      filterOptions: [
        {
          text: "Non pagata",
          value: "0",
        },
        {
          text: "Pagata parzialmente",
          value: "1",
        },
        {
          text: "Pagata",
          value: "2",
        },
      ],
    },
    {
      title: 'Stato pagamento',
      dataIndex: 'payment_state',
      search: false,
    },
    {
      title: 'AC abilitata',
      dataIndex: 'payable_from_ca',
      search: true,
      render: (value, row) => {
        if(value === false) return 'No'
        return 'Sì'
      },
      filterType: 'radio',
      filterOptions: [
        {
          text: 'Sì',
          value: '1'
        },
        {
          text: 'No',
          value: '0'
        }
      ]
    },
  ];

  const columnsPortedOut = [
    {
      title: 'ID',
      dataIndex: 'id',
      search: false
    },
    {
      title: 'Risorsa',
      dataIndex: 'resource',
      search: false,
    },
    {
      title: 'Recipient',
      dataIndex: ['recipient', 'name'],
      search: false
    },
    {
      title: 'Codice',
      dataIndex: 'recipient_code',
      search: false
    },
    {
      title: 'Data ricezione',
      dataIndex: 'created_at',
      render: value => value ? moment(value).format('D MMM YYYY HH:mm') : '---',
      search: false,
    },
    {
      title: 'Data scadenza',
      dataIndex: 'expires_at',
      render: value => value ? moment(value).format('D MMM YYYY HH:mm') : '---',
      search: false,
    },
    {
      title: 'Stato',
      dataIndex: 'state_name',
      search: false,
    },
  ];

  const columnsShippings = [
    { title: "Codice", dataIndex: "shipping_code", key: "shipping_code" },
    { title: "DDT", dataIndex: "ddt", key: "ddt" },
    { title: "CPE", dataIndex: "cpe_id", key: "cpe_id" },
    { title: "Stato corrente", dataIndex: "state_name", key: "state_name" },
    {
      title: "Data di consegna",
      dataIndex: "delivered_at",
      key: "delivered_at",
      render: (value) =>
        (value && moment(value).format("DD/MM/YYYY HH:mm")) || "---",
    },
    {
      title: "Ultimo aggiornamento",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (value) =>
        (value && moment(value).format("DD/MM/YYYY HH:mm")) || "---",
    },
  ];

  const columnsNotifications = [
    { title: "Tipologia", dataIndex: "type_name", key: "type_name" },
    { title: "Stato", dataIndex: "state_name", key: "state_name" },
    { title: "Codice CRM", dataIndex: "crm_code", key: "crm_code" },
    { title: "Codice Ordine", dataIndex: "fw_code", key: "fw_code" },
    { title: "ID E2E", dataIndex: "e2e_code", key: "e2e_code" },
    {
      title: "Data invio",
      dataIndex: "created_at",
      key: "created_at",
      render: (value) => moment(value).format("DD/MM/YYYY HH:mm"),
    },
  ];

  const columnsLogs = [
    {
      title: "Utente",
      dataIndex: ["creator", "first_name"],
      key: "user",
      render: (value, row) =>
        row.creator_type === null ? (
          "Sistema"
        ) : (
          <>
            {row.creator.first_name} {row.creator.last_name}{" "}
            <Tag color="cyan">{row.creator_type}</Tag>
          </>
        ),
    },
    {
      title: "Nota",
      dataIndex: "notes",
      key: "notes",
      render: (value, row) => (
        <>
          {tagMap[row.type]} {value}
        </>
      ),
    },
    {
      title: "Data inserimento",
      dataIndex: "created_at",
      key: "created_at",
      render: (value) => moment(value).format("DD/MM/YYYY HH:mm"),
    },
  ];

  const expandedRowRender = (record) => {
    const columns = [
      { title: "Notifica", dataIndex: "object_type", key: "object_type" },
      {
        title: "Codice OLO",
        dataIndex: "olo_notification_id",
        key: "olo_notification_id",
      },
      {
        title: "Stato",
        dataIndex: ["object", "provisioning_state_name"],
        key: "provisioning_state_name",
      },
      {
        title: "Descrizione",
        dataIndex: ["object", "description"],
        key: "description",
        render: (value) =>
          value.map((row, i) => (
            <p key={i} style={{ margin: 0 }}>
              {row}
            </p>
          )),
      },
      {
        title: "Data ricezione",
        dataIndex: "created_at",
        key: "created_at",
        render: (value) => moment(value).format("DD/MM/YYYY HH:mm"),
      },
    ];

    return (
      <Table
        columns={columns}
        dataSource={record.olo_notifications}
        pagination={false}
        rowKey={(row) => row.id}
      />
    );
  };

  return (
    <div className="main-container">
      {loading.includes("getContractDetailsStatus") || selected === null ? (
        <Skeleton active />
      ) : (
        <div className="main-content">
          <PageHeader
            //className="site-page-header-responsive"
            onBack={() => history.goBack()}
            title={`Codice contratto: ${selected?.contract_code || "---"}`}
            tags={
              <Tag color="blue" size="large">
                {selected?.state_name}
              </Tag>
            }
            //subTitle="This is a subtitle"
            extra={[
              <Dropdown
                placement="bottomRight"
                overlay={actionsMenu}
                key="contract-details-actions"
                trigger={["click"]}
                style={{ minWidth: 250 }}
              >
                <Button type="primary">
                  Azioni
                  <DownOutlined />
                </Button>
              </Dropdown>,
              // <Button key="1" type="primary">
              //   Azioni
              // </Button>,
            ]}
          >
            <Row style={{ marginBottom: 16 }}>
              {selected?.recess_requested && (
                <Alert
                  message="È stata effettuata una richiesta di recesso"
                  type="warning"
                  style={{ marginBottom: 8, width: "100%" }}
                  // closable
                  // onClose={onClose}
                />
              )}
              {selected?.cancel_requested && (
                <Alert
                  message="È stata effettuata una richiesta di cancellazione"
                  type="warning"
                  style={{ marginBottom: 8, width: "100%" }}
                  // closable
                  // onClose={onClose}
                />
              )}
            </Row>
            <Row>
              <Col
                span={20}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Statistic
                  title="Offerta"
                  value={selected?.commercial_offer?.name || "---"}
                />
                {/* <Statistic
                  title="Costo mensile"
                  prefix="€"
                  precision={2}
                  value={selected?.commercial_offer?.monthly_amount || "---"}
                  style={{
                    margin: "0 32px",
                  }}
                /> */}
                <Statistic
                  title="Data sottoscrizione"
                  value={
                    selected?.created_at
                      ? moment(selected?.created_at).format("DD/MM/YYYY")
                      : "---"
                  }
                />
                <Statistic
                  title="Data attivazione"
                  value={
                    selected?.activated_at
                      ? moment(selected?.activated_at).format("DD/MM/YYYY")
                      : "---"
                  }
                />
                <Statistic
                  title="Data inizio fatturazione"
                  value={
                    selected?.invoiceable_at
                      ? moment(selected?.invoiceable_at).format("DD/MM/YYYY")
                      : "---"
                  }
                />
                {userRole !== "PARTNER" && (
                  <Statistic
                    title="Friendly launch"
                    value={selected?.friendly_launch ? "Si" : "No"}
                  />
                )}
                {userRole !== "PARTNER" && (
                  <Statistic
                    title="JustPremium"
                    value={selected?.has_premium_odl ? "Si" : "No"}
                  />
                )}
                {userRole !== "PARTNER" && (
                  <Statistic title="Carrier" value={selected?.carrier} />
                )}
              </Col>
            </Row>
          </PageHeader>

          <Tabs type="card" defaultActiveKey="1" onChange={handleChangeTab}>
            <Tabs.TabPane tab="Dettagli" key="details">
              <Row gutter={[24, 24]}>
                <Col span={8}>
                  <DescriptionsContainer
                    title="Indirizzo di attivazione"
                    type="card"
                  >
                    <Item
                      label="Nome sul citofono"
                      value={
                        selected?.activation_address?.intercom_name || "---"
                      }
                    />
                    <Item
                      label="Indirizzo"
                      value={
                        (selected?.activation_address?.street_name || "---") +
                        ", " +
                        (selected?.activation_address?.address_number || "---")
                      }
                    />
                    {/* <Item label="Civico" value={selected?.activation_address?.address_number || '---'}/> */}
                    <Item
                      label="Scala - Interno -Piano"
                      value={
                        (selected?.activation_address?.stair || "") +
                        " - " +
                        (selected?.activation_address?.nui || "") +
                        " - " +
                        (selected?.activation_address?.floor || "")
                      }
                    />
                    {/* <Item label="Interno" value={selected?.activation_address?.nui || '---'}/>
                    <Item label="Piano" value={selected?.activation_address?.floor || '---'}/> */}
                    <Item
                      label="Città"
                      value={
                        (selected?.activation_address?.city || "---") +
                        " (" +
                        (selected?.activation_address?.province || "---") +
                        ")"
                      }
                    />
                    {/* <Item label="Provincia" value={selected?.activation_address?.province || '---'}/> */}
                    <Item
                      label="Cap"
                      value={selected?.activation_address?.postal_code || "---"}
                    />
                  </DescriptionsContainer>
                </Col>
                <Col span={8}>
                  <DescriptionsContainer
                    title="Indirizzo di spedizione"
                    type="card"
                  >
                    <Item
                      label="Nome sul citofono"
                      value={selected?.delivery_address?.intercom_name || "---"}
                    />
                    <Item
                      label="Indirizzo"
                      value={
                        (selected?.delivery_address?.street_name || "---") +
                        ", " +
                        (selected?.delivery_address?.address_number || "---")
                      }
                    />
                    {/* <Item label="Civico" value={selected?.delivery_address?.address_number || '---'}/> */}
                    <Item
                      label="Scala - Interno - Piano"
                      value={
                        (selected?.delivery_address?.stair || "") +
                        " - " +
                        (selected?.delivery_address?.nui || "") +
                        " - " +
                        (selected?.delivery_address?.floor || "")
                      }
                    />
                    {/* <Item label="Interno" value={selected?.delivery_address?.nui || '---'}/>
                    <Item label="Piano" value={selected?.delivery_address?.floor || '---'}/> */}
                    <Item
                      label="Città"
                      value={
                        (selected?.delivery_address?.city || "---") +
                        " (" +
                        (selected?.delivery_address?.province || "---") +
                        ")"
                      }
                    />
                    {/* <Item label="Provincia" value={selected?.delivery_address?.province || '---'}/> */}
                    <Item
                      label="Cap"
                      value={selected?.delivery_address?.postal_code || "---"}
                    />
                  </DescriptionsContainer>
                </Col>
                <Col span={8}>
                  <DescriptionsContainer
                    title="Indirizzo di fatturazione"
                    type="card"
                  >
                    <Item
                      label="Nome sul citofono"
                      value={selected?.billing_address?.intercom_name || "---"}
                    />
                    <Item
                      label="Indirizzo"
                      value={
                        (selected?.billing_address?.street_name || "---") +
                        ", " +
                        (selected?.billing_address?.address_number || "---")
                      }
                    />
                    {/* <Item label="Civico" value={selected?.billing_address?.address_number || '---'}/> */}
                    <Item
                      label="Scala - Interno - Piano"
                      value={
                        (selected?.billing_address?.stair || "") +
                        " - " +
                        (selected?.billing_address?.nui || "") +
                        " - " +
                        (selected?.billing_address?.floor || "")
                      }
                    />
                    {/* <Item label="Interno" value={selected?.billing_address?.nui || '---'}/>
                    <Item label="Piano" value={selected?.billing_address?.floor || '---'}/> */}
                    <Item
                      label="Città"
                      value={
                        (selected?.billing_address?.city || "---") +
                          " (" +
                          (selected?.billing_address?.province || "---") +
                          ")" || "---"
                      }
                    />
                    {/* <Item label="Provincia" value={selected?.billing_address?.province || '---'}/> */}
                    <Item
                      label="Cap"
                      value={selected?.billing_address?.postal_code || "---"}
                    />
                  </DescriptionsContainer>
                </Col>
                <Col span={8}>
                  <DescriptionsContainer
                    title="Dati di connessione"
                    type="card"
                  >
                    <Item
                      label="Tipologia di connessione"
                      value={
                        selected?.connection_type?.connection_type || "---"
                      }
                    />
                    <Item
                      label="Velocità di download"
                      value={selected?.connection_type?.download_speed || "---"}
                    />
                    <Item
                      label="Velocità di upload"
                      value={selected?.connection_type?.upload_speed || "---"}
                    />
                    <Item
                      label="Velocità di download effettiva"
                      value={selected?.coverage?.actual_download_speed || "---"}
                    />
                    <Item
                      label="Velocità di upload effettiva"
                      value={selected?.coverage?.actual_upload_speed || "---"}
                    />
                    <Item
                      label="Latenza"
                      value={selected?.coverage?.latency || "---"}
                    />
                    <Item
                      label="Zona"
                      value={selected?.connection_type?.zone || "---"}
                    />
                    <Item
                      label="Kit Id"
                      value={selected?.coverage?.kit_id || "---"}
                    />
                    <Item
                      label="Codice migrazione"
                      value={selected?.migration_code || "---"}
                    />
                    <Item
                      label="Copertura anomala"
                      value={selected?.coverage?.issues === true ? 'Sì' : 'No'}
                    />
                  </DescriptionsContainer>
                </Col>
                <Col span={8}>
                  <DescriptionsContainer title="Dati referente" type="card">
                    <Item
                      label="Nome"
                      value={selected?.referent?.first_name || "---"}
                    />
                    <Item
                      label="Cognome"
                      value={selected?.referent?.last_name || "---"}
                    />
                    <Item
                      label="Cellulare"
                      value={selected?.referent?.phone_number || "---"}
                    />
                    <Item
                      label="Telefono fisso"
                      value={selected?.referent?.home_phone_number || "---"}
                    />
                  </DescriptionsContainer>
                </Col>
                <Col span={8}>
                  <DescriptionsContainer
                    title="Informazioni di pagamento"
                    type="card"
                  >
                    <Item
                      label="Tipologia"
                      value={
                        parsePaymentMethodType[
                          selected?.payment_method?.type
                        ] || "---"
                      }
                    />
                    {selected?.payment_method?.type === "cc" ? (
                      <>
                        <Item
                          label="Numero carta"
                          value={
                            `**** **** **** *${selected?.payment_method?.cc_last_numbers}` ||
                            "---"
                          }
                        />
                        <Item
                          label="Scadenza carta"
                          value={
                            (selected?.payment_method?.token_expiration_month ||
                              "---") +
                            "/" +
                            (selected?.payment_method?.token_expiration_year ||
                              "---")
                          }
                        />
                      </>
                    ) : (
                      <>
                        <Item
                          label="Iban"
                          value={selected?.payment_method?.iban || "---"}
                        />
                      </>
                    )}
                    <Item
                      label="Data di inserimento"
                      value={
                        (selected?.payment_method?.created_at &&
                          moment(selected?.payment_method?.created_at).format(
                            "DD/MM/YYYY"
                          )) ||
                        "---"
                      }
                    />
                  </DescriptionsContainer>
                </Col>
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Cliente" key="customer">
              {loading.includes("getContractDetailsCustomerStatus") ? (
                <LoadingFullwidth />
              ) : (
                <Row gutter={[24, 24]}>
                  <Col span={8}>
                    <DescriptionsContainer title="Dati anagrafica" type="card">
                      <Item
                        label="Codice cliente"
                        value={selected?.customer?.customer_code}
                        onClick={() =>
                          history.push(`/clienti/${selected?.customer?.id}`)
                        }
                      />
                      <Item
                        label="Codice cliente Linkem"
                        value={selected?.customer?.linkem_customer_code || "---"}
                        onClick={() =>
                          history.push(`/clienti/${selected?.customer?.id}`)
                        }
                      />
                      <Item
                        label="Nome"
                        value={selected?.customer?.first_name || "---"}
                      />
                      <Item
                        label="Cognome"
                        value={selected?.customer?.last_name || "---"}
                      />
                      <Item
                        label="Genere"
                        value={selected?.customer?.gender_name || "---"}
                      />
                      <Item
                        label="Cellulare"
                        value={selected?.customer?.phone_number || "---"}
                      />
                      <Item
                        label="Telefono fisso"
                        value={selected?.customer?.home_phone_number || "---"}
                      />
                      <Item
                        label="E-mail"
                        value={selected?.customer?.email || "---"}
                      />
                      <Item
                        label="Data di nascita"
                        value={setDate({ date: selected?.customer?.born_at })}
                      />
                      <Item
                        label="Luogo di nascita"
                        value={selected?.customer?.born_city || "---"}
                      />
                      <Item
                        label="Provincia di nascita"
                        value={selected?.customer?.born_province || "---"}
                      />
                      <Item
                        label="Nazione di nascita"
                        value={selected?.customer?.born_country || "---"}
                      />
                      <Item
                        label="Codice fiscale"
                        value={selected?.customer?.fiscal_code || "---"}
                      />
                    </DescriptionsContainer>
                  </Col>
                  <Col span={8}>
                    <DescriptionsContainer
                      title="Documento di riconoscimento"
                      type="card"
                    >
                      <Item
                        label="Nazionalità"
                        value={
                          selected?.customer?.document?.nationality || "---"
                        }
                      />
                      <Item
                        label="Tipologia"
                        value={selected?.customer?.document?.type_name || "---"}
                      />
                      <Item
                        label="Numero"
                        value={selected?.customer?.document?.number || "---"}
                      />
                      <Item
                        label="Data di rilascio"
                        value={
                          moment(
                            selected?.customer?.document?.issued_at
                          ).format("DD/MM/YYYY") || "---"
                        }
                      />
                      <Item
                        label="Rilasciato dal "
                        value={selected?.customer?.document?.entity || "---"}
                      />
                    </DescriptionsContainer>
                  </Col>
                </Row>
              )}
            </Tabs.TabPane>
            <Tabs.TabPane tab="Offerta commerciale" key="commercial-offer">
              <Row gutter={[24, 24]}>
                <Col>
                  <DescriptionsContainer
                    title={selected.commercial_offer.name}
                    type="card"
                  >
                    <Row style={{ width: "100%" }}>
                      <h2>Costi mensili</h2>
                    </Row>
                    <Item
                      label="Canone"
                      value={selected.commercial_offer.head_name}
                    />{" "}
                    <Row style={{ width: "100%" }}>
                      <BoxItem
                        bgColor="rgb(204,46,49)"
                        color="white"
                        value={selected.commercial_offer.sub_name}
                      />
                      {selected.commercial_offer.discount_months > 0 && (
                        <Item
                          label="Costo"
                          mlAuto
                          discountText={`${selected.commercial_offer.standard_amount.toFixed(
                            2
                          )} €/mese`}
                          value={`${selected.commercial_offer.discount_amount.toFixed(
                            2
                          )} €/mese per i primi ${
                            selected.commercial_offer.discount_months
                          } mesi poi ${selected.commercial_offer.monthly_amount.toFixed(
                            2
                          )} €/mese`}
                        />
                      )}
                      {/* {selected.commercial_offer.discount_months == 0 && (
                        <Item
                          label="Costo"
                          mlAuto
                          value={`${selected.commercial_offer.amount.toFixed(
                            2
                          )} €/mese`}
                        />
                      )} */}
                      {selected.commercial_offer.trial_days != 0 && (
                        <Item
                          label="Costo"
                          mlAuto
                          value={`Gratis per i primi ${selected.commercial_offer.trial_days} giorni poi ${selected.commercial_offer.monthly_amount.toFixed(
                            2
                          )} €/mese`}
                        />
                      )}
                    </Row>
                    <Row style={{ width: "100%" }}>
                      <h2>Dettagli</h2>
                    </Row>
                    <Row style={{ width: "100%" }}>
                      {selected.commercial_offer.activation_amount ==
                        selected.commercial_offer
                          .discount_activation_amount && (
                        <Item
                          label="Contributo attivazione"
                          value={`${selected.commercial_offer.activation_amount} €`}
                        />
                      )}
                      {selected.commercial_offer.activation_amount !=
                        selected.commercial_offer
                          .discount_activation_amount && (
                        <>
                          <Item value="Contributo attivazione" />
                          <Row style={{ minWidth: "100px" }}>
                            <div style={{ fontWeight: "bold", opacity: 1 }}>
                              Costi d'attivazione
                            </div>
                            <Item
                              noLabel
                              discount
                              value={`${selected.commercial_offer.activation_amount} €`}
                            />
                            <Item
                              noLabel
                              value={`${selected.commercial_offer.discount_activation_amount} €`}
                            />
                          </Row>
                        </>
                      )}
                    </Row>
                    <Row style={{ width: "100%" }}>
                      <Col span={12}>
                        <h2>Informazioni</h2>

                        {selected.commercial_offer.lines.map((line) => (
                          <p style={{ width: "100%" }}>{line}</p>
                        ))}
                      </Col>
                      <Col>
                        <Item
                          label="Durata contratto"
                          value={`${selected.commercial_offer.contract_duration_months} mesi`}
                        />
                      </Col>
                    </Row>
                  </DescriptionsContainer>
                </Col>
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Storico" key="logs">
              <Table
                columns={columnsLogs}
                rowKey={(record) => record.id}
                dataSource={selected?.history}
                pagination={false}
              />
            </Tabs.TabPane>

            {userRole !== "PARTNER" && (
              <>
                <Tabs.TabPane
                  tab="Notifiche Provisioning"
                  key="notifications_fw"
                >
                  {loading.includes("getContractDetailsNotificationsStatus") ? (
                    <LoadingFullwidth />
                  ) : (
                    <Table
                      className="components-table-demo-nested"
                      columns={columnsNotifications}
                      rowKey={(record) => record.id}
                      expandable={{
                        expandedRowRender,
                        rowExpandable: (record) =>
                          userRole === "PARTNER" ? false : true,
                      }}
                      defaultExpandAllRows={true}
                      dataSource={selected?.crm_notifications}
                      pagination={false}
                    />
                  )}
                </Tabs.TabPane>
                <Tabs.TabPane tab="Tickets" key="tickets">
                  <FitlerTable
                    columns={columnsTickets}
                    dataSource={selected?.tickets?.list}
                    loading={loading}
                    pagination={selected?.tickets?.pagination}
                    filters={selected?.tickets?.filters}
                    getList={(data) =>
                      getContractTickets({ data: data, contractId: params.id })
                    }
                  />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Fatture" key="invoices">
                  <FitlerTable
                    columns={columnsInvoices}
                    dataSource={selected?.invoices?.list}
                    loading={loading}
                    pagination={selected?.invoices?.pagination}
                    filters={selected?.invoices?.filters}
                    getList={(data) =>
                      getContractInvoices({ data: data, contractId: params.id })
                    }
                  />
                </Tabs.TabPane>
              </>
            )}
            {userRole == "CUSTOMER_CARE" && selected?.carrier != "FW" && (
              <>
                <Tabs.TabPane tab="Spedizioni" key="shippings">
                  <FitlerTable
                    columns={columnsShippings}
                    dataSource={selected?.shippings}
                    loading={loading}
                    // pagination={selected?.tickets?.pagination}
                    // filters={selected?.tickets?.filters}
                    // getList={(data) => getContractTickets({data: data,contractId: params.id})}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Rete" key="network">
                  {loading.includes("getContractDetailsNetworkStatus") ? (
                    <LoadingFullwidth />
                  ) : (
                    <Row gutter={[24, 24]}>
                      {selected?.network_data?.map((item) => {
                        console.log(item);
                        return (
                          <Col key={`${item.olo_order_id}.${item.id}`} span={8}>
                            <DescriptionsContainer
                              title="Dati di rete"
                              type="card"
                            >
                              <Item
                                label="Carrier"
                                value={item.carrier || "---"}
                              />
                              <Item
                                label="Username"
                                value={item.username || "---"}
                              />
                              <Item
                                label="Password"
                                value={item.password || "---"}
                              />
                              <Item label="CPE" value={item.cpe_id || "---"} />
                              <Item label="COS" value={item.cos || "---"} />
                              <Item
                                label="IP Statico"
                                value={item.public_ip || "---"}
                              />
                              <Item
                                label="Netmask"
                                value={item.netmask || "---"}
                              />
                              <Item
                                label="Stato ACS"
                                value={item.hsi_status_name || "---"}
                              />
                              <Item
                                label="Stato Radius"
                                value={item.radius_status_name || "---"}
                              />
                            </DescriptionsContainer>
                          </Col>
                        );
                      })}
                    </Row>
                  )}
                </Tabs.TabPane>
              </>
            )}
            <Tabs.TabPane tab="Documenti" key="documentation">
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <h2>Generati</h2>
                      {selected?.generated_documentation?.map((doc) => (
                        <Row gutter={[16, 16]} key={doc.id}>
                          <Col span={20}>
                            <DocBox
                              docId={doc.id}
                              docName={doc.type_name}
                              category="generated"
                              loading={loading}
                              tagColor={"red"}
                              tagName={doc.state === "rejected" && "Rifiutato"}
                              onDownload={(cat, id) =>
                                downloadAndOpen("generated", id)
                              }
                              subtitle={moment(doc.updated_at).format(
                                "DD/MM/YYYY HH:MM"
                              )}
                            />
                          </Col>
                        </Row>
                      ))}
                    </Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row gutter={[16,16]}>
                    <Col span={24}>
                      <h2>Caricati</h2>
                      {
                        selected?.uploaded_documentation?.map(doc => (
                          <Row gutter={[16, 16]} key={doc.id}>
                            <Col span={20}>
                              <DocBox 
                                key={doc.id}
                                docId={doc.id}
                                docName={doc.type_name}
                                category="generated"
                                loading={loading}
                                tagColor={'red'}
                                tagName={doc.state === 'rejected' && 'Rifiutato'}
                                onDownload={(cat,id) => downloadAndOpen('uploaded',id)}
                                subtitle={moment(doc.updated_at).format('DD/MM/YYYY HH:MM')}
                              />
                            </Col>
                          </Row>
                        ))
                      }
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Tabs.TabPane>
            {userRole == "CUSTOMER_CARE" && (
            <Tabs.TabPane tab="Migrazioni" key="portedout">
                  <FitlerTable
                    columns={columnsPortedOut}
                    dataSource={selected?.portedout?.list}
                    loading={loading}
                    pagination={selected?.portedout?.pagination}
                    filters={selected?.portedout?.filters}
                    getList={(data) =>
                      getContractPortedOut({ data: data, contractId: params.id })
                    }
                  />
                </Tabs.TabPane>
            )}
          </Tabs>
          <Modal
            title={stateActions.title}
            visible={stateActions.type !== ""}
            destroyOnClose
            width={760}
            onCancel={() => setStateActions({ title: "", type: "" })}
            footer={false}
            // footer={[
            //   <Button
            //     key="back"
            //     type="primary"
            //     onCancel={() => setStateActions({ title: '', type: ''})}
            //   >
            //     Chiudi
            //   </Button>
            // ]}
          >
            {actionsContentMap({
              actionName: stateActions.type,
              onSuccess: () => {
                setStateActions({ title: "", type: "" });
              },
              onCancel: () => setStateActions({ title: "", type: "" }),
            })}
          </Modal>
          <Modal
            title="Richiesta conferma"
            visible={stateConfirm.type !== ""}
            // onOk={handleBackButton}
            onCancel={() => setStateConfirm({ message: "", type: "" })}
            footer={[
              <Button
                key="back"
                onClick={() => setStateConfirm({ message: "", type: "" })}
              >
                Annulla
              </Button>,
              <Button key="submit" type="primary" onClick={handleConfirm}>
                Confermo
              </Button>,
            ]}
          >
            <p>{stateConfirm.message}</p>
          </Modal>
        </div>
      )}
    </div>
  );
}

// ContractsDetails.PropTypes = {

// }

export default ContractsDetails;
