import React from 'react';
import moment from 'moment';
//import PropTypes from 'prop-types';
import { 
  useSelector,
  //useDispatch 
} from 'react-redux';
import { Link, useLocation } from "react-router-dom";
import { 
  //Menu,
  //Dropdown,
  Tag,
  PageHeader,
  //Button
} from 'antd';
//import { DownOutlined } from '@ant-design/icons';
import Table from 'views/Tickets/TicketsList/_Table';
//import './ContractsList.css';

import { 
  getOrdersList
} from 'redux/reducers/ordersReducer';

function OrdersList() {
  
  const { list, pagination, filters, loading } = useSelector(state => state.orders);
  
  const columns = [
    {
      title: 'Cod. CRM',
      dataIndex: 'crm_code',
      search: true
    },
    {
      title: 'Cod. FW',
      dataIndex: 'fw_code',
      search: true
    },
    {
      title: 'Cod. E2E',
      dataIndex: 'e2e_code',
      search: true
    },
    {
      title: 'Tipologia',
      dataIndex: 'type_name'
    },
    {
      title: 'Cod. contratto',
      dataIndex: ['contract','contract_code'],
      //search: true,
      //sorter: true,
      render: (value,row) => <Link to={`/contratti/${row?.contract?.id}`}>{value}</Link>,
      //width: '20%',
    },
    {
      title: 'Nome',
      dataIndex: ['contract','customer','first_name'],
      //search: true
    },
    {
      title: 'Cognome',
      dataIndex: ['contract','customer','last_name'],
      //search: true
    },
    {
      title: 'Indirizzo',
      dataIndex: ['contract','coverage','street_name'],
      render: (value,row) => `${row.contract?.coverage?.toponymic_particle} ${row.contract?.coverage?.street_name}, ${row.contract?.coverage?.address_number}`,
      //search: true
    },
    {
      title: 'Città',
      dataIndex: ['contract','coverage','city'],
      //search: true
    },
    {
      title: 'Data sottoscrizione',
      dataIndex: 'created_at',
      render: value => value ? moment(value).format('D MMM YYYY HH:mm') : '---',
      // search: true,
      // filterType: 'dateRange'
    },
    {
      title: 'Stato',
      dataIndex: 'last_state_name',
      // search: true,
      // filterType: 'radio',
      // filterOptions: contract_states
    }
  ];
  
  const states = useLocation().pathname.split('/');
  states.shift();
  const stateFilter = states[1] || '';
  console.log({stateFilter})
  
  return (
    <div className="main-container">
      <PageHeader
        title={"Elenco ordini"}
        style={{ paddingBottom: 0 }}
        tags={stateFilter && <Tag color="blue" size="large" >{stateFilter.replace('-',' ')}</Tag>}
        // extra={[
        //   <Dropdown overlay={menu} key="crea-contratto-button">
        //     <Button 
        //       type="primary" 
        //       //size="large"
        //     >
        //       Crea contratto <DownOutlined />
        //     </Button>
        //   </Dropdown>
        // ]}
      >
      </PageHeader>
      <Table
        columns={columns}
        dataSource={list}
        pagination={pagination}
        filters={filters}
        loading={loading}
        reloadList={stateFilter}
        getList={(data) => getOrdersList({data, stateFilter})}
      />
    </div>
  )
}

// OrdersList.PropTypes = {
  
// }

export default OrdersList;