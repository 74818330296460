import React, { useState } from 'react';
//import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { 
  Form, 
  Input, 
  Button,
  Row,
  Col,
  Alert
} from 'antd';
import './Login.css';
import { 
  signInUser, 
  //fetchUserAuthenticated 
} from 'redux/reducers/authReducer';
import logo from 'assets/img/logo-justspeed.svg';


function Login() {

  //const { user, loading, error } = useSelector(state => state.auth)
  const [error, setError] = useState()
  const dispatch = useDispatch()

  const onFinish = async (values) => {
    const result = await dispatch(signInUser(values))
  
    if(result.payload?.status >= 400) {
      setError(result.payload?.message?.email[0])
    }
  };


  return (
    <div className="loginPageContainer">
      <div className="loginImageContainer">
        <div className="loginFeed">
          {/* <h1><strong>Benvenuto</strong> su JUST SPEED Admin</h1> */}
          <p>Per collegarti esegui il login con le credeziali in tuo possesso</p>
        </div>
      </div>
      <div className="loginSidebar">
        <img src={logo} alt="Logo" width="200" style={{marginBottom: 40}} />
        <Form
          name="loginForm"
          layout="vertical"
          onFinish={onFinish}
        >
          <Form.Item
            //label="Username"
            name="username"
            rules={[
              {
                required: true,
                message: 'Campo obbligatorio',
              },
            ]}
          >
            <Input size="large" placeholder="Username"/>
          </Form.Item>

          <Form.Item
            //label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: 'Campo obbligatorio',
              },
            ]}
          >
            <Input.Password size="large" placeholder="Password" />
          </Form.Item>

          {/* <Form.Item {...tailLayout} name="remember" valuePropName="checked">
            <Checkbox>Remember me</Checkbox>
          </Form.Item> */}
          <Row>
            <Col span={24}>
              {
                error && <Alert message={error} type="error" />
              }
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Button 
                type="primary" 
                htmlType="submit" 
                size="large" 
                style={{ marginTop: 16 }}
                block
              >
                ACCEDI
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </div>

  )
}

// Login.PropTypes = {
  
// }

export default Login;