import React, { useState, useEffect } from 'react';
import { Menu } from 'antd';
// import { menuLinks } from 'routes';
// import { authSelectors } from 'redux/selectors';
// import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { checkRole } from 'utils/rbac';

function SideMenu({ 
  routePath,
  menuLinks, 
  userRole,
  mode="inline",
  theme="light"
}) {
  const [selectedKey, setSelectedKey] = useState();
  //const [openKeys, setOpenKeys] = useState();
  
  useEffect(() => {
    var pathArr = menuLinks
      .filter(route => routePath.includes(route.path))
      .reduce((acc,item) => {
        var newarr = [item.key]
        if(item.items) {
            const secondKey = item.items.filter(sub => sub.path === routePath)[0]?.key;
            secondKey && newarr.push(secondKey)
        }
        return newarr
  },[])
    //console.log('pathArr > ',pathArr)
    setSelectedKey([pathArr.join(',')])
    //setOpenKeys(pathArr)
  },[routePath])

  //console.log('SIDEMENU ROUTE > ',routePath, menuLinks, openKeys)
  return (
    <Menu
      mode={mode}
      theme={theme}
      selectedKeys={selectedKey}
      //defaultOpenKeys={['contratti']}
      //inlineCollapsed={false}
    >
      {
        menuLinks
          .filter(({ accessControl }) => checkRole(accessControl, userRole))
          .map(({ label, key, path, icon: Icon, items }) => items && (
            <Menu.SubMenu key={key} title={label} icon={<Icon />}>
              {
                items
                  .filter(({ accessControl: _accessControl }) => checkRole(_accessControl, userRole))
                  .map(({ label: _label, key: _key, path: _path, icon: _Icon }) => (
                    <Menu.Item 
                      //icon={<_Icon />} 
                      key={[key, _key].join('_')}
                    >
                      <Link to={_path}>{_label}</Link>
                    </Menu.Item> )
                  )
              }
            </Menu.SubMenu>  
          ) || (
            <Menu.Item key={key} icon={<Icon />}>
                <Link to={path}>{label}</Link>
            </Menu.Item>  
          ))
      }
    </Menu>
  );
}

export default SideMenu;