import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
  Row,
  Col, 
  Form,
  Input,
  DatePicker
} from 'antd';

//import './step2.css';

function CreditCardFields({ locale }) {
  
  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Form.Item 
            name={"creditCard_owner"}
            label="Intestatario carta" 
            rules={[
              {
                required: true,
                message: 'Campo obbligatorio'
              },
            ]}
          >
            <Input
              size="large"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item 
            name={"creditCard_number"}
            label="Numero carta"
            rules={[
              {
                required: true,
                message: 'Campo obbligatorio'
              },
            ]}
          >
            <Input
              size="large"
            />
          </Form.Item>
        </Col>
      </Row>
      
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Form.Item 
            name={"creditCard_expiration"}
            label="Scadenza carta"
            rules={[
              {
                required: true,
                message: 'Campo obbligatorio'
              },
            ]}
          >
            <DatePicker 
              locale={locale} 
              picker="month" 
              size="large"
              style={{width: '100%'}}
              format={'MM/YYYY'}
              placeholder=""
              disabledDate={(current) => current > moment()}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item 
            name={"creditCard_cvv"}
            label="CVV"
            rules={[
              {
                required: true,
                message: 'Campo obbligatorio'
              },
            ]}
          >
            <Input
              size="large"
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  )
}

// CreditCardFields.defaultProps = {
//   disabled: false
// }

CreditCardFields.propTypes = {
  locale: PropTypes.object
}

export default CreditCardFields;