import React, { 
  useState,
  useEffect
} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Input,
  Col,
  Row,
  Form,
  message
} from 'antd';
import { useHistory } from 'react-router-dom';

import {  
  checkLeadOtp,
  resendLeadOtp
} from 'redux/reducers/contractsReducer';
import { 
  useDispatch 
} from 'react-redux';

function Counter({ seconds, children }) {
  const [time, setTime] = useState(seconds);
  const [isChildrenDisabled,setIsChildrenDisabled] = useState(true);
  //console.log('chid >',children)
  useEffect(() => {
    const interval = time > 0 && 
      setInterval(()=> {
        //console.log('setTime > ',time - 1)
        setTime(time - 1)
      }, 1000) ||
      setIsChildrenDisabled(false)
    
    return () => {
      //console.log('unmount counter')
      //setIsChildrenDisabled(false)
      clearInterval(interval)
    }
  },[time])

  const restartCounter = () => {
    setIsChildrenDisabled(true);
    setTime(seconds);
  }

  // const fn = child => (
  //   React.cloneElement(child, { 
  //     disabledChild: isChildrenDisabled, 
  //     restartCounter: () => restartCounter()
  //   })
  // )

  return (
    <div>
      {/* { React.Children.map(children, fn)} */}
      {children({ 
        disabledChild: isChildrenDisabled, 
        restartCounter: () => restartCounter()
      })}
      {
        time > 0 &&
          <p style={{textAlign: 'center', fontSize: 12}}>Attivo fra {time} secondi</p>
      }
    </div>
  )
}
  
function Verify({ onSuccess }) {
  
  const [otpContract] = Form.useForm();
  const dispatch = useDispatch();
  
  const sendOTP = async (cb) => {
    const result = await dispatch(resendLeadOtp())
    if(result.payload === 200) {
      message.success('SMS inviato con successo!');
      cb();
    }
    else {
      message.error(result.payload.message);
    }
    //setOtpSent(true);
  }

  const onFinish = async (values) => {
    
    const result = await dispatch(checkLeadOtp(values))
    if(result.payload.status !== 403 && result.payload.status !== 422 && result.payload.data) {
      onSuccess()
    }
    else {
      message.error(result.payload.message);
    }
  };


  return (
    <div className="pageContainer">
      <Form
        form={otpContract}
        name="formVerify"
        layout="vertical" 
        onFinish={onFinish}
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Form.Item 
              name="otp"
              label="Inserisci il codice ricevuto"
              rules={[
                {
                  required: true,
                  message: 'Campo obbligatorio'
                }
              ]}
            >
               {/* <Input.Group compact> */}
                <Input 
                  //placeholder="Inserisci il codice ricevuto" 
                  //style={{ width: '70%' }} 
                  size="large"
                />
              {/* </Input.Group> */}
            </Form.Item>
            <Button 
              type="primary" 
              htmlType="submit"
              //style={{ width: '30%' }}
              size="large"
              block
            >
              Conferma
            </Button>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Counter
              seconds={120}
            >{
              ({disabledChild, restartCounter}) => (
                <Button 
                  size="large" 
                  type="link" 
                  onClick={() => {
                    sendOTP(restartCounter);
                  }}
                  disabled={disabledChild}
                  block
                >
                  Invia codice OTP
                </Button>
              )
            }
            </Counter>
          </Col>
        </Row>
      </Form>
    </div>
  )
}
  
Verify.propTypes = {
  onSucess: PropTypes.func
}
  
export default Verify;