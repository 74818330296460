import moment from 'moment';

function convertJsonToFormData(data) {
  const formData = new FormData()
  //const entries = Object.entries(data) // returns array of object property as [key, value]
  
  for(let key in data) {
    if(typeof(data[key]) === 'boolean') {
      //console.log('is boolean PRIMO LIVELLO > ',key, ' > ',~~data[key], data[key], typeof data[key])
      formData.append(key, ~~data[key]);
    }
    else if(typeof(data[key]) === 'object') {
      //console.log(data[key],data[key] instanceof moment)
      if(data[key] instanceof moment) {
        //console.log('primo livello moment > ',data[key],data[key].format('YYYY-MM-DD'))
        formData.append(key, data[key].format('YYYY-MM-DD') || '')
      }
      else {
        for (let subKey1 in data[key]) {
          if(typeof(data[key][subKey1]) === 'boolean') {
            formData.append(`${key}[${subKey1}]`, ~~data[key][subKey1]);
          }
          else if(typeof(data[key][subKey1]) === 'object') {
            //console.log(data[key][subKey1],data[key][subKey1] instanceof moment)
            if(data[key][subKey1] instanceof moment) {
              //console.log('secondo livello moment > ',data[key][subKey1],data[key][subKey1].format('YYYY-MM-dd'))
              formData.append(`${key}[${subKey1}]`, data[key][subKey1].format('YYYY-MM-DD') || '')
            }
            else {
              for (let subKey2 in data[key][subKey1]) {
                if(data[key][subKey1][subKey2] instanceof moment) {
                  //console.log('TERZO livello moment > ',data[key][subKey1],data[key][subKey1][subKey2].format('YYYY-MM-dd') || '')
                  formData.append(`${key}[${subKey1}][${subKey2}]`, data[key][subKey1][subKey2].format('YYYY-MM-DD') || '');
                }
                else {
                  if(typeof(data[key][subKey1][subKey2]) === 'boolean') {
                    formData.append(`${key}[${subKey1}][${subKey2}]`, ~~data[key][subKey1][subKey2]);
                  }
                  else {
                    formData.append(`${key}[${subKey1}][${subKey2}]`, data[key][subKey1][subKey2] || '');
                  } 
                }
              }
            }
          }
          else {
            //console.log('CHECK UNDEFINED > ',data[key][subKey1] || '', data[key][subKey1], typeof data[key][subKey1])
            formData.append(`${key}[${subKey1}]`, data[key][subKey1] || '');
          }
        }
      }
    }
    else {
      formData.append(key, data[key] || '');
    }
  }

  return formData
}

export default convertJsonToFormData;